import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './LineItem.scss'
import { ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  CustomItemCreatingEvent,
  ValueChangedEvent as ValueChangedEventSelectBox,
  InitializedEvent,
} from 'devextreme/ui/select_box'
import Popover, { PopoverRef } from 'devextreme-react/cjs/popover'
import notify from 'devextreme/ui/notify'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { handleSubscriptionMouseOut, handleSubscriptionMouseOver } from '@/routes/quotes/utils'

// eslint-disable-next-line react/display-name
const LineItemExtension = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const { item, csn, creating, readOnly, removeSelf } = props
    const { service } = useQsAdminApiManager()
    const title = item.quoteLineNumber
      ? `${item.action} ${item.quoteLineNumber}`
      : item.opportunityLineItemId
        ? `${item.action} ${item.opportunityLineItemId}`
        : `${item.action}`

    const formRef = useRef<FormRef>(null)
    const subsPopoverRef = useRef<PopoverRef>(null)
    const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<ad_subscription[]>([])
    const [currentSubs, setCurrentSubs] = useState<string | ad_subscription | null | undefined>(item.subscription)
    const [style, setStyle] = useState<string>('line-item')

    const getSubscriptions = async (csnId: number) => {
      const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
        builder.filter(subscription.csn.props.id.eq(csnId))
        builder.filter(subscription.status.eq('Active'))
        builder.select(
          'id',
          'subscriptionId',
          'serialNumber',
          'quantity',
          'productCode',
          'productName',
          'term',
          'endDate',
          'switchType',
        )
      })
      return subscriptionsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          return formRef.current?.instance().validate()
        },
        isDirty() {
          return formRef.current?.instance().option().isDirty
        },
        setDirty(value: boolean) {
          formRef.current?.instance().option('isDirty', value)
        },
      } satisfies ILineItemRef
    }, [])

    //al primo render del componente carico le subs
    useEffect(() => {
      getSubscriptions(Number(csn?.id)).then((subs: ad_subscription[]) => {
        setSubscriptionsDataSource(subs)
      })
    }, [])

    return (
      <>
        <div id={`item-quote-container-${item.quoteLineNumber}`} className={style}>
          <DXForm
            id={`item_form_${item.quoteLineNumber}`}
            key={`item_form_${item.quoteLineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.quoteLineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <SimpleItem>
                <h3 className="title">{title}</h3>
              </SimpleItem>
              {item.operation === '' || item.operation === 'Insert' ? (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Elimina"
                    icon={'trash'}
                    stylingMode={'text'}
                    type="danger"
                    onClick={(e) => {
                      removeSelf()
                      setStyle('line-item deleted')
                    }}
                  />
                </ButtonItem>
              ) : (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Ripristina"
                    icon={'undo'}
                    stylingMode={'text'}
                    type="success"
                    onClick={(e) => {
                      item.operation = ''
                      setStyle('line-item')
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
            <GroupItem colCount={5}>
              <SimpleItem
                colSpan={1}
                dataField="subscription"
                label={{ text: 'Subscription' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', async (e: ClickEvent) => {
                      if (item.subscription) {
                        if (typeof item.subscription === 'object')
                          window.open(`/software/autodesk/${item.subscription.id}`, '_blank')
                        else {
                          const sub = item.subscription
                          const getSub = await service.ad_subscription().query((builder, subsciption) => {
                            builder.filter(subsciption.subscriptionId.eq(sub))
                            builder.select('id')
                          })
                          if (getSub.data.value.length > 0)
                            window.open(`/software/autodesk/${getSub.data.value[0].id}`, '_blank')
                          else notify(`Nessun subcription trovato.`, 'warning', 3000)
                        }
                      } else notify(`Deve essere selezionato un subscription id per poterlo aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentSubs,
                  dataSource: ArraySourceComposer('id', subscriptionsDataSource),
                  placeholder: 'Selezionare subscription',
                  displayExpr: 'subscriptionId',
                  searchEnabled: true,
                  acceptCustomValue: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare subscription...',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || item.opportunityLineItemId || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                  onCustomItemCreating: (e: CustomItemCreatingEvent) => {
                    console.log('CustomItemCreatingEvent', e)
                    if (!e.customItem) e.customItem = e.text
                    setCurrentSubs(e.customItem)
                  },
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log('ValueChangedEventSelectBox', e)
                    if (e.previousValue?.id === e.value?.id) return
                    setCurrentSubs(e.value)
                  },
                  onInitialized: (e: InitializedEvent) => {
                    e.element?.addEventListener('mouseout', (event) =>
                      handleSubscriptionMouseOut(event, e, subsPopoverRef),
                    )
                    e.element?.addEventListener('mouseover', (event) =>
                      handleSubscriptionMouseOver(event, e, subsPopoverRef),
                    )
                  },
                }}
              >
                <RequiredRule message="Subscription obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  disabled: item.operation === 'Delete',
                  type: 'date',
                  pickerType: 'calendar',
                  applyValueMode: 'instantly',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              >
                <RequiredRule message="Data Fine obbligatorio"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && (
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="pricing.unitSRP"
                  label={{ text: 'Prezzo Singolo Prodotto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedSRP"
                  label={{ text: 'Prezzo Totale Prodotti' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedDiscountedSRP"
                  label={{ text: 'Prezzo Totale Prodotti con Sconto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.endUserPrice"
                  label={{ text: 'Prezzo Finale' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
              </GroupItem>
            )}
          </DXForm>
          <Popover position="bottom" ref={subsPopoverRef} />
        </div>
      </>
    )
  },
)

export default LineItemExtension
