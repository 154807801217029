import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { nota_ticket, ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ScrollView } from 'devextreme-react'
import TicketEditorEvents from '@/routes/tickets/TIcketEditor.events'
import NoteView from '@/routes/tickets/notes/NoteView'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'
import DocumentList from '@/components/document/DocumentList'
import { DocumentPermissions } from '@/enums'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useRef } from 'react'

const TicketBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const ticket: ticket = props.data.data
  const documentListRef = useRef<any>(null)
  const { client } = useQsAdminApiManager()

  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'ticket_brief_detail_form'} formData={ticket} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              <Tab title="Note">
                <SimpleItem
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{ readOnly: true }}
                  render={() => {
                    return (
                      <>
                        <ScrollView height={300} width={'100%'} direction="vertical">
                          <div>
                            <div id="chat-window">
                              {ticket?.note_ticket && ticket?.note_ticket?.length > 0
                                ? ticket?.note_ticket
                                    ?.sort((notaA: nota_ticket, notaB: nota_ticket) => {
                                      const dataA: Date = new Date(notaA.data ?? '')
                                      const dataB: Date = new Date(notaB.data ?? '')
                                      return dataB.getTime() - dataA.getTime()
                                    })
                                    .map((nota: nota_ticket) => {
                                      return (
                                        <>
                                          <NoteView note={nota} readOnly={true} visibleEditClick={false}></NoteView>
                                        </>
                                      )
                                    })
                                : 'Nessuna nota associata al ticket'}
                            </div>
                          </div>
                        </ScrollView>
                      </>
                    )
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Motivo">
                <SimpleItem
                  dataField="descrizione"
                  label={{ visible: false }}
                  editorType="dxHtmlEditor"
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    readOnly: true,
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                  }}
                ></SimpleItem>
              </Tab>
              {ticket?.stato?.id === StatoTicket.COMPLETATO && (
                <Tab title="Soluzione">
                  <SimpleItem
                    dataField="soluzione"
                    label={{ visible: false }}
                    editorType="dxHtmlEditor"
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      readOnly: true,
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                <Tab title="Rating">
                  <SimpleItem
                    dataField="rating_note"
                    label={{ visible: false }}
                    editorType="dxHtmlEditor"
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      readOnly: true,
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              <Tab title="Eventi">
                <TicketEditorEvents ticket={ticket}></TicketEditorEvents>
              </Tab>
              <Tab title="Allegati">
                <DocumentList
                  title="Allegati"
                  folderPath={ticket.attachmentsFolderUrl}
                  documentListGetRef={documentListRef}
                  preliminarPermissionsAction={async () => {
                    try {
                      const response = await client.post(
                        '/api/ticket/drivepermissions',
                        {
                          objectId: ticket?.id,
                          path: ticket?.attachmentsFolderUrl,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                      return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
                    } catch (error) {
                      console.log('Errore in settaggio permessi sulla cartella allegati ticket', error)
                      return DocumentPermissions.Deny
                    }
                  }}
                  readOnly={true}
                />
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default TicketBriefDetail
