import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { MeetingCreatorRoute } from '@/routes/meetings/MeetingCreator.route'
import { MeetingEditorRoute } from '@/routes/meetings/MeetingEditor.route'
import MeetingsPage from '@/routes/meetings/MeetingsPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

export const meetingsPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getTipologie = qsAdminApi.tipologia_riunione().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })
  return defer({
    title: 'Riunioni',
    tipologie: await getTipologie,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const MeetingsPageRoute: RoleRoute = {
  path: 'meetings',
  id: 'meetings',
  children: [
    {
      index: true,
      element: <MeetingsPage />,
      loader: meetingsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    MeetingEditorRoute,
    MeetingCreatorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  mainMenuRoute: true,
  text: 'Riunioni',
  faIcon: faUsers,
  handle: {
    crumb: () => ({
      label: 'Riunioni',
      icon: faUsers,
    }),
  },
  allowedRoles: [Roles.Guest],
}
