import ActivitiesPage from '@/routes/activities/ActivitiesPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import ActivityCreatorRoute from '@/routes/activities/ActivityCreator.route'
import ActivityEditorRoute from '@/routes/activities/ActivityEditor.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'

export const activitiesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Attività Commerciali` : 'Attività Commerciali'

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.orderBy(stato.nome.asc())
    builder.select('nome', 'id')
  })

  return defer({
    title,
    aziende: await getAziende,
    stati: await getStati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Supervisor,
    ],
  })
}) satisfies LoaderFunction

export const ActivitiesPageRoute: RoleRoute = {
  path: 'activities',
  children: [
    {
      index: true,
      element: <ActivitiesPage />,
      loader: activitiesPageRouteLoader,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.Marketing,
        Roles.Supervisor,
        Roles.ExternalSales,
      ],
    } as RoleRouteObject,
    ActivityCreatorRoute,
    ActivityEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Attività Commerciali',
  faIcon: faBookmark,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Attività Commerciali',
      icon: faBookmark,
    }),
  },
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
    Roles.ExternalSales,
  ],
}
