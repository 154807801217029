import { Form as DxForm, GroupItem, SimpleItem } from 'devextreme-react/form'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { ChildFormHandle } from '@/routes/admin/users/user/forms/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import UserStyles from '@/routes/admin/users/user/User.module.scss'
import { HashLoader } from 'react-spinners'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { user } from '@/model/qsadminapi/QsAdminApiModuleModel'

export type BasicUserDataProps = {
  userId: number
}

export const BasicUserData = forwardRef<ChildFormHandle, BasicUserDataProps>((props, ref) => {
  const qsadminapiService = useQsAdminApiManager()
  const formRef = useRef<HTMLFormElement>(null)

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current?.requestSubmit()
    },
  }))

  const fetcher = useQuery({
    queryKey: ['basicUserData', props.userId],
    queryFn: () => {
      return qsadminapiService.service.user(props.userId).query()
    },
  })
  const patcher = useMutation({
    mutationFn: (formData: user) => {
      return qsadminapiService.service.user(props.userId).patch(formData)
    },
  })

  if (fetcher.isLoading)
    return (
      <div className={UserStyles.loaderWrapper}>
        <HashLoader loading={fetcher.isLoading} size={100} color={'#ff5722'} />
      </div>
    )
  if (fetcher.error) return <RouterErrorHandler />

  return (
    <>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault()
          if (fetcher.data?.data) patcher.mutate(fetcher.data?.data)
        }}
      >
        <DxForm formData={fetcher.data?.data}>
          <GroupItem caption="Dati Utente" colCount={4}>
            <GroupItem colSpan={4} colCount={5}>
              <SimpleItem
                dataField={'id'}
                label={{
                  text: 'ID',
                }}
                colSpan={1}
                editorOptions={{
                  disabled: true,
                }}
              />
              <SimpleItem
                dataField={'cognome'}
                colSpan={2}
                label={{
                  text: 'Cognome',
                }}
              />
              <SimpleItem
                dataField={'nome'}
                colSpan={2}
                label={{
                  text: 'Nome',
                }}
              />
            </GroupItem>
            <GroupItem colSpan={4} colCount={2}>
              <SimpleItem
                dataField={'email'}
                label={{
                  text: 'Email',
                }}
              />
              <SimpleItem
                dataField={'email_ad'}
                label={{
                  text: 'Email AD',
                }}
              />
            </GroupItem>
            <GroupItem colSpan={2} caption="Impostazioni utente" colCount={2}>
              <SimpleItem
                dataField={'attivo'}
                label={{
                  text: 'Attivo',
                  location: 'top',
                  alignment: 'left',
                }}
                editorType={'dxSwitch'}
              />
              <SimpleItem
                dataField={'contractor'}
                label={{
                  text: 'Contractor',
                  location: 'top',
                  alignment: 'left',
                }}
                editorType={'dxSwitch'}
              />
            </GroupItem>
            <SimpleItem
              colSpan={2}
              dataField={'note'}
              label={{
                text: 'Note',
              }}
              editorType={'dxTextArea'}
              editorOptions={{
                height: 100,
              }}
            />
          </GroupItem>
        </DxForm>
      </form>
    </>
  )
})

BasicUserData.displayName = 'BasicUserData'
