import { forwardRef, useImperativeHandle } from 'react'
import { Column, DataGrid, FilterRow, Selection } from 'devextreme-react/data-grid'
import { TextEditorButton, ValidationRule } from 'devextreme/common'
import { ArraySourceComposer } from '@/auth/api/connector'
import { DropDownBox, Validator } from 'devextreme-react'
import { DropDownBoxTypes } from 'devextreme-react/cjs/drop-down-box'
import { ValidationResult } from 'devextreme/ui/validation_group'
import { DropDownPredefinedButton } from 'devextreme/ui/drop_down_editor/ui.drop_down_editor'

interface DropDownBoxDataGridItemProps {
  object: any
  values: any
  label: string
  displayExprField: string | ((item: any) => string)
  formValidation: string
  columns: { dataField: string; caption?: string; width?: string }[]
  onChange: (value: any) => void
  readOnly?: boolean
  buttons?: (DropDownPredefinedButton | TextEditorButton)[] | undefined
  validationRules?: ValidationRule[] | undefined
}

export type IDropDownBoxDataGridItemRef = {
  validate: () => ValidationResult | undefined
}
// eslint-disable-next-line react/display-name
const DropDownBoxDataGridItem = forwardRef<IDropDownBoxDataGridItemRef, DropDownBoxDataGridItemProps>(
  (props: DropDownBoxDataGridItemProps, ref: React.ForwardedRef<IDropDownBoxDataGridItemRef>) => {
    const onSelectionChanged = (e: any) => {
      const value = e.selectedRowsData[0]
      props.onChange(value)
    }
    const btns: (DropDownPredefinedButton | TextEditorButton)[] | undefined = []
    if (props.buttons) for (const btn of props.buttons) btns.push(btn)

    btns.push({ name: 'clear', location: 'after' }, { name: 'dropDown', location: 'after' })

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          let validationResult: ValidationResult | undefined
          return validationResult
        },
      } satisfies IDropDownBoxDataGridItemRef
    }, [])

    return (
      <DropDownBox
        dataSource={ArraySourceComposer('id', props.values)}
        value={props.object}
        displayExpr={props.displayExprField}
        placeholder={`Seleziona ${props.label}...`}
        onValueChanged={(e: DropDownBoxTypes.ValueChangedEvent) => {
          if (!e.value) {
            console.log('e', e)
            props.onChange(null)
          }
        }}
        showClearButton={true}
        dropDownOptions={{ resizeEnabled: true, width: 'auto', maxHeight: 300 }}
        readOnly={props.readOnly}
        buttons={btns}
      >
        <DataGrid
          dataSource={props.values}
          keyExpr="id"
          onSelectionChanged={onSelectionChanged}
          width={'auto'}
          height={'auto'}
        >
          <Selection mode="single" />
          <FilterRow visible={true}></FilterRow>
          {props.columns.map((column, index) => {
            return (
              <Column
                key={index}
                dataField={column.dataField}
                caption={column.caption ?? column.dataField.toUpperCase()}
                width={column.width ?? 'auto'}
              ></Column>
            )
          })}
        </DataGrid>
        <Validator validationGroup={props.formValidation} validationRules={props.validationRules}></Validator>
      </DropDownBox>
    )
  },
)

export default DropDownBoxDataGridItem
