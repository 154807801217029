import { RouteFunctionParams, TileRoleRoute } from '@/types'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '@/auth/azure/Roles'
import { User } from './User'
import { LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const userRouteLoader = (async ({ request, params }: RouteFunctionParams<'userId'>) => {
  const apiService = useQsAdminApiManager.getState().service
  try {
    const userData = await apiService.user(Number(params.userId)).query((builder) => {
      builder.select('id', 'fullname')
      builder.expanding('info', (expander) => {
        expander.select('id')
      })
    })
    return {
      user: userData,
    }
  } catch {
    throw new Response('Utente non trovato', { status: 404 })
  }
}) satisfies LoaderFunction

export const UserRoute = {
  path: ':userId',
  text: 'User Management',
  element: <User />,
  mainMenuRoute: false,
  faIcon: faUser,
  loader: userRouteLoader,
  errorElement: <RouterErrorHandler />,
  handle: {
    crumb: (match) => {
      return {
        label: match.data ? match.data.user.data.fullname : 'Utente sconosciuto',
      }
    },
  },
  tile: false,
  allowedRoles: [Roles.GlobalAdministrator],
} satisfies TileRoleRoute<typeof userRouteLoader>
