import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense, useRef, useState } from 'react'
import { Await, useLoaderData } from 'react-router-typesafe'
import { Editing, Column, StateStoring } from 'devextreme-react/tree-list'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { DataGrid, Lookup } from 'devextreme-react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import { Paging } from 'devextreme-react/cjs/data-grid'
import { causalsEditorRouteLoader } from '@/routes/costcenters/causals/CausalsEditor.route'
import { centro_costo } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { LookupRef } from 'devextreme-react/lookup'
import DocumentHead from '@/components/document-head/DocumentHead'

const CausalsEditor = () => {
  const { title, getCentriCosto } = useLoaderData<typeof causalsEditorRouteLoader>()
  const token = useTokenRefresh()

  const [currentFilter, setCurrentFilter] = useState<any[]>([])

  const refCentroCosto = useRef<LookupRef>(null)

  const causalsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/causale_centro_costo`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    expand: ['centro_costo'],
    filter: currentFilter?.length > 0 ? currentFilter : [['id', null]],
    requireTotalCount: true,
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={getCentriCosto}>
          {(centriCosto) => (
            <>
              <h2 className={'content-block'}>{title}</h2>
              <div id="editor-causals-cost-centers-container" className={'content-block dx-card responsive-paddings'}>
                <Lookup
                  ref={refCentroCosto}
                  dataSource={ArraySourceComposer('id', [
                    ...new Map(
                      centriCosto.data.value.map((centroCosto: centro_costo) => [centroCosto['nome'], centroCosto]),
                    ).values(),
                  ])}
                  searchEnabled={true}
                  placeholder="Centro di Costo"
                  displayExpr="nome"
                  showClearButton={true}
                  dropDownCentered={true}
                  dropDownOptions={{ showTitle: true, title: 'Centro di Costo', hideOnOutsideClick: true }}
                  onValueChanged={(e: ValueChangedEvent) => {
                    if (e.value)
                      if (e.value?.id === e.previousValue?.id) return
                      else {
                        setCurrentFilter([['centro_costo/id', e.value.id]])
                      }
                    else setCurrentFilter([['id', null]])
                  }}
                />
                <DataGrid
                  id={`causals-cost-centers-editor-datagrid`}
                  dataSource={causalsDataSource}
                  noDataText="Nessuna causale trovata"
                  keyExpr={'id'}
                  showBorders={true}
                  showColumnLines={true}
                  focusedRowEnabled={true}
                  defaultFocusedRowIndex={0}
                  columnHidingEnabled={false}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  rowAlternationEnabled={true}
                  wordWrapEnabled={false}
                  repaintChangesOnly={true}
                  width="100%"
                  onSaving={(e: SavingEvent) => {
                    console.log('SAVING', e)
                  }}
                  onInitNewRow={(e: InitNewRowEvent) => {
                    e.data.centro_costo = refCentroCosto.current?.instance().option().selectedItem
                  }}
                >
                  <StateStoring enabled={true} type="localStorage" storageKey={'causals-datagrid'} savingTimeout={50} />
                  <Paging defaultPageSize={20} />
                  <Editing
                    mode="batch"
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    selectTextOnEditStart={true}
                    startEditAction={'click'}
                  />
                  <Column dataField={'nome'} caption={'NOME'} visible={true} />
                  <Column dataField={'note'} caption={'NOTE'} visible={true} />
                </DataGrid>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default CausalsEditor
