import React from 'react'
import { ManagementGrid } from './ManagementGrid'
import { ManagementGridExtendedColumn } from './types'
import { References } from './References'
import { DataGridTypes } from 'devextreme-react/data-grid'

/**
 * Simplified props for the "factory."
 * This will show a main grid for the "stateEntity" (like stato_analisi),
 * and a references column that fetches child items from "referencesCollection."
 */
interface ManagementGridFactoryProps {
  entity: string
  referencesCollection: string
  referencesTitle: string
  displayField?: string
  entityRoute?: string
}

export function ManagementGridFactory({
  entity,
  referencesCollection,
  referencesTitle,
  displayField = 'ded_Dis',
  entityRoute,
}: ManagementGridFactoryProps) {
  const columns: ManagementGridExtendedColumn[] = [
    {
      dataField: 'id',
      caption: 'ID',
      dataType: 'number',
      allowEditing: false,
      width: 60,
    },
    {
      dataField: 'nome',
      caption: 'Nome',
      dataType: 'string',
      allowEditing: true,
    },
    {
      caption: referencesTitle,
      dataType: 'object',
      allowEditing: false,
      allowSorting: false,
      cellRender: (cell: DataGridTypes.Row) => (
        <References
          parentEntityPath={entity}
          childCollectionName={referencesCollection}
          childDisplayField={displayField}
          childSelectFields={['id', displayField]}
          entityRoute={entityRoute}
          parentId={cell.data.id}
          maxCount={4}
        />
      ),
    },
  ]

  return (
    <div className="content-block dx-card wide-card">
      <ManagementGrid
        endpoint={`${import.meta.env.VITE_QSADMINAPI_HOST}/${entity}`}
        title={`${referencesTitle}`}
        columns={columns}
        expandRelations={[]}
        storageKey={`${entity}-state-management-grid`}
        defaultPageSize={20}
        allowAdding={true}
        allowUpdating={true}
        allowDeleting={true}
      />
    </div>
  )
}
