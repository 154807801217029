import classes from './TileSectionHeader.module.css'
import { RoleRoute } from '@/types'

export const TileSectionHeader = (item: RoleRoute) => {
  console.log(item)
  return (
    <div className={classes.tileSectionHeader}>
      <span>{item.text}</span>
    </div>
  )
}
