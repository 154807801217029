import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { offerta, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import OfferEditor from '@/routes/offers/OfferEditor'
import { StatoOfferta, TemplateOfferta } from '@/routes/offers/OfferEditor.enums'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { Qanalisi, Qcommessa, Qofferta } from '@/model/qsadminapi/QQsAdminApiModule'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'

export const offerEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'offerId' | 'clientId'>) => {
  const offerId = params.offerId
  const clientId = params.clientId
  const analysisId = new URL(request.url).searchParams.get('analysisId')
  const projectId = new URL(request.url).searchParams.get('projectId')

  const qsAdminApi = useQsAdminApiManager.getState().service

  const userInfo = await getAzureUserInformation()

  const user = await qsAdminApi.user().query((builder, user) => {
    builder.select('id', 'fullname', 'email', 'azienda')
    builder.expanding('azienda', (riferimentiBuilder, azienda) => {
      riferimentiBuilder.select('id', 'nome')
    })
    builder.filter(user.email.eq(userInfo?.email ?? ''))
  })

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
        gruppo_aziendaleBuilder.select('id', 'nome')
      })
      builder.select('id', 'nome', 'gruppo_aziendale')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``
  let offer: offerta

  if (offerId) {
    //editor
    const offerData = await qsAdminApi
      .offerta(Number(offerId))
      .query((builder, offerta) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
            })
            aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppoAziendaleBuilder, gruppo_aziendale) => {
              gruppoAziendaleBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore', 'gruppo_aziendale')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('riferimenti', (riferimentiBuilder, contatto) => {
          riferimentiBuilder.select('id', 'fullname')
          riferimentiBuilder.orderBy(contatto.fullname.asc())
        })
        builder.expanding('riferimenti_entita', (riferimentiEntitaBuilder, contatto) => {
          riferimentiEntitaBuilder.select('id', 'nome')
          riferimentiEntitaBuilder.orderBy(contatto.nome.asc())
        })
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('autore', (autoreBuilder, impiegato) => {
          autoreBuilder.select('id', 'fullname')
        })
        builder.expanding('tipologia_pagamento', (tipologiaPagamentoBuilder, tipologia) => {
          tipologiaPagamentoBuilder.select('id', 'nome')
        })
        builder.expanding('analisi', (analisiBuilder, analisi) => {
          analisiBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'titolo', 'ded_Dis')
        })
        builder.expanding('ordini', (ordiniBuilder, ordine) => {
          ordiniBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('template', (templateBuilder, template) => {
          templateBuilder.select('id', 'nome')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Offerta non trovata')
        throw new Error()
      })
    offer = offerData.data satisfies offerta
    title += `${offer.ded_Dis}`
    if (clientId && offer?.sede?.azienda && offer?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'offerta ${offer.ded_Dis} appartiene al cliente ${offer?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += `Nuova offerta`
    //creator a partire da analisi quidi recupero analisi
    const analysisData = analysisId
      ? await qsAdminApi.analisi(Number(analysisId)).query((builder, analysis) => {
          builder.select('id', 'ded_Dis')
        })
      : undefined

    //creator a partire da commessa
    const projectData = projectId
      ? await qsAdminApi.commessa(Number(projectId)).query((builder, project) => {
          builder.select('id', 'ded_Dis', 'titolo')
        })
      : undefined

    const sedeData =
      analysisData?.data || projectData?.data
        ? await qsAdminApi.sede().query((sedeBuilder, sede) => {
            if (analysisData?.data)
              sedeBuilder.filter(sede.analisi.any((analisi: Qanalisi) => analisi.id.eq(analysisData?.data.id)))
            if (projectData?.data)
              sedeBuilder.filter(sede.commesse.any((commessa: Qcommessa) => commessa.id.eq(projectData?.data.id)))
            sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
              aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
                sediAziendaSedeBuilder.select('id', 'nome', 'note')
                sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
              })
              aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
                rivenditoreBuilder.select('id', 'nome')
              })
              aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppoAziendaleBuilder, gruppo_aziendale) => {
                gruppoAziendaleBuilder.select('id', 'nome')
              })
              aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore', 'gruppo_aziendale')
            })
            sedeBuilder.select('id', 'nome', 'azienda', 'note')
          })
        : undefined

    const riferimentiData = analysisData?.data
      ? await qsAdminApi.contatto_aziendale().query((contattoBuilder, contatto) => {
          contattoBuilder.filter(
            contatto.analisi_assegnate.any((analisi: Qanalisi) => analisi.id.eq(analysisData?.data.id)),
          )
          contattoBuilder.select('id', 'fullname')
        })
      : undefined

    const riferimentiEntitaData = analysisData?.data
      ? await qsAdminApi.entita_aziendale().query((entitaBuilder, entita) => {
          entitaBuilder.filter(
            entita.analisi_assegnate.any((analisi: Qanalisi) => analisi.id.eq(analysisData?.data.id)),
          )
          entitaBuilder.select('id', 'nome')
        })
      : undefined

    const statoDefaultData = await qsAdminApi.stato_offerta(StatoOfferta.APERTA).query((builder, stato) => {
      builder.select('id', 'nome')
    })

    const templateDefaultData = await qsAdminApi.template_offerta(TemplateOfferta.QS).query((builder, template) => {
      builder.select('id', 'nome')
    })

    offer = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Rev: '',
      ded_Id: 0,
      revisione: 0,
      data_creazione: new Date().toISOString(),
      note: null,
      anno_rif: new Date().getFullYear(),
      data_invio: null,
      creazione_automatica: null,
      stato: statoDefaultData.data,
      autore: user.data.value[0],
      sede: sedeData?.data.value[0] ?? null,
      riferimenti: riferimentiData?.data.value ?? [],
      riferimenti_entita: riferimentiEntitaData?.data.value ?? [],
      analisi: analysisData?.data ?? null,
      commessa: projectData?.data ?? null,
      attachmentsFolderUrl: '',
      mainDocumentUrl: '',
      template: templateDefaultData.data,
      attivitaTecnicaRichiesta: false,
    }
  }

  //query di popolazione lookup
  const getPersone = offer.sede
    ? qsAdminApi.contatto_aziendale().query((builder, persona) => {
        builder.filter(persona.sede.props.id.eq(Number(offer.sede?.id)))
        builder.filter(
          persona.attivo
            .eq(true)
            .or(offer.riferimenti ? persona.offerte.any((offerta: Qofferta) => offerta.id.eq(offer.id)) : null),
        )
        builder.expanding('emails', (emailsBuilder, email) => {
          emailsBuilder.select('id', 'email')
        })
        builder.select('id', 'fullname', 'emails')
        builder.orderBy(persona.fullname.asc())
      })
    : undefined
  const getEntita = offer.sede
    ? qsAdminApi.entita_aziendale().query((builder, entita) => {
        builder.filter(entita.sede.props.id.eq(Number(offer.sede?.id)))
        builder.expanding('emails', (emailsBuilder, email) => {
          emailsBuilder.select('id', 'email')
        })
        builder.select('id', 'nome', 'emails')
        builder.orderBy(entita.nome.asc())
      })
    : undefined
  const getAnalisi =
    offer.sede || clientId
      ? qsAdminApi.analisi().query((builder, analisi) => {
          if (offer.sede?.azienda?.gruppo_aziendale?.id)
            builder.filter(
              analisi.sede.props.azienda.props.gruppo_aziendale.props.id.eq(offer.sede.azienda.gruppo_aziendale.id),
            )
          else if (azienda?.data.gruppo_aziendale?.id)
            builder.filter(
              analisi.sede.props.azienda.props.gruppo_aziendale.props.id.eq(azienda?.data.gruppo_aziendale?.id),
            )
          else
            builder.filter(analisi.sede.props.azienda.props.id.eq(Number(offer.sede?.azienda?.id || azienda?.data.id)))
          builder.filter(
            analisi.stato.props.id
              .eq(StatoAnalisi.COMPLETATA)
              .or(offer.analisi ? analisi.id.eq(Number(offer.analisi?.id)) : null),
          )
          builder.expanding('sede', (sedeBuilder) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda')
          })
          builder.select('id', 'ded_Dis', 'sede')
          builder.orderBy(analisi.ded_Dis.desc())
        })
      : undefined
  const getCommesse =
    offer.sede || clientId
      ? qsAdminApi.commessa().query((builder, commessa) => {
          if (offer.sede?.azienda?.gruppo_aziendale?.id)
            builder.filter(
              commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(offer.sede.azienda.gruppo_aziendale.id),
            )
          else if (azienda?.data.gruppo_aziendale?.id)
            builder.filter(
              commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(azienda?.data.gruppo_aziendale?.id),
            )
          else
            builder.filter(commessa.sede.props.azienda.props.id.eq(Number(offer.sede?.azienda?.id || azienda?.data.id)))
          builder.filter(
            commessa.stato.props.id
              .eq(StatoCommessa.APERTA)
              .or(offer.commessa ? commessa.id.eq(Number(offer.commessa?.id)) : null),
          )
          builder.expanding('sede', (sedeBuilder) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda')
          })
          builder.select('id', 'ded_Dis', 'titolo', 'sede')
          builder.orderBy(commessa.ded_Dis.desc())
        })
      : undefined

  const getStati = qsAdminApi.stato_offerta().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getTemplates = qsAdminApi.template_offerta().query((builder, template) => {
    builder.select('id', 'nome')
    builder.orderBy(template.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    if (analysisId || projectId)
      builder.filter(
        offer.sede?.azienda?.gruppo_aziendale
          ? azienda.gruppo_aziendale.props.id.eq(Number(offer.sede.azienda.gruppo_aziendale.id))
          : offer.sede
            ? azienda.id.eq(Number(offer.sede.azienda?.id))
            : null,
      )
    else builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    if (userInfo?.roles?.hasRole(Roles.Sales))
      builder.filter(
        azienda.agente.props.commerciale_qs.props.id
          .eq(Number(user.data.value[0].id))
          .or(offer.sede ? azienda.id.eq(Number(offer.sede.azienda?.id)) : null),
      )
    if (userInfo?.roles?.hasRole(Roles.ExternalSales))
      builder.filter(
        azienda.rivenditore.props.id
          .eq(Number(user.data.value[0].azienda?.id))
          .or(offer.sede ? azienda.id.eq(Number(offer.sede.azienda?.id)) : null),
      )
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    title,
    offer,
    getAziende,
    getStati,
    getQsImpiegati,
    getPersone,
    getEntita,
    getAnalisi,
    getCommesse,
    getTemplates,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const offerEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const offer = (await request.json()) as offerta
  console.log('offerta', offer)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.offerta().create(offer)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.offerta(offer.id).update(offer)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const OfferEditorRoute = {
  path: ':offerId',
  element: <OfferEditor creating={false} />,
  loader: offerEditorRouteLoader,
  action: offerEditorRouteAction,
  allowedRoles: [
    Roles.Sales,
    Roles.ExternalSales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
  ],
} as RoleRouteObject

export default OfferEditorRoute
