import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { stato_task, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { onInitializedHtmlEditor, onValueChangedHtmlEditor, toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import {
  ButtonItem,
  ButtonOptions,
  GroupItem,
  SimpleItem,
  Form as DXForm,
  FormRef,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form'
import { ValidationCallbackData } from 'devextreme/common'
import { ClickEvent } from 'devextreme/ui/button'
import React, { useRef } from 'react'
import { FetcherWithComponents } from 'react-router-dom'

const FormPopupClientNote = (props: {
  task: task | null | undefined
  stati: stato_task[]
  refPopup: React.RefObject<PopupRef>
  fetcher?: FetcherWithComponents<any>
}) => {
  const currentScreenSize = useScreenSize()
  const { task, stati, refPopup, fetcher } = props
  const { client } = useQsAdminApiManager()
  const formRef = useRef<FormRef>(null)
  const closingStates = new Set(['COMPLETATO', 'ANNULLATO'])

  return (
    <>
      <Popup
        ref={refPopup}
        id="complete_task_popup"
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Chiusura Task`}
        container=".dx-viewport"
        resizeEnabled={false}
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 800 : currentScreenSize.isXSmall ? 350 : 700}
        height={'auto'}
      >
        <DXForm
          id={'complete_task_form'}
          formData={task}
          ref={formRef}
          labelLocation={'top'}
          colCount={1}
          showValidationSummary={true}
          validationGroup="CloseTaskValidationGroup"
        >
          <GroupItem colCount={1}>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer(
                  'id',
                  stati.filter((state_task: stato_task) => closingStates.has(state_task.nome!)),
                ),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Stato obbligatorio" />
              <CustomRule
                message={'Lo stato del ticket deve essere impostato come COMPLETATO o ANNULLATO'}
                validationCallback={({ value }: ValidationCallbackData) => {
                  return (
                    value !== null && value !== undefined && (value.nome === 'COMPLETATO' || value.nome === 'ANNULLATO')
                  )
                }}
              ></CustomRule>
            </SimpleItem>
            <SimpleItem
              dataField="note_completamento"
              label={{ text: 'Note Chiusura' }}
              editorType="dxHtmlEditor"
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: 300,
                onValueChanged: onValueChangedHtmlEditor,
                onInitialized: onInitializedHtmlEditor,
              }}
            >
              <RequiredRule message="Note chiusura obbligatorie"></RequiredRule>
            </SimpleItem>
          </GroupItem>
          <GroupItem visible={true} cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="check"
                  text="Chiudi Task"
                  width="150px"
                  onClick={async (e: ClickEvent) => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    fetcher?.submit(
                      JSON.stringify({
                        taskId: task?.ded_Dis,
                        note: task?.note_completamento,
                        status: task?.stato?.nome,
                      }),
                      {
                        method: 'POST',
                        action: `/tasks/${task?.id}/close`,
                        encType: 'application/json',
                      },
                    )
                    refPopup.current?.instance().hide()
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

export default FormPopupClientNote
