import React, { useEffect, useState } from 'react'
import { azienda, contatto_aziendale, entita_aziendale, rapporto, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Popup } from 'devextreme-react'
import { useScreenSize } from '@/themes/media-query'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'

import ContactEditorForm from '@/routes/contacts/ContactEditor.form'

export type FormPopupContactProps = {
  popupRef: React.RefObject<PopupRef>
  contact: contatto_aziendale | undefined
  azienda?: azienda | null
  migrating: boolean
  onContactSaved: (newContact: contatto_aziendale | entita_aziendale | undefined) => void
}

export const FormPopupContact = (props: FormPopupContactProps) => {
  const { contact, azienda, popupRef, migrating, onContactSaved } = props

  const currentScreenSize = useScreenSize()
  const { service } = useQsAdminApiManager()
  const [aziendeList, setAziendeList] = useState<azienda[]>([])
  const [rapportiList, setRapportiList] = useState<rapporto[]>([])
  const [sediList, setSediList] = useState<sede[]>([])
  const [newContact, setNewContact] = useState<contatto_aziendale>()

  const getAziende = async () => {
    const aziendeData = await service.azienda().query((builder, azienda) => {
      builder.filter(azienda.sedi.any())
      builder.orderBy(azienda.nome.asc())
      builder.select('id', 'nome')
    })
    return aziendeData.data.value
  }

  const getRapporti = async () => {
    const rapportiData = await service.rapporto().query((builder, rapporto) => {
      builder.orderBy(rapporto.nome.asc())
      builder.select('id', 'nome')
    })
    return rapportiData.data.value
  }

  //primo render del componente
  useEffect(() => {
    getAziende().then((aziende: azienda[]) => {
      setAziendeList(aziende)
    })
    getRapporti().then((rapporti: rapporto[]) => {
      setRapportiList(rapporti)
    })
  }, [])

  useEffect(() => {
    const newC: contatto_aziendale = {
      id: migrating ? Number(contact?.id) : 0,
      fullname: null,
      hasLinkedObjects: false,
      ad_recipient: false,
      nome: contact?.nome ?? null,
      cognome: contact?.cognome ?? null,
      ruolo: contact?.ruolo ?? null,
      note: contact?.note ?? null,
      titolo: contact?.titolo ?? null,
      telefono: null,
      attivo: true,
      rapporto: contact?.rapporto,
      sede: migrating ? null : contact?.sede,
      emails: [
        {
          id: 0,
          email: null,
          note: null,
          rif_ad: false,
        },
      ],
      ricezione_rapportino: false,
    }
    setNewContact(newC)
    setSediList(migrating ? [] : contact?.sede ? [contact.sede] : [])
  }, [contact, migrating])

  return (
    <>
      <Popup
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 900 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isSmall ? '350' : 'auto'}
        hideOnOutsideClick={true}
        title={migrating ? `Migra contatto` : `Crea contatto`}
        showCloseButton={true}
        deferRendering={true}
        ref={popupRef}
        onHiding={() => {}}
        resizeEnabled={false}
      >
        <div id="person-migrate-selector-form-container" className={'dx-card responsive-paddings'}>
          <ContactEditorForm
            contact={newContact}
            rapporti={rapportiList}
            aziende={aziendeList}
            sedi={sediList}
            azienda={azienda}
            creating={true}
            migrating={migrating}
            onDataSaved={(newContact) => {
              onContactSaved(newContact)
            }}
          ></ContactEditorForm>
        </div>
      </Popup>
    </>
  )
}

export default FormPopupContact
