import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { ProjectsPageRoute } from '@/routes/projects/ProjectsPage.route'

export const ProjectsRoute: TileRoleRoute = {
  path: 'projects',
  text: 'Commesse',
  faIcon: faProjectDiagram,
  element: (
    <ManagementGridFactory
      entity={'stato_commessa'}
      referencesCollection={'commesse'}
      referencesTitle={'Commesse'}
      displayField={'ded_Dis'}
      entityRoute={ProjectsPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Commesse',
      icon: faProjectDiagram,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
