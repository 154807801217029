import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { MeetingsPageRoute } from '@/routes/meetings/MeetingsPage.route'

export const MeetingRoute: TileRoleRoute = {
  path: 'meeting',
  text: 'Riunione',
  faIcon: faCalendarCheck,
  element: (
    <ManagementGridFactory
      entity="tipologia_riunione"
      referencesCollection="riunioni"
      referencesTitle="Riunioni"
      displayField="ded_Dis"
      entityRoute={MeetingsPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Riunione',
      icon: faCalendarCheck,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
