import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { SoftwarePageRoute } from '@/routes/software/SoftwarePage.route'
import { SoftwareAltrePiattaformeRoute } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeGrid.route'

export const ExternalLicenseRoute: TileRoleRoute = {
  path: 'externalLicense',
  text: 'Licenza Esterna',
  faIcon: faIdCard,
  element: (
    <ManagementGridFactory
      entity={'est_stato_noleggio'}
      referencesCollection={'licenze_est'}
      referencesTitle={'Licenze Esterne'}
      displayField={'id'}
      entityRoute={`${SoftwarePageRoute.path}/${SoftwareAltrePiattaformeRoute.path}`}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Licenza Esterna',
      icon: faIdCard,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
