import { SingleCard } from '@/layouts'
import { Login, MgtTemplateProps } from '@microsoft/mgt-react'
import { RequiredRule } from 'devextreme-react/cjs/form'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { useState, useRef } from 'react'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Form as DXForm, Item, Label, ButtonItem, ButtonOptions, EmailRule } from 'devextreme-react/form'
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react'
import './LoginComponent.scss'

// Template for the signed-out button content
const SignedOutButtonContent = (props: MgtTemplateProps) => (
  <div className="dx-style-login-button">
    <img src="/icons/microsoft-logo.svg" alt="Microsoft logo" className="ms-logo" />
    <span>Accesso QS Informatica</span>
  </div>
)

const LoginComponent: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })

  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to={`${redirectTo ?? '/dashboard'}`} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SingleCard title={`Login`} description={'Accesso utenti Qs Web Admin'} logo={'https://tinyurl.com/25sgqoyx'}>
          <div>
            <form className={'login-form'}>
              <DXForm formData={formData.current} disabled={loading}>
                <Item dataField={'email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
                  <RequiredRule message="Email is required" />
                  <EmailRule message="Email is invalid" />
                  <Label visible={false} />
                </Item>
                <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                  <RequiredRule message="Password is required" />
                  <Label visible={false} />
                </Item>
                <Item dataField={'rememberMe'} editorType={'dxCheckBox'} editorOptions={rememberMeEditorOptions}>
                  <Label visible={false} />
                </Item>
                <ButtonItem>
                  <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                    <span className="dx-button-text">
                      {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Sign In'}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <Item>
                  <div className={'link'}>
                    <Link to={'/reset-password'}>Forgot password?</Link>
                  </div>
                </Item>
                <ButtonItem>
                  <ButtonOptions
                    text={'Crea un account'}
                    width={'100%'}
                    disabled
                    visible={false}
                    onClick={() => navigate('/signup')}
                  />
                </ButtonItem>
              </DXForm>
            </form>

            <div className="mgt-login-wrapper">
              <Login
                className="devextreme-styled-login"
                showPresence={false}
                title="Accesso per dipendenti QS Informatica utilizzando il sistema di login aziendale"
              >
                <SignedOutButtonContent template="signed-out-button-content" />
              </Login>
            </div>
          </div>
        </SingleCard>
      </UnauthenticatedTemplate>
    </>
  )
}

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
}
const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
}
const rememberMeEditorOptions = {
  text: 'Remember me',
  elementAttr: { class: 'form-text' },
}

export default LoginComponent
