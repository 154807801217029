import { useNavigate, useRevalidator } from 'react-router-dom'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useRef, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { ArraySourceComposer } from '@/auth/api/connector'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { AxiosError, AxiosResponse } from 'axios'
import notify from 'devextreme/ui/notify'
import { MeetingEditorFormProps } from '@/routes/meetings/MeetingEditor.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { isUserRoleAllowed } from '@/routes/utils'

const MeetingEditorForm = (props: MeetingEditorFormProps) => {
  const { creating, meeting, meetingTypes, employees, verbaleGetRef, isRoleAllowed, fetcher } = props
  const { client } = useQsAdminApiManager()
  const navigate = useNavigate()

  const formRef = useRef<FormRef>(null)
  const revalidate = useRevalidator()

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const handleOnClickSave = (e: ClickEvent) => {
    const validationResult = formRef.current?.instance().validate()
    if (!validationResult?.isValid) return
    console.log('validation ok')
    console.log(meeting)
    try {
      if (meeting) {
        fetcher?.submit(JSON.stringify(meeting), {
          method: creating ? 'POST' : 'PUT',
          encType: 'application/json',
        })
      } else console.error('no data tu submit')
    } finally {
      verbaleGetRef.current?.refresh(true)
    }
  }
  const handleOnClickNuovaRev = (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/riunione/createrevision', meeting.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        navigate(`../${response.data.id}`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidate.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'meeting_form'}
        formData={meeting}
        validationGroup="meetingDataValidation"
        ref={formRef}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              dataField="tipologia"
              label={{ text: 'Tipologia' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', meetingTypes),
                placeholder: 'Seleziona tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipologia',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Tipologia is required" />
            </SimpleItem>
            <SimpleItem
              dataField="autore"
              label={{ text: 'Autore' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', employees),
                placeholder: 'Seleziona autore...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare autore',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Autore is required" />
            </SimpleItem>
            <SimpleItem
              dataField="data"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data' }}
            >
              <RequiredRule message="Data is required" />
            </SimpleItem>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione is required" />
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              dataField="impiegati"
              editorType="dxTagBox"
              label={{ text: 'Partecipanti' }}
              editorOptions={{
                dataSource: ArraySourceComposer('id', employees),
                displayExpr: 'fullname',
                placeholder: 'Seleziona partecipanti...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            >
              <RequiredRule message="Partecipanti is required" />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={1} colSpan={3}>
            <SimpleItem dataField={'argomento'} editorType={'dxTextArea'} editorOptions={{ height: 200 }}>
              <RequiredRule message="Argomento is required" />
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            <ButtonItem name="btn-newRev">
              <ButtonOptions
                text="nuova rev"
                icon="newfolder"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickNuovaRev}
                disabled={creating}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                text="salva"
                icon="save"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickSave}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem
                name="btn-cancel"
                buttonOptions={{
                  disabled: !formRef.current?.instance().option().isDirty,
                  icon: 'undo',
                  text: 'Annulla',
                  width: '150px',
                  onClick: async (e) => {
                    revalidate.revalidate()
                  },
                }}
              ></ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default MeetingEditorForm
