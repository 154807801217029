import React from 'react'
import { Link, useMatches } from 'react-router-dom'
import { Item, Toolbar } from 'devextreme-react/toolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import styles from './BreadCrumbs.module.scss'
import { Crumb, CrumbResult, OptionalLoaderGeneric, TypedMatch } from '@/types'

// Type safe breadcrumb content component
const BreadcrumbContent = ({ crumbs }: { crumbs: Array<CrumbResult & { path: string }> }) => (
  <nav className={styles.breadcrumbsWrapper} aria-label="Breadcrumb">
    <Link to="/" className={styles.breadcrumbItem}>
      {/*<FontAwesomeIcon icon={faHome} className={styles.homeIcon} aria-label="Home" />*/}
      <span className={styles.homeTitle}>Qs Web Admin</span>
    </Link>

    {crumbs.map((crumb, index) => (
      <React.Fragment key={crumb.path}>
        <FontAwesomeIcon icon={faChevronRight} className={styles.separator} aria-hidden="true" />
        <Link
          to={crumb.path}
          className={classNames(styles.breadcrumbItem, {
            [styles.active]: index === crumbs.length - 1,
          })}
          aria-current={index === crumbs.length - 1 ? 'page' : undefined}
        >
          {crumb.icon && <FontAwesomeIcon icon={crumb.icon} className={styles.itemIcon} aria-hidden="true" />}
          <span className={styles.breadcrumbText}>{crumb.label}</span>
        </Link>
      </React.Fragment>
    ))}
  </nav>
)

export const BreadCrumbs = () => {
  // Get all matches from the router with proper typing for void/optional loader functions
  const matches = useMatches() as Array<TypedMatch<void>>
  // Type guard for checking if a match has a crumb function
  // This is necessary for type safety when accessing the crumb function
  const hasCrumb = <T extends OptionalLoaderGeneric>(
    match: TypedMatch<T>,
  ): match is TypedMatch<T> & { handle: { crumb: Crumb<T> } } => {
    // this guarantees that the match has a crumb function
    return Boolean(match.handle?.crumb)
  }

  // Filter and map the matches to breadcrumb items
  const crumbs = matches
    .filter((element) => hasCrumb(element))
    .map((match) => {
      // Since we've filtered, we know crumb exists and can safely call it
      const crumbData = match.handle.crumb(match)
      return {
        label: crumbData.label,
        icon: crumbData.icon,
        path: match.pathname,
      }
    })

  return (
    <div className={styles.breadcrumbsContainer}>
      <Toolbar>
        <Item location="before" render={() => <BreadcrumbContent crumbs={crumbs} />} />
      </Toolbar>
    </div>
  )
}

export default BreadCrumbs
