import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { pacchetto_utilizzo, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import PackageUsageEditor from '@/routes/packages/usage/PackageUsageEditor'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Qintervento, Qpacchetto_vendita } from '@/model/qsadminapi/QQsAdminApiModule'

export const packageUsageEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const packageUsage = (await request.json()) as pacchetto_utilizzo
  console.log('pacchetto', packageUsage)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.pacchetto_utilizzo().create(packageUsage)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.pacchetto_utilizzo(packageUsage.id).update(packageUsage)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

export const packageUsageEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'packageUsageId' | 'clientId'>) => {
  const packageUsageId = params.packageUsageId
  const clientId = params.clientId
  const interventionId = new URL(request.url).searchParams.get('interventionId')

  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  let title = azienda ? `${azienda.data.nome} - ` : ``
  let packageUsage: pacchetto_utilizzo

  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  if (packageUsageId) {
    //editor
    const getPackageUsage = await qsAdminApi
      .pacchetto_utilizzo(Number(packageUsageId))
      .query((builder, pacchetto) => {
        builder.expanding('pacchetto_vendita', (pacchettoVenditaBuilder, pacchettoVendita) => {
          pacchettoVenditaBuilder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
            tipologia_unitaBuilder.select('id', 'nome')
          })
          pacchettoVenditaBuilder.select('id', 'ded_Dis', 'tipologia_unita')
        })
        builder.expanding('intervento', (interventoBuilder, intervento) => {
          interventoBuilder.select('id', 'ded_Dis', 'durata_intervento')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Pacchetto non trovato')
        throw new Error()
      })
    packageUsage = getPackageUsage.data satisfies pacchetto_utilizzo
    title += `${packageUsage.ded_Dis}`
    if (
      clientId &&
      packageUsage.intervento?.sede?.azienda &&
      packageUsage?.intervento?.sede?.azienda.id !== Number(clientId)
    ) {
      throw new Error(
        `Il pacchetto utilizzo ${packageUsage.ded_Dis} appartiene al cliente ${packageUsage?.intervento?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += `Nuovo pacchetto`
    //creator a partire da intervento
    const interventionData = interventionId
      ? await qsAdminApi.intervento(Number(interventionId)).query((builder, intervention) => {
          builder.select('id', 'ded_Dis', 'durata_intervento')
        })
      : undefined

    packageUsage = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      unita_utilizzate: 0,
      note: null,
      creazione: new Date().toISOString(),
      pacchetto_vendita: null,
      intervento: interventionData?.data ?? null,
    }
  }

  const sede = await qsAdminApi.sede().query((builder, sede) => {
    if (packageUsage.pacchetto_vendita)
      builder.filter(
        sede.pacchetti_vendita.any((pacchetto: Qpacchetto_vendita) =>
          pacchetto.id.eq(Number(packageUsage.pacchetto_vendita?.id)),
        ),
      )
    else
      builder.filter(
        sede.interventi.any((intervento: Qintervento) => intervento.id.eq(Number(packageUsage.intervento?.id))),
      )
    builder.expanding('azienda', (aziendaBuilder, azienda) => {
      aziendaBuilder.expanding('sedi', (sediAziendaBuilder, sedeAzienda) => {
        sediAziendaBuilder.select('id', 'nome', 'note')
      })
      aziendaBuilder.select('id', 'nome', 'sedi')
    })
    builder.select('id', 'nome', 'azienda', 'note')
  })

  const getPacchettiVendita =
    packageUsage.pacchetto_vendita || packageUsage.intervento
      ? qsAdminApi.pacchetto_vendita().query((builder, pacchetto) => {
          builder.filter(pacchetto.sede.props.id.eq(sede.data.value[0].id))
          builder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
            tipologia_unitaBuilder.select('id', 'nome')
          })
          builder.select('id', 'ded_Dis', 'tipologia_unita')
          builder.orderBy(pacchetto.ded_Dis.desc())
        })
      : undefined

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
    builder.select('id', 'nome')
    builder.orderBy(qazienda.nome.asc())
  })

  const getBillingStates = qsAdminApi.fatturazione_intervento().query((builder, fatturazione_intervento) => {
    builder.select('id', 'nome')
    builder.orderBy(fatturazione_intervento.nome.asc())
  })

  const getIntervento = qsAdminApi.intervento(Number(packageUsage.intervento?.id)).query((builder, intervento) => {
    builder.expanding('sede', (sedeInterventoBuilder, sedeIntervento) => {
      sedeInterventoBuilder.expanding('azienda', (aziendaSedeInterventoBuilder, aziendaSedeIntervento) => {
        aziendaSedeInterventoBuilder.expanding('sedi', (sediBuilder, sedeSedi) => {
          sediBuilder.select('id', 'nome', 'note')
        })
        aziendaSedeInterventoBuilder.select('id', 'nome', 'sedi')
      })
      sedeInterventoBuilder.select('id', 'nome', 'azienda', 'note')
    })
    builder.expanding('task', (taskBuilder, task) => {
      taskBuilder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'titolo', 'ded_Dis')
      })
      taskBuilder.select('id', 'ded_Dis', 'commessa')
    })
    builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
      impiegatiBuilder.select('id', 'fullname')
    })
    builder.expanding('riferimenti_cliente', (riferimentiBuilder, riferimento) => {
      riferimentiBuilder.select('id', 'fullname')
    })
    builder.expanding('stato_fatturazione', (statoFatturazioneBuilder, statoFatturazione) => {
      statoFatturazioneBuilder.select('id', 'nome')
    })
  })

  return defer({
    title,
    packageUsage,
    sede,
    getIntervento,
    getAziende,
    getPacchettiVendita,
    getBillingStates,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const PackageUsageEditorRoute = {
  path: ':packageUsageId',
  element: <PackageUsageEditor creating={false} />,
  loader: packageUsageEditorRouteLoader,
  action: packageUsageEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
