import { useRef, useState } from 'react'
import DataGrid, {
  Column,
  DataGridRef,
  Editing,
  Export,
  Item,
  StateStoring,
  Toolbar,
  Selection,
  Paging,
} from 'devextreme-react/data-grid'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ordine, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import CustomStore from 'devextreme/data/custom_store'
import saveAs from 'file-saver'
import dxDataGrid, { ColumnButtonClickEvent, ExportingEvent } from 'devextreme/ui/data_grid'
import { Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { AxiosResponse } from 'axios'
import notify from 'devextreme/ui/notify'
import OutlookAppointmentSelector from '@/routes/tasks/OutlookAppointmentSelector'
import { PopupRef } from 'devextreme-react/cjs/popup'

interface TaskAppointment {
  id: string
  task: string
  order: string
  user: string
  Location: string
  Subject: string
  StartDate: string
  EndDate: string
  Duration: number
  ShowAs: number
  Notes: string
}

export interface TaskPlannerProps {
  task?: task
  order?: ordine
  readOnly?: boolean
  areActionEnabled?: boolean
}

enum eventState {
  Sconosciuto = 0,
  Disponibile = 1,
  Provvisorio = 2,
  Occupato = 3,
  Oof = 4,
  Altro = 5,
}

export const TaskPlanner = (props: TaskPlannerProps) => {
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const client = useQsAdminApiManager.getState().client
  const createOutlookApponitmentPopupRef = useRef<PopupRef>(null)
  const [appointmentData, setAppointmentData] = useState<any>()

  const store = new CustomStore({
    key: 'id',
    async load(loadOptions) {
      try {
        let planningUrl: string
        if (props.task !== undefined) {
          planningUrl = `/api/task/planning?taskId=${props.task.id}`
        } else if (props.order !== undefined) {
          planningUrl = `/api/task/planning?orderId=${props.order.id}`
        } else {
          throw new Error(`Dati necessari del task o dell'ordine mancanti`)
        }

        const response = await client.get(planningUrl, {
          headers: {
            'Content-Type': 'application/json',
          },
        })

        const result: TaskAppointment[] = response.data

        return {
          data: result,
          totalCount: result.length,
        }
      } catch (error) {
        throw new Error(`Data Loading Error : ${error}`)
      }
    },
  })

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    let planningExportUrl: string
    let planningExportFile: string
    if (props.task !== undefined) {
      planningExportUrl = `/api/task/ExportPlanning?taskId=${props.task.id}`
      planningExportFile = `Planning-Task-${props.task?.ded_Dis}.xlsx`
    } else if (props.order !== undefined) {
      planningExportUrl = `/api/task/ExportPlanning?orderId=${props.order.id}`
      planningExportFile = `Planning-Order-${props.order?.ded_Dis}.xlsx`
    } else {
      throw new Error(`Dati necessari del task o dell'ordine mancanti`)
    }

    try {
      client
        .post(planningExportUrl, null, {
          headers: {
            'Content-Disposition': `attachment;filename=${planningExportFile}`,
            'Content-Type': 'application/octet-stream',
          },
          responseType: 'arraybuffer',
        })
        .then((response: AxiosResponse) => {
          saveAs(new Blob([response.data], { type: 'application/octet-stream' }), planningExportFile)
        })
    } catch (error: unknown) {
      notify(`Errore esportazione task planning. Dettagli : ${error}`, 'error', 5000)
    }
  }

  const onAddOutlookAppointmentForTaskClick = (e: ClickEvent) => {
    createOutlookApponitmentPopupRef.current?.instance().show()
  }

  const onDuplicateOutlookAppointmentForTaskClick = (data: any) => {
    setAppointmentData(data)
    createOutlookApponitmentPopupRef.current?.instance().show()
  }

  return (
    <div>
      <DataGrid
        dataSource={store}
        ref={gridRef}
        keyExpr="id"
        showBorders={true}
        showColumnLines={true}
        columnAutoWidth={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        onExporting={onExporting}
        noDataText="Nessun evento associato al task"
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'task-planner-datagrid'} savingTimeout={50} />
        <Export enabled={!props.readOnly} />
        <Editing allowAdding={!props.readOnly} />
        <Selection mode="single"></Selection>
        <Paging pageSize={8}></Paging>
        {!props.readOnly && (
          <Toolbar>
            {!currentScreenSize.isXSmall && <Item name="groupPanel" />}
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={props.areActionEnabled}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: onAddOutlookAppointmentForTaskClick,
                hint: 'Nuovo appuntamento outlook',
                text: 'uovo appuntamento outlook',
              }}
            />
            <Item
              visible={props.areActionEnabled}
              location="after"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Duplica appuntamento outlook',
                text: 'Duplica appuntamento outlook',
                icon: 'copy',
                onClick: (e: ClickEvent) => {
                  if (gridRef.current?.instance().getSelectedRowsData().length === 0)
                    notify('Selezionare un appuntamento per poterlo duplicare.', 'warning', 2000)
                  else onDuplicateOutlookAppointmentForTaskClick(gridRef.current?.instance().getSelectedRowsData()[0])
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
          </Toolbar>
        )}
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(e.row?.data.WebLink, '_blank')
            }}
          />
        </Column>
        <Column
          dataField="task"
          caption="TASK"
          visible={props.order !== null}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
          }
        />
        <Column
          dataField="order"
          caption="ORDINE"
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        <Column
          dataField="User.fullName"
          caption="UTENTE"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField="Subject"
          caption="OGGETTO"
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Column
          dataField="Location"
          caption="LUOGO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField="StartDate"
          caption="INIZIO"
          dataType="date"
          format="dd-MM-yyyy HH:mm"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          dataField="EndDate"
          caption="FINE"
          dataType="date"
          format="dd-MM-yyyy HH:mm"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField="Notes"
          caption="NOTE"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField="Duration"
          caption="DURATA"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField="ShowAs"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
          caption="STATO"
          cellRender={({ data }) => {
            const value: number = data.ShowAs ?? 0
            return eventState[value]
          }}
        />
      </DataGrid>
      <OutlookAppointmentSelector
        task={props.task}
        appointmentData={appointmentData}
        appointmentGridRef={gridRef}
        popupRef={createOutlookApponitmentPopupRef}
      />
    </div>
  )
}
