export const formatMinAsHHMM = (min: number, defaultValue?: string) => {
  if (min < 0) return defaultValue ?? '08:00'
  return `${Math.floor(min / 60)
    .toString()
    .padStart(2, '0')}:${(min % 60).toString().padStart(2, '0')}`
}

export const formatHHMMAsMin = (hhmm: string | null | undefined, defaultValue?: number) => {
  if (!hhmm) return defaultValue ?? 0
  const [hours, minutes] = hhmm.split(':').map(Number)
  return hours * 60 + minutes
}
