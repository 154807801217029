import LoginRoute from './_unauthenticated/login.route'
import { LoaderFunction, RouteObject } from 'react-router-dom'
import { typesafeBrowserRouter } from 'react-router-typesafe'
import App from '@/App'
import { AuthenticatedLayoutRoute } from '@/routes/authenticated/AuthenticatedLayout.route'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import AccessDeniedRoute from '@/routes/_unauthenticated/AccessDenied.route'
import TicketRatingRoute from '@/routes/_unauthenticated/ticketrating.route'
import LogoutRoute from './_unauthenticated/logout.route'
import { LandingRoute } from '@/routes/landing/landing.route'
import { LandingPage } from '@/routes/landing/LandingPage'

export const getRouter = async () => {
  const baseRoutes = [
    {
      element: <App />,
      errorElement: <RouterErrorHandler />,
      path: '/',
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        LoginRoute,
        LandingRoute,
        // CreateRoute,
        // ResetRoute,
        TicketRatingRoute,
        AuthenticatedLayoutRoute,
        AccessDeniedRoute,
        LogoutRoute,
      ],
    },
  ] satisfies RouteObject[]

  const { router } = typesafeBrowserRouter(baseRoutes)
  return router
}
