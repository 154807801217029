import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import AnalysisCreatorRoute from '@/routes/analysis/AnalysisCreator.route'
import AnalysisEditorRoute from '@/routes/analysis/AnalysisEditor.route'
import AnalysisPage from '@/routes/analysis/AnalysisPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faFileText } from '@fortawesome/free-solid-svg-icons'

export const analysisPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Analisi` : 'Analisi'

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('id', 'nome')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getStati = qsAdminApi.stato_analisi().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    title,
    sites: await getSites,
    stati: await getStati,
    impiegti: await getImpiegati,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const AnalysisPageRoute: RoleRoute = {
  path: 'analysis',
  children: [
    {
      index: true,
      element: <AnalysisPage />,
      loader: analysisPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    AnalysisCreatorRoute,
    AnalysisEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Analisi',
  faIcon: faFileText,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Analisi',
      icon: faFileText,
    }),
  },
  allowedRoles: [Roles.Guest],
}
