import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'

export const QSLicenseRoute: TileRoleRoute = {
  path: 'qsLicense',
  text: 'Licenza QS',
  faIcon: faKey,
  element: (
    <ManagementGridFactory
      entity="tipologia_qs_licenza"
      referencesCollection="qs_licenza_applicazioni"
      referencesTitle="Licenze QS"
      displayField="id"
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Tipo Licenza QS',
      icon: faKey,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
