import { useEffect, useState } from 'react'
import type {
  contatto_aziendale,
  entita_aziendale,
  azienda,
  rapporto,
  sede,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { WithLoadPanel } from '@/components/WithLoadPanel'
import './ContactEditorPanel.scss'
import { ContactEditorPanelForm } from './ContactEditorPanel.form'
import { ClickEvent } from 'devextreme/ui/button'

type ContactEditorPanelProps = {
  contact: contatto_aziendale | entita_aziendale | undefined
  isOpened: boolean
  changePanelOpened: (value: boolean) => void
  changePanelPinned: (value: boolean) => void
  onContactUpdate: (data: any) => Promise<contatto_aziendale | entita_aziendale | null>
  onContactReset: (data: any) => Promise<contatto_aziendale | entita_aziendale | null>
  onContactDelete: (data: any) => Promise<void>
  onContactMigrateClick?: (e: ClickEvent) => void
  onContactHistoryClick?: (e: ClickEvent) => void
  rapporti: rapporto[]
  aziende: azienda[]
  sedi: sede[]
  readOnly?: boolean
}

const ContactEditorWithPanel = WithLoadPanel(ContactEditorPanelForm)

export const ContactEditorPanel = (props: ContactEditorPanelProps) => {
  const {
    contact,
    isOpened,
    changePanelOpened,
    changePanelPinned,
    onContactUpdate,
    onContactReset,
    onContactDelete,
    onContactMigrateClick,
    onContactHistoryClick,
    rapporti,
    aziende,
    sedi,
    readOnly,
  } = props
  const [data, setData] = useState<contatto_aziendale | entita_aziendale>()

  const loadData = (contactData: contatto_aziendale | entita_aziendale | undefined) => {
    if (contactData === null) return
    // console.log('CONTACT LOAD', contactData, new Date().getTime())
    setData(structuredClone(contactData))
  }

  const onDataReset = (contactData: contatto_aziendale | entita_aziendale) => {
    console.log('CONTACT RESET', contactData)
    return new Promise<boolean>((resolve, reject) => {
      onContactReset(contactData)
        .then((res: contatto_aziendale | entita_aziendale | null) => {
          console.log('CONTATTO RESET', res)
          if (res) loadData(res)
          resolve(true)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  const onDataSave = (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      onContactUpdate(newdata)
        .then((res: contatto_aziendale | entita_aziendale | null) => {
          console.log('CONTATTO UPDATED', res)
          if (res) loadData(res)
          resolve(true)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  const onDataDelete = (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      onContactDelete(newdata)
        .then(() => {
          resolve(true)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  useEffect(() => {
    // console.log('CONTACT EFFECT', contact)
    loadData(contact)
  }, [contact])

  return (
    <ContactEditorWithPanel
      contact={data}
      hasData={!!data}
      isOpened={isOpened}
      onDataReset={onDataReset}
      onDataSave={onDataSave}
      onDataDelete={onDataDelete}
      onDataMigrateClick={onContactMigrateClick}
      onDataHistoryClick={onContactHistoryClick}
      changePanelOpened={changePanelOpened}
      changePanelPinned={changePanelPinned}
      panelProps={{
        position: { of: '.panel' },
        container: '.panel',
      }}
      rapporti={rapporti}
      aziende={aziende}
      sedi={sedi}
      readOnly={readOnly}
    />
  )
}
