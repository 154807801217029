import { InterventionEditorFormProps } from '@/routes/interventions/InterventionEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { azienda, contatto_aziendale, intervento, sede, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  EmptyItem,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import DataSource from 'devextreme/data/data_source'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValidationCallbackData } from 'devextreme/common'
import { AxiosError, AxiosResponse } from 'axios'
import saveAs from 'file-saver'
import notify from 'devextreme/ui/notify'
import { ClickEvent } from 'devextreme/ui/button'
import {
  customButton,
  customDisplayExpr,
  isUserRoleAllowed,
  onInitializedHtmlEditor,
  onValueChangedHtmlEditor,
  toolbarHtmlEditor,
} from '@/routes/utils'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { ValueChangedEvent as ValueChangedEventSwitch } from 'devextreme/ui/switch'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { useScreenSize } from '@/themes/media-query'
import { Roles } from '@/auth/azure/Roles'
import { StatoTask } from '@/routes/tasks/tasks.types'
import FormPopupContact from '@/routes/contacts/person/FormPopupContact'

const InterventionEditorForm = (props: InterventionEditorFormProps) => {
  const {
    creating,
    intervention,
    clients,
    billingStates,
    technicians,
    references,
    tasks,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props
  const { clientId, packageUsageId } = useParams()
  const { service, client } = useQsAdminApiManager()

  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')
  //id dell'intervento sulla quale vado a creare il pacchetto di utilizzo
  const puInterventionId = searchParams.get('interventionId')

  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const refNuovoContattoPopup = useRef<PopupRef>(null)

  const localeItems = [
    { id: 'it', value: 'Italiano' },
    { id: 'en', value: 'Inglese' },
  ]

  const currentScreenSize = useScreenSize()
  const revalidator = useRevalidator()

  const [remote, setRemote] = useState<boolean | undefined>(intervention?.remote)
  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? clients[0] : intervention?.sede?.azienda,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', intervention?.sede?.azienda?.sedi),
  )

  const [tasksDataSource, setTasksDataSource] = useState<DataSource>(ArraySourceComposer('id', tasks))
  const [referencesDataSource, setReferencesDataSource] = useState<DataSource>(ArraySourceComposer('id', references))

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
          gruppo_aziendaleBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
          rivenditoreBuilder.select('id', 'nome', 'invio_rapportino_cliente_disabilitato', 'is_rivenditore')
        })
        aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
          agenteBuilder.select('id', 'commerciale_qs')
          agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commerciale) => {
            commercialeQsBuilder.select('id', 'fullname')
          })
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale', 'rivenditore', 'agente')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteTasks = useCallback(async () => {
    const tasksData = await service.task().query((builder, task) => {
      builder.filter(
        intervention?.sede?.azienda?.gruppo_aziendale
          ? task.sede.props.azienda.props.gruppo_aziendale.props.id.eq(
              Number(intervention?.sede?.azienda?.gruppo_aziendale.id),
            )
          : task.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.filter(task.stato.props.id.eq(StatoTask.APERTO))
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.select('id', 'ded_Dis', 'sede')
      builder.orderBy(task.id.desc())
    })
    return tasksData.data.value
  }, [currentCompany?.id, intervention?.sede?.azienda?.gruppo_aziendale, service])

  const getSiteReferences = useCallback(async () => {
    const referencesData = await service.contatto_aziendale().query((builder, contatto) => {
      builder.filter(contatto.sede.props.id.eq(Number(intervention?.sede?.id)))
      builder.filter(contatto.attivo.eq(true))
      builder.expanding('emails', (emailsBuilder, email) => {
        emailsBuilder.select('id', 'email')
      })
      builder.select('id', 'fullname', 'emails')
      builder.orderBy(contatto.fullname.asc())
    })
    return referencesData.data.value
  }, [intervention?.sede?.id, service])

  const getResellerReferences = useCallback(async () => {
    const referencesData = await service.contatto_aziendale().query((builder, contatto) => {
      builder.filter(contatto.sede.props.azienda.props.id.eq(Number(intervention?.sede?.azienda?.rivenditore?.id)))
      builder.filter(contatto.attivo.eq(true))
      builder.filter(contatto.ricezione_rapportino.eq(true))
      builder.select('id', 'fullname')
      builder.orderBy(contatto.fullname.asc())
    })
    return referencesData.data.value
  }, [intervention?.sede?.azienda?.rivenditore?.id, service])

  const handleOnClickExportPDF = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/intervento/exportpdf', {
        params: { interventoId: Number(intervention?.id) },
        headers: {
          'Content-Disposition': `attachment;filename=${intervention?.ded_Dis}.pdf`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${intervention?.ded_Dis}.pdf`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickSendEmail = async (e: ClickEvent) => {
    let result: boolean = true
    if (
      intervention?.sede?.azienda?.rivenditore?.invio_rapportino_cliente_disabilitato &&
      intervention?.sede?.azienda?.rivenditore?.is_rivenditore
    ) {
      const contattiRivenditore = await getResellerReferences()
      if (contattiRivenditore.length === 0) result = false
    }
    if (!result) notify('Nessun contatto del rivenditore abilitato per ricevere il rapportino.', 'warning', 3000)
    else {
      setLoadPanelVisible(true)
      client
        .get('/api/intervento/sendemail', {
          params: { interventoId: Number(intervention?.id) },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response: AxiosResponse<intervento, intervento>) => {
          notify('Invio email effettuato con successo', 'success', 2000)
          formRef.current?.instance().updateData('inviato', response.data.inviato)
          formRef.current?.instance().option('isDirty', false)
        })
        .catch((error: AxiosError) => {
          notify(`Errore invio email. Dettagli : ${error}`, 'error', 2000)
        })
        .finally(() => {
          setLoadPanelVisible(false)
        })
    }
  }

  const handleOnClickFatturazione = async () => {
    if (intervention)
      service
        .intervento(intervention.id)
        .patch(intervention)
        .then(() => {
          notify('Aggiornamento intervento avvenuto con successo', 'success', 2000)
        })
        .catch((error) => {
          notify(`Errore aggiornamento intervento. Dettagli : ${error}`, 'error', 2000)
        })
  }

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    if (intervention?.sede) {
      if (!taskId && !clientId)
        getSiteTasks().then((tasks: task[]) => {
          console.log('tasks', tasks)
          setTasksDataSource(ArraySourceComposer('id', tasks))
          formRef.current?.instance().updateData('task', null)
        })
      getSiteReferences().then((ref: contatto_aziendale[]) => {
        console.log('ref', ref)
        setReferencesDataSource(ArraySourceComposer('id', ref))
        formRef.current?.instance().updateData('riferimenti_cliente', [])
      })
    }
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, intervention?.sede, getSiteTasks, getSiteReferences, taskId, clientId])

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (remote !== undefined && remote !== null) formRef.current?.instance().validate()
  }, [remote])

  const getInterventionHourDateRange = (hours: number, minutes: number) => {
    const baseline = new Date(0) // 1970-01-01T00:00:00Z
    baseline.setUTCHours(hours, minutes, 0, 0)
    return baseline
  }

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'intervention_form'}
        formData={intervention}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="interventionValidation"
        ref={formRef}
        readOnly={packageUsageId !== undefined || puInterventionId !== null || !isRoleAllowed}
      >
        <GroupItem colCount={packageUsageId === undefined && puInterventionId === null ? 5 : 2}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', clients),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly:
                  !isRoleAllowed || (clientId ? true : packageUsageId !== undefined || puInterventionId !== null),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (intervention?.sede) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed || packageUsageId !== undefined || puInterventionId !== null,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) setCurrentSiteChanged(true)
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              editorType="dxTextBox"
              label={{ text: 'Agente' }}
              dataField="sede.azienda.agente.commerciale_qs.fullname"
              editorOptions={{ readOnly: true }}
            ></SimpleItem>
            <SimpleItem
              editorType="dxTextBox"
              label={{ text: 'Rivenditore' }}
              dataField="sede.azienda.rivenditore.nome"
              editorOptions={{ readOnly: true }}
            ></SimpleItem>
            <SimpleItem
              dataField="riferimenti_cliente"
              editorType="dxTagBox"
              label={{ text: 'Riferimenti' }}
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    (e: ClickEvent) => {
                      if (intervention?.sede) {
                        const contact: contatto_aziendale = {
                          id: 0,
                          fullname: null,
                          ad_recipient: false,
                          nome: null,
                          cognome: null,
                          ruolo: null,
                          note: null,
                          titolo: null,
                          telefono: null,
                          attivo: true,
                          sede: intervention.sede,
                          emails: [
                            {
                              id: 0,
                              email: null,
                              note: null,
                              rif_ad: false,
                            },
                          ],
                          hasLinkedObjects: false,
                          ricezione_rapportino: false,
                        }
                        setCurrentContact(contact)
                        refNuovoContattoPopup.current?.instance().show()
                      } else notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                    },
                    !isRoleAllowed || packageUsageId !== undefined || puInterventionId !== null,
                  ),
                ],
                dataSource: referencesDataSource,
                // displayExpr: 'fullname',
                displayExpr: (item: contatto_aziendale) => {
                  let displayExpreValue: string = ''
                  if (item) {
                    displayExpreValue += item.fullname
                    if (item.emails && item.emails.length > 0) displayExpreValue += ` - ${item.emails[0].email}`
                  }
                  return displayExpreValue
                },
                placeholder: 'Seleziona riferimenti...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            >
              <RequiredRule message="Riferimento obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="data"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data' }}
            >
              <RequiredRule message="Data obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="impiegati"
              editorType="dxTagBox"
              label={{ text: 'Tecnici' }}
              editorOptions={{
                dataSource: ArraySourceComposer('id', technicians),
                displayExpr: 'fullname',
                placeholder: 'Seleziona tecnici...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            >
              <RequiredRule message="Necessario specificare almeno un tecnico" />
            </SimpleItem>
            <SimpleItem
              dataField="durata_viaggio"
              editorType="dxNumberBox"
              label={{ text: 'Ore Viaggio' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Ore Viaggio obbligatorio" />
              <CustomRule
                message={"Le ore di viaggio devono essere una valore maggiore di 0 se l'intervento non è remoto"}
                validationCallback={({ value }: ValidationCallbackData) => {
                  if (remote) return true
                  if (value) {
                    return value > 0
                  }
                  return false
                }}
              />
            </SimpleItem>
            <GroupItem colCount={4} colSpan={2}>
              <SimpleItem
                dataField="mattina_inizio"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: getInterventionHourDateRange(5, 0),
                  max: getInterventionHourDateRange(14, 0),
                  dateOutOfRangeMessage: 'L’orario deve essere compreso tra le 05:00 e le 14:00',
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Mattino dalle' }}
              >
                <CustomRule
                  reevaluate={true}
                  message="L'orario di inizio deve essere impostato e deve essere minore dell'orario di fine"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.mattina_fine === undefined || intervention?.mattina_fine === null) return true
                    if (
                      intervention.mattina_fine !== undefined &&
                      intervention.mattina_fine !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(value)
                    const endDate = new Date(intervention.mattina_fine)
                    console.log('MORNING VALIDATION', endDate, startDate, value)
                    return endDate > startDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="mattina_fine"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: getInterventionHourDateRange(5, 0),
                  max: getInterventionHourDateRange(14, 0),
                  dateOutOfRangeMessage: 'L’orario deve essere compreso tra le 05:00 e le 14:00',
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Mattino alle' }}
              >
                <CustomRule
                  reevaluate={true}
                  message="L'orario di fine deve essere impostato e deve essere maggiore dell'orario di inizio"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.mattina_inizio === undefined || intervention?.mattina_inizio === null) return true
                    if (
                      intervention.mattina_inizio !== undefined &&
                      intervention.mattina_inizio !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(intervention.mattina_inizio)
                    const endDate = new Date(value)
                    return startDate < endDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="pomeriggio_inizio"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: getInterventionHourDateRange(11, 30),
                  max: getInterventionHourDateRange(23, 45),
                  dateOutOfRangeMessage: 'L’orario deve essere compreso tra le 11:30 e le 23:45',
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Pomeriggio dalle' }}
              >
                <CustomRule
                  reevaluate={true}
                  message="L'orario di inizio deve essere impostato e deve essere minore dell'orario di fine"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.pomeriggio_fine === undefined || intervention?.pomeriggio_fine === null)
                      return true
                    if (
                      intervention.pomeriggio_fine !== undefined &&
                      intervention.pomeriggio_fine !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(value)
                    const endDate = new Date(intervention.pomeriggio_fine)
                    console.log('AFT1 VALIDATION', endDate, startDate, value)
                    return endDate > startDate
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="pomeriggio_fine"
                editorOptions={{
                  displayFormat: 'HH:mm',
                  type: 'time',
                  pickerType: 'list',
                  interval: 15,
                  min: getInterventionHourDateRange(11, 30),
                  max: getInterventionHourDateRange(23, 45),
                  dateOutOfRangeMessage: 'L’orario deve essere compreso tra le 11:30 e le 23:45',
                  showClearButton: true,
                }}
                editorType="dxDateBox"
                label={{ text: 'Pomeriggio alle' }}
              >
                <CustomRule
                  reevaluate={true}
                  message="L'orario di fine deve essere impostato e deve essere maggiore dell'orario di inizio"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (intervention?.pomeriggio_inizio === undefined || intervention?.pomeriggio_inizio === null)
                      return true
                    if (
                      intervention.pomeriggio_inizio !== undefined &&
                      intervention.pomeriggio_inizio !== null &&
                      (value === undefined || value === null)
                    )
                      return false
                    const startDate = new Date(intervention.pomeriggio_inizio)
                    const endDate = new Date(value)
                    console.log('AFT2 VALIDATION', endDate, startDate, value)
                    return startDate < endDate
                  }}
                />
              </SimpleItem>
            </GroupItem>
            <SimpleItem
              dataField="task"
              label={{ text: 'Task' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: tasksDataSource,
                placeholder: 'Seleziona il task...',
                // displayExpr: 'ded_Dis',
                displayExpr: customDisplayExpr,
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il task',
                  hideOnOutsideClick: true,
                  width: currentScreenSize.isLarge ? '150%' : '',
                  resizeEnabled: currentScreenSize.isLarge ? true : false,
                },
                readOnly: taskId || !isRoleAllowed || packageUsageId !== undefined || puInterventionId !== null,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato_fatturazione"
              label={{ text: 'Fatturazione' }}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: ArraySourceComposer('id', billingStates),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed || puInterventionId !== null,
                buttons:
                  packageUsageId === undefined
                    ? [{ name: 'dropDown', location: 'after' }]
                    : [
                        {
                          name: 'check',
                          location: 'after',
                          options: {
                            disabled: false,
                            visible: true,
                            stylingMode: 'text',
                            icon: 'check',
                            onClick: handleOnClickFatturazione,
                          },
                        },
                        { name: 'dropDown', location: 'after' },
                      ],
              }}
            >
              <RequiredRule message="Fatturazione obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="remote"
              label={{ text: 'Remoto' }}
              editorType="dxSwitch"
              editorOptions={{
                onValueChanged: (e: ValueChangedEventSwitch) => {
                  setRemote(e.value)
                },
              }}
            />
            <SimpleItem
              dataField="locale"
              label={{ text: 'Locale' }}
              editorType="dxRadioGroup"
              editorOptions={{ items: localeItems, valueExpr: 'id', displayExpr: 'value', layout: 'horizontal' }}
            />
            <SimpleItem
              dataField="inviato"
              label={{ text: 'Inviato' }}
              editorType="dxCheckBox"
              editorOptions={{ readOnly: true }}
            />
            <EmptyItem></EmptyItem>
            {(packageUsageId !== undefined || puInterventionId !== null) && (
              <SimpleItem
                colSpan={2}
                dataField="note"
                label={{ text: 'Note' }}
                editorType="dxHtmlEditor"
                editorOptions={{
                  stylingMode: 'filled',
                  valueType: 'html',
                  toolbar: toolbarHtmlEditor(currentScreenSize),
                  mediaResizing: {
                    enabled: true,
                  },
                  imageUpload: {
                    tabs: ['file', 'url'],
                    fileUploadMode: 'base64',
                  },
                  height: 330,
                  onValueChanged: onValueChangedHtmlEditor,
                  onInitialized: onInitializedHtmlEditor,
                }}
              >
                <RequiredRule message="Note obbligatorie"></RequiredRule>
              </SimpleItem>
            )}
          </GroupItem>
          <GroupItem visible={packageUsageId === undefined && puInterventionId === null} colSpan={3}>
            <SimpleItem
              dataField="note"
              label={{ text: 'Note' }}
              editorType="dxHtmlEditor"
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: 480,
                onValueChanged: onValueChangedHtmlEditor,
                onInitialized: onInitializedHtmlEditor,
              }}
            >
              <RequiredRule message="Note obbligatorie"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={packageUsageId === undefined && puInterventionId === null} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 4 : 5}>
            <ButtonItem name="btn-PU">
              <ButtonOptions
                text="PU"
                icon="box"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/packages/usage/new?interventionId=${intervention?.id}`, '_blank')
                }}
                disabled={
                  creating || !isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-sendEmail">
              <ButtonOptions
                text="Invia Rapporto"
                icon="email"
                useSubmitBehavior={false}
                width={'160px'}
                onClick={handleOnClickSendEmail}
                disabled={creating || !isRoleAllowed}
              />
            </ButtonItem>
            <ButtonItem name="btn-exportPDF">
              <ButtonOptions
                text="esporta pdf"
                icon="pdffile"
                useSubmitBehavior={false}
                width={'160px'}
                onClick={handleOnClickExportPDF}
                disabled={creating}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                icon="save"
                disabled={!formRef.current?.instance().option().isDirty}
                text="Salva"
                width="160px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('intervention', intervention)
                  if (intervention) {
                    console.log('intervention', intervention)
                    fetcher?.submit(JSON.stringify(intervention), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="160px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={intervention?.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === intervention?.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
      {packageUsageId === undefined && puInterventionId === null && (
        <FormPopupContact
          migrating={false}
          popupRef={refNuovoContattoPopup}
          contact={currentContact}
          onContactSaved={(newContact) => {
            refNuovoContattoPopup.current?.instance().hide()
            getSiteReferences().then((contacts: contatto_aziendale[]) => {
              formRef.current?.instance().updateData('riferimenti_cliente', [newContact])
              setReferencesDataSource(ArraySourceComposer('id', contacts))
            })
          }}
        ></FormPopupContact>
      )}
    </>
  )
}

export default InterventionEditorForm
