import { Editing, Column, StateStoring } from 'devextreme-react/tree-list'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { DataGrid } from 'devextreme-react'
import { SavingEvent } from 'devextreme/ui/data_grid'
import { Paging } from 'devextreme-react/cjs/data-grid'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { platformsEditorRouteLoader } from '@/routes/costcenters/platforms/PlatformsEditor.route'
import { useLoaderData } from 'react-router-typesafe'
import DocumentHead from '@/components/document-head/DocumentHead'

const PlatformsEditor = () => {
  const token = useTokenRefresh()
  const { title } = useLoaderData<typeof platformsEditorRouteLoader>()

  const platformsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/piattaforma_attivita_lavorativa`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    requireTotalCount: true,
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>
      <div id="editor-platforms-container" className={'content-block dx-card responsive-paddings'}>
        <DataGrid
          id={`platforms-editor-datagrid`}
          dataSource={platformsDataSource}
          noDataText="Nessuna piattaforma trovata"
          keyExpr={'id'}
          showBorders={true}
          showColumnLines={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          wordWrapEnabled={false}
          repaintChangesOnly={true}
          width="100%"
          onSaving={(e: SavingEvent) => {
            console.log('SAVING', e)
          }}
        >
          <StateStoring enabled={true} type="localStorage" storageKey={'platforms-datagrid'} savingTimeout={50} />
          <Paging defaultPageSize={20} />
          <Editing
            mode="batch"
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
            selectTextOnEditStart={true}
            startEditAction={'click'}
          />
          <Column dataField={'nome'} caption={'NOME'} visible={true} />
          <Column dataField={'note'} caption={'NOTE'} visible={true} />
        </DataGrid>
      </div>
    </>
  )
}

export default PlatformsEditor
