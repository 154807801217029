import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import {
  azienda,
  gruppo_aziendale,
  ordine,
  punto_fatturazione_ordine,
  sede,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { TaskEditorFormProps } from '@/routes/tasks/TaskEditor.types'
import {
  customButton,
  customDisplayExpr,
  isUserRoleAllowed,
  onInitializedHtmlEditor,
  onValueChangedHtmlEditor,
  toolbarHtmlEditor,
} from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'
import { TaskPlanner } from '@/routes/tasks/TaskEditor.planner'
import TaskEditorFormHoursGrid from '@/routes/tasks/TaskEditor.form.hours'
import TaskEditorFormInterventionsGrid from '@/routes/tasks/TaskEditor.form.interventions'
import { StatoTask } from '@/routes/tasks/tasks.types'
import FormPopupCompleteTask from '@/components/form-popup-complete-task/FormPopupCompleteTask'
import { StatoPuntoFatturazione } from '@/routes/invoicingsteps/invoicingstep.types'

const TaskEditorForm = (props: TaskEditorFormProps) => {
  const { creating, task, stati, aziende, impiegati, ordini, puntiFatturazione, isRoleAllowed, userInfo, fetcher } =
    props
  const { clientId } = useParams()
  const { service } = useQsAdminApiManager()
  const revalidator = useRevalidator()

  const currentScreenSize = useScreenSize()

  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const popupCompleteTaskRef = useRef<PopupRef>(null)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? aziende[0] : task?.sede?.azienda,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(ArraySourceComposer('id', task?.sede?.azienda?.sedi))

  const [ordiniDataSource, setOrdiniDataSource] = useState<DataSource>(ArraySourceComposer('id', ordini))
  const [puntiFatturazioneDataSource, setPuntiFatturazioneDataSource] = useState<DataSource>(
    ArraySourceComposer('id', puntiFatturazione),
  )

  const [currentGruppoAziendale, setCurrentGruppoAziendale] = useState<gruppo_aziendale | null | undefined>(
    task?.sede?.azienda?.gruppo_aziendale,
  )

  const getAziendaData = useCallback(async () => {
    const aziendaData = await service.azienda(Number(currentCompany?.id)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppoBuilder, gruppo) => {
        gruppoBuilder.select('id', 'nome')
      })
      builder.select('gruppo_aziendale')
    })
    return aziendaData.data
  }, [currentCompany?.id, service])

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
          gruppo_aziendaleBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteOrders = useCallback(async () => {
    const ordersData = await service.ordine().query((builder, ordine) => {
      builder.filter(
        currentGruppoAziendale
          ? ordine.commessa.props.sede.props.azienda.props.gruppo_aziendale.props.id.eq(
              Number(currentGruppoAziendale.id),
            )
          : ordine.commessa.props.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
            aziendaBuilder.select('id', 'nome')
          })
          sedeBuilder.select('id', 'nome', 'azienda')
        })
        commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
      })
      builder.expanding('offerta', (offertaOrdineBuilder, offertaOrdine) => {
        offertaOrdineBuilder.expanding('analisi', (analisiBuilder, analisi) => {
          analisiBuilder.expanding('sede', (sedeAnalisiBuilder, sede) => {
            sedeAnalisiBuilder.expanding('azienda', (aziendaSedeAnalisiBuilder, azienda) => {
              aziendaSedeAnalisiBuilder.select('id', 'nome')
            })
            sedeAnalisiBuilder.select('id', 'nome', 'azienda')
          })
          analisiBuilder.select('id', 'ded_Dis', 'sede')
        })
        offertaOrdineBuilder.select('id', 'ded_Dis', 'analisi')
      })
      builder.select('id', 'ded_Dis', 'note', 'commessa', 'offerta')
      builder.orderBy(ordine.ded_Dis.desc())
    })
    return ordersData.data.value
  }, [currentCompany?.id, currentGruppoAziendale, service])

  const getOrderInvoicingSteps = useCallback(async () => {
    const invoicingStepsData = await service.punto_fatturazione_ordine().query((builder, puntoFatturazione) => {
      builder.filter(puntoFatturazione.ordine.props.id.eq(Number(task.ordine?.id)))
      builder.filter(puntoFatturazione.stato.props.id.eq(StatoPuntoFatturazione['DA FATTURARE']))
      builder.select('id', 'ded_Dis', 'note')
    })
    return invoicingStepsData.data.value
  }, [service, task.ordine?.id])

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      getAziendaData().then((company: azienda) => {
        console.log('data', company)
        setCurrentGruppoAziendale(company.gruppo_aziendale ?? null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    if (!clientId)
      getSiteOrders().then((ordini: ordine[]) => {
        console.log('ordini', ordini)
        setOrdiniDataSource(ArraySourceComposer('id', ordini))
        formRef.current?.instance().updateData('ordine', null)
      })
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, task.sede, getSiteOrders, clientId])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'task_form'}
        formData={task}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="taskValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (task?.sede) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) setCurrentSiteChanged(true)
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="ordine"
              label={{ text: 'Ordine' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'open',
                    (e: ClickEvent) => {
                      if (task.ordine) window.open(`/orders/${task.ordine.id}`, '_blank')
                      else notify(`Deve essere selezionato un ordine per poterlo aprire.`, 'warning', 3000)
                    },
                    !isUserRoleAllowed(userInfo?.roles, [Roles.Guest]),
                  ),
                  { name: 'clear', location: 'after' },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: ordiniDataSource,
                placeholder: 'Seleziona ordine...',
                // displayExpr: 'ded_Dis',
                displayExpr: (item: ordine) => {
                  return item
                    ? `${item.ded_Dis}${item.commessa?.sede?.azienda?.nome ? ' - ' : ''}${item.commessa?.sede?.azienda?.nome ?? ''}${item.commessa?.sede?.nome ? ' - ' : ''}${item.commessa?.sede?.nome ?? ''}`
                    : ``
                },
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare ordine',
                  hideOnOutsideClick: true,
                  width: currentScreenSize.isLarge ? '150%' : '',
                  resizeEnabled: currentScreenSize.isLarge ? true : false,
                },
                showClearButton: true,
                readOnly: !isUserRoleAllowed(userInfo?.roles, [
                  Roles.GlobalAdministrator,
                  Roles.Administrator,
                  Roles.Supervisor,
                ]),
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log('FRM CHANGED', task)
                  if (e.value) {
                    getOrderInvoicingSteps().then((puntiFatturazione: punto_fatturazione_ordine[]) => {
                      console.log('puntiFatturazione', puntiFatturazione)
                      setPuntiFatturazioneDataSource(ArraySourceComposer('id', puntiFatturazione))
                      formRef.current?.instance().updateData('punto_fatturazione', null)
                    })
                  } else {
                    setPuntiFatturazioneDataSource(ArraySourceComposer('id', []))
                    formRef.current?.instance().updateData('punto_fatturazione', null)
                  }
                  props.onOrderChanged(e.value)
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="punto_fatturazione"
              label={{ text: 'Punto di Fatturazione' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'open',
                    (e: ClickEvent) => {
                      if (task.punto_fatturazione)
                        window.open(`/invoicingsteps/${task.punto_fatturazione.id}`, '_blank')
                      else
                        notify(`Deve essere selezionato un punto di fatturazione per poterlo aprire.`, 'warning', 3000)
                    },
                    !isUserRoleAllowed(userInfo?.roles, [
                      Roles.GlobalAdministrator,
                      Roles.Administrator,
                      Roles.Supervisor,
                    ]),
                  ),
                  { name: 'clear', location: 'after' },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: puntiFatturazioneDataSource,
                placeholder: 'Seleziona punto di fatturazione...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare punto di fatturazione',
                  hideOnOutsideClick: true,
                },
                showClearButton: true,
                readOnly: !isUserRoleAllowed(userInfo?.roles, [
                  Roles.GlobalAdministrator,
                  Roles.Administrator,
                  Roles.Supervisor,
                ]),
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log('FRM CHANGED', task)
                  props.onPuntoFatturazioneChanged(e.value)
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            >
              <RequiredRule message="Stato obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="resp_tecnico"
              label={{ text: 'Responsabile' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona responsabile...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare responsabile',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Responsabile tecnico obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="proprietario"
              label={{ text: 'Autore' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona proprietario...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare proprietario',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Proprietario obbligatorio" />
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              dataField="tecnici"
              editorType="dxTagBox"
              label={{ text: 'Tecnici Ausiliari' }}
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                displayExpr: 'fullname',
                placeholder: 'Seleziona tecnici ausiliari...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="apertura"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Apertura' }}
            >
              <RequiredRule message="Data apertura obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="consegna_prevista"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Consegna Prevista' }}
            ></SimpleItem>
            <SimpleItem
              dataField="chiusura"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Chiusura' }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Analisi' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (task.ordine?.offerta?.analisi)
                      window.open(`/analysis/${task.ordine.offerta.analisi.id}`, '_blank')
                    else notify(`Deve essere selezionata un' analisi per poterla aprire.`, 'warning', 3000)
                  }),
                ],
                dataSource: ArraySourceComposer(
                  'id',
                  task.ordine?.offerta?.analisi ? [task.ordine?.offerta?.analisi] : [],
                ),
                value: task.ordine?.offerta?.analisi ?? null,
                placeholder: 'Seleziona analisi...',
                // displayExpr: 'ded_Dis',
                displayExpr: customDisplayExpr,
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare analisi',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            ></SimpleItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={false} deferRendering={false} />
              <Tab title="note">
                <SimpleItem
                  dataField="note"
                  label={{ visible: false }}
                  editorType="dxHtmlEditor"
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: 370,
                    onValueChanged: onValueChangedHtmlEditor,
                    onInitialized: onInitializedHtmlEditor,
                  }}
                >
                  <RequiredRule message="Note obbligatorie"></RequiredRule>
                </SimpleItem>
              </Tab>
              {!creating && task?.stato?.id !== StatoTask.APERTO && (
                <Tab title="note chiusura">
                  <SimpleItem
                    dataField="note_completamento"
                    label={{ visible: false }}
                    editorType="dxHtmlEditor"
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      toolbar: toolbarHtmlEditor(currentScreenSize),
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: 370,
                      onValueChanged: onValueChangedHtmlEditor,
                      onInitialized: onInitializedHtmlEditor,
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {!creating && (
                <Tab title="Pianificazione">
                  <SimpleItem>
                    <TaskPlanner
                      task={task}
                      areActionEnabled={
                        !creating &&
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.Supervisor,
                          Roles.TechDeveloper,
                        ])
                      }
                    />
                  </SimpleItem>
                </Tab>
              )}
              {!creating && (
                <Tab title="Interventi">
                  <SimpleItem>
                    <TaskEditorFormInterventionsGrid task={task} userInfo={userInfo}></TaskEditorFormInterventionsGrid>
                  </SimpleItem>
                </Tab>
              )}
              {!creating && (
                <Tab title="Attività Lavorative">
                  <SimpleItem>
                    <TaskEditorFormHoursGrid task={task} userInfo={userInfo}></TaskEditorFormHoursGrid>
                  </SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 4 : 5}>
            <ButtonItem name="btn-hour">
              <ButtonOptions
                text="ORE"
                icon="clock"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/hours/new?taskId=${task.id}`, '_blank')
                }}
                disabled={
                  creating ||
                  task.stato?.id !== StatoTask.APERTO ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Supervisor,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-intervention">
              <ButtonOptions
                text="INTERVENTO"
                icon="car"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/interventions/new?taskId=${task.id}`, '_blank')
                }}
                disabled={
                  creating ||
                  task.stato?.id !== StatoTask.APERTO ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Supervisor,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-complete">
              <ButtonOptions
                text="CHIUDI"
                icon="check"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  popupCompleteTaskRef.current?.instance().show()
                }}
                disabled={
                  creating ||
                  task?.stato?.id !== StatoTask.APERTO ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Supervisor,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('task', task)
                  if (task) {
                    fetcher?.submit(JSON.stringify(task), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupCompleteTask
        stati={stati}
        task={task}
        refPopup={popupCompleteTaskRef}
        fetcher={fetcher}
      ></FormPopupCompleteTask>
      <FormPopupClientNote
        data={task.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === task.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default TaskEditorForm
