import React, { useRef } from 'react'
import styles from './User.module.scss'
import { BasicUserData } from './forms/BasicUserData'
import { Button } from 'devextreme-react'
import { ChildFormHandle } from '@/routes/admin/users/user/forms/types'
import { UserInfoPane } from './UserInfoPane'
import { useLoaderData } from 'react-router-typesafe'
import { userRouteLoader } from '@/routes/admin/users/user/User.route'

export function User() {
  const { user } = useLoaderData<typeof userRouteLoader>()
  const basicUserDataHandle = useRef<ChildFormHandle>(null)
  const detailedUserDataHandle = useRef<ChildFormHandle>(null)

  const handleSubmitForms = () => {
    basicUserDataHandle.current?.submit()
    detailedUserDataHandle.current?.submit()
  }

  return (
    <>
      <div className={'content-block'}>
        <div className={`${styles.layoutContainer}`}>
          <BasicUserData userId={user.data.id} ref={basicUserDataHandle} />
          <UserInfoPane infoId={user.data.info?.id} ref={detailedUserDataHandle} />
        </div>
        <Button text="Salva" type="success" onClick={handleSubmitForms} />
      </div>
    </>
  )
}
