import { clientEditorRouteAction, clientEditorRouteLoader } from '@/routes/clients/client/ClientEditor.route'
import ClientEditor from '@/routes/clients/client/ClientEditor'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faWrench } from '@fortawesome/free-solid-svg-icons'

export const ClientCreatorRoute = {
  path: 'new',
  element: (
    <>
      <h2 className={'content-block'}>{'Nuovo cliente'}</h2>
      <div className={'content-block dx-card responsive-paddings'}>
        <ClientEditor creating={true} />
      </div>
    </>
  ),
  loader: clientEditorRouteLoader,
  action: clientEditorRouteAction,
  handle: {
    crumb: () => ({
      label: 'Crea',
      icon: faWrench,
    }),
  },
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
