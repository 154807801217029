import '@/themes/generated/variables.css'
import { Outlet } from 'react-router-dom'
import { useScreenSizeClass } from '@/themes/media-query'
import React, { StrictMode, useEffect } from 'react'
import '@/routes/authenticated/AuthenticatedLayout.scss'
import { SideNavOuterToolbar } from '@/layouts'

export const AuthenticatedLayout = () => {
  const [title, setTitle] = React.useState('Qs Web Admin')
  return (
    <div className={`app ${useScreenSizeClass}`}>
      <SideNavOuterToolbar title={title}>
        <StrictMode>
          <Outlet />
        </StrictMode>
      </SideNavOuterToolbar>
    </div>
  )
}
