import { TicketActionDescriptions, TicketActions, TicketEditorFormProps } from '@/routes/tickets/TicketEditor.types'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  argomento_problema,
  azienda,
  email_aziendale,
  nota_ticket,
  piattaforma_ticket,
  qs_applicazione,
  qs_supporto_applicazione,
  sede,
  stato_ticket,
  ticket,
  user,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  EmailRule,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { ValueChangedEvent as ValueChangedEventTagBox } from 'devextreme/ui/tag_box'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ClickEvent } from 'devextreme/ui/button'
import { StatoTicket, TipologiaTicket } from '@/routes/tickets/TicketEditor.enums'
import { ValidationCallbackData } from 'devextreme/common'
import { DropDownButton, Popup, ScrollView, TextBox } from 'devextreme-react'
import TicketEditorCompleteForm from '@/routes/tickets/TicketEditor.complete'
import TicketEditorNoteForm from '@/routes/tickets/TicketEditor.note'
import './TicketEditor.form.scss'
import NoteView from '@/routes/tickets/notes/NoteView'
import TicketEditorEvents from '@/routes/tickets/TIcketEditor.events'
import AttachmentsManager from '@/components/file-manager/AttachmentsManager'
import { v4 as uuidv4 } from 'uuid'
import {
  customButton,
  isUserRoleAllowed,
  onInitializedHtmlEditor,
  onValueChangedHtmlEditor,
  toolbarHtmlEditor,
} from '@/routes/utils'
import { PopupRef } from 'devextreme-react/popup'
import { FileManagerRef } from 'devextreme-react/file-manager'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import notify from 'devextreme/ui/notify'
import { Roles } from '@/auth/azure/Roles'
import { DocumentPermissions } from '@/enums'
import AssignSelector from '@/routes/tickets/AssignSelector'
import { useScreenSize } from '@/themes/media-query'
import DropDownBoxDataGridItem from '@/components/ddb-datagrid-item/DropDownBoxDataGridItem'
import { TextBoxRef } from 'devextreme-react/cjs/text-box'
import {
  handleOnClickAssignTo,
  handleOnClickAssignToMe,
  handleOnClickDelete,
  handleOnClickRelease,
  handleOnClickRemoveAssignee,
  handleOnClickReopen,
  handleOnClickSendClosureNotice,
  handleOnClickSendContactNotice,
  handleOnClickSendOtherVendorNotice,
  handleOnClickSendSupportExpiredNotice,
  handleOnClickTakeCharge,
} from '@/routes/tickets/utils/utils'
import { TagBoxTypes } from 'devextreme-react/cjs/tag-box'

const TicketEditorForm = (props: TicketEditorFormProps) => {
  const {
    creating,
    ticket,
    states,
    platforms,
    applications,
    clients,
    problemCategories,
    categories,
    employees,
    problemArguments,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props
  const newTicketNote: nota_ticket = {
    id: 0,
    data: null,
    inviata: false,
    testo: null,
    ticket,
    chiusura: false,
    tag: uuidv4(),
  }
  const { clientId } = useParams()
  const { service, client } = useQsAdminApiManager()
  const [formData, setFormData] = useState<ticket | null | undefined>(ticket)

  const popupActionRef = useRef<PopupRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const fileManagerRef = useRef<FileManagerRef>(null)

  const currentScreenSize = useScreenSize()
  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)

  const [popup, setPopup] = useState<{
    visible: boolean
    action: TicketActions
    ticket?: ticket | null
    complete?: { ticket: ticket | null | undefined; closure: string; solution: string }
    note?: nota_ticket
  }>({
    visible: false,
    action: TicketActions.None,
  })
  const textBoxAssistenzaTelefonicaRef = useRef<TextBoxRef>(null)
  const [loadPanelVisible, setLoadPanelVisible] = useState(false)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [disjointedFieldChanged, setDisjointedFieldChanged] = useState<boolean>(false)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? clients[0] : ticket?.sede?.azienda,
  )

  const [sediList, setSediList] = useState<sede[] | undefined>(ticket?.sede?.azienda?.sedi)
  const [problemArgumentsList, setProblemArgumentsList] = useState<argomento_problema[] | undefined>(problemArguments)

  const popupAssignRef = useRef<PopupRef>(null)
  const [assignToData, setAssignToData] = useState<{
    formData: {
      technician: user | null | undefined
    }
    onSubmitEditor: (
      e: any,
      params: {
        ticketId: string | null | undefined
        userId: number | null | undefined
      },
    ) => Promise<void>
    tecniciList: (user | null | undefined)[]
    ticket: ticket | null | undefined
  }>()

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.expanding('assistenze', (assistenzeBuilder, assistenza) => {
              assistenzeBuilder.expanding('telefonica', (telefonicaBuilder, telefonica) => {
                telefonicaBuilder.select('id', 'nome')
              })
              assistenzeBuilder.select('id', 'sospesa', 'telefonica')
            })
            sediAziendaSedeBuilder.select('id', 'nome', 'note', 'assistenze')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.expanding('assistenze', (assistenzeBuilder, assistenza) => {
          assistenzeBuilder.expanding('telefonica', (telefonicaBuilder, telefonica) => {
            telefonicaBuilder.select('id', 'nome')
          })
          assistenzeBuilder.select('id', 'sospesa', 'telefonica')
        })
        builder.select('id', 'nome', 'note', 'azienda', 'assistenze')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getProblemArguments = async (
    applicationName: string | null | undefined,
    platformName: string | null | undefined,
  ) => {
    if (applicationName || platformName) {
      const problemArgumentsData = await service.argomento_problema().query((builder, argomentoProblema) => {
        builder.filter(
          applicationName && applicationName !== 'Non Presente'
            ? argomentoProblema.qs_applicazione.props.nome.eq(applicationName)
            : argomentoProblema.piattaforma.props.nome.eq(platformName ?? ''),
        )
        builder.select('id', 'nome', 'note')
        builder.orderBy(argomentoProblema.ord.asc())
      })
      return [...new Map(problemArgumentsData.data.value.map((arg: argomento_problema) => [arg['nome'], arg])).values()]
    } else {
      return []
    }
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediList(sites)
        formRef.current?.instance().updateData('sede', sites[0])
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    console.log('set formData', ticket)
    setFormData(ticket)
  }, [ticket])

  useEffect(() => {
    if (!formRef.current?.instance().option().isDirty && disjointedFieldChanged)
      formRef.current?.instance().option('isDirty', true)
    setDisjointedFieldChanged(false)
  }, [disjointedFieldChanged])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'ticket_form'}
        formData={formData}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="ticketValidation"
        ref={formRef}
        readOnly={
          !!(
            !isRoleAllowed ||
            (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
            (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
          )
        }
      >
        <GroupItem colCount={4}>
          <GroupItem colSpan={2}>
            <GroupItem caption={`Dati Cliente`}>
              <GroupItem colCount={4}>
                <SimpleItem
                  colSpan={2}
                  label={{ text: 'Azienda' }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    buttons: [
                      customButton('open', (e: ClickEvent) => {
                        if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                        else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                      }),
                      { name: 'dropDown', location: 'after' },
                    ],
                    value: currentCompany,
                    dataSource: ArraySourceComposer('id', clients),
                    placeholder: "Seleziona l'azienda...",
                    displayExpr: 'nome',
                    searchEnabled: true,
                    readOnly:
                      !isRoleAllowed ||
                      (clientId
                        ? true
                        : (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                            (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                          ? true
                          : false),
                    dropDownOptions: {
                      showTitle: true,
                      title: "Selezionare l'azienda",
                      hideOnOutsideClick: true,
                      width: currentScreenSize.isLarge ? '150%' : '',
                      resizeEnabled: currentScreenSize.isLarge ? true : false,
                    },
                    showClearButton: true,
                    onValueChanged: (e: ValueChangedEventSelectBox) => {
                      if (e.previousValue?.id === e.value?.id) return
                      console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                      if (e.value) {
                        setCurrentCompany(e.value)
                        setCurrentCompanyChanged(true)
                      }
                    },
                  }}
                >
                  <CustomRule
                    message="Azienda obbligatoria se stato 'ASSEGNATO'"
                    validationCallback={({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                    }}
                  />
                  <CustomRule
                    message="Azienda obbligatoria se stato 'COMPLETATO'"
                    validationCallback={({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                    }}
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="sede"
                  label={{ text: 'Sede' }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    buttons: [
                      customButton(
                        'info',
                        () => {
                          console.log('ticket.sede', ticket?.sede)
                          if (ticket?.sede) {
                            popupNoteClienteRef.current?.instance().show()
                          } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                        },
                        false,
                        'Note cliente',
                      ),
                      {
                        name: 'clear',
                        location: 'after',
                      },
                      { name: 'dropDown', location: 'after' },
                    ],
                    dataSource: ArraySourceComposer('id', sediList),
                    placeholder: 'Seleziona la sede...',
                    displayExpr: 'nome',
                    searchEnabled: true,
                    showDropDownButton: true,
                    dropDownButtonTemplate: 'dropDownButton',
                    dropDownOptions: {
                      showTitle: true,
                      title: 'Selezionare la sede',
                      hideOnOutsideClick: true,
                    },
                    readOnly:
                      !isRoleAllowed ||
                      (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                      (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                        ? true
                        : false,
                    onValueChanged: (e: ValueChangedEventSelectBox) => {
                      if (e.previousValue?.id === e.value?.id) return
                      console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                      textBoxAssistenzaTelefonicaRef.current
                        ?.instance()
                        .option(
                          'value',
                          e.value.assistenze?.[0]?.sospesa
                            ? 'SOSPESA'
                            : (e.value.assistenze?.[0]?.telefonica?.nome ?? ''),
                        )
                    },
                  }}
                >
                  <CustomRule
                    message="Sede obbligatoria se stato 'ASSEGNATO'"
                    validationCallback={({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.ASSEGNATO && !value ? false : true
                    }}
                  ></CustomRule>
                  <CustomRule
                    message="Sede obbligatoria se stato 'COMPLETATO'"
                    validationCallback={({ value }: ValidationCallbackData) => {
                      return ticket?.stato?.id === StatoTicket.COMPLETATO && !value ? false : true
                    }}
                  ></CustomRule>
                </SimpleItem>
                <SimpleItem label={{ text: 'Assistenza Telefonica' }}>
                  <TextBox
                    ref={textBoxAssistenzaTelefonicaRef}
                    readOnly={true}
                    className={`dx-texteditor-input assistenza-telefonica ${
                      !ticket?.sede?.assistenze || ticket.sede.assistenze?.length === 0
                        ? ''
                        : ticket?.sede?.assistenze?.[0].sospesa
                          ? 'sospesa'
                          : (ticket?.sede?.assistenze?.[0].telefonica?.nome?.toLowerCase() ?? '')
                    }`}
                    defaultValue={
                      !ticket?.sede?.assistenze || ticket.sede.assistenze?.length === 0
                        ? ''
                        : ticket?.sede?.assistenze?.[0].sospesa
                          ? 'SOSPESA'
                          : (ticket?.sede?.assistenze?.[0].telefonica?.nome ?? '')
                    }
                  ></TextBox>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={4}>
                <GroupItem colCount={2} colSpan={3}>
                  <SimpleItem dataField="leg_contatto" editorType="dxTextBox" label={{ text: 'Contatto' }}>
                    <RequiredRule message="Contatto obbligatorio"></RequiredRule>
                  </SimpleItem>
                  <SimpleItem
                    dataField="leg_email_contatto"
                    editorType="dxTextBox"
                    label={{ text: 'Email Riferimento' }}
                  >
                    <RequiredRule message="Email riferimento obbligatoria"></RequiredRule>
                    <EmailRule message="Inserire email riferimento valida"></EmailRule>
                  </SimpleItem>
                </GroupItem>
                <SimpleItem
                  dataField="leg_phone_contatto"
                  editorType="dxTextBox"
                  label={{ text: 'Telefono' }}
                  editorOptions={{
                    buttons: [
                      customButton('call', (e: ClickEvent) => {
                        if (ticket?.leg_phone_contatto)
                          window.open(`tel:${ticket?.leg_phone_contatto.replace(/\s+/g, '')}`, '_self')
                        else notify('Nessun numero di telefono da chiamare.', 'warning', 2000)
                      }),
                    ],
                  }}
                ></SimpleItem>
              </GroupItem>
              <GroupItem colCount={1}>
                <SimpleItem
                  dataField="email_addizionali"
                  label={{ text: `Email Addizionali` }}
                  editorType="dxTagBox"
                  editorOptions={{
                    placeholder: 'Inserisci email addizionali...',
                    acceptCustomValue: true,
                    displayExpr: 'email',
                    openOnFieldClick: false,
                    onValueChanged: (e: ValueChangedEventTagBox) => {
                      console.log('onValueChanged', e.previousValue, e.value)
                    },
                    onCustomItemCreating: (e: TagBoxTypes.CustomItemCreatingEvent) => {
                      const newValue = e.text
                      if (newValue) {
                        console.log('new value', newValue)
                        const regexp = new RegExp(
                          /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Z\\a-z-]+\.)+[A-Za-z]{2,}))$/,
                        )
                        if (regexp.test(newValue) && newValue !== formData?.leg_email_contatto) {
                          const newEmail: email_aziendale = {
                            id: 0,
                            email: newValue,
                            note: null,
                            rif_ad: false,
                          }
                          const isEmailInValues = e.component
                            .option()
                            .value?.find((email: email_aziendale) => email.email === newValue)
                          if (isEmailInValues) {
                            console.log('isItemInValues', isEmailInValues)
                            e.customItem = null
                          } else {
                            console.log('not isItemInValues', isEmailInValues)
                            e.customItem = newEmail
                          }
                        } else {
                          e.customItem = null
                        }
                      } else e.customItem = null
                    },
                  }}
                ></SimpleItem>
              </GroupItem>
            </GroupItem>
            <GroupItem caption="Dati Ticket">
              <GroupItem colCount={3}>
                <SimpleItem
                  dataField="stato"
                  label={{ text: 'Stato' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer(
                      'id',
                      creating
                        ? states.filter((state: stato_ticket) => {
                            return state.id === StatoTicket.APERTO
                          })
                        : states,
                    ),
                    readOnly: true,
                    placeholder: 'Seleziona lo stato...',
                    displayExpr: 'nome',
                    searchEnabled: true,
                    dropDownCentered: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: 'Selezionare lo stato',
                      hideOnOutsideClick: true,
                    },
                    onValueChanged: (e: ValueChangedEvent) => {
                      if (e.previousValue?.id === e.value?.id) return
                      console.log('STATE EVENT CHANGED', e.previousValue, e.value)
                      if (e.value.id === StatoTicket.APERTO) formRef.current?.instance().updateData('assegnato', null)
                    },
                  }}
                >
                  <RequiredRule message="Stato obbligatorio" />
                </SimpleItem>
                <SimpleItem
                  dataField="assegnato"
                  label={{ text: 'Assegnato' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer('id', employees),
                    placeholder: 'Seleziona utente...',
                    displayExpr: 'fullname',
                    searchEnabled: true,
                    dropDownCentered: true,
                    readOnly: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: `Selezionare l'utente`,
                      hideOnOutsideClick: true,
                    },
                    showClearButton: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="incaricato"
                  label={{ text: 'Incaricato' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer('id', employees),
                    placeholder: 'Seleziona utente...',
                    displayExpr: 'fullname',
                    searchEnabled: true,
                    dropDownCentered: true,
                    readOnly: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: `Selezionare l'utente`,
                      hideOnOutsideClick: true,
                    },
                    showClearButton: true,
                  }}
                ></SimpleItem>
              </GroupItem>
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="tipologia_ticket"
                  label={{ text: 'Categoria' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer('id', categories),
                    placeholder: 'Seleziona categoria...',
                    displayExpr: 'nome',
                    searchEnabled: true,
                    dropDownCentered: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: `Selezionare la categoria`,
                      hideOnOutsideClick: true,
                    },
                  }}
                >
                  <RequiredRule message="Categoria obbligatoria" />
                </SimpleItem>
                <SimpleItem
                  dataField="durata"
                  editorType="dxNumberBox"
                  label={{ text: 'Durata' }}
                  editorOptions={{
                    showSpinButtons: true,
                    min: 0,
                    step: 1,
                  }}
                >
                  <RequiredRule message="Durata obbligatoria"></RequiredRule>
                </SimpleItem>
                <SimpleItem dataField="urgente" label={{ text: 'Urgente' }} editorType="dxSwitch" />
                <SimpleItem dataField="attesa" label={{ text: 'Attesa' }} editorType="dxSwitch" />
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem label={{ text: `Piattaforma` }}>
                  <DropDownBoxDataGridItem
                    object={formData?.piattaforma}
                    values={platforms}
                    label="Piattaforma"
                    displayExprField={'nome'}
                    formValidation="ticketValidation"
                    columns={[
                      { dataField: 'nome', width: '200' },
                      { dataField: 'note', width: '800' },
                    ]}
                    readOnly={
                      !!(
                        !isRoleAllowed ||
                        (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                        (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                      )
                    }
                    onChange={(newValue: any) => {
                      setFormData((prev) => {
                        if (!prev) {
                          return prev
                        }
                        console.log('newvalue piattaforma', newValue)
                        return {
                          ...prev,
                          piattaforma: newValue,
                        }
                      })
                      getProblemArguments(
                        formData?.applicazione ? formData.applicazione.nome : null,
                        newValue ? newValue.nome : null,
                      ).then((args: argomento_problema[]) => {
                        console.log('ARGS', args)
                        setProblemArgumentsList(args)
                        setDisjointedFieldChanged(true)
                      })
                    }}
                    validationRules={[
                      {
                        type: 'custom',
                        message: `Piattaforma diversa da 'Non Presente' se applicazione uguale a 'Non Presente' quando tipologia è 'ASSISTENZA TECNICA'`,
                        reevaluate: true,
                        validationCallback: (options: ValidationCallbackData) => {
                          console.log('Validation called:', new Date().toISOString(), options)
                          const piattaforma: piattaforma_ticket = options.value
                          return piattaforma &&
                            piattaforma.nome === formData?.applicazione?.nome &&
                            piattaforma.nome === 'Non Presente' &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                            ? false
                            : true
                        },
                      },
                      {
                        type: 'custom',
                        message: `Piattaforma obbligatoria se stato 'COMPLETATO'`,
                        validationCallback: ({ value }: ValidationCallbackData) => {
                          return formData?.stato?.id === StatoTicket.COMPLETATO &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA &&
                            !value
                            ? false
                            : true
                        },
                      },
                    ]}
                  ></DropDownBoxDataGridItem>
                </SimpleItem>
                <SimpleItem label={{ text: `Software Qs` }}>
                  <DropDownBoxDataGridItem
                    object={formData?.applicazione}
                    values={applications}
                    label="Software Qs"
                    displayExprField={'nome'}
                    formValidation="ticketValidation"
                    columns={[
                      { dataField: 'nome', width: '200' },
                      { dataField: 'note', width: '200' },
                    ]}
                    readOnly={
                      !!(
                        !isRoleAllowed ||
                        (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                        (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                      )
                    }
                    onChange={(newValue: any) => {
                      setFormData((prev) => {
                        if (!prev) {
                          return prev
                        }
                        console.log('newvalue applicazione', newValue)
                        return {
                          ...prev,
                          applicazione: newValue,
                        }
                      })
                      getProblemArguments(
                        newValue ? newValue.nome : null,
                        formData?.piattaforma ? formData.piattaforma.nome : null,
                      ).then((args: argomento_problema[]) => {
                        console.log('ARGS', args)
                        setProblemArgumentsList(args)
                        setDisjointedFieldChanged(true)
                      })
                    }}
                    validationRules={[
                      {
                        type: 'custom',
                        message: `Software Qs diverso da 'Non Presente' se piattaforma uguale a 'Non Presente' quando tipologia è 'ASSISTENZA TECNICA'`,
                        reevaluate: true,
                        validationCallback: (options: ValidationCallbackData) => {
                          console.log('Validation called:', new Date().toISOString(), options)
                          const applicazione: qs_supporto_applicazione = options.value
                          return applicazione &&
                            applicazione.nome === formData?.piattaforma?.nome &&
                            applicazione.nome === 'Non Presente' &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                            ? false
                            : true
                        },
                      },
                      {
                        type: 'custom',
                        message: `Software QS obbligatorio se stato 'COMPLETATO'`,
                        validationCallback: ({ value }: ValidationCallbackData) => {
                          return formData?.stato?.id === StatoTicket.COMPLETATO &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA &&
                            !value
                            ? false
                            : true
                        },
                      },
                    ]}
                  ></DropDownBoxDataGridItem>
                </SimpleItem>
                <SimpleItem label={{ text: `Categoria Problema` }}>
                  <DropDownBoxDataGridItem
                    object={formData?.tipologia_problema}
                    values={problemCategories}
                    label="Categoria Problema"
                    displayExprField={'nome'}
                    formValidation="ticketValidation"
                    columns={[
                      { dataField: 'nome', width: '250' },
                      { dataField: 'note', width: '300' },
                    ]}
                    readOnly={
                      !!(
                        !isRoleAllowed ||
                        (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                        (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                      )
                    }
                    onChange={(newValue: any) => {
                      setFormData((prev) => {
                        if (!prev) {
                          return prev
                        }
                        console.log('newvalue tipologia problema', newValue)
                        return {
                          ...prev,
                          tipologia_problema: newValue,
                        }
                      })
                      setDisjointedFieldChanged(true)
                    }}
                    validationRules={[
                      {
                        type: 'custom',
                        message: `Categoria Problema obbligatoria se stato 'COMPLETATO'`,
                        validationCallback: ({ value }: ValidationCallbackData) => {
                          return formData?.stato?.id === StatoTicket.COMPLETATO &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA &&
                            !value
                            ? false
                            : true
                        },
                      },
                    ]}
                  ></DropDownBoxDataGridItem>
                </SimpleItem>
                <SimpleItem label={{ text: `Argomento Problema` }}>
                  <DropDownBoxDataGridItem
                    object={formData?.argomento_problema}
                    values={problemArgumentsList}
                    label="Argomento Problema"
                    displayExprField={'nome'}
                    formValidation="ticketValidation"
                    columns={[
                      { dataField: 'nome', width: '250' },
                      { dataField: 'note', width: '800' },
                    ]}
                    readOnly={
                      !!(
                        !isRoleAllowed ||
                        (!creating && ticket?.stato?.id === StatoTicket.APERTO) ||
                        (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
                      )
                    }
                    onChange={(newValue: any) => {
                      setFormData((prev) => {
                        if (!prev) {
                          return prev
                        }
                        console.log('newvalue argomento', newValue)
                        return {
                          ...prev,
                          argomento_problema: newValue,
                        }
                      })
                      setDisjointedFieldChanged(true)
                    }}
                    validationRules={[
                      {
                        type: 'custom',
                        message: `Argomento Problema obbligatorio se stato 'COMPLETATO'`,
                        validationCallback: ({ value }: ValidationCallbackData) => {
                          return formData?.stato?.id === StatoTicket.COMPLETATO &&
                            formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA &&
                            !value
                            ? false
                            : true
                        },
                      },
                    ]}
                  ></DropDownBoxDataGridItem>
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colSpan={2}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              {!creating && (
                <Tab title="Note">
                  <SimpleItem
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{ readOnly: true }}
                    render={() => {
                      return (
                        <>
                          <ScrollView height={550} width={'100%'} direction="vertical">
                            <div>
                              <div id="chat-window">
                                {ticket?.note_ticket && ticket?.note_ticket?.length > 0
                                  ? ticket?.note_ticket?.map((nota: nota_ticket) => {
                                      return (
                                        <>
                                          <NoteView
                                            note={nota}
                                            readOnly={
                                              !isRoleAllowed ||
                                              nota.chiusura ||
                                              nota.inviata ||
                                              !nota.utente ||
                                              (nota.utente && nota.utente.fullname !== userInfo?.name)
                                                ? true
                                                : false
                                            }
                                            onEditClick={(e: ClickEvent) => {
                                              setPopup({
                                                visible: true,
                                                action:
                                                  !isRoleAllowed ||
                                                  nota.chiusura ||
                                                  nota.inviata ||
                                                  !nota.utente ||
                                                  (nota.utente && nota.utente.fullname !== userInfo?.name)
                                                    ? TicketActions.ShowTicketNote
                                                    : TicketActions.EditTicketNote,
                                                note: nota,
                                                ticket,
                                              })
                                              popupActionRef.current?.instance().show()
                                            }}
                                          ></NoteView>
                                        </>
                                      )
                                    })
                                  : 'Nessuna nota associata al ticket'}
                              </div>
                            </div>
                          </ScrollView>
                        </>
                      )
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              <Tab title="Motivo">
                <SimpleItem
                  dataField="descrizione"
                  label={{ visible: false }}
                  editorType="dxHtmlEditor"
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    readOnly: !creating,
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: 550,
                    onValueChanged: onValueChangedHtmlEditor,
                    onInitialized: onInitializedHtmlEditor,
                  }}
                >
                  <CustomRule
                    message="Motivo obbligatorio"
                    validationCallback={(options: ValidationCallbackData) => {
                      if (creating) return options.value ? true : false
                      else return true
                    }}
                  ></CustomRule>
                </SimpleItem>
              </Tab>
              {!creating && (
                <Tab title="Allegati">
                  <AttachmentsManager
                    fileManagerRef={fileManagerRef}
                    folderUrl={ticket?.attachmentsFolderUrl}
                    preliminarPermissionsAction={async () => {
                      try {
                        const response = await client.post(
                          '/api/ticket/drivepermissions',
                          {
                            objectId: ticket?.id,
                            path: ticket?.attachmentsFolderUrl,
                          },
                          {
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          },
                        )
                        return response.status === 200
                          ? (response.data as DocumentPermissions)
                          : DocumentPermissions.Deny
                      } catch (error) {
                        console.log('Errore in settaggio permessi sulla cartella allegati commessa', error)
                        return DocumentPermissions.Deny
                      }
                    }}
                    createDirectoryAction={async (rootFolderPath: string, name: string, conflictBehavior: string) => {
                      return await client.post(
                        '/api/ticket/createfolder',
                        {
                          rootFolder: rootFolderPath,
                          newFolderName: name,
                          conflictBehavior,
                          objectId: ticket?.id,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                    }}
                  />
                </Tab>
              )}
              {!creating && (
                <Tab title="Eventi">
                  <TicketEditorEvents ticket={ticket}></TicketEditorEvents>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && (
                <Tab title="Soluzione">
                  <SimpleItem
                    dataField="soluzione"
                    label={{ visible: false }}
                    editorType="dxHtmlEditor"
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      readOnly: true,
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: 400,
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                <Tab title="Rating">
                  <SimpleItem
                    dataField="rating_note"
                    label={{ visible: false }}
                    editorType="dxHtmlEditor"
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      readOnly: true,
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: 400,
                    }}
                  ></SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 4}>
            {!creating && (
              <SimpleItem>
                <DropDownButton
                  id="ddbAzioni-ticket"
                  text="Azioni"
                  useItemTextAsTitle={false}
                  items={[
                    {
                      icon: 'email',
                      text: 'Invia nota',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.APERTO &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                      onClick: (e: ClickEvent) => {
                        const validationResult = formRef.current?.instance().validate()
                        if (!validationResult?.isValid) return
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          setPopup({ visible: true, action: TicketActions.SendTicketNote, note: newTicketNote, ticket })
                          popupActionRef.current?.instance().show()
                        }
                      },
                    },
                    {
                      icon: 'comment',
                      text: 'Nota interna',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Administrator,
                          Roles.Supervisor,
                        ]) && !creating,
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          setPopup({
                            visible: true,
                            action: TicketActions.CreateTicketNote,
                            note: newTicketNote,
                            ticket,
                          })
                          popupActionRef.current?.instance().show()
                        }
                      },
                    },
                    {
                      icon: 'user',
                      text: `Assegna a me`,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.assegnato,
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickAssignToMe(ticket, userInfo, fetcher, employees)
                      },
                    },
                    {
                      icon: 'user',
                      text: `Assegna a`,
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Supervisor]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.assegnato,
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickAssignTo(ticket, employees, fetcher, setAssignToData, popupAssignRef)
                      },
                    },
                    {
                      icon: 'cart',
                      text: `Prendi in carico`,
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickTakeCharge(ticket, userInfo, fetcher)
                      },
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        !ticket?.incaricato &&
                        (!ticket?.assegnato || ticket.assegnato.email === userInfo?.email)
                          ? true
                          : false,
                    },
                    {
                      icon: 'arrowback',
                      text: 'Rimuovi assegnazione',
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickRemoveAssignee(ticket, userInfo, fetcher)
                      },
                      visible:
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        (isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Supervisor]) ||
                          (isUserRoleAllowed(userInfo?.roles, [Roles.TechDeveloper]) &&
                            ticket.assegnato.email === userInfo?.email)),
                    },
                    {
                      icon: 'arrowback',
                      text: 'Rilascia',
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickRelease(ticket, userInfo, fetcher)
                      },
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.incaricato &&
                        ticket?.incaricato.email === userInfo?.email,
                    },
                    {
                      icon: 'check',
                      text: 'Completa',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.APERTO &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                      onClick: (e: ClickEvent) => {
                        const validationResult = formRef.current?.instance().validate()
                        if (!validationResult?.isValid) return
                        if (formRef.current?.instance().option().isDirty) {
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                          return
                        }
                        if (
                          !formData?.piattaforma &&
                          formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                        ) {
                          notify(`Valorizzare piattaforma`, 'warning', 3000)
                          return
                        }
                        if (
                          !formData?.applicazione &&
                          formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                        ) {
                          notify(`Valorizzare software Qs`, 'warning', 3000)
                          return
                        }
                        if (
                          !formData?.tipologia_problema &&
                          formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                        ) {
                          notify(`Valorizzare categoria problema`, 'warning', 3000)
                          return
                        }
                        if (
                          !formData?.argomento_problema &&
                          formData?.tipologia_ticket?.id === TipologiaTicket.ASSISTENZA_TECNICA
                        ) {
                          notify(`Valorizzare argomento problema`, 'warning', 3000)
                          return
                        }
                        setPopup({
                          visible: true,
                          action: TicketActions.Complete,
                          complete: { closure: '', solution: '', ticket },
                        })
                        popupActionRef.current?.instance().show()
                      },
                    },
                    {
                      icon: 'revert',
                      text: 'Riapri',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        !creating &&
                        ticket?.stato?.id === StatoTicket.COMPLETATO
                          ? true
                          : false,
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickReopen(ticket, userInfo, fetcher)
                      },
                    },
                    {
                      icon: 'close',
                      text: 'Elimina',
                      onClick: (e: ClickEvent) => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else handleOnClickDelete(ticket, fetcher)
                      },
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        !creating &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket?.assegnato.email === userInfo?.email
                          ? true
                          : false,
                    },
                    {
                      text: 'Invia notifica chiusura',
                      icon: 'bell',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket.assegnato.email === userInfo?.email,
                      onClick: () => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          handleOnClickSendClosureNotice(ticket, client, undefined, setLoadPanelVisible)
                        }
                      },
                    },
                    {
                      text: 'Invia notifica contatto',
                      icon: 'bell',
                      visible:
                        isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.TechDeveloper,
                          Roles.Supervisor,
                        ]) &&
                        ticket?.stato?.id !== StatoTicket.COMPLETATO &&
                        ticket?.stato?.id !== StatoTicket.ELIMINATO &&
                        ticket?.assegnato &&
                        ticket.assegnato.email === userInfo?.email,
                      onClick: () => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          handleOnClickSendContactNotice(ticket, client, undefined, setLoadPanelVisible)
                        }
                      },
                    },
                    {
                      text: 'Invia notifica assistenza scaduta',
                      icon: 'bell',
                      visible: isRoleAllowed,
                      onClick: async () => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          handleOnClickSendSupportExpiredNotice(ticket, client, undefined, setLoadPanelVisible)
                        }
                      },
                    },
                    {
                      text: 'Invia notifica altro fornitore',
                      icon: 'bell',
                      visible: isRoleAllowed,
                      onClick: async () => {
                        if (formRef.current?.instance().option().isDirty)
                          notify(
                            `Salvare o annullare le modifiche sul ticket prima di procedere con l'azione.`,
                            'warning',
                            3000,
                          )
                        else {
                          handleOnClickSendOtherVendorNotice(ticket, client, undefined, setLoadPanelVisible)
                        }
                      },
                    },
                  ]}
                  dropDownOptions={{ width: '250' }}
                  width={'150px'}
                />
              </SimpleItem>
            )}
            <ButtonItem name="btn-historic">
              <ButtonOptions
                text="Storico"
                icon="eyeopen"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  window.open(`/tickets/historic`, '_blank')
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok', formData)
                  if (formData) {
                    console.log('formData', formData)
                    fetcher?.submit(JSON.stringify(formData), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <Popup
        width={300}
        height={'auto'}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title={'Assegna a'}
        ref={popupAssignRef}
        className="assignto-popup-content"
        resizeEnabled={false}
      >
        <AssignSelector data={assignToData} />
      </Popup>
      <Popup
        ref={popupActionRef}
        id="ticket_action_popup"
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={TicketActionDescriptions[popup.action]}
        container=".dx-viewport"
        resizeEnabled={false}
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 1000 : currentScreenSize.isXSmall ? 350 : 700}
        height={currentScreenSize.isXSmall ? 700 : currentScreenSize.isSmall ? 300 : 'auto'}
      >
        {popup.action === TicketActions.Complete ? (
          <TicketEditorCompleteForm
            complete={popup.complete}
            action={popup.action}
            onSaveClick={() => {
              fetcher?.submit(JSON.stringify(popup.complete), {
                method: 'POST',
                action: `/tickets/${ticket?.id}/complete`,
                encType: 'application/json',
              })
              popupActionRef.current?.instance().hide()
            }}
          ></TicketEditorCompleteForm>
        ) : (
          <TicketEditorNoteForm
            note={popup.note}
            action={popup.action}
            readOnly={
              (popup.action === TicketActions.EditTicketNote || popup.action === TicketActions.ShowTicketNote) &&
              (popup.note?.chiusura ||
                popup.note?.inviata ||
                !popup.note?.utente ||
                (popup.note?.utente && popup.note?.utente.fullname !== userInfo?.name))
                ? true
                : false
            }
            onSaveClick={async (files: File[] | undefined) => {
              const formData = new FormData()
              formData.append(
                'ticketNote',
                JSON.stringify({
                  ticketId: ticket?.ded_Dis,
                  hasToBeSent: popup.note?.inviata ? true : popup.action === TicketActions.SendTicketNote,
                  content: popup.note?.testo,
                  tag: popup.note?.tag,
                  ticketNoteId: popup.note?.id,
                }),
              )
              files?.forEach((file: File, index: number) => {
                formData.append(`files`, file)
              })
              fetcher?.submit(formData, {
                method: popup.action === TicketActions.EditTicketNote ? 'PUT' : 'POST',
                action: `/tickets/${ticket?.id}/ticketnote`,
                encType: 'multipart/form-data',
              })
            }}
          ></TicketEditorNoteForm>
        )}
      </Popup>
      <FormPopupClientNote
        data={ticket?.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediList(sites)
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === ticket?.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default TicketEditorForm
