import React, { useReducer } from 'react'
import { Popup, ResponsiveBox, Scheduler } from 'devextreme-react'
import { Resource, SchedulerTypes, View } from 'devextreme-react/cjs/scheduler'
import CustomStore from 'devextreme/data/custom_store'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { formatDate } from 'devextreme/localization'
import { Col, Item as ResponsiveItem, Location, Row } from 'devextreme-react/responsive-box'
import { calendarPageRouteLoader } from '@/routes/calendar/CalendarPage.route'
import { useLoaderData } from 'react-router-typesafe'
import { useScreenSize } from '@/themes/media-query'
import { create } from 'zustand'
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware'
import { Column, FilterRow, Selection, StateStoring, TreeList, TreeListTypes } from 'devextreme-react/tree-list'
import '../calendar/CalendarPage.scss'
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'

export type SchedulerViewType =
  | 'agenda'
  | 'day'
  | 'month'
  | 'timelineDay'
  | 'timelineMonth'
  | 'timelineWeek'
  | 'timelineWorkWeek'
  | 'week'
  | 'workWeek'

export interface BaseCalendarState {
  selectedUserIds: string[]
  currentSchedulerView: SchedulerViewType
}

export interface CalendarState extends BaseCalendarState {
  setSelectedUserIds: (selectedUserIds: string[]) => void
  setCurrentSchedulerView: (schedulerView: SchedulerViewType) => void
}

export const DEFAULT_CALENDAR_PROPS: BaseCalendarState = {
  selectedUserIds: [],
  currentSchedulerView: 'workWeek',
}

export const createCalendarStore = () => {
  return create<CalendarState>()(
    devtools(
      persist(
        subscribeWithSelector((set, get) => ({
          ...DEFAULT_CALENDAR_PROPS,
          setSelectedUserIds: (selectedUserIds: string[]) => set({ selectedUserIds }),
          setCurrentSchedulerView: (schedulerView: SchedulerViewType) => set({ currentSchedulerView: schedulerView }),
        })),
        {
          name: 'calendar-store',
        },
      ),
    ),
  )
}

export const useCalendarStore = createCalendarStore()

export default function CalendarPage() {
  const { impiegati, userInfo, groupedImpiegati } = useLoaderData<typeof calendarPageRouteLoader>()
  const currentScreenSize = useScreenSize()
  const { client } = useQsAdminApiManager()
  const currentDate = new Date()
  const calendarStore = useCalendarStore()
  type popupStateType = {
    popupVisible: boolean
    popupTitle: string
    editData: any
  }

  const initState: popupStateType = {
    popupVisible: false,
    popupTitle: '',
    editData: {},
  }

  const reducer = (state: popupStateType, action: { popupVisible: boolean; editData: any }) => {
    return { ...state, ...action }
  }

  const [state, dispatch] = useReducer(reducer, initState)

  const schedulerDataSource = new CustomStore({
    load: (loadOptions) => {
      const filterRequest = {
        filter: loadOptions.filter, // Default filter from Scheduler
        users: calendarStore.selectedUserIds, // Array of user IDs
      }

      return client
        .post(`${import.meta.env.VITE_QSADMINAPI_HOST}/api/calendar/load`, filterRequest)
        .then((response) => response.data.data)
        .catch((error) => {
          console.error('Error loading data:', error)
          throw error
        })
    },
  })

  type AppointmentProps = {
    data: { targetedAppointmentData: SchedulerTypes.Appointment }
  }

  const Appointment = (props: AppointmentProps) => {
    const { targetedAppointmentData } = props.data
    const foundUser = impiegati.find((item) => item.email === targetedAppointmentData.email)
    const isProvisional = targetedAppointmentData.Status === 'Tentative'
    const isAvailable = targetedAppointmentData.Status === 'Free'
    let mainDivStyle: React.CSSProperties = { backgroundColor: foundUser?.color }
    if (isProvisional) {
      mainDivStyle = {
        borderLeft: '6px solid transparent',
        borderImage: 'repeating-linear-gradient(45deg, white, white 5px, transparent 5px, transparent 10px) 8',
        paddingLeft: '4px',
        backgroundColor: foundUser?.color,
      }
    } else if (isAvailable) {
      mainDivStyle = {
        borderLeft: '4px solid white',
        paddingLeft: '4px',
        marginLeft: '2px',
        backgroundColor: foundUser?.color,
      }
    }
    return (
      <div style={mainDivStyle} className="showtime-preview">
        <div>
          <strong>
            {targetedAppointmentData.Subject} {isProvisional && <b>(Da confermare)</b>}{' '}
            {isAvailable && <b>(Disponibile)</b>}
          </strong>
        </div>
        <div>{targetedAppointmentData.Location}</div>
        <div>
          {formatDate(targetedAppointmentData.displayStartDate, 'shortTime')}
          {' - '}
          {formatDate(targetedAppointmentData.displayEndDate, 'shortTime')}
        </div>
      </div>
    )
  }

  const AppointmentTooltip = (props: AppointmentProps) => {
    const { targetedAppointmentData } = props.data
    const isProvisional = targetedAppointmentData.Status === 'Tentative'
    const isAvailable = targetedAppointmentData.Status === 'Free'
    const foundUser = impiegati.find((item) => item.email === targetedAppointmentData.email)
    const formatter = new Intl.DateTimeFormat('it-IT', {
      timeZone: 'Europe/Rome',
      hour: '2-digit',
      minute: '2-digit',
    })

    let markerStyle: React.CSSProperties = { backgroundColor: foundUser?.color }
    if (isProvisional) {
      markerStyle = {
        backgroundImage: `repeating-linear-gradient(
          90deg,
          ${foundUser?.color} 0,
          ${foundUser?.color} 2px,
          white 2px,
          white 4px
        )`,
        backgroundSize: '8px 8px',
      }
    } else if (isAvailable) {
      markerStyle = {
        width: '18px', // Set the size of the circle
        height: '18px',
        borderRadius: '50%', // Make it round
        border: `4px solid ${foundUser?.color}`, // Set border color
        backgroundColor: 'white', // Inner white background
      }
    }

    return (
      <div className={'dx-tooltip-appointment-item'}>
        <div className={'dx-tooltip-appointment-item-marker'}>
          <div className={'dx-tooltip-appointment-item-marker-body'} style={markerStyle}></div>
        </div>
        <div className={'dx-tooltip-appointment-item-content'}>
          <div className={'dx-tooltip-appointment-item-content'}>
            <div className={'dx-tooltip-appointment-item-content-subject'}>
              <strong>{targetedAppointmentData.Subject}</strong>
            </div>
            <div className={'dx-tooltip-appointment-item-content-date'}>
              {isProvisional && <b>Da confermare</b>} {isAvailable && <b>Disponibile</b>}
              <div>{targetedAppointmentData.Location}</div>
              {!targetedAppointmentData.AllDay && (
                <div>
                  {formatter.format(new Date(targetedAppointmentData['start/dateTime']))}
                  {' - '}
                  {formatter.format(new Date(targetedAppointmentData['end/dateTime']))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const onAppointmentFormOpening = (e: SchedulerTypes.AppointmentFormOpeningEvent) => {
    e.cancel = true
    console.log('onAppointmentFormOpening', e)
    dispatch({ popupVisible: true, editData: { ...e.appointmentData } })
  }

  const AppointmentPopupRender = () => {
    const dateFormat = state.editData.AllDay ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'
    const foundUser = impiegati.find((item) => item.email === state.editData.email)
    const readableStatus =
      state.editData.Status === 'Free'
        ? 'Disponibile'
        : state.editData.Status === 'Tentative'
          ? 'Da confermare'
          : 'Occupato'
    state.editData = { ...state.editData, user: foundUser, readableStatus }
    return (
      <Form id={'appointment_popup_form'} formData={state.editData} labelLocation={'top'} colCount={1} readOnly={true}>
        <GroupItem colCount={2}>
          <SimpleItem dataField="Subject" label={{ text: 'Oggetto' }}></SimpleItem>
          <SimpleItem dataField="Location" label={{ text: 'Luogo' }}></SimpleItem>
          <SimpleItem dataField="user.fullname" label={{ text: 'Utente' }}></SimpleItem>
          <SimpleItem dataField="readableStatus" label={{ text: 'Disponibilità' }}></SimpleItem>
          <SimpleItem
            dataField="start/dateTime"
            editorType={'dxDateBox'}
            label={{ text: 'Data inizio' }}
            editorOptions={{ displayFormat: dateFormat }}
          ></SimpleItem>
          <SimpleItem
            dataField="end/dateTime"
            editorType={'dxDateBox'}
            label={{ text: 'Data Fine' }}
            editorOptions={{ displayFormat: dateFormat }}
          ></SimpleItem>
          <SimpleItem
            colSpan={2}
            dataField="Description"
            label={{ text: 'Note' }}
            editorType={'dxTextArea'}
            editorOptions={{
              height: '250px',
            }}
          ></SimpleItem>
        </GroupItem>
      </Form>
    )
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Calendario</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <ResponsiveBox>
          <Row screen="xs sm md lg" ratio={1}></Row>
          <Row screen="xs sm" ratio={1} />
          <Col screen="xs sm md lg" ratio={1}></Col>
          <Col screen="md" ratio={2} />
          <Col screen="lg" ratio={5} />
          <ResponsiveItem>
            <Location row={0} col={0} />
            <TreeList
              id="employees-structure"
              dataSource={groupedImpiegati}
              showRowLines={true}
              showBorders={true}
              columnAutoWidth={true}
              itemsExpr="items"
              dataStructure="tree"
              onSelectionChanged={(e: TreeListTypes.SelectionChangedEvent) => {
                const selectedData = e.component.getSelectedRowsData('leavesOnly')
                const emailArray = selectedData.map((item) => item.email)
                calendarStore.setSelectedUserIds(emailArray)
              }}
            >
              <Selection recursive={true} mode="multiple" />
              <StateStoring enabled={true} type="localStorage" storageKey="users-calendar-tree" />
              <FilterRow visible={true} />
              <Column dataField="fullname" caption="Utenti Qs Informatica" />
            </TreeList>
          </ResponsiveItem>
          <ResponsiveItem>
            <Location screen="md lg" row={0} col={1} />
            <Location screen="xs sm" row={1} col={0} />
            <Scheduler
              timeZone="Europe/Rome"
              dataSource={schedulerDataSource}
              defaultCurrentView={calendarStore.currentSchedulerView}
              defaultCurrentDate={currentDate}
              height={'100%'}
              startDayHour={5}
              endDayHour={22}
              remoteFiltering={true}
              dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
              textExpr="Subject"
              startDateExpr="start/dateTime"
              endDateExpr="end/dateTime"
              recurrenceRuleExpr=""
              descriptionExpr="Description"
              allDayExpr="AllDay"
              appointmentComponent={Appointment}
              appointmentTooltipComponent={AppointmentTooltip}
              onAppointmentFormOpening={onAppointmentFormOpening}
              editing={false}
              onCurrentViewChange={(view) => calendarStore.setCurrentSchedulerView(view as SchedulerViewType)}
            >
              <Resource
                label="Impiegato"
                fieldExpr="email"
                valueExpr={'email'}
                displayExpr="fullname"
                dataSource={impiegati}
                allowMultiple={false}
                useColorAsDefault={true}
              />
              <View type="day" name="Giorno" />
              <View type="week" name="Settimana" />
              <View type="workWeek" name="Settimana Lavorativa" />
              <View type="month" name="Mese" />
            </Scheduler>
          </ResponsiveItem>
        </ResponsiveBox>
        <Popup
          resizeEnabled={false}
          visible={state.popupVisible}
          width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 800 : currentScreenSize.isXSmall ? 350 : 700}
          height={currentScreenSize.isSmall ? 300 : 600}
          hideOnOutsideClick={true}
          showCloseButton={true}
          onHiding={(e) => {
            dispatch({
              popupVisible: false,
              editData: {},
            })
          }}
          title={state.editData.Subject}
          contentRender={AppointmentPopupRender}
        />
      </div>
    </React.Fragment>
  )
}
