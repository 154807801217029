import { PersonGrid } from '@/routes/contacts/person/PersonGrid'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import PersonCreatorRoute from '@/routes/contacts/person/PersonCreator.route'
import { Roles } from '@/auth/azure/Roles'
import { ContactsUnifierRoute } from '@/routes/contacts/ContactsUnifier.route'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faPerson } from '@fortawesome/free-solid-svg-icons'

export const personGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Contatti Persone` : 'Contatti Persone'

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getRapporti = qsAdminApi.rapporto().query((builder, rapporto) => {
    builder.select('id', 'nome')
    builder.orderBy(rapporto.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    title,
    sites: await getSites,
    rapporti: await getRapporti,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const PersonGridRoute = {
  path: 'people',
  children: [
    {
      index: true,
      element: <PersonGrid />,
      loader: personGridRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PersonCreatorRoute,
    ContactsUnifierRoute,
  ],
  faIcon: faPerson,
  text: 'Persone',
  handle: {
    crumb: () => ({
      label: 'Persone',
      icon: faPerson,
    }),
  },
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
