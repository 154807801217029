import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { PopoverRef } from 'devextreme-react/cjs/popover'
import { InitializedEvent } from 'devextreme/ui/select_box'
import { ad_quote } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { LineItemQuoteRequest, Offer, QuoteRequest } from '@/routes/quotes/QuoteEditor.types'
import {
  Connectivity,
  IntendedUsage,
  LineItemModel,
  OfferModel,
  TermModel,
} from '@/routes/quotes/line-items/LineItem.types'
import { GetQuoteDetailsResponse, Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { GetOpportunityResponse, LineItemGetOpportunity } from '@/routes/quotes/pws/GetOpportunity.type'

export const handleSubscriptionMouseOut = (
  event: MouseEvent,
  eventInitialized: InitializedEvent,
  popoverRef: React.RefObject<PopoverRef>,
) => {
  if (eventInitialized.component?.option().value) popoverRef.current?.instance().hide()
}

export const handleSubscriptionMouseOver = async (
  event: MouseEvent,
  eventInitialized: InitializedEvent,
  popoverRef: React.RefObject<PopoverRef>,
) => {
  const service = useQsAdminApiManager.getState().service
  if (eventInitialized.component?.option().value) {
    if (eventInitialized.component?.option().value.productName) {
      popoverRef.current?.instance().option('contentTemplate', function (contentElement: any) {
        const endDate = new Date(eventInitialized.component?.option().value.endDate)
        const product = eventInitialized.component?.option().value.productName
        const term = eventInitialized.component?.option().value.term
        const switchType = eventInitialized.component?.option().value.switchType
        return `<div><b>Prodotto: ${product ?? ''}</b></b><br><b>Scadenza: ${endDate ? endDate.toLocaleDateString() : ''}</b><br><b>Term: ${term ?? ''}</b><br><b>Switch Type: ${switchType ?? ''}</b></div>`
      })
    } else {
      const getSub = await service.ad_subscription().query((builder, subsciption) => {
        builder.filter(subsciption.subscriptionId.eq(eventInitialized.component?.option().value))
        builder.select('endDate', 'productName', 'term', 'switchType')
      })
      if (getSub.data.value.length > 0) {
        const endDate = new Date(getSub.data.value[0].endDate ?? '')
        const product = getSub.data.value[0].productName
        const term = getSub.data.value[0].term
        const switchType = getSub.data.value[0].switchType
        popoverRef.current
          ?.instance()
          .option(
            'contentTemplate',
            `<div><b>Prodotto: ${product ?? ''}</b></b><br><b>Scadenza: ${endDate ? endDate.toLocaleDateString() : ''}</b><br><b>Term: ${term ?? ''}</b><br><b>Switch Type: ${switchType ?? ''}</b></div>`,
          )
      } else
        popoverRef.current
          ?.instance()
          .option('contentTemplate', `<div><b>Dettaglio subscription non disponibile.</b></div>`)
    }
    popoverRef.current?.instance().option('target', eventInitialized.element)
    popoverRef.current?.instance().show()
  }
}

export const CreateItemModel = async (
  action: string,
  creating: boolean,
  index: number,
  lineItem?: LineItemGetOpportunity,
): Promise<LineItemModel> => {
  const service = useQsAdminApiManager.getState().service
  const iu: IntendedUsage = {
    code: 'COM',
    description: 'Commercial',
  }
  const conn: Connectivity = {
    code: 'C100',
    description: 'Online',
  }
  const item: LineItemModel = {
    lineNumber: index,
    operation: creating ? '' : 'Insert',
  }
  switch (action) {
    case 'NEW': {
      if (lineItem) {
        item.opportunityLineItemId = lineItem.id
        item.quantity = lineItem.quantity
        item.startDate = lineItem.startDate ? new Date(lineItem.startDate) : null
        const ad_product_catalog = await service.ad_product_catalog().query((builder, ad_prod_catalog) => {
          builder.filter(ad_prod_catalog.offeringId.eq(lineItem.offeringId))
        })
        item.productCatalog = ad_product_catalog.data.value[0]
      } else {
        const off: OfferModel = {
          term: null,
          intendedUsage: iu,
          connectivity: conn,
        }
        item.productCatalog = null
        item.quantity = 0
        item.startDate = null
        item.offer = off
      }
      item.action = 'New'
      break
    }
    case 'RENEWAL': {
      if (lineItem) {
        item.opportunityLineItemId = lineItem.id
        item.quantity = lineItem.quantity
        const ad_subscription = await service.ad_subscription().query((builder, ad_sub) => {
          builder.filter(ad_sub.subscriptionId.eq(lineItem.subscriptionId))
        })
        item.subscription = ad_subscription.data.value[0]
      } else {
        item.quantity = 0
        item.subscription = null
      }
      item.action = 'Renewal'

      break
    }
    case 'SWITCH PRODUCT': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.productCatalog = null
      item.action = 'Switch'
      item.quantity = 0
      item.referenceSubscription = null
      item.offer = off
      break
    }
    case 'SWITCH TERM': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.action = 'Switch'
      item.quantity = 0
      item.subscription = null
      item.offer = off
      break
    }
    case 'EXTENSION': {
      if (lineItem) {
        item.opportunityLineItemId = lineItem.id
        item.endDate = lineItem.endDate ? new Date(lineItem.endDate) : null
        const ad_subscription = await service.ad_subscription().query((builder, ad_sub) => {
          builder.filter(ad_sub.subscriptionId.eq(lineItem.subscriptionId))
        })
        item.subscription = ad_subscription.data.value[0]
      } else {
        item.subscription = null
        item.endDate = null
      }
      item.action = 'Extension'
      break
    }
    case 'TRUE-UP': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.action = 'True-Up'
      item.quantity = 0
      item.productCatalog = null
      item.startDate = null
      item.endDate = null
      item.offer = off
      break
    }
    case 'CO-TERM': {
      if (lineItem) {
        item.opportunityLineItemId = lineItem.id
        item.quantity = lineItem.quantity
        item.startDate = lineItem.startDate ? new Date(lineItem.startDate) : null
        const ad_subscription = await service.ad_subscription().query((builder, ad_sub) => {
          builder.filter(ad_sub.subscriptionId.eq(lineItem.referenceSubscriptionIds))
        })
        item.referenceSubscription = ad_subscription.data.value[0]
      } else {
        const off: OfferModel = {
          term: null,
          intendedUsage: iu,
          connectivity: conn,
        }
        item.quantity = 0
        item.productCatalog = null
        item.referenceSubscription = null
        item.startDate = null
        item.offer = off
      }
      item.action = 'Co-term'
      break
    }
  }
  return item
}

export const CreateQuoteRequest = (
  creating: boolean,
  quoteFormData: ad_quote,
  lineItems: LineItemModel[],
): QuoteRequest => {
  const quoteRequest: QuoteRequest = {
    azienda: quoteFormData?.azienda,
    techActivityRequired: quoteFormData.attivitaTecnicaRichiesta,
    language: quoteFormData.language,
    refCsn: quoteFormData.csn?.csn,
    commessa: quoteFormData.commessa,
    mainContact: quoteFormData.contact,
    admin: quoteFormData.admin ?? null,
    otherContacts: quoteFormData.contatti_addizionali,
    lineItems: [],
    skipDDACheck: quoteFormData.skipDDACheck,
    opportunityNumber: quoteFormData.opportunityNumber,
  }
  if (!creating) {
    quoteRequest.quoteNumber = quoteFormData.number
    quoteRequest.quoteId = quoteFormData.id
  }
  lineItems.map((item, index) => {
    const lineItem: LineItemQuoteRequest = {}
    switch (item.operation) {
      //nuovo item in creazione e vecchio item in update
      case '': {
        lineItem.action = item.action
        if (!creating) {
          lineItem.operation = 'Update'
          lineItem.quoteLineNumber = item.quoteLineNumber
        }
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (item.action) {
          case 'New': {
            if (item.opportunityLineItemId) {
              lineItem.opportunityLineItemId = item.opportunityLineItemId
            } else {
              lineItem.promotionCode = item.promotion ? item.promotion.code : null
              if (creating) {
                const off: Offer = {
                  term: {
                    code: item.offer?.term?.termCode,
                    description: item.offer?.term?.termDescription,
                  },
                  servicePlan: {
                    code: item.productCatalog?.servicePlanCode,
                    description: item.productCatalog?.servicePlanDescription,
                  },
                  accessModel: {
                    code: item.productCatalog?.accessModelCode,
                    description: item.productCatalog?.accessModelDescription,
                  },
                  connectivity: item.offer?.connectivity,
                  intendedUsage: item.offer?.intendedUsage,
                }
                lineItem.offeringId = item.productCatalog?.offeringId
                lineItem.offeringName = item.productCatalog?.offeringName
                lineItem.offeringCode = item.productCatalog?.offeringCode
                lineItem.offer = off
              }
            }
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            break
          }
          case 'Renewal': {
            if (item.opportunityLineItemId) {
              lineItem.opportunityLineItemId = item.opportunityLineItemId
            }
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            if (creating)
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            break
          }
          case 'Switch': {
            lineItem.quantity = item.quantity
            if (item.promotion?.code) lineItem.promotionCode = item.promotion?.code
            if ('referenceSubscription' in item) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offeringId = item.productCatalog?.offeringId
              if (creating) {
                lineItem.offeringName = item.productCatalog?.offeringName
                lineItem.offeringCode = item.productCatalog?.offeringCode
              }
              lineItem.referenceSubscriptionId =
                typeof item.referenceSubscription === 'object'
                  ? item.referenceSubscription?.subscriptionId
                  : item.referenceSubscription
              lineItem.offer = off
            } else {
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
              }
              lineItem.offer = off
            }

            break
          }
          case 'Extension': {
            if (item.opportunityLineItemId) {
              lineItem.opportunityLineItemId = item.opportunityLineItemId
            }
            if (creating)
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null

            break
          }
          case 'Co-term': {
            if (item.opportunityLineItemId) {
              lineItem.opportunityLineItemId = item.opportunityLineItemId
            } else {
              lineItem.endDate = item.endDate
                ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                  `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
                : null
              lineItem.promotionCode = item.promotion ? item.promotion.code : null
              if (creating) {
                const off: Offer = {
                  term: {
                    code: item.offer?.term?.termCode,
                    description: item.offer?.term?.termDescription,
                  },
                  servicePlan: {
                    code: item.productCatalog?.servicePlanCode,
                    description: item.productCatalog?.servicePlanDescription,
                  },
                  accessModel: {
                    code: item.productCatalog?.accessModelCode,
                    description: item.productCatalog?.accessModelDescription,
                  },
                  connectivity: item.offer?.connectivity,
                  intendedUsage: item.offer?.intendedUsage,
                }
                lineItem.offeringId = item.productCatalog?.offeringId
                lineItem.offeringName = item.productCatalog?.offeringName
                lineItem.offeringCode = item.productCatalog?.offeringCode
                lineItem.offer = off
              }
            }
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.referenceSubscriptionId =
              typeof item.referenceSubscription === 'object'
                ? item.referenceSubscription?.subscriptionId
                : item.referenceSubscription
            break
          }
          case 'True-Up': {
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            if (creating) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.offeringName = item.productCatalog?.offeringName
              lineItem.offeringCode = item.productCatalog?.offeringCode
              lineItem.offer = off
            }
            break
          }
        }
        break
      }
      //nuovo item in update
      case 'Insert': {
        lineItem.action = item.action
        lineItem.operation = item.operation
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (item.action) {
          case 'New': {
            const off: Offer = {
              term: {
                code: item.offer?.term?.termCode,
                description: item.offer?.term?.termDescription,
              },
              servicePlan: {
                code: item.productCatalog?.servicePlanCode,
                description: item.productCatalog?.servicePlanDescription,
              },
              accessModel: {
                code: item.productCatalog?.accessModelCode,
                description: item.productCatalog?.accessModelDescription,
              },
              connectivity: item.offer?.connectivity,
              intendedUsage: item.offer?.intendedUsage,
            }
            lineItem.offeringId = item.productCatalog?.offeringId
            lineItem.offer = off
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            break
          }
          case 'Renewal': {
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            lineItem.subscriptionId =
              typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            break
          }
          case 'Switch': {
            lineItem.quantity = item.quantity
            if (item.promotion?.code) lineItem.promotionCode = item.promotion?.code
            if ('referenceSubscription' in item) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offer = off
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.referenceSubscriptionId =
                typeof item.referenceSubscription === 'object'
                  ? item.referenceSubscription?.subscriptionId
                  : item.referenceSubscription
            } else {
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
              }
              lineItem.offer = off
            }

            break
          }
          case 'Extension': {
            lineItem.subscriptionId =
              typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            break
          }
          case 'Co-term': {
            lineItem.offeringId = item.productCatalog?.offeringId
            lineItem.referenceSubscriptionId =
              typeof item.referenceSubscription === 'object'
                ? item.referenceSubscription?.subscriptionId
                : item.referenceSubscription
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            const off: Offer = {
              term: {
                code: item.offer?.term?.termCode,
                description: item.offer?.term?.termDescription,
              },
              servicePlan: {
                code: item.productCatalog?.servicePlanCode,
                description: item.productCatalog?.servicePlanDescription,
              },
              accessModel: {
                code: item.productCatalog?.accessModelCode,
                description: item.productCatalog?.accessModelDescription,
              },
              connectivity: item.offer?.connectivity,
              intendedUsage: item.offer?.intendedUsage,
            }
            lineItem.offer = off
            break
          }
        }
        break
      }
      //eliminazione item in update
      case 'Delete': {
        lineItem.quoteLineNumber = item.quoteLineNumber
        lineItem.operation = item.operation
        break
      }
    }
    quoteRequest.lineItems.push(lineItem)
  })
  return quoteRequest
}

export const TransformGetQuoteDetails = async (details: GetQuoteDetailsResponse, quote: ad_quote) => {
  const service = useQsAdminApiManager.getState().service
  const lineItemsModel: LineItemModel[] = []
  let pricing: Pricing | null = null
  let adminEmailChanged: boolean = false
  let quoteContactEmailChanged: boolean = false
  let additionalsEmailChanged: boolean = false

  //lineItems
  for (let index = 0; index < details[0].lineItems.length; index++) {
    const lineItemModel: LineItemModel = {
      lineNumber: details[0].lineItems[index].lineNumber,
      quoteLineNumber: details[0].lineItems[index].quoteLineNumber,
      operation: '',
      action: details[0].lineItems[index].action,
      pricing: details[0].lineItems[index].pricing,
    }
    if ('offer' in details[0].lineItems[index] && details[0].lineItems[index].offer) {
      const offerModel: OfferModel = {}
      if ('term' in details[0].lineItems[index].offer && details[0].lineItems[index].offer.term) {
        const termModel: TermModel = {
          termCode: details[0].lineItems[index].offer.term.code,
          termDescription: details[0].lineItems[index].offer.term.description,
        }
        offerModel.term = termModel
      }
      if ('intendedUsage' in details[0].lineItems[index].offer && details[0].lineItems[index].offer.intendedUsage)
        offerModel.intendedUsage = details[0].lineItems[index].offer.intendedUsage
      if ('connectivity' in details[0].lineItems[index].offer && details[0].lineItems[index].offer.connectivity)
        offerModel.connectivity = details[0].lineItems[index].offer.connectivity

      lineItemModel.offer = offerModel
    }

    lineItemModel.quantity = details[0].lineItems[index].quantity ?? undefined
    lineItemModel.startDate = details[0].lineItems[index].startDate
      ? new Date(details[0].lineItems[index].startDate)
      : null
    lineItemModel.endDate = details[0].lineItems[index].endDate ? new Date(details[0].lineItems[index].endDate) : null

    if (details[0].lineItems[index].offeringId) {
      const getProduct = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringId.eq(details[0].lineItems[index].offeringId))
        builder.select(
          'offeringId',
          'offeringCode',
          'offeringName',
          'servicePlanCode',
          'servicePlanDescription',
          'accessModelCode',
          'accessModelDescription',
          'termCode',
          'termDescription',
        )
      })
      lineItemModel.productCatalog = getProduct?.data.value[0]
    }

    if ('subscription' in details[0].lineItems[index] && details[0].lineItems[index].subscription) {
      lineItemModel.subscription = details[0].lineItems[index].subscription.id
      lineItemModel.productSubscription = lineItemModel.productCatalog
    }

    if ('referenceSubscription' in details[0].lineItems[index] && details[0].lineItems[index].referenceSubscription) {
      lineItemModel.referenceSubscription = details[0].lineItems[index].referenceSubscription.id
      //recupero il prodotto della refSubs
      const getRefSubsProduct = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(details[0].lineItems[index].referenceSubscription.offeringCode))
        builder.select(
          'offeringId',
          'offeringCode',
          'offeringName',
          'servicePlanCode',
          'servicePlanDescription',
          'accessModelCode',
          'accessModelDescription',
          'termCode',
          'termDescription',
        )
      })
      lineItemModel.productSubscription = getRefSubsProduct.data.value[0]
    }

    if (details[0].lineItems[index].promotionCode) {
      const getPromotion = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.code.eq(details[0].lineItems[index].promotionCode))
        builder.select('code', 'name', 'description')
      })
      lineItemModel.promotion = getPromotion?.data.value[0]

      const getPromotionsAvailable = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(lineItemModel.productCatalog?.offeringCode ?? null))
        builder.filter(promotion.orderAction.eq(lineItemModel.action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq('COM').or(promotion.intendedUsageCode.eq(null)))
        builder.filter(
          promotion.termCode.eq(lineItemModel.productCatalog?.termCode ?? null).or(promotion.termCode.eq(null)),
        )
        builder.filter(
          promotion.accessModelCode
            .eq(lineItemModel.productCatalog?.accessModelCode ?? null)
            .or(promotion.accessModelCode.eq(null)),
        )
        builder.filter(promotion.quantityNeeded.lowerEquals(lineItemModel.quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      lineItemModel.promotions = getPromotionsAvailable.data.value
    }

    lineItemsModel.push(lineItemModel)
  }

  //pricing totale della quota
  pricing = details[0].pricing

  //check changed admin email
  console.log('admin', details[0].admin.email, quote.admin?.emails?.[0].email)
  if (details[0].admin.email !== quote.admin?.emails?.[0].email) adminEmailChanged = true

  //check changed quoteContact email
  console.log('admin', details[0].quoteContact.email, quote.contact?.emails?.[0].email)
  if (details[0].quoteContact.email !== quote.contact?.emails?.[0].email) quoteContactEmailChanged = true

  //check changed additionRecipients email
  console.log('additionRecipients', details[0].additionalRecipients, quote.contatti_addizionali)
  for (const addRec of details[0].additionalRecipients) {
    const contact = quote.contatti_addizionali?.find((contact) => contact.emails?.[0].email === addRec.email)
    if (!contact) additionalsEmailChanged = true
  }

  return { lineItemsModel, pricing, adminEmailChanged, quoteContactEmailChanged, additionalsEmailChanged }
}

export const TransformGetOpportunity = async (detail: GetOpportunityResponse) => {
  const lineItemsModel: LineItemModel[] = []

  //lineItems
  for (let index = 0; index < detail.results[0].lineItems.length; index++) {
    console.log('index', index)
    const lineItemModel = await CreateItemModel(
      detail.results[0].lineItems[index].LineItemAction.toUpperCase(),
      true,
      index,
      detail.results[0].lineItems[index],
    )

    lineItemsModel.push(lineItemModel)
  }

  return { lineItemsModel }
}
