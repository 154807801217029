import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import ProjectCreatorRoute from '@/routes/projects/ProjectCreator.route'
import ProjectEditorRoute from '@/routes/projects/ProjectEditor.route'
import ProjectsPage from '@/routes/projects/ProjectsPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

export const projectsPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Commesse` : 'Commesse'

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('nome', 'id')
  })

  const getStati = qsAdminApi.stato_commessa().query((builder, stato) => {
    builder.orderBy(stato.nome.asc())
    builder.select('nome', 'id')
  })

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  return defer({
    title,
    aziende: await getAziende,
    stati: await getStati,
    sites: await getSites,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const ProjectsPageRoute: RoleRoute = {
  path: 'projects',
  children: [
    {
      index: true,
      element: <ProjectsPage />,
      loader: projectsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    ProjectCreatorRoute,
    ProjectEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Commesse',
  faIcon: faProjectDiagram,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Commesse',
      icon: faProjectDiagram,
    }),
  },
  allowedRoles: [Roles.Guest],
}
