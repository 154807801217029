import './themes/generated/variables.css'
import './dx-styles.scss'

import { useEffect, useState } from 'react'

import { NavigationProvider } from '@/providers/navigation'
import { ThemeProvider } from '@/providers/theme'
import {
  MsalProvider,
  useMsal,
  useIsAuthenticated,
  MsalAuthenticationTemplate,
  useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { locale } from 'devextreme/localization'
import { useAzureManager } from '@/auth/azure/azureManager'
import Debugger from '@/components/Debugger/Debugger'
import { Providers, ProviderState } from '@microsoft/mgt-element'
import { GlobalLoadingComponent } from '@/components/GlobalLoadingComponent/GlobalLoadingComponent'

const MsalErrorComponent = () => {
  return (
    <>
      <h2>L&apos;autenticazione non è riuscta.</h2>
      <h3>
        Torna alla <Link to="/login">pagina di login</Link>
      </h3>
    </>
  )
}

function ApplicationWrapperWithAuthStatusSync() {
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const { pca } = useAzureManager()
  const location = useLocation()

  // Sync MSAL authentication state with Microsoft Graph Toolkit
  useEffect(() => {
    const getProviderState = () => {
      if (inProgress !== 'none') return ProviderState.Loading
      return isAuthenticated ? ProviderState.SignedIn : ProviderState.SignedOut
    }

    Providers.globalProvider.setState(getProviderState())
  }, [isAuthenticated, inProgress])

  // Handle logout state
  useEffect(() => {
    if (location.pathname === '/logout') {
      console.log('Logout path detected')
    }
  }, [location.pathname])

  // Only apply MsalAuthenticationTemplate for protected routes
  // Check if we have accounts (meaning we're authenticated)
  const accounts = pca?.getAllAccounts() || []
  const isAuthRoute =
    window.location.pathname.startsWith('/dashboard') || window.location.pathname.startsWith('/authenticated')

  if (accounts.length > 0 && isAuthRoute) {
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={MsalErrorComponent}
        loadingComponent={GlobalLoadingComponent}
      >
        <Outlet />
      </MsalAuthenticationTemplate>
    )
  }

  // For public routes or landing page, just render the Outlet
  return <Outlet />
}

export default function App() {
  const pca = useAzureManager.getState().pca
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 1,
          },
        },
      }),
  )

  useEffect(() => {
    // Set locale for DevExtreme components
    locale('it-IT')
  }, [])

  // Wait for pca to be initialized
  if (!pca) {
    return <GlobalLoadingComponent />
  }

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <NavigationProvider>
            <ApplicationWrapperWithAuthStatusSync />
          </NavigationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <Debugger />
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  )
}
