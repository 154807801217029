import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { task, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import TaskEditor from '@/routes/tasks/TaskEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, LoaderFunction, ActionFunction } from 'react-router-typesafe'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { StatoTask } from '@/routes/tasks/tasks.types'
import { Reparto } from '@/enums'
import { Qtask } from '@/model/qsadminapi/QQsAdminApiModule'
import { StatoPuntoFatturazione } from '@/routes/invoicingsteps/invoicingstep.types'

export const taskEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'taskId' | 'clientId'>) => {
  const taskId = params.taskId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
        gruppo_aziendaleBuilder.select('id', 'nome')
      })
      builder.select('id', 'nome', 'gruppo_aziendale')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let task: task

  if (taskId) {
    //editor
    const taskData = await qsAdminApi
      .task(Number(taskId))
      .query((builder, task) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
            })
            aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
              gruppo_aziendaleBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('resp_tecnico', (respBuilder, resp) => {
          respBuilder.select('id', 'fullname', 'email')
        })
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('proprietario', (proprietarioBuilder, proprietario) => {})
        builder.expanding('ordine', (ordineBuilder, ordine) => {
          ordineBuilder.expanding('offerta', (offertaOrdineBuilder, offertaOrdine) => {
            offertaOrdineBuilder.expanding('analisi', (analisiBuilder, analisi) => {
              analisiBuilder.expanding('sede', (sedeAnalisiBuilder, sede) => {
                sedeAnalisiBuilder.expanding('azienda', (aziendaSedeAnalisiBuilder, azienda) => {
                  aziendaSedeAnalisiBuilder.select('id', 'nome')
                })
                sedeAnalisiBuilder.select('id', 'nome', 'azienda')
              })
              analisiBuilder.select('id', 'ded_Dis', 'sede')
            })
            offertaOrdineBuilder.select('id', 'ded_Dis', 'analisi')
          })
          ordineBuilder.expanding('commessa', (commessaBuilder, commessa) => {
            commessaBuilder.select('id', 'ded_Dis', 'titolo')
          })
          ordineBuilder.select('id', 'ded_Dis', 'offerta', 'commessa', 'note')
        })
        builder.expanding('tecnici', (tecniciBuilder, tecnico) => {})
        builder.expanding('punto_fatturazione', (puntoFatturazioneBuilder, puntoFatturazione) => {
          puntoFatturazioneBuilder.select('id', 'ded_Dis', 'note')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Task non trovato')
        throw new Error()
      })
    task = taskData.data satisfies task
    title += `${task.ded_Dis}`
    if (clientId && task?.sede?.azienda && task?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `Il task ${task.ded_Dis} appartiene al cliente ${task?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += 'Nuovo task'
    const autoreData = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })

    const statoDefaultData = await qsAdminApi.stato_task(StatoTask.APERTO).query((builder, stato) => {
      builder.select('id', 'nome')
    })

    task = {
      id: 0,
      ded_Dis: '',
      attachmentsFolderUrl: '',
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      consegna_prevista: null,
      apertura: new Date().toISOString(),
      concluso: false,
      chiusura: null,
      note: null,
      note_completamento: null,
      ultima_modifica: null,
      avviso_completamento: false,
      appuntamento_outlook: null,
      proprietario: autoreData.data.value[0],
      stato: statoDefaultData.data,
      sede: null,
      anno_rif: new Date().getFullYear(),
    }
  }

  //se il task non ha il proprietario (caso raro che succede per i pochi task creati appena prima dell'import) lo imposto di default
  if (!task.proprietario) {
    const autoreData = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })
    task.proprietario = autoreData.data.value[0]
  }

  //query di popolazione lookup
  const getOrdini =
    task.sede || clientId
      ? qsAdminApi.ordine().query((builder, ordine) => {
          if (task.sede?.azienda?.gruppo_aziendale?.id)
            builder.filter(
              ordine.commessa.props.sede.props.azienda.props.gruppo_aziendale.props.id.eq(
                task.sede.azienda.gruppo_aziendale.id,
              ),
            )
          else if (azienda?.data.gruppo_aziendale?.id)
            builder.filter(
              ordine.commessa.props.sede.props.azienda.props.gruppo_aziendale.props.id.eq(
                azienda?.data.gruppo_aziendale?.id,
              ),
            )
          else
            builder.filter(
              ordine.commessa.props.sede.props.azienda.props.id.eq(Number(task.sede?.azienda?.id || azienda?.data.id)),
            )
          builder.expanding('commessa', (commessaBuilder, commessa) => {
            commessaBuilder.expanding('sede', (sedeBuilder, sede) => {
              sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
                aziendaBuilder.select('id', 'nome')
              })
              sedeBuilder.select('id', 'nome', 'azienda')
            })
            commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
          })
          builder.select('id', 'ded_Dis', 'note', 'commessa')
          builder.orderBy(ordine.ded_Dis.desc())
        })
      : undefined

  const getPuntiFatturazione = task.ordine
    ? qsAdminApi.punto_fatturazione_ordine().query((builder, puntoFatturazione) => {
        builder.filter(puntoFatturazione.ordine.props.id.eq(Number(task.ordine?.id)))
        builder.filter(
          puntoFatturazione.stato.props.id
            .eq(StatoPuntoFatturazione['DA FATTURARE'])
            .or(task.punto_fatturazione ? puntoFatturazione.id.eq(Number(task.punto_fatturazione.id)) : null),
        )
        builder.select('id', 'ded_Dis', 'note')
      })
    : undefined

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getStati = qsAdminApi.stato_task().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getQsImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(
      impiegato.attivo
        .eq(true)
        .and(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
        .or(
          taskId
            ? impiegato.tasks_create
                .any((task_create: Qtask) => task_create.id.eq(Number(taskId)))
                .or(
                  impiegato.tasks_responsabile_tecnico.any((task_resp_tecnico: Qtask) =>
                    task_resp_tecnico.id.eq(Number(taskId)),
                  ),
                )
                .or(impiegato.tasks_tecnico.any((task_tecnico: Qtask) => task_tecnico.id.eq(Number(taskId))))
            : null,
        ),
    )
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title,
    task,
    getAziende,
    getStati,
    getQsImpiegati,
    getOrdini,
    getPuntiFatturazione,
    userInfo,
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

const closeAction = (async ({ request, params }: RouteFunctionParams<'taskId'>) => {
  const closingData = (await request.json()) as { taskId: string; note: string; status: string }
  const qsAdminApi = useQsAdminApiManager.getState()
  return qsAdminApi.client.post('/api/task/close', closingData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}) satisfies ActionFunction

export const taskEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const task = (await request.json()) as task
  console.log('task', task)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      task.commessa = task.ordine?.commessa
      const res = await qsAdminApi.task().create(task)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      task.commessa = task.ordine?.commessa
      return await qsAdminApi.task(task.id).update(task)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const TaskEditorRoute = {
  path: ':taskId',
  element: <TaskEditor creating={false} />,
  children: [
    {
      path: 'close',
      action: closeAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
  ],
  loader: taskEditorRouteLoader,
  action: taskEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TaskEditorRoute
