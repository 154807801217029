import { useEffect, useState } from 'react'
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider'
import { FileManager, Permissions, FileManagerTypes, Upload } from 'devextreme-react/file-manager'
import FileSystemError from 'devextreme/file_management/error'
import { AttachmentsManagerProps } from '@/components/file-manager/AttachmentsManager.types'
import { useAzureManager } from '@/auth/azure/azureManager'
import FileSystemItem from 'devextreme/file_management/file_system_item'
import UploadInfo from 'devextreme/file_management/upload_info'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { LoadIndicator } from 'devextreme-react'
import { DocumentPermissions } from '@/enums'
import { Providers } from '@microsoft/mgt-react'

const AttachmentsManager = (props: AttachmentsManagerProps) => {
  const { folderUrl, fileManagerRef, preliminarPermissionsAction, createDirectoryAction } = props
  const [isLoading, setIsLoading] = useState(true)
  const [permissions, setPermissions] = useState<DocumentPermissions>()
  const { client } = useQsAdminApiManager()

  useEffect(() => {
    const computePermissions = async () => {
      let objperm: DocumentPermissions = DocumentPermissions.Deny
      if (preliminarPermissionsAction) {
        objperm = await preliminarPermissionsAction()
      }
      setPermissions(objperm)
      setIsLoading(false)
    }

    computePermissions()
  }, [preliminarPermissionsAction])

  const getItems = async (parentFolder: FileSystemItem) => {
    let currentFolder = folderUrl
    if (parentFolder.path.length > 0) {
      currentFolder = `${folderUrl}/${parentFolder.path}`
    }
    try {
      const items = await Providers.globalProvider.graph
        .api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${currentFolder}:/children`)
        .get()
      return items.value
    } catch (error) {
      console.error(`Error reading ${currentFolder}`, error)
      return []
    }
  }

  const renameItem = async (item: FileSystemItem, newName: string) => {
    const body = JSON.stringify({
      name: newName,
    })
    return Providers.globalProvider.graph
      .api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`)
      .patch(body)
  }

  const moveItem = async (item: FileSystemItem, destinationDirectory: FileSystemItem) => {
    const body = JSON.stringify({
      parentReference: { id: destinationDirectory.dataItem.id },
      name: item.name,
    })
    return Providers.globalProvider.graph
      ?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`)
      .patch(body)
  }

  const deleteItem = async (item: FileSystemItem) => {
    return Providers.globalProvider.graph
      ?.api(`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/items/${item.dataItem.id}`)
      .delete()
  }

  const createDirectory = async (parentDirectory: FileSystemItem, name: string) => {
    let currentFolder = folderUrl
    if (parentDirectory.path.length > 0) {
      currentFolder = `${folderUrl}/${parentDirectory.path}`
    }
    return createDirectoryAction(currentFolder ?? '', name, 'rename')
  }

  const uploadFileChunk = async (file: File, uploadInfo: UploadInfo, destinationDirectory: FileSystemItem) => {
    if (file.size === 0) {
      throw new FileSystemError(
        32_767,
        undefined,
        'Uploading folders is not supported. Please select individual files.',
      )
    }

    try {
      if (uploadInfo.chunkIndex === 0) {
        // Controllo ogni volta l'esistenza della cartalla principale
        try {
          await createDirectoryAction(extractParentPath(folderUrl) ?? '', extractDirectoryName(folderUrl), 'replace')
        } catch {
          // Non segnalo niente la creaazione potrebbe essere già avvenuta
        }

        let currentFolder = folderUrl
        if (destinationDirectory.path.length > 0) {
          currentFolder = `${folderUrl}/${destinationDirectory.path}`
        }

        const driveItem = {
          name: file.name,
        }

        const uploadSession = await Providers.globalProvider.graph
          .api(
            `drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${currentFolder}/${file.name}:/createUploadSession`,
          )
          .post({
            item: driveItem,
          })

        uploadInfo.customData.uploadUrl = uploadSession.uploadUrl
      }

      return client.put(uploadInfo.customData.uploadUrl, uploadInfo.chunkBlob, {
        headers: {
          'Content-Length': `${uploadInfo.chunkBlob.size}`,
          'Content-Range': `bytes ${uploadInfo.bytesUploaded}-${uploadInfo.bytesUploaded + uploadInfo.chunkBlob.size - 1}/${file.size}`,
        },
      })
    } catch (error) {
      console.error('Upload error:', error)
      throw error
    }
  }

  const isDirectoryExpr = 'folder'
  const dateModifiedExpr = 'lastModifiedDateTime'

  const customFileProvider = new CustomFileSystemProvider({
    getItems,
    renameItem,
    moveItem,
    deleteItem,
    createDirectory,
    uploadFileChunk,
    isDirectoryExpr,
    dateModifiedExpr,
  })

  const extractDirectoryName = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    const pathArray = path.split('/')
    const lastIndex = pathArray.length - 1
    return pathArray[lastIndex]
  }

  const extractParentPath = (path: string | null | undefined) => {
    if (path === null || path === undefined) return ''
    return path.split('/').slice(0, -1).join('/')
  }

  if (isLoading) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  if (permissions && permissions !== DocumentPermissions.Deny) {
    return (
      <>
        <FileManager
          height={550}
          selectionMode={'single'}
          ref={fileManagerRef}
          fileSystemProvider={customFileProvider}
          onSelectedFileOpened={(e: FileManagerTypes.SelectedFileOpenedEvent) => {
            window.open(e.file.dataItem.webUrl, '_blank')
          }}
          onItemDownloading={(e: FileManagerTypes.ItemDownloadingEvent) => {
            window.open(e.item.dataItem['@microsoft.graph.downloadUrl'], '_blank')
          }}
          rootFolderName={extractDirectoryName(folderUrl)}
        >
          <Permissions
            create={permissions === DocumentPermissions.Write}
            move={permissions === DocumentPermissions.Write}
            delete={permissions === DocumentPermissions.Write}
            rename={permissions === DocumentPermissions.Write}
            upload={permissions === DocumentPermissions.Write}
            download={true}
          />
          <Upload chunkSize={4_000_000} />
        </FileManager>
      </>
    )
  } else {
    return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
  }
}

export default AttachmentsManager
