import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Sorting,
  Search,
  FilterPanel,
  DataGridTypes,
  Pager,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import { useLocation, useNavigate } from 'react-router'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import dxDataGrid, {
  CellHoverChangedEvent,
  CellPreparedEvent,
  ColumnButtonClickEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { Popover, PopoverRef } from 'devextreme-react/popover'
import axios, { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import { useLoaderData } from 'react-router-typesafe'
import { ClickEvent } from 'devextreme/ui/button'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { supportPageRouteLoader } from '@/routes/support/SupportPage.route'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { Link } from 'react-router-dom'
import { assistenza } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'

export default function SupportPage() {
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()
  const gridPopoverRef = useRef<PopoverRef>(null)
  const token = useTokenRefresh()
  const title = 'Assistenza'
  const { aziende, tipologieAssistenza, userInfo } = useLoaderData<typeof supportPageRouteLoader>()
  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'telefonica',
        placeHolder: 'Telefonica',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: tipologieAssistenza.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['telefonica/id', this.currentValue]]
        },
      },
      {
        fieldName: 'maintenance',
        placeHolder: 'Maintenance',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: tipologieAssistenza.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['maintenance/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, tipologieAssistenza])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>([])

  const assistenzaDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/assistenza`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter.length > 0 ? currentFilter : null,
    expand: [
      'sede($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore))',
      'riferimento($expand=sede)',
      'telefonica',
      'maintenance',
    ],
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const cellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'telefonica.nome' || e.column.dataField === 'maintenance.nome') {
        if (e.value) {
          if (e.value === 'ATTIVA') {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          } else if (e.value === 'SCADUTA') {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          } else {
            e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          }
        }
      } else {
        if (e.data.sospesa) {
          console.log('PREP', e)
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; ${e.cellElement.style.cssText}background-color: ${GridCellColor.ORANGE}`
        }
      }
    }
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Aziende')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Support.xlsx')
      })
    })
  }

  const getColumnTooltip = (col: any): string => {
    switch (col.dataField) {
      case 'tariffa_oraria': {
        return 'TARIFFA ORARIA'
      }
      case 'sospesa': {
        return 'CONTRATTO TEMPORANEAMENTE SOSPESO'
      }
      case 'posti': {
        return 'POSTI DI LAVORO'
      }
      case 'canone_personalizzazioni': {
        return 'CANONE PERSONALIZZAZIONI'
      }
      case 'canone_telefonica': {
        return 'CANONE LISTINO TELEFONICA'
      }
      case 'importo_precedente': {
        return 'IMPORTO PRECEDENTE'
      }
      case 'data_fatturazione': {
        return 'DATA FATTURAZIONE'
      }
      case 'seconda_fatturazione': {
        return 'SECONDA DATA FATTURAZIONE'
      }
      case 'fatturazione_viaggio': {
        return 'FATTURAZIONE VIAGGIO'
      }
      case 'timbro_firma': {
        return 'ACCETTAZIONE CON TIMBRO E FIRMA'
      }
      case 'disdetta_telefonica': {
        return 'DATA DISDETTA ASSISTENZA TELEFONICA'
      }
      case 'disdetta_aggiornamenti': {
        return 'DATA DISDETTA MAINTENANCE'
      }
      // No default
    }
    return (col.caption ?? '').toUpperCase()
  }

  const onCellHoverChanged = (e: CellHoverChangedEvent) => {
    if (e.rowType === 'header' && e.eventType === 'mouseover') {
      gridPopoverRef.current?.instance().option('contentTemplate', function (contentElement: HTMLElement) {
        return `<div><b>${getColumnTooltip(e.column)}</b></div>`
      })
      gridPopoverRef.current?.instance().option('target', e.cellElement)
      gridPopoverRef.current?.instance().show()
    }
    if (e.eventType === 'mouseout') {
      gridPopoverRef.current?.instance().hide()
    }
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>
      <div className={'content-block'}>
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id="support-datagrid"
          height={getGridHeight}
          dataSource={assistenzaDataSource}
          noDataText="Nessuna assistenza trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          keyExpr="id"
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          onCellPrepared={cellPrepared}
          onExporting={(e) => ExportDataGridToExcel(e.component)}
          onCellHoverChanged={onCellHoverChanged}
          ref={gridRef}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`/clients/${e.data.sede.azienda.id}/support/${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <FilterPanel visible={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring enabled={true} type="localStorage" storageKey="support-datagrid" savingTimeout={50} />
          <Sorting mode="multiple" />
          <Column
            visible={currentScreenSize.isXSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            caption="ID"
            visible={false}
            dataType="number"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'id', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="sospesa"
            width={'2.5%'}
            caption="S"
            dataType={'boolean'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
            }
          />
          <Column
            dataField="sede.azienda.nome"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: assistenza = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <div className={data.sospesa ? 'customLink' : ''}>
                    <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                  </div>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption="AZIENDA"
            defaultSortOrder="asc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 23 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.azienda.agente.commerciale_qs.fullname'}
            caption={'AGENTE'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 22 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.azienda.rivenditore.nome'}
            width={'15%'}
            caption={'RIVENDITORE'}
            hidingPriority={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 21 : undefined}
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
              <Search enabled={true} searchExpr={'rivenditore.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="sede.nome"
            caption="SEDE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 20 : undefined
            }
          />
          <Column
            dataField="maintenance.nome"
            caption="MAINTENANCE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 19 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'maintenance.nome')}>
              <Search enabled={true} searchExpr={'maintenance.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="telefonica.nome"
            caption="TELEFONICA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 18 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'telefonica.nome')}>
              <Search enabled={true} searchExpr={'telefonica.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="tariffa_oraria"
            caption="TH"
            dataType="number"
            width={'2.5%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'tariffa_oraria', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="posti"
            caption="PDL"
            dataType="number"
            width={'2.5%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'posti', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="canone"
            caption="CANONE"
            dataType="number"
            width={'3%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'canone', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="canone_personalizzazioni"
            caption="CP"
            dataType="number"
            width={'3%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'assistenza', 'canone_personalizzazioni', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField="canone_telefonica"
            dataType="number"
            caption="CT"
            width={'3%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'assistenza', 'canone_telefonica', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField="importo_precedente"
            dataType="number"
            caption="IMP. PREC."
            width={'3%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'assistenza', 'importo_precedente', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField="durata"
            caption="DURATA"
            dataType="number"
            width={'2.5%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'assistenza', 'durata', true)}></HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'scadenza',
            caption: 'SCADENZA',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 17 : undefined,
          })}
          {DateColumn({
            dataField: 'data_fatturazione',
            caption: 'FATTURAZIONE',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 16 : undefined,
          })}
          {DateColumn({
            dataField: 'seconda_fatturazione',
            caption: '2ND FATT.',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined,
          })}
          <Column
            dataField="fatturazione_viaggio"
            caption="FV"
            dataType="boolean"
            allowHeaderFiltering={false}
            width={'2.5%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          />
          <Column
            dataField="timbro_firma"
            caption="TF"
            dataType="boolean"
            allowHeaderFiltering={false}
            width={'2.5%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          />
          {DateColumn({
            dataField: 'disdetta_telefonica',
            caption: 'DISDETTA T',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined,
          })}
          {DateColumn({
            dataField: 'disdetta_aggiornamenti',
            caption: 'DISDETTA M',
            format: 'dd/MM/yyyy',

            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined,
          })}
          <Column
            dataField="note"
            caption="NOTE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          />
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Nuovo preventivo assistenza',
                text: 'Nuovo preventivo assistenza',
                icon: 'money',
                onClick: (e: ClickEvent) => {
                  e.event?.preventDefault()
                  navigate('quote')
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Aggiorna costo canone tel.',
                text: 'Aggiorna costo canone tel.',
                icon: 'pulldown',
                onClick: (e: ClickEvent) => {
                  gridRef?.current?.instance().beginCustomLoading('Aggiornamento costo canone telefonica in corso....')
                  const config = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                  }
                  axios
                    .post(
                      `${import.meta.env.VITE_QSADMINAPI_HOST}/api/supportquote/updateallphonesupportquote`,
                      undefined,
                      config,
                    )
                    .then((response: AxiosResponse) => {
                      notify(
                        `Aggiornamento del prezzo di listino dei contratti di assistenza completato con successo`,
                        'success',
                        2000,
                      )
                    })
                    .catch((error: AxiosError) => {
                      notify(
                        `Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`,
                        'error',
                        2000,
                      )
                      console.log(`Errore in fase di calcolo preventivo assistenza telefonica. Dettagli : ${error}`)
                    })
                    .finally(() => {
                      gridRef.current?.instance().refresh()
                      gridRef?.current?.instance().endCustomLoading()
                    })
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <Popover position="bottom" ref={gridPopoverRef} />
      </div>
    </>
  )
}
