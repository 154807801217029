import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_quote, azienda, commessa, contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import QuoteEditor from '@/routes/quotes/QuoteEditor'
import { QuoteRequest } from '@/routes/quotes/QuoteEditor.types'
import { LineItemModel } from '@/routes/quotes/line-items/LineItem.types'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { AxiosError, AxiosResponse } from 'axios'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { GetQuoteDetailsResponse, Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'
import { CreateQuoteResponse } from '@/routes/quotes/pws/CreateQuote.type'
import { UpdateQuoteResponse } from '@/routes/quotes/pws/UpdateQuote.type'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { TransformGetQuoteDetails } from '@/routes/quotes/utils'
import { Roles } from '@/auth/azure/Roles'
import { Qad_quote } from '@/model/qsadminapi/QQsAdminApiModule'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'

export const quoteEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'quoteId' | 'clientId'>) => {
  const quoteId = params.quoteId
  let clientId = params.clientId
  const projectId = new URL(request.url).searchParams.get('projectId')

  const service = useQsAdminApiManager.getState().service
  const client = useQsAdminApiManager.getState().client

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  let title = azienda ? `${azienda.data.nome} - ` : ``
  let quote: ad_quote
  let quoteDetailsObjectWebAdmin: {
    lineItemsModel: LineItemModel[]
    pricing: Pricing | null
    adminEmailChanged: boolean
    quoteContactEmailChanged: boolean
    additionalsEmailChanged: boolean
  } | null = null

  const userInfo = await getAzureUserInformation()
  const user = await service.user().query((builder, user) => {
    builder.select('id', 'nome', 'email', 'azienda')
    builder.expanding('azienda', (riferimentiBuilder, azienda) => {
      riferimentiBuilder.select('id', 'nome')
    })
    builder.filter(user.email.eq(userInfo?.email ?? ''))
  })

  if (clientId)
    azienda = await service.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  if (quoteId) {
    //editor
    const getQuote = await service
      .ad_quote(Number(quoteId))
      .query((builder, quote) => {
        builder.expanding('csn', (csnBuilder, csn) => {
          csnBuilder.select('id', 'csn')
        })
        builder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.expanding('sedi', (sediBuilder, sede) => {
            sediBuilder.select('id', 'nome', 'note', 'stato')
            sediBuilder.orderBy(sede.principale.desc())
          })
          aziendaBuilder.select('id', 'nome', 'sedi')
        })
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('contact', (contactBuilder, contact) => {
          contactBuilder.expanding('emails', (emailsBuilder, email) => {
            emailsBuilder.select('id', 'email')
          })
          contactBuilder.select('id', 'fullname', 'emails')
        })
        builder.expanding('admin', (adminBuilder, admin) => {
          adminBuilder.expanding('emails', (emailsBuilder, email) => {
            emailsBuilder.select('id', 'email')
          })
          adminBuilder.select('id', 'fullname', 'emails')
        })
        builder.expanding('ad_quote_histories', (historiesBuilder, history) => {
          historiesBuilder.select('quoteStatus', 'message')
          historiesBuilder.orderBy(history.eventDate.desc())
        })
        builder.expanding('author', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        builder.expanding('contatti_addizionali', (contattiAddizionaliBuilder, contatto) => {
          contattiAddizionaliBuilder.expanding('emails', (emailsBuilder, email) => {
            emailsBuilder.select('id', 'email')
          })
          contattiAddizionaliBuilder.select('id', 'fullname', 'emails')
        })
        builder.expanding('ordini', (ordiniBuilder, ordine) => {
          ordiniBuilder.select('id', 'ded_Dis')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Quota non trovata')
        throw new Error()
      })
    quote = getQuote.data satisfies ad_quote
    title += quote.number ? `${quote.number}` : `${quote.transactionId}`
    if (clientId && quote?.azienda && quote?.azienda.id !== Number(clientId)) {
      throw new Error(`La quota ${quote.number} appartiene al cliente ${quote?.azienda.nome} e non al cliente corrente`)
    }
    //recupero i dati della quota che non sono persistenti nel web admin (lineItems e additionalRecipients) se ho un quoteNumber
    if (quote.status !== 'RECEIVED' && quote.status !== 'FAILED') {
      const response = await client.get(`/api/autodeskpws/quotes?quoteNumber=${quote.number}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const getQuoteDetails: GetQuoteDetailsResponse = response.data
      console.log('quote', quote)
      console.log('getQuoteDetails', getQuoteDetails)
      quoteDetailsObjectWebAdmin = await TransformGetQuoteDetails(getQuoteDetails, quote)
      console.log('quoteDetailsObjectWebAdmin', quoteDetailsObjectWebAdmin)
    }
  } else {
    //creator
    let getProject: HttpResponseModel<ODataModelResponseV4<commessa>> | undefined

    if (projectId) {
      //creator a partire da commessa
      getProject = await service.commessa(Number(projectId)).query((builder, project) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
            aziendaBuilder.select('id')
          })
          sedeBuilder.select('id', 'azienda')
        })
      })
      clientId = getProject?.data.sede?.azienda?.id.toString()
    }

    title += `Nuova quota`
    let clnt: azienda | null = null
    let additionals: contatto_aziendale[] = []
    if (clientId) {
      const getClient = await service.azienda(Number(clientId)).query((builder, azienda) => {
        builder.expanding('sedi', (sediBuilder, sede) => {
          sediBuilder.select('id', 'nome', 'note', 'stato')
          sediBuilder.orderBy(sede.principale.desc())
        })
        builder.select('id', 'nome', 'sedi')
      })
      clnt = getClient.data
      const getAdditionals = await service.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.azienda.props.id.eq(Number(clientId)))
        builder.filter(contatto.emails.any())
        builder.filter(contatto.ad_recipient.eq(true))
        builder.select('id', 'fullname', 'ad_recipient')
      })
      additionals = getAdditionals.data.value
    }
    quote = {
      id: 0,
      status: '',
      transactionId: null,
      number: null,
      auto: false,
      azienda: clnt,
      csn: null,
      admin: null,
      contact: null,
      commessa: getProject?.data,
      autoExpiringDate: null,
      autoFinalizationDate: null,
      attivitaTecnicaRichiesta: null,
      author: null,
      creationDate: null,
      expiringDate: null,
      language: clnt ? (clnt.sedi?.[0].stato?.toUpperCase() === 'ITALIA' ? 'it' : 'en') : null,
      contatti_addizionali: additionals,
      skipDDACheck: false,
      opportunityNumber: null,
    }
  }

  const aziendaId = quote.azienda?.id ?? Number(clientId)

  return defer({
    title,
    clients: await service.azienda().query((builder, qazienda) => {
      builder.filter(aziendaId ? qazienda.id.eq(aziendaId) : null)
      builder.filter(qazienda.sedi.any())
      if (userInfo?.roles?.hasRole(Roles.Sales))
        builder.filter(
          qazienda.agente.props.commerciale_qs.props.id
            .eq(Number(user.data.value[0].id))
            .or(quote.azienda ? qazienda.id.eq(Number(quote.azienda.id)) : null),
        )
      if (userInfo?.roles?.hasRole(Roles.ExternalSales))
        builder.filter(
          qazienda.rivenditore.props.id
            .eq(Number(user.data.value[0].azienda?.id))
            .or(quote.azienda ? qazienda.id.eq(Number(quote.azienda.id)) : null),
        )
      if (aziendaId) {
        builder.expanding('sedi', (sediBuilder, sede) => {
          sediBuilder.select('id', 'nome', 'note', 'stato')
          sediBuilder.orderBy(sede.principale.desc())
        })
        builder.select('id', 'nome', 'sedi')
      } else builder.select('id', 'nome')
      builder.orderBy(qazienda.nome.asc())
    }),
    productsCatalog: await service.ad_product_catalog().query((builder, product) => {
      builder.select(
        'offeringId',
        'offeringName',
        'offeringCode',
        'accessModelCode',
        'accessModelDescription',
        'servicePlanCode',
        'servicePlanDescription',
        'termCode',
        'termDescription',
      )
      builder.orderBy(product.offeringName.asc())
    }),
    promotions: await service.ad_promotion().query((builder, promotion) => {
      builder.orderBy(promotion.code.asc())
    }),
    getImpiegati: service.user().query((builder, impiegato) => {
      builder.select('id', 'fullname')
      builder.orderBy(impiegato.fullname.asc())
    }),
    actions: ['NEW', 'RENEWAL', 'SWITCH PRODUCT', 'SWITCH TERM', 'EXTENSION', 'TRUE-UP', 'CO-TERM'],
    contacts: aziendaId
      ? await service.contatto_aziendale().query((builder, contact) => {
          builder.expanding('emails', (emailsBuilder, email) => {
            emailsBuilder.select('id', 'email')
          })
          builder.select('id', 'fullname', 'emails')
          builder.filter(contact.sede.props.azienda.props.id.eq(aziendaId))
          builder.filter(contact.emails.any())
          builder.filter(
            contact.attivo
              .eq(true)
              .or(quote.admin ? contact.id.eq(Number(quote.admin?.id)) : null)
              .or(quote.contact ? contact.id.eq(Number(quote.contact?.id)) : null)
              .or(
                quote.contatti_addizionali
                  ? contact.ad_quotes.any((ad_quote: Qad_quote) => ad_quote.id.eq(quote.id))
                  : null,
              ),
          )
          builder.filter(contact.emails.any())
          builder.orderBy(contact.fullname.asc())
        })
      : undefined,
    csns: aziendaId
      ? await service.ad_csn().query((builder, csn) => {
          builder.expanding('quoteContact', (quoteContactBuilder, quoteContact) => {
            quoteContactBuilder.select('id', 'fullname')
          })
          builder.expanding('admin', (adminBuilder, admin) => {
            adminBuilder.select('id', 'fullname')
          })
          builder.filter(csn.azienda.props.id.eq(aziendaId))
          builder.select('id', 'csn', 'admin', 'quoteContact')
        })
      : undefined,
    commesse: aziendaId
      ? await service.commessa().query((builder, commessa) => {
          builder.filter(commessa.sede.props.azienda.props.id.eq(aziendaId))
          builder.filter(
            commessa.stato.props.id
              .eq(StatoCommessa.APERTA)
              .or(quote.commessa ? commessa.id.eq(Number(quote.commessa?.id)) : null),
          )
          builder.select('id', 'ded_Dis', 'titolo')
          builder.orderBy(commessa.ded_Dis.desc())
        })
      : undefined,
    quote,
    quoteDetailsObjectWebAdmin,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Supervisor,
    ],
  })
}) satisfies LoaderFunction

export const quoteEditorRouteAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const quota = (await request.json()) as QuoteRequest
  const quoteId = params.quoteId
  console.log('quoteId', quoteId)
  const client = useQsAdminApiManager.getState().client
  const service = useQsAdminApiManager.getState().service
  let responseError: AxiosError<CreateQuoteResponse | UpdateQuoteResponse, CreateQuoteResponse | UpdateQuoteResponse> =
    new AxiosError<CreateQuoteResponse | UpdateQuoteResponse, CreateQuoteResponse | UpdateQuoteResponse>()
  let response: CreateQuoteResponse | UpdateQuoteResponse | null = null
  let ad_quote: number | null = null
  switch (request.method) {
    case 'POST': {
      await client
        .post('/api/autodeskpws/quotes', quota, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (res: AxiosResponse<CreateQuoteResponse, CreateQuoteResponse>) => {
          response = res.data
          if (res.data.ad_quote) ad_quote = res.data.ad_quote
          console.log('ad_quote', ad_quote)
        })
        .catch((error: AxiosError<CreateQuoteResponse, CreateQuoteResponse>) => {
          responseError = error
        })
      return responseError.response ? responseError : ad_quote ? redirect(`../${ad_quote}`) : response
    }
    case 'PUT': {
      await client
        .put('/api/autodeskpws/quotes', quota, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(async (res: AxiosResponse) => {
          response = res.data
        })
        .catch((error: AxiosError<CreateQuoteResponse, CreateQuoteResponse>) => {
          responseError = error
        })
      return response ?? responseError
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const finalizeAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string }
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/finalize',
    { quoteNumber: req.quoteNumber },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const cancelAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string }
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/cancel',
    { quoteNumber: req.quoteNumber },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const statusUpdateAction = (async ({ request, params }: RouteFunctionParams<'quoteId'>) => {
  const req = (await request.json()) as { quoteNumber: string; transactionId: string }
  console.log('req', req)
  const client = useQsAdminApiManager.getState().client
  return await client.put(
    '/api/autodeskpws/quotes/status',
    { quoteNumber: req.quoteNumber, transactionId: req.transactionId },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}) satisfies ActionFunction

const QuoteEditorRoute = {
  path: ':quoteId',
  element: <QuoteEditor creating={false} />,
  children: [
    {
      path: 'finalize',
      action: finalizeAction,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.ExternalSales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    {
      path: 'cancel',
      action: cancelAction,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.ExternalSales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    {
      path: 'status',
      action: statusUpdateAction,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.ExternalSales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
  ],
  loader: quoteEditorRouteLoader,
  action: quoteEditorRouteAction,
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.ExternalSales,
    Roles.Marketing,
    Roles.Supervisor,
  ],
} as RoleRouteObject

export default QuoteEditorRoute
