import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { DetailedUserInfo } from './forms/DetailedUserInfo'
import { ChildFormHandle } from '@/routes/admin/users/user/forms/types'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useMutation } from '@tanstack/react-query'
import UserStyles from '@/routes/admin/users/user/User.module.scss'
import { HashLoader } from 'react-spinners'
import { Button } from 'devextreme-react'
import { useParams, useRevalidator } from 'react-router-dom'

export type UserInfoPaneProps = {
  infoId?: number
}

export const UserInfoPane = forwardRef<ChildFormHandle, UserInfoPaneProps>((props, ref) => {
  const qsadminapiService = useQsAdminApiManager()
  const { userId } = useParams()
  const detailedUserInfoRef = useRef<ChildFormHandle>(null)
  const revalidator = useRevalidator()

  // Forward the submit method to the detailedUserInfoRef
  useImperativeHandle(ref, () => ({
    submit: () => {
      detailedUserInfoRef.current?.submit()
    },
  }))

  // Mutation for creating new user info
  const createUserInfoMutation = useMutation({
    mutationFn: async () => {
      const newInfo = await qsadminapiService.service.info_impiegato().create({
        ore: 8,
        entrata_mattina: '08:30',
        entrata_pomerig: '14:00',
      })
      await qsadminapiService.service.user(Number(userId)).patch({
        // @ts-expect-error the api only acceps an editable user, but this is wrong, it should accept a full user, inlcuding collections as this works
        info: newInfo.data,
      })
    },
    onSuccess: async (data) => {
      revalidator.revalidate()
    },
    onError: async (error) => {
      // api is wrongly returning 500 status code
      // fix this ASAP
      revalidator.revalidate()
    },
  })
  // const deleteUserInfoMutation = useMutation({
  //   mutationFn: async () => {
  //     await qsadminapiService.service.user(Number(userId)).patch({
  //       info: null,
  //     })
  //     await qsadminapiService.service.info_impiegato(Number(props.infoId)).delete()
  //   },
  //   onSuccess: async (data) => {
  //     revalidator.revalidate()
  //   },
  // })

  return props.infoId ? (
    <div className="content-block">
      <DetailedUserInfo infoId={props.infoId} ref={detailedUserInfoRef} />
      {/*<Button*/}
      {/*  text="Elimina Informazioni Utente"*/}
      {/*  type="danger"*/}
      {/*  onClick={() => deleteUserInfoMutation.mutate()}*/}
      {/*  disabled={deleteUserInfoMutation.isPending}*/}
      {/*/>*/}
    </div>
  ) : (
    <div className="content-block">
      <h2>Informazioni dettagliate utente</h2>
      <p>Non sono state trovate informazioni dettagliate. Vuoi aggiungerle?</p>
      <Button
        text="Crea Informazioni Utente"
        type="default"
        onClick={() => createUserInfoMutation.mutate()}
        disabled={createUserInfoMutation.isPending}
      />
      {createUserInfoMutation.isPending && (
        <div className={UserStyles.loaderWrapper}>
          <HashLoader loading={true} size={50} color={'#ff5722'} />
        </div>
      )}
    </div>
  )
})

UserInfoPane.displayName = 'UserInfoPane'
