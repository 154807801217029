import { RoleRoute } from '@/types'
import classes from './Tile.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

interface TileProps {
  item: RoleRoute
  path?: string
  key: number
}

export const Tile = (props: TileProps) => {
  const [tilePath, setTilePath] = useState<string>(props.path || '../')
  const [faIcon, setFaIcon] = useState<IconProp>(props.item.faIcon ?? faQuestion)
  return (
    <Link to={tilePath}>
      <div className={classes.tile}>
        <FontAwesomeIcon className={classes.icon} icon={faIcon} />
        <span className={classes.description}>{props.item.text}</span>
      </div>
    </Link>
  )
}
