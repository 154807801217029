import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { memo, useRef } from 'react'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useTokenRefresh, AzureUserInfo } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import DateColumn from '@/components/date-column/DateColumn'
import type { commessa, contatto_aziendale, entita_aziendale, offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import {
  Column,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Item,
  Paging,
  StateStoring,
  Toolbar,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import type { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { isUserRoleAllowed } from '@/routes/utils'
import { Link } from 'react-router-dom'

const ProjectEditorFormOffersGrid = (props: { project: commessa; userInfo: AzureUserInfo | undefined }) => {
  const token = useTokenRefresh()
  const gridRef = useRef<DataGridRef>(null)
  const { project, userInfo } = props
  const currentScreenSize = useScreenSize()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'PERSA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ORDINATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`

          break
        }
        case 'SUPERATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`

          break
        }
      }
    }
  }

  const calculateRiferimentiValue = (rowData: offerta) => {
    let riferimenti: string = ''
    rowData.riferimenti?.map(
      (contatto: contatto_aziendale) => (riferimenti = `${riferimenti + contatto.fullname?.toUpperCase()} `),
    )
    rowData.riferimenti_entita?.map(
      (contatto: entita_aziendale) => (riferimenti = `${riferimenti + contatto.nome?.toUpperCase()} `),
    )
    return riferimenti.trim()
  }
  const cellRiferimentiRender = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    return (
      <>
        {cellData.data.riferimenti?.map((contatto: contatto_aziendale, index: number) => {
          return (
            <div className={`dx-tag-content custom-tag-padding`} key={`ctc-${index}`}>
              <span>{contatto.fullname}</span>
            </div>
          )
        })}
        {cellData.data.riferimenti_entita?.map((entita: entita_aziendale, index: number) => {
          return (
            <div className={`dx-tag-content custom-tag-padding`} key={`ent-${index}`}>
              <span>{entita.nome}</span>
            </div>
          )
        })}
      </>
    )
  }

  const offersDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/offerta`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['commessa.id', project.id]],
    expand: ['riferimenti', 'riferimenti_entita', 'autore', 'stato'],
  })

  return (
    <>
      <DataGrid
        id={`project-offers-grid`}
        dataSource={offersDataSource}
        ref={gridRef}
        noDataText="Nessun' offerta associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/offers/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'project-offers-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/offers/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        ,
        <Column
          dataField={'ded_Dis'}
          caption={'CODICE'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: offerta = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/offers/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        ,
        <Column
          dataField={'revisione'}
          caption={'REV'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        ,
        <Column
          dataField={'autore.fullname'}
          caption={'AUTORE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        <Column
          name={'info_riferimenti'}
          caption={'RIFERIMENTI'}
          calculateCellValue={calculateRiferimentiValue}
          cellRender={cellRiferimentiRender}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        {DateColumn({
          dataField: 'data_creazione',
          caption: 'DATA',
          format: 'dd/MM/yyyy',
          hidingPriority:
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined,
        })}
        <Column
          dataField={'note'}
          caption={'NOTE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField={'stato.nome'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
        </Toolbar>
      </DataGrid>
    </>
  )
}

const ProjectEditorFormOffersGridMemoed = memo(ProjectEditorFormOffersGrid, (oldProps, newProps) => {
  return oldProps.project === newProps.project
})

export default ProjectEditorFormOffersGridMemoed
