import './TestComponent.scss'
import Rating from '@/components/Rating/Rating'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'
import { DataGrid, DropDownBox, Form, SelectBox, Validator } from 'devextreme-react'
import { ButtonItem, ButtonOptions, FormRef, GroupItem, SimpleItem } from 'devextreme-react/cjs/form'
import { useLoaderData } from 'react-router-typesafe'
import { TestComponentLoader } from '@/components/TestComponent.route'
import { Label } from 'devextreme-react/cjs/bar-gauge'
import { ArraySourceComposer } from '@/auth/api/connector'
import { useEffect, useRef, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { FilterRow, RequiredRule, Selection } from 'devextreme-react/cjs/data-grid'
import DropDownBoxDataGridItem from '@/components/ddb-datagrid-item/DropDownBoxDataGridItem'

interface WrappedItemProps {
  object: any
  values: any
  label: string
  displayExprField: string
  formValidation: string
  onChange: (value: any) => void
}

// const WrappedItem = ({ object, values, label, displayExprField, onChange }: WrappedItemProps) => {
//   console.log('values', values)
//   return (
//     <SimpleItem label={{ text: label }}>
//       <SelectBox
//         dataSource={ArraySourceComposer('id', values)}
//         value={object}
//         displayExpr={displayExprField}
//         //valueExpr={'id'}
//         onValueChanged={(e) => onChange(e.value)}
//       ></SelectBox>
//     </SimpleItem>
//   )
// }

const TestComponent = () => {
  const { analysis, stati } = useLoaderData<typeof TestComponentLoader>()
  const [formData, setFormData] = useState(analysis)
  const formRef = useRef<FormRef>(null)
  const handleStatoChange = (newValue: any) => {
    setFormData((prev) => ({ ...prev, stato: newValue }))
  }

  console.log('stati', stati, analysis)
  return (
    <Form
      id={'appointment_popup_form'}
      formData={formData}
      labelLocation={'top'}
      colCount={1}
      readOnly={true}
      onFieldDataChanged={(e: FieldDataChangedEvent) => {
        console.log('formdata', formData)
      }}
      ref={formRef}
      validationGroup="formValidation"
    >
      <GroupItem colCount={2}>
        <SimpleItem dataField="ded_Dis" label={{ text: 'Codice' }}></SimpleItem>
        {/* {DropDownBoxDataGridItem({
          object: formData.stato,
          values: stati.value,
          label: 'Stato',
          displayExprField: 'nome',
          formValidation: 'formValidation',
          columns: ['nome'],
          onChange: handleStatoChange,
        })} */}
      </GroupItem>
      <ButtonItem name="btn-test">
        <ButtonOptions
          text="reset"
          icon="revert"
          useSubmitBehavior={false}
          width={'150px'}
          onClick={(e: ClickEvent) => {
            const validationResult = formRef.current?.instance().validate()
            if (!validationResult?.isValid) return
            console.log('click', formData)
          }}
        />
      </ButtonItem>
    </Form>
  )
}

export default TestComponent
