import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { SoftwarePageRoute } from '@/routes/software/SoftwarePage.route'
import { SoftwareQsRoute } from '@/routes/software/qs/SoftwareQsGrid.route'

export const QSLicenseRoute: TileRoleRoute = {
  path: 'qsLicense',
  text: 'Licenza QS',
  faIcon: faKey,
  element: (
    <ManagementGridFactory
      entity={'stato_qs_licenza'}
      referencesCollection={'licenze'}
      referencesTitle={'Licenze QS'}
      displayField={'ded_Dis'}
      entityRoute={`${SoftwarePageRoute.path}/${SoftwareQsRoute.path}`}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Licenza QS',
      icon: faKey,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
