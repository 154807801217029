import { RoleRoute } from '@/types'
import { Outlet } from 'react-router-dom'
import { Roles } from '@/auth/azure/Roles'

export const RolesRoute: RoleRoute = {
  path: 'roles',
  text: 'Roles',
  element: (
    <>
      <Outlet />
    </>
  ),
  mainMenuRoute: false,
  allowedRoles: [Roles.GlobalAdministrator],
}
