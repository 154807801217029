import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_promotion, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import {
  CustomItemCreatingEvent,
  InitializedEvent,
  ValueChangedEvent as ValueChangedEventSelectBox,
} from 'devextreme/ui/select_box'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { NumberBoxTypes } from 'devextreme-react/cjs/number-box'
import './LineItem.scss'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Popover } from 'devextreme-react'
import { PopoverRef } from 'devextreme-react/cjs/popover'
import notify from 'devextreme/ui/notify'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { handleSubscriptionMouseOut, handleSubscriptionMouseOver } from '@/routes/quotes/utils'

// eslint-disable-next-line react/display-name
const LineItemRenewal = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const { item, csn, creating, readOnly, promotions, removeSelf, refreshPromotions } = props

    const [action, setAction] = useState<string>(item.action ?? '')
    const title = useMemo(() => {
      return item.quoteLineNumber
        ? `${action} ${item.quoteLineNumber}`
        : item.opportunityLineItemId
          ? `${action} ${item.opportunityLineItemId}`
          : `${action}`
    }, [action, item.opportunityLineItemId, item.quoteLineNumber])

    const { service } = useQsAdminApiManager()

    const formRef = useRef<FormRef>(null)
    const subsPopoverRef = useRef<PopoverRef>(null)
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)
    const [currentSubs, setCurrentSubs] = useState<string | ad_subscription | null | undefined>(item.subscription)
    const [style, setStyle] = useState<string>('line-item')
    const [quantitySubscription, setQuantitySubscription] = useState<number>(0)
    const [productSubscription, setProductSubscription] = useState<ad_product_catalog | null>(null)
    const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<ad_subscription[]>([])
    const [promotionDataSource, setPromotionDataSource] = useState<DataSource>(ArraySourceComposer('code', promotions))

    const getPromotions = async (
      action: string | undefined,
      productCode: string | null | undefined,
      iuCode: string | undefined,
      termCode: string | undefined | null,
      amCode: string | undefined | null,
      quantity: number | undefined,
    ) => {
      const promotionsData = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(productCode ?? null))
        builder.filter(promotion.orderAction.eq(action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq(iuCode ?? null).or(promotion.intendedUsageCode.eq(null)))
        builder.filter(promotion.termCode.eq(termCode ?? null).or(promotion.termCode.eq(null)))
        builder.filter(promotion.accessModelCode.eq(amCode ?? null).or(promotion.accessModelCode.eq(null)))
        builder.filter(promotion.quantityNeeded.lowerEquals(quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      return promotionsData.data.value
    }

    const getProductCatalog = async (productCode: string, termCode: string) => {
      const productData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.filter(product.termCode.eq(termCode))
        builder.select(
          'offeringId',
          'offeringName',
          'offeringCode',
          'accessModelCode',
          'accessModelDescription',
          'servicePlanCode',
          'servicePlanDescription',
        )
      })
      return productData.data.value[0]
    }

    const getSubscriptions = async (csnId: number) => {
      const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
        builder.filter(subscription.csn.props.id.eq(csnId))
        builder.filter(subscription.status.eq('Active').or(subscription.status.eq('VAR Changed')))
        builder.filter(subscription.subscriptionId.isNotNull().and(subscription.subscriptionId.ne('')))
        builder.select(
          'id',
          'subscriptionId',
          'serialNumber',
          'quantity',
          'productCode',
          'productName',
          'term',
          'endDate',
          'switchType',
        )
      })
      return subscriptionsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          return formRef.current?.instance().validate()
        },
        isDirty() {
          return formRef.current?.instance().option().isDirty
        },
        setDirty(value: boolean) {
          formRef.current?.instance().option('isDirty', value)
        },
      } satisfies ILineItemRef
    }, [])

    //al primo render del componente carico le subs
    useEffect(() => {
      getSubscriptions(Number(csn?.id)).then((subs: ad_subscription[]) => {
        setSubscriptionsDataSource(subs)
      })
    }, [])

    useEffect(() => {
      setPromotionDataSource(ArraySourceComposer('code', promotions))
    }, [promotions])

    return (
      <>
        <div id={`item-quote-container-${item.quoteLineNumber}`} className={style}>
          <LoadingPanel
            position={{ of: `#item-quote-container-${item.quoteLineNumber}` }}
            visible={loadIndicatorVisible}
          ></LoadingPanel>
          <DXForm
            id={`item_form_${item.quoteLineNumber}`}
            key={`item_form_${item.quoteLineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.quoteLineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <GroupItem colCount={1}>
                <SimpleItem>
                  <h3>{title}</h3>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={5} colSpan={1}>
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                {item.action === 'Renewal' && item.opportunityLineItemId ? (
                  <ButtonItem>
                    <ButtonOptions
                      text="Extension"
                      icon={'redo'}
                      stylingMode={'text'}
                      type="success"
                      onClick={(e) => {
                        console.log('e')
                        item.action = 'Extension'
                        setAction(item.action)
                      }}
                    />
                  </ButtonItem>
                ) : item.action === 'Extension' && item.opportunityLineItemId ? (
                  <ButtonItem>
                    <ButtonOptions
                      text="Renewal"
                      icon={'revert'}
                      stylingMode={'text'}
                      type="danger"
                      onClick={(e) => {
                        console.log('e')
                        item.action = 'Renewal'
                        setAction(item.action)
                      }}
                    />
                  </ButtonItem>
                ) : (
                  <EmptyItem></EmptyItem>
                )}
                {item.operation === '' || item.operation === 'Insert' ? (
                  <ButtonItem>
                    <ButtonOptions
                      disabled={readOnly}
                      text="Elimina"
                      icon={'trash'}
                      stylingMode={'text'}
                      type="danger"
                      onClick={(e) => {
                        removeSelf()
                        setStyle('line-item deleted')
                      }}
                    />
                  </ButtonItem>
                ) : (
                  <ButtonItem>
                    <ButtonOptions
                      disabled={readOnly}
                      text="Ripristina"
                      icon={'undo'}
                      stylingMode={'text'}
                      type="success"
                      onClick={(e) => {
                        setStyle('line-item')
                        item.operation = ''
                      }}
                    />
                  </ButtonItem>
                )}
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={5}>
              <SimpleItem
                dataField="subscription"
                label={{ text: 'Subscription' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', async (e: ClickEvent) => {
                      if (item.subscription) {
                        if (typeof item.subscription === 'object')
                          window.open(`/software/autodesk/${item.subscription.id}`, '_blank')
                        else {
                          const sub = item.subscription
                          const getSub = await service.ad_subscription().query((builder, subsciption) => {
                            builder.filter(subsciption.subscriptionId.eq(sub))
                            builder.select('id')
                          })
                          if (getSub.data.value.length > 0)
                            window.open(`/software/autodesk/${getSub.data.value[0].id}`, '_blank')
                          else notify(`Nessun subcription trovato.`, 'warning', 3000)
                        }
                      } else notify(`Deve essere selezionato un subscription id per poterlo aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentSubs,
                  dataSource: ArraySourceComposer('id', subscriptionsDataSource),
                  placeholder: 'Selezionare subscription...',
                  displayExpr: 'subscriptionId',
                  searchEnabled: true,
                  acceptCustomValue: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare subscription',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || item.opportunityLineItemId || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log('ValueChangedEventSelectBox', e)
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('SUBSCRIPTION EVENT CHANGED', e.previousValue, e.value)
                    if (e.value && typeof e.value === 'object') {
                      setQuantitySubscription(e.value.quantity)
                      formRef.current?.instance().updateData('quantity', e.value.quantity)
                      getProductCatalog(e.value.productCode, e.value.term).then((res: ad_product_catalog) => {
                        setProductSubscription(res)
                      })
                      setCurrentSubs(e.value)
                    }
                  },
                  onCustomItemCreating: (e: CustomItemCreatingEvent) => {
                    console.log('CustomItemCreatingEvent', e)
                    if (!e.customItem) e.customItem = e.text
                    setCurrentSubs(e.customItem)
                    setQuantitySubscription(0)
                    formRef.current?.instance().updateData('quantity', 0)
                    setProductSubscription(null)
                  },
                  onInitialized: (e: InitializedEvent) => {
                    e.element?.addEventListener('mouseout', (event) =>
                      handleSubscriptionMouseOut(event, e, subsPopoverRef),
                    )
                    e.element?.addEventListener('mouseover', (event) =>
                      handleSubscriptionMouseOver(event, e, subsPopoverRef),
                    )
                  },
                }}
              >
                <RequiredRule message="Subscription obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                visible={item.action === 'Renewal'}
                dataField="quantity"
                editorType="dxNumberBox"
                label={{ text: 'Quantità' }}
                editorOptions={{
                  disabled: item.operation === 'Delete',
                  showSpinButtons: true,
                  min: 0,
                  onValueChanged: (e: NumberBoxTypes.ValueChangedEvent) => {
                    console.log('quantity event changed', e)
                    getPromotions(
                      item.action,
                      productSubscription?.offeringCode,
                      'COM',
                      productSubscription?.termCode,
                      productSubscription?.accessModelCode,
                      item.quantity,
                    ).then((res: ad_promotion[]) => {
                      setPromotionDataSource(
                        ArraySourceComposer('code', [
                          ...new Map(res.map((promotion: ad_promotion) => [promotion['code'], promotion])).values(),
                        ]),
                      )
                    })
                  },
                }}
              >
                <RequiredRule message="Quantità obbligatoria"></RequiredRule>
                {/* <CustomRule
                  message="Quantità deve essere maggiore di 0 ma non maggiore dell'attuale quantità presente in abbonamento"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return value > 0 && value <= quantitySubscription
                  }}
                ></CustomRule> */}
              </SimpleItem>
              <SimpleItem
                visible={item.action === 'Renewal'}
                dataField="promotion"
                label={{ text: 'Promozione' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'refresh',
                        hint: 'Aggiorna elenco',
                        onClick: () => {
                          setLoadIndicatorVisible(true)
                          if (refreshPromotions) {
                            refreshPromotions().finally(() => {
                              setLoadIndicatorVisible(false)
                            })
                          }
                        },
                      },
                    },
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: promotionDataSource,
                  placeholder: 'Selezionare la promozione...',
                  displayExpr: (item: ad_promotion) => {
                    return item ? `${item.name} - ${item.code}` : ''
                  },
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la promozione',
                    hideOnOutsideClick: true,
                    resizeEnabled: true,
                  },
                  clearButtonText: 'Clear',
                  showClearButton: true,
                  disabled: item.operation === 'Delete',
                }}
              ></SimpleItem>
              <SimpleItem
                visible={item.action === 'Extension'}
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  applyValueMode: 'instantly',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              >
                <RequiredRule message="Data Fine obbligatorio"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && (
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="pricing.unitSRP"
                  label={{ text: 'Prezzo Singolo Prodotto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedSRP"
                  label={{ text: 'Prezzo Totale Prodotti' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedDiscountedSRP"
                  label={{ text: 'Prezzo Totale Prodotti con Sconto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.endUserPrice"
                  label={{ text: 'Prezzo Finale' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
              </GroupItem>
            )}
          </DXForm>
          <Popover position="bottom" ref={subsPopoverRef} />
        </div>
      </>
    )
  },
)

export default LineItemRenewal
