import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { memo, useEffect, useRef, useState } from 'react'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import type {
  ad_quote,
  ad_subscription,
  commessa,
  Editablead_subscription,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useTokenRefresh, AzureUserInfo, getAzureUserInformation } from '@/auth/azure/azureManager'
import { GridCellColor } from '@/enums'
import {
  Column,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Item,
  Paging,
  StateStoring,
  Toolbar,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import type { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { Link } from 'react-router-dom'

const QuotesBriefGrid = (props: {
  project?: commessa
  licenseAdsk?: ad_subscription | Editablead_subscription | undefined
  readOnly?: boolean
}) => {
  const { project, licenseAdsk, readOnly } = props
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const token = useTokenRefresh()
  const [userInfo, setUserInfo] = useState<AzureUserInfo | undefined>()
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'status' && e.value) {
      switch (e.value) {
        case 'ORDERED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'UNDER REVIEW': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
        case 'QUOTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`
          break
        }

        case 'DRAFT': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.GREY}`
          break
        }
        case 'FINALIZING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.VIOLET}`
          break
        }
        case 'EXPIRED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.BROWN}`
          break
        }
        case 'CANCELLED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'CANCELLING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'FAILED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
          break
        }
        case 'ORDER SUBMITTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }

  const quotesDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_quote`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: project
      ? [['commessa.id', project?.id]]
      : licenseAdsk && 'id' in licenseAdsk
        ? [[`ad_subscriptions/any(a: (a/id eq ${licenseAdsk.id}))`]]
        : [['number', '']],
    expand: ['csn', 'contact', 'admin'],
  })

  useEffect(() => {
    getAzureUserInformation().then((userInfo: AzureUserInfo | undefined) => {
      setUserInfo(userInfo)
    })
  }, [])

  return (
    <>
      <DataGrid
        id={`quotes-brief-grid`}
        ref={gridRef}
        dataSource={quotesDataSource}
        noDataText={
          project
            ? `Nessuna quota associata alla commessa`
            : licenseAdsk
              ? `Nessuna quota associata alla subscription`
              : `No data`
        }
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/quotes/${e.data.id}`, '_blank')
        }}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'quotes-brief-grid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/quotes/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
          defaultSortOrder="desc"
        />
        ,
        <Column
          dataField="transactionId"
          caption="ID TRANSACTION"
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_quote = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              data.number && data.number !== 'NA' ? (
                <>{data.transactionId?.toString()}</>
              ) : (
                <>
                  <Link to={`/quotes/${data.id}`}>{data.transactionId?.toString()}</Link>
                </>
              )
            ) : (
              <>{data.transactionId?.toString()}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField="number"
          caption="NUMBER"
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_quote = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              data.number === 'NA' ? (
                <>{data.number}</>
              ) : (
                <>
                  <Link to={`/quotes/${data.id}`}>{data.number}</Link>
                </>
              )
            ) : (
              <>{data.number}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField="csn.csn"
          caption="CSN"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        />
        <Column
          dataField="status"
          caption="STATO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField="contact.fullname"
          caption="CONTATTO"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        />
        <Column
          dataField="admin.fullname"
          caption="AMMINISTRATORE"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Column
          dataField="auto"
          caption="AUTOMATICA"
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Toolbar visible={!readOnly}>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
        </Toolbar>
      </DataGrid>
    </>
  )
}

const QuotesBriefGridMemoed = memo(QuotesBriefGrid, (oldProps, newProps) => {
  return oldProps.project === newProps.project
})

export default QuotesBriefGridMemoed
