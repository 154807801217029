import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { intervento, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import InterventionEditor from '@/routes/interventions/InterventionEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { redirect } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'
import { StatoFatturazioneIntervento } from '@/routes/interventions/InterventionEditor.enums'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import { Qintervento, Qtask } from '@/model/qsadminapi/QQsAdminApiModule'
import { StatoTask } from '@/routes/tasks/tasks.types'
import { Reparto } from '@/enums'
import { AxiosResponse } from 'axios'

export const interventionEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'interventionId' | 'clientId'>) => {
  const taskId = new URL(request.url).searchParams.get('taskId')
  const interventionId = params.interventionId
  const clientId = params.clientId

  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
        gruppo_aziendaleBuilder.select('id', 'nome')
      })
      builder.select('id', 'nome', 'gruppo_aziendale')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let intervention: intervento

  if (interventionId) {
    //editor
    const interventionData = await qsAdminApi
      .intervento(Number(interventionId))
      .query((builder, intervento) => {
        builder.expanding('stato_fatturazione', (statoFattBuilder, statoFatt) => {
          statoFattBuilder.select('id', 'nome')
        })
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
            })
            aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
              gruppo_aziendaleBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome', 'invio_rapportino_cliente_disabilitato', 'is_rivenditore')
            })
            aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
              agenteBuilder.select('id', 'commerciale_qs')
              agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commerciale) => {
                commercialeQsBuilder.select('id', 'fullname')
              })
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale', 'rivenditore', 'agente')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('task', (taskBuilder, task) => {
          taskBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('pacchetti_utilizzo', (pacchettiUtilizzoBuilder, pacchetto) => {
          pacchettiUtilizzoBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('attivita_lavorative', (attivitaLavorativeBuilder, attivita) => {
          attivitaLavorativeBuilder.select('id', 'ded_Dis')
        })
        builder.expanding('impiegati', (impiegatiBuilder, impiegato) => {
          impiegatiBuilder.select('id', 'fullname')
        })
        builder.expanding('riferimenti_cliente', (riferimentiClienteBuilder, riferimento) => {
          riferimentiClienteBuilder.select('id', 'fullname')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Intervento non trovato')
        throw new Error()
      })
    intervention = interventionData.data satisfies intervento
    title += `${intervention.ded_Dis}`
    if (clientId && intervention?.sede?.azienda && intervention?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'intervento ${intervention.ded_Dis} appartiene al cliente ${intervention?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator
    title += `Nuovo intervento`

    //intervento creato a partire da task
    const taskData = taskId
      ? await qsAdminApi.task(+taskId).query((builder, task) => {
          builder.select('id', 'ded_Dis')
        })
      : undefined

    const sedeData = taskData?.data
      ? await qsAdminApi.sede().query((sedeBuilder, sede) => {
          sedeBuilder.filter(sede.tasks.any((task: Qtask) => task.id.eq(taskData?.data.id)))
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
            })
            aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppoAziendaleBuilder, gruppo_aziendale) => {
              gruppoAziendaleBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome', 'invio_rapportino_cliente_disabilitato', 'is_rivenditore')
            })
            aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
              agenteBuilder.select('id', 'commerciale_qs')
              agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commerciale) => {
                commercialeQsBuilder.select('id', 'fullname')
              })
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale', 'rivenditore', 'agente')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
      : undefined

    const statoFatturazioneDefaultData = await qsAdminApi
      .fatturazione_intervento(StatoFatturazioneIntervento['DA FATTURARE'])
      .query((builder, stato) => {
        builder.select('id', 'nome')
      })

    const userInfo = await getAzureUserInformation()
    const autoreData = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.select('id', 'fullname')
    })

    intervention = {
      id: 0,
      ded_Dis: '',
      durata_intervento: 0,
      ded_RootFam: '',
      ded_SubFam: '',
      ded_Id: 0,
      data: new Date().toISOString(),
      durata_viaggio: 0,
      note: null,
      anno_rif: new Date().getFullYear(),
      mattina_inizio: null,
      mattina_fine: null,
      pomeriggio_inizio: null,
      pomeriggio_fine: null,
      sede: sedeData?.data.value[0] ?? null,
      task: taskData?.data ?? null,
      remote: false,
      locale: 'it',
      impiegati: [autoreData?.data.value[0]],
      stato_fatturazione: statoFatturazioneDefaultData.data,
      mainDocumentUrl: '',
      riferimenti_cliente: [],
      ignorePackages: false,
      inviato: false,
    }
  }
  const getClients = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    if (taskId)
      builder.filter(
        intervention.sede?.azienda?.gruppo_aziendale
          ? azienda.gruppo_aziendale.props.id.eq(Number(intervention.sede.azienda.gruppo_aziendale.id))
          : intervention.sede
            ? azienda.id.eq(Number(intervention.sede.azienda?.id))
            : null,
      )
    else builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getBillingStates = qsAdminApi.fatturazione_intervento().query((builder, fatturazione_intervento) => {
    builder.select('id', 'nome')
    builder.orderBy(fatturazione_intervento.nome.asc())
  })

  const getTechnicians = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(
      impiegato.attivo
        .eq(true)
        .and(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
        .or(
          interventionId
            ? impiegato.interventi.any((intervento: Qintervento) => intervento.id.eq(Number(interventionId)))
            : null,
        ),
    )
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  const getTasks =
    intervention.sede || clientId
      ? qsAdminApi.task().query((builder, task) => {
          if (intervention.sede?.azienda?.gruppo_aziendale?.id)
            builder.filter(
              task.sede.props.azienda.props.gruppo_aziendale.props.id.eq(intervention.sede.azienda.gruppo_aziendale.id),
            )
          else if (azienda?.data.gruppo_aziendale?.id)
            builder.filter(
              task.sede.props.azienda.props.gruppo_aziendale.props.id.eq(azienda?.data.gruppo_aziendale?.id),
            )
          else
            builder.filter(
              task.sede.props.azienda.props.id.eq(Number(intervention.sede?.azienda?.id || azienda?.data.id)),
            )
          builder.filter(
            task.stato.props.id
              .eq(StatoTask.APERTO)
              .or(intervention.task ? task.id.eq(Number(intervention.task?.id)) : null),
          )
          builder.expanding('sede', (sedeBuilder, sede) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda')
          })
          builder.select('id', 'ded_Dis', 'sede')
          builder.orderBy(task.ded_Dis.desc())
        })
      : undefined

  const getReferences = intervention.sede
    ? qsAdminApi.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.id.eq(Number(intervention.sede?.id)))
        builder.filter(
          contatto.attivo
            .eq(true)
            .or(
              intervention.riferimenti_cliente
                ? contatto.interventi.any((intervento: Qintervento) => intervento.id.eq(intervention.id))
                : null,
            ),
        )
        builder.expanding('emails', (emailsBuilder, email) => {
          emailsBuilder.select('id', 'email')
        })
        builder.select('id', 'fullname', 'emails')
        builder.orderBy(contatto.fullname.asc())
      })
    : undefined

  return defer({
    title,
    intervention,
    getBillingStates,
    getClients,
    getTechnicians,
    getReferences,
    getTasks,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const interventionEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const intervention = (await request.json()) as intervento
  console.log('intervento', intervention)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      // const res = await qsAdminApi.intervento().create(intervention)
      // console.log(res)
      // return redirect(`../${res.data.id}`)
      const response: AxiosResponse<intervento, intervento> = await useQsAdminApiManager.getState().client.post(
        'api/intervento/intervention',
        { intervention },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      return redirect(`../${response.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      // return await qsAdminApi.intervento(intervention.id).update(intervention)
      return await useQsAdminApiManager.getState().client.put(
        'api/intervento/intervention',
        { intervention },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const InterventionEditorRoute = {
  path: ':interventionId',
  element: <InterventionEditor creating={false} />,
  loader: interventionEditorRouteLoader,
  action: interventionEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default InterventionEditorRoute
