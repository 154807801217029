import { MgtTemplateProps, Person, Providers } from '@microsoft/mgt-react'
import { PuffLoader } from 'react-spinners'
import { useCallback, useEffect, useState } from 'react'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import './UserCard.scss'
import { Button } from 'devextreme-react'

type APIStatus = {
  status: string
  delay: number
}

const UserCard = (props: MgtTemplateProps) => {
  const version = __APP_VERSION__
  const [qsApiStatus, setQsApiStatus] = useState<APIStatus>({
    status: 'Loading',
    delay: 0,
  })
  const [azureApiStatus, setAzureApiStatus] = useState<APIStatus>({
    status: 'Loading',
    delay: 0,
  })
  const { client } = useQsAdminApiManager()
  const apiHost = import.meta.env.VITE_QSADMINAPI_HOST

  const refreshApiStatus = useCallback(async () => {
    const qsStartTime = Date.now()
    setQsApiStatus({ status: 'Loading', delay: 0 })
    client.get('/health').then((res) => {
      const endTime = Date.now()
      setQsApiStatus({
        status: res.data.status,
        delay: endTime - qsStartTime,
      })
    })

    const azureStartTime = Date.now()
    setAzureApiStatus({ status: 'Loading', delay: 0 })
    try {
      Providers.globalProvider.graph
        .api('/me')
        .select('displayName,mail')
        .headers({
          ConsistencyLevel: 'eventual',
        })
        .get()
        .then((res) => {
          const endTime = Date.now()
          setAzureApiStatus({
            status: 'Healthy',
            delay: endTime - azureStartTime,
          })
        })
        .catch((error) => {
          console.error('Graph API error:', error)
          setAzureApiStatus({
            status: 'Unhealthy',
            delay: Date.now() - azureStartTime,
          })
        })
    } catch (error) {
      console.error('Graph API exception:', error)
      setAzureApiStatus({
        status: 'Unhealthy',
        delay: Date.now() - azureStartTime,
      })
    }
  }, [client])

  useEffect(() => {
    refreshApiStatus()
  }, [client, refreshApiStatus])

  return (
    <div className="card-container">
      <div className="header">
        <Person personQuery="me" view="fourlines" />
      </div>
      <div className={'section'}>
        <div className={'section-title'}>
          <Button icon={'refresh'} onClick={refreshApiStatus} />
          <span>API Status</span>
          <span id="mode">({import.meta.env.MODE})</span>
        </div>
        <div className={'section-block'}>
          <PuffLoader
            color={
              {
                Healthy: '#11c500',
                Unhealthy: '#FF0000',
                Loading: '#00c4ff',
              }[qsApiStatus.status]
            }
            size={'1.5rem'}
            cssOverride={{
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          />
          <a href={apiHost} target="_blank" rel="noreferrer" className="api-link">
            <span className={'endpoint'}>QS APIs</span>
          </a>
          <span className="accent">({qsApiStatus.delay ? `${qsApiStatus.delay} ms` : `awaiting...`})</span>
        </div>
        <div className={'section-block'}>
          <PuffLoader
            color={
              {
                Healthy: '#11c500',
                Unhealthy: '#FF0000',
                Loading: '#00c4ff',
              }[azureApiStatus.status]
            }
            size={'1.5rem'}
            cssOverride={{
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          />
          <a
            href="https://learn.microsoft.com/en-us/graph/overview"
            target="_blank"
            rel="noreferrer"
            className="api-link"
          >
            <span className={'endpoint'}>Microsoft Graph APIs</span>
          </a>
          <span className="accent">( {azureApiStatus.delay ? `${azureApiStatus.delay} ms` : `awaiting...`})</span>
        </div>
      </div>
      <div className={'section-footer'}>
        <span>Version: </span>
        <span className={'accent'}>{version}</span>
      </div>
    </div>
  )
}
export default UserCard
