import SupportQuotePage from '@/routes/support/SupportQuotePage'

import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import { RoleRouteObject } from '@/types'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'

export const supportQuotePageRouteLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const getLicenseQsApps = qsAdminApi.qs_applicazione().query((builder, qs_applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(qs_applicazione.nome.asc())
  })
  const getAdskApps = qsAdminApi.ad_product_catalog().query((builder, ad_product_catalog) => {
    builder.select('offeringName')
    builder.orderBy(ad_product_catalog.offeringName.asc())
  })
  const getPlatformQsApps = qsAdminApi.est_software().query((builder, software) => {
    builder.select('id', 'nome')
    builder.orderBy(software.nome.asc())
  })
  return defer({
    licenseQsApps: await getLicenseQsApps,
    adskApps: await getAdskApps,
    platformQsApps: await getPlatformQsApps,
  })
}) satisfies LoaderFunction

export const SupportQuotePageRoute = {
  path: 'support/quote',
  loader: supportQuotePageRouteLoader,
  element: <SupportQuotePage />,
  handle: {
    crumb: () => ({
      label: 'Preventivo',
      icon: faFileInvoice,
    }),
  },
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator],
} satisfies RoleRouteObject
