import { DocumentUploaderProps } from '@/components/document/DocumentUploader.types'
import { useEffect, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { Get as MgtGet, MgtTemplateProps, File as MgtFile } from '@microsoft/mgt-react'
import { Button, FileUploader, LoadIndicator } from 'devextreme-react'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { FileUploaderTypes } from 'devextreme-react/file-uploader'
import { DocumentPermissions } from '@/enums'
import { AxiosError, AxiosResponse } from 'axios'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'

const BaseFileUploader = (baseprops: DocumentUploaderProps) => {
  const token = useTokenRefresh()
  console.log('OGGE', baseprops)
  return (
    <FileUploader
      disabled={false}
      name={baseprops.paramName}
      selectButtonText={'Seleziona un documento'}
      labelText={'o trascina un file qui'}
      multiple={false}
      accept={'*'}
      uploadMode={'instantly'}
      uploadUrl={baseprops.uploadUrl}
      allowedFileExtensions={baseprops.allowedExtensions}
      onBeforeSend={(e) => {
        e.request.setRequestHeader('Authorization', `Bearer ${token}`)
      }}
      onUploadError={(e: FileUploaderTypes.UploadErrorEvent) => {}}
      onFilesUploaded={(e: FileUploaderTypes.FilesUploadedEvent) => {
        baseprops.documentGetRef.current?.refresh(true)
      }}
    />
  )
}

const DocumentUploader = (uploaderprops: DocumentUploaderProps) => {
  const [permissions, setPermissions] = useState<DocumentPermissions>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const computePermissions = async () => {
      let objperm: DocumentPermissions = DocumentPermissions.Deny
      if (uploaderprops.preliminarPermissionsAction) {
        objperm = await uploaderprops.preliminarPermissionsAction()
      }
      setPermissions(objperm)
      setIsLoading(false)
    }

    computePermissions()
  }, [uploaderprops, uploaderprops.preliminarPermissionsAction])

  if (isLoading) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileCustom = (custprops: MgtTemplateProps) => {
    const data = custprops.dataContext
    if (permissions && permissions !== DocumentPermissions.Deny) {
      return (
        <>
          <MgtFile
            onClick={async (event) => {
              window.open(data.webUrl, '_blank')
            }}
            driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
            itemId={data.id}
          ></MgtFile>
          {permissions === DocumentPermissions.Write && <BaseFileUploader {...uploaderprops} />}
        </>
      )
    } else {
      return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
    }
  }

  const MgtFileLoading = (props: MgtTemplateProps) => {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileError = (props: MgtTemplateProps) => {
    return <div>Impossibile individuare il documento</div>
  }

  const MgtFileNoData = (props: MgtTemplateProps) => {
    if (permissions && permissions === DocumentPermissions.Write) {
      return <BaseFileUploader {...uploaderprops} />
    } else if (permissions && permissions === DocumentPermissions.Read) {
      return <div>Nessun documento presente</div>
    } else {
      return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
    }
  }

  return (
    <>
      <MgtGet
        ref={uploaderprops.documentGetRef}
        resource={`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${uploaderprops.fileUrl}`}
      >
        <MgtFileCustom template="default" />
        <MgtFileLoading template="loading" />
        <MgtFileNoData template="no-data" />
        <MgtFileError template="error" />
      </MgtGet>
    </>
  )
}

export default DocumentUploader
