import { LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRoute, RouteFunctionParams } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import ClientMigration from '@/routes/clients/ClientMigration'
import { azienda, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'

export interface NuovaSede extends sede {
  nuovo_nome: string
}

export const clientMigrationRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const clientId = params.clientId

  let title: string

  const migrationData: {
    azienda_da_migrare: azienda | null
    azienda_destinazione: azienda | null
    sedi: NuovaSede[]
  } = {
    azienda_da_migrare: null,
    azienda_destinazione: null,
    sedi: [],
  }

  const getAziende = useQsAdminApiManager
    .getState()
    .service.azienda()
    .query((builder, azienda) => {
      builder.filter(azienda.sedi.any())
      builder.orderBy(azienda.nome.asc())
      builder.select('id', 'nome')
    })

  if (clientId) {
    const client = await useQsAdminApiManager
      .getState()
      .service.azienda(+clientId)
      .query((builder, azienda) => {
        builder.expanding('sedi', (sediBuilder, sede) => {
          sediBuilder.select('id', 'nome')
        })
        builder.select('id', 'nome', 'sedi')
      })

    migrationData.azienda_da_migrare = client?.data ?? null
    client?.data.sedi?.forEach((sede: sede) => {
      const newSite: NuovaSede = {
        id: sede.id,
        principale: null,
        nome: sede.nome,
        note: null,
        email: null,
        email_pec: null,
        telefono: null,
        fax: null,
        leg_srvpath: null,
        leg_email_rin_ad: null,
        leg_rif_ad: null,
        strada: null,
        citta: null,
        provincia: null,
        cap: null,
        stato: null,
        archived: null,
        nuovo_nome: `${sede.nome} nuova`,
      }
      migrationData.sedi?.push(newSite)
    })
    title = `${client?.data.nome} - Migra sedi`
  } else title = `Migra sedi`

  return {
    title,
    getAziende,
    migrationData,
  }
}) satisfies LoaderFunction

export const ClientMigrationRoute = {
  path: 'migrate',
  loader: clientMigrationRouteLoader,
  element: <ClientMigration />,
  visible: false,
  handle: {
    crumb: () => ({
      label: 'Migrazione',
      icon: faSuitcase,
    }),
  },
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator],
} satisfies RoleRoute
