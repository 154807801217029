import TestComponent from '@/components/TestComponent'
import React from 'react'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { satisfies } from 'semver'
import { RoleRouteObject } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { analisi, stato_analisi } from '@/model/qsadminapi/QsAdminApiModuleModel'

export const TestComponentLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAnalysis = await qsAdminApi.analisi(Number(1800)).query((builder, analysis) => {
    builder.expanding('stato', (statoBuilder, stato) => {
      statoBuilder.select('id', 'nome')
    })
    builder.expanding('creatore', (creatoreBuilder, impiegato) => {
      creatoreBuilder.select('id', 'fullname')
    })
  })

  const getStati = await qsAdminApi.stato_analisi().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const analysis = getAnalysis.data satisfies analisi
  const stati = getStati.data

  return defer({
    analysis,
    stati,
  })
}) satisfies LoaderFunction

export const TestRoute = {
  path: 'test',
  loader: TestComponentLoader,
  element: <TestComponent />,
  allowedRoles: [Roles.Guest],
} satisfies RoleRouteObject
