import { LoaderFunction } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import PackagesUsageGrid from '@/routes/packages/usage/PackagesUsageGrid'
import { PackageUsageEditorRoute } from '@/routes/packages/usage/PackageUsageEditor.route'
import { PackageUsageCreatorRoute } from '@/routes/packages/usage/PackageUsageCreator.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons'

export const packagesUsageGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Pacchetti Utilizzati` : 'Pacchetti Utilizzati'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    title,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const PackagesUsageGridRoute = {
  path: 'usage',
  children: [
    {
      index: true,
      loader: packagesUsageGridRouteLoader,
      element: <PackagesUsageGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PackageUsageCreatorRoute,
    PackageUsageEditorRoute,
  ],

  faIcon: faBoxOpen,
  text: 'Pacchetti Utilizzati',
  handle: {
    crumb: () => ({
      label: 'Utilizzo',
      icon: faBoxOpen,
    }),
  },
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
