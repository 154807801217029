import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { PackageSaleEditorRoute } from '@/routes/packages/sale/PackageSaleEditor.route'

export const SharingRoute: TileRoleRoute = {
  path: 'sharing',
  text: 'Condivisione',
  faIcon: faShareNodes,
  element: (
    <ManagementGridFactory
      entity={'stato_condivisione'}
      referencesCollection={'pacchetti_vendita'}
      referencesTitle={'Pacchetti di Vendita'}
      displayField={'ded_Dis'}
      entityRoute={PackageSaleEditorRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Condivisione',
      icon: faShareNodes,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
