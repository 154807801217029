import { useLoaderData } from 'react-router-typesafe'
import { clientMasterViewRouteLoader, clientTabs } from '@/routes/clients/client/ClientMasterView.route'
import TabbedSelector from '@/components/TabbedSelector'
import DocumentHead from '@/components/document-head/DocumentHead'
import { RoleRoute } from '@/types'

export default function ClientMasterView() {
  const client = useLoaderData<typeof clientMasterViewRouteLoader>()
  const clientTabsFiltered: RoleRoute[] = clientTabs.filter(
    (tab: RoleRoute) => tab.path === 'details' || tab.path === 'sites',
  )
  return (
    <>
      <DocumentHead title={client.nome ?? ''}></DocumentHead>
      <h2 className={'content-block'}>{client.nome}</h2>
      <div className={'dx-card responsive-paddings content-block'}>
        <TabbedSelector tabs={client.sedi && client.sedi?.length > 0 ? clientTabs : clientTabsFiltered} />
      </div>
    </>
  )
}
