import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Paging,
  RequiredRule,
  Button as GridButton,
  StateStoring,
  DataGridRef,
  Toolbar,
  Item,
  DataGridTypes,
} from 'devextreme-react/data-grid'
import type { CellPreparedEvent, InitNewRowEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OrderEditorTasksGridProps } from '@/routes/orders/OrderEditor.types'
import { ProgressBar, TagBox } from 'devextreme-react'
import { TagBoxTypes } from 'devextreme-react/cjs/tag-box'
import { Link, useNavigate } from 'react-router-dom'
import { Form as DxForm, GroupItem } from 'devextreme-react/form'
import DateColumn from '@/components/date-column/DateColumn'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { punto_fatturazione_ordine, stato_task, task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed, onDataGridSelectedRowButtonClick } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { StatoTask } from '@/routes/tasks/tasks.types'
import './OrderEditor.tasks.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck as faEmptyCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { AxiosError, AxiosResponse } from 'axios'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { GridCellColor, Reparto } from '@/enums'
import { StatoPuntoFatturazione } from '@/routes/invoicingsteps/invoicingstep.types'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useTokenRefresh } from '@/auth/azure/azureManager'

const TecniciTagBoxComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: TagBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value)
    },
    [props],
  )

  const onSelectionChanged = useCallback(() => {
    props.data.component.updateDimensions()
  }, [props])

  return (
    <TagBox
      dataSource={props.data.column.lookup.dataSource}
      defaultValue={props.data.value}
      displayExpr="fullname"
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="useButtons"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
    />
  )
}

const OrderEditorTasksGrid = (props: OrderEditorTasksGridProps) => {
  const { order, creating, invoicingStep, readOnly, userInfo, showTitle } = props
  const currentScreenSize = useScreenSize()
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const { client, service } = useQsAdminApiManager()
  const [statiTask, setStatiTask] = useState<stato_task[]>([])
  const [impiegati, setImpiegati] = useState<user[]>([])
  const [autore, setAutore] = useState<user>()
  const [puntiFatturazione, setPuntiFatturazione] = useState<punto_fatturazione_ordine[]>([])
  const token = useTokenRefresh()
  const [tasks, setTasks] = useState<task[]>([])

  const tasksDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/task`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [
      invoicingStep
        ? ['punto_fatturazione.id', invoicingStep && 'id' in invoicingStep ? invoicingStep?.id : null]
        : ['ordine.id', order && 'id' in order ? order?.id : null],
    ],
    expand: [
      'ordine',
      'sede($expand=azienda)',
      'resp_tecnico',
      'proprietario',
      'stato',
      'tecnici',
      'punto_fatturazione',
    ],
  })

  const cellTemplate = (container: { textContent: any; title: any }, options: any) => {
    const noBreakSpace = '\u00A0'
    console.log(options)
    const tecnici = (options.value || []).map((tecnico: any) => options.column.lookup.calculateCellValue(tecnico.id))
    const text = tecnici.join(', ')

    container.textContent = text || noBreakSpace
    container.title = text
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.id') {
      switch (e.value) {
        case StatoTask.APERTO: {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case StatoTask.COMPLETATO: {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
      }
    }
  }

  const handleDuplicateTaskClick = (e: ClickEvent) => {
    onDataGridSelectedRowButtonClick(gridRef, (rowdata: task) => {
      console.log('task', rowdata)
      gridRef?.current?.instance().beginCustomLoading('Duplicazione task in corso....')
      client
        .post(
          '/api/task/duplicatetask',
          { taskId: rowdata.id },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response: AxiosResponse) => {
          gridRef.current?.instance().refresh()
        })
        .catch((error: AxiosError) => {
          notify(`Errore duplicazione task. Dettagli : ${error}`, 'error', 2000)
        })
        .finally(() => {
          gridRef.current?.instance().endCustomLoading()
        })
    })
  }

  const getQsImpiegati = useCallback(async () => {
    const impiegati = await service.user().query((builder, impiegato) => {
      builder.filter(
        impiegato.attivo
          .eq(true)
          .and(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO))),
      )
      builder.select('id', 'fullname')
      builder.orderBy(impiegato.fullname.asc())
    })
    return impiegati.data.value
  }, [service])

  const getStatiTask = useCallback(async () => {
    const statiTask = await service.stato_task().query((builder, stato) => {
      builder.select('id', 'nome')
      builder.orderBy(stato.id.asc())
    })
    return statiTask.data.value
  }, [service])

  const getPuntiFatturazione = useCallback(
    async (orderId?: number) => {
      const pfs = await service.punto_fatturazione_ordine().query((builder, pf) => {
        builder.filter(pf.ordine.props.id.eq(Number(orderId)))
        builder.filter(pf.stato.props.id.eq(StatoPuntoFatturazione['DA FATTURARE']))
        builder.select('id', 'ded_Dis')
        builder.orderBy(pf.ded_Dis.desc())
      })
      return pfs.data.value
    },
    [service],
  )

  const getUser = useCallback(
    async (email: string | undefined) => {
      const user = await service.user().query((builder, user) => {
        builder.select('id', 'fullname')
        builder.filter(user.email.eq(email ?? ''))
      })
      return user.data.value[0]
    },
    [service],
  )

  const getTasks = useCallback(
    async (orderId?: number, invoicingStepId?: number) => {
      const tasks = await service.task().query((builder, task) => {
        builder.filter(
          invoicingStepId
            ? task.punto_fatturazione.props.id.eq(Number(invoicingStepId))
            : task.ordine.props.id.eq(Number(orderId)),
        )
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.select('id', 'stato')
      })
      return tasks.data.value
    },
    [service],
  )

  useEffect(() => {
    getStatiTask().then((result) => {
      setStatiTask(result)
    })
    getQsImpiegati().then((result) => {
      setImpiegati(result)
    })
    getUser(userInfo?.email).then((result) => {
      setAutore(result)
    })
    if (!invoicingStep)
      getPuntiFatturazione(order?.id).then((result) => {
        setPuntiFatturazione(result)
      })
    getTasks(order?.id, invoicingStep?.id).then((result) => {
      setTasks(result)
    })
  }, [])

  return (
    <>
      <DxForm id="order-tasks">
        <GroupItem caption={showTitle === undefined || showTitle ? `Task` : ``}>
          <ProgressBar
            id="order-tasks-progress"
            className={
              tasks.filter((t) => t.stato?.id === StatoTask.COMPLETATO).length === tasks.length
                ? 'test complete'
                : 'test'
            }
            visible={tasks.length > 0}
            min={0}
            showStatus={true}
            max={tasks.length}
            value={tasks.filter((t) => t.stato?.id === StatoTask.COMPLETATO).length}
          />
          <DataGrid
            ref={gridRef}
            id={invoicingStep ? `invoicingStep-task-grid` : `order-task-grid`}
            dataSource={tasksDataSource}
            noDataText={
              invoicingStep ? `Nessun task associato al punto di fatturazione` : `Nessun task associato all'ordine`
            }
            keyExpr={'id'}
            showBorders={true}
            showColumnLines={true}
            focusedRowEnabled={true}
            columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            wordWrapEnabled={false}
            repaintChangesOnly={true}
            onCellPrepared={onCellPrepared}
            width="100%"
            onInitNewRow={(e: InitNewRowEvent) => {
              e.data.ordine = order
              e.data.proprietario = autore
              e.data.stato = statiTask[0]
              e.data.apertura = new Date().toISOString()
              e.data.commessa = order?.commessa
              e.data.sede = order?.commessa?.sede
              e.data.anno_rif = new Date().getFullYear()
              e.data.ded_Id = 0
            }}
            onRowDblClick={(e: RowDblClickEvent<any, any>) => {
              if (e.rowType === 'data') navigate(`../../tasks/${e.data.id}`)
            }}
          >
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={invoicingStep ? `invoicingStep-task-grid` : `order-task-grid`}
              savingTimeout={50}
            />
            <Paging defaultPageSize={8} />
            <Editing
              mode="batch"
              allowAdding={!creating && !readOnly}
              allowDeleting={!creating && !readOnly}
              selectTextOnEditStart={true}
              startEditAction={'click'}
            />
            <Column
              dataType={'boolean'}
              dataField={'stato'}
              fixed={true}
              fixedPosition={'left'}
              showInColumnChooser={false}
              width={50}
              caption={''}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
              }
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const stato: task = cellData.data.stato
                console.log('stato', stato)
                return (
                  <FontAwesomeIcon
                    icon={stato.id ? faCircleCheck : faEmptyCircleCheck}
                    color={stato.id ? 'green' : 'orange'}
                  />
                )
              }}
            ></Column>
            <Column
              dataField={'ded_Dis'}
              caption={'CODICE'}
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const data: task = cellData.row.data
                return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                  <>
                    <Link to={`/tasks/${data.id}`}>{data.ded_Dis}</Link>
                  </>
                ) : (
                  <>{data.ded_Dis}</>
                )
              }}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
              }
            />
            <Column dataField={'id'} caption={'ID'} visible={false} />,
            <Column dataField={'ordine.ded_Dis'} caption={'ORDINE'} visible={false} />,
            <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} visible={false} />
            <Column dataField={'sede.nome'} caption={'SEDE'} visible={false} />
            <Column
              dataField={'proprietario.id'}
              caption={'AUTORE'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
              }
              editorOptions={{ readOnly: true }}
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" allowClearing={true} />
            </Column>
            <Column
              dataField={'resp_tecnico.id'}
              caption={'RESPONSABILE'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
              }
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" allowClearing={true} />
              <RequiredRule message="Il responsabile tecnico è obbligatorio" />
            </Column>
            <Column
              dataField={'tecnici'}
              caption={'TECNICI AUSILIARI'}
              editCellComponent={TecniciTagBoxComponent}
              cellTemplate={cellTemplate}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
              }
            >
              <Lookup dataSource={impiegati} displayExpr="fullname" valueExpr="id" />
            </Column>
            <Column
              dataField={'stato.id'}
              caption={'STATO'}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
              }
            >
              <Lookup dataSource={statiTask} displayExpr="nome" valueExpr="id" allowClearing={true} />
            </Column>
            {DateColumn({
              dataField: 'apertura',
              caption: 'APERTURA',
              format: 'dd/MM/yyyy',
              hidingPriority:
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined,
            })}
            {DateColumn({
              dataField: 'consegna_prevista',
              caption: 'CONSEGNA',
              format: 'dd/MM/yyyy',
              hidingPriority:
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined,
            })}
            <Column
              dataField={'punto_fatturazione.id'}
              caption={'PUNTO FATTURAZIONE'}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
              }
              visible={!invoicingStep}
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const data: task = cellData.row.data
                return isUserRoleAllowed(userInfo?.roles, [
                  Roles.GlobalAdministrator,
                  Roles.Supervisor,
                  Roles.Administrator,
                ]) ? (
                  <>
                    <Link to={`/invoicingsteps/${data.punto_fatturazione?.id}`}>
                      {data.punto_fatturazione?.ded_Dis}
                    </Link>
                  </>
                ) : (
                  <>{data.punto_fatturazione?.ded_Dis}</>
                )
              }}
            >
              <Lookup dataSource={puntiFatturazione} displayExpr="ded_Dis" valueExpr="id" allowClearing={true} />
            </Column>
            <Column dataField={'note'} caption={'NOTE'} visible={false} />
            <Column type="buttons" width={'4%'} alignment="left">
              <GridButton name="delete"></GridButton>
            </Column>
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                showText="inMenu"
                widget="dxButton"
                options={{
                  hint: 'Refresh',
                  text: 'Refresh',
                  icon: 'refresh',
                  onClick: (e: ClickEvent) => {
                    // gridRef.current?.instance().refresh()
                    getTasks(order?.id, invoicingStep?.id).then((result) => {
                      setTasks(result)
                    })
                  },
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="addRowButton"
                options={{
                  hint: 'Nuovo task',
                  text: 'Nuovo task',
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                visible={!readOnly}
                widget="dxButton"
                options={{
                  hint: 'Duplica',
                  icon: 'copy',
                  onClick: handleDuplicateTaskClick,
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="saveButton"
                options={{
                  hint: 'Salva',
                  text: 'Salva',
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="revertButton"
                options={{
                  hint: 'Annulla modifiche',
                  text: 'Annulla modifiche',
                  stylingMode: 'text',
                }}
              ></Item>
            </Toolbar>
          </DataGrid>
        </GroupItem>
      </DxForm>
    </>
  )
}

export default OrderEditorTasksGrid
