import React from 'react'
import { RouteObject } from 'react-router-dom'
import { LogoutComponent } from '@/components/LogoutComponent'

const LogoutRoute: RouteObject = {
  path: '/logout',
  element: <LogoutComponent />,
}

export default LogoutRoute
