import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { Suspense } from 'react'
import { useFetcher } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'
import { PackageUsageEditorProps } from '@/routes/packages/usage/PackageUsageEditor.types'
import { packageUsageEditorRouteLoader } from '@/routes/packages/usage/PackageUsageEditor.route'
import PackageUsageEditorForm from '@/routes/packages/usage/PackageUsageEditor.form'
import { isUserRoleAllowed } from '@/routes/utils'
import DocumentHead from '@/components/document-head/DocumentHead'

const PackageUsageEditor = (props: PackageUsageEditorProps) => {
  const { creating } = props
  const {
    title,
    packageUsage,
    sede,
    getIntervento,
    getAziende,
    getPacchettiVendita,
    getBillingStates,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof packageUsageEditorRouteLoader>()

  const fetcher = useFetcher()

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2>{title}</h2>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={Promise.all([getAziende, getPacchettiVendita, getBillingStates, getIntervento, getUserInfo])}>
          {([aziende, pacchetti, billingStates, intervento, userInfo]) => (
            <>
              <div id="edit-adsk-license-container" className={`dx-card responsive-paddings`}>
                <fetcher.Form>
                  <PackageUsageEditorForm
                    pacchetto={packageUsage}
                    sede={sede.data.value[0]}
                    intervento={intervento.data}
                    aziende={aziende.data.value}
                    pacchetti={pacchetti?.data.value}
                    statiFatturazioneIntervento={billingStates.data.value}
                    creating={creating}
                    userInfo={userInfo}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  ></PackageUsageEditorForm>
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default PackageUsageEditor
