import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import OrderCreatorRoute from '@/routes/orders/OrderCreator.route'
import OrderEditorRoute from '@/routes/orders/OrderEditor.route'
import OrderResellerCreatorRoute from '@/routes/orders/OrderResellerCreator.route'
import OrdersPage from '@/routes/orders/OrdersPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'

export const ordersPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Ordini` : 'Ordini'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  const getStati = qsAdminApi.stato_ordine().query((builder, ordine) => {
    builder.select('id', 'nome')
    builder.orderBy(ordine.nome.asc())
  })

  return defer({
    title,
    aziende: await getAziende,
    stati: await getStati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
    ],
  })
}) satisfies LoaderFunction

export const OrdersPageRoute: RoleRoute = {
  path: 'orders',
  children: [
    {
      index: true,
      element: <OrdersPage />,
      loader: ordersPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    OrderCreatorRoute,
    OrderResellerCreatorRoute,
    OrderEditorRoute,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Ordini',
  faIcon: faClipboardList,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Ordini',
      icon: faClipboardList,
    }),
  },
  allowedRoles: [Roles.Guest],
}
