import ODataStore from 'devextreme/data/odata/store'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Editing,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  StateStoring,
  Summary,
} from 'devextreme-react/data-grid'
import type { RowDblClickEvent, RowPreparedEvent } from 'devextreme/ui/data_grid'
import { useCallback, useRef } from 'react'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useScreenSize } from '@/themes/media-query'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { columnSourceFactory } from '@/routes/utils'
import type { PermissionPolicyRole, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useNavigate } from 'react-router'

const roleColors: Record<string, string> = {
  GlobalAdministrator: '#FF4136', // Strong Red
  Administrator: '#FF851B', // Bright Orange
  Supervisor: '#b39700', // Golden Yellow
  TechDeveloper: '#39a5fb', // Bright Blue
  Marketing: '#2ECC40', // Vibrant Green
  Sales: '#39CCCC', // Teal
}

const getHighestPriorityRole = (roles: PermissionPolicyRole[]) => {
  const rolePriority = Object.keys(roleColors)

  return (
    roles
      ?.map((r) => r.Name ?? '')
      ?.filter((role) => rolePriority.includes(role)) // Exclude roles not in roleColors
      ?.sort((a, b) => rolePriority.indexOf(a) - rolePriority.indexOf(b))[0] || 'Default'
  )
}

export function UsersGrid() {
  const dataGridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()
  const token = useTokenRefresh()
  const title = 'Utenti'

  const getGridHeight = useCallback(() => {
    if (currentScreenSize.isSmall) return '120vh'
    if (currentScreenSize.isMedium) return '75vh'
    if (currentScreenSize.isXSmall) return '75vh'
    return '79vh'
  }, [currentScreenSize])

  const onRowPrepared = useCallback((e: RowPreparedEvent<user>) => {
    if (e.rowType === 'data') {
      if (!e.data.attivo) {
        e.rowElement.style.color = '#999'
        return // No need to continue processing
      }

      const userRoles = e.data.applicationUser?.Roles || []
      const highestRole = getHighestPriorityRole(userRoles)

      if (highestRole === 'GlobalAdministrator') {
        e.rowElement.style.fontWeight = 'bold'
      }

      e.rowElement.style.color = roleColors[highestRole] || '' // Fallback to default if needed
    }
  }, [])

  const userDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/User`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['reparto', 'applicationUser(expand=Roles)'],
    requireTotalCount: true,
    sort: [
      { selector: 'attivo', desc: false },
      { selector: 'fullname', desc: false },
    ],
  }

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <h2 className={'content-block'}>{title}</h2>
      <div className={'content-block'}>
        <DataGrid
          id="users-grid"
          height={getGridHeight}
          className={'dx-card wide-card'}
          dataSource={userDataSource}
          noDataText="Nessun utente trovato"
          remoteOperations={{
            filtering: true,
            paging: true,
            sorting: true,
            grouping: true,
            groupPaging: false,
          }}
          showBorders={false}
          showColumnLines={true}
          focusedRowEnabled={true}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          ref={dataGridRef}
          onRowDblClick={(e: RowDblClickEvent) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
          selection={{ mode: 'single' }}
          onRowPrepared={onRowPrepared}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />

          <Grouping autoExpandAll={true} contextMenuEnabled={true} />
          <GroupPanel
            visible={currentScreenSize.isLarge || currentScreenSize.isMedium}
            emptyPanelText={'Trascina qui una colonna per raggruppare'}
          />
          <Summary groupItems={[{ column: 'reparto', summaryType: '' }]} />

          <StateStoring enabled={true} type="localStorage" storageKey="users-grid" />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} title={'Scelta Colonne'} />

          <Column dataField={'id'} width={'5%'} caption="ID" dataType="number" visible={false} />

          <Column dataField={'fullname'} caption={'Nome Completo'} width={'20%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'User', 'fullname')}>
              <Search enabled={true} searchExpr={'fullname'} />
            </HeaderFilter>
          </Column>

          <Column dataField={'nome'} caption={'Nome'} width={'15%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'User', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>

          <Column dataField={'cognome'} caption={'Cognome'} width={'15%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'User', 'cognome')}>
              <Search enabled={true} searchExpr={'cognome'} />
            </HeaderFilter>
          </Column>

          <Column dataField={'email'} caption={'Email'} width={'20%'}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'User', 'email')}>
              <Search enabled={true} searchExpr={'email'} />
            </HeaderFilter>
          </Column>

          <Column dataField={'email_ad'} caption={'Email AD'} width={'20%'} visible={false} />

          <Column dataField={'reparto.nome'} caption={'Reparto'} width={'15%'} defaultGroupIndex={0}>
            <HeaderFilter dataSource={columnSourceFactory(token, 'User', 'reparto.nome')}>
              <Search enabled={true} searchExpr={'reparto.nome'} />
            </HeaderFilter>
          </Column>

          <Column dataField={'contractor'} caption={'Contractor'} dataType="boolean" width={'8%'} visible={false} />

          <Column dataField={'note'} caption={'Note'} width={'20%'} visible={false} />
          <Column
            dataField={'attivo'}
            caption={'Attivo'}
            dataType="boolean"
            width={'8%'}
            visible={false}
            defaultGroupIndex={1}
            groupCellRender={(options: DataGridTypes.ColumnGroupCellTemplateData) => {
              return options.value === 1 ? 'Attivi' : 'Inattivi'
            }}
            calculateGroupValue={(rowData: user) => (rowData.attivo ? 1 : 2)}
            autoExpandGroup={true}
          />
          <Column dataField={'applicationUser.roles'} caption={'Ruoli'} width={'15%'} visible={false} />

          <Editing allowAdding={false} allowDeleting={false} allowUpdating={true} mode="form" />
        </DataGrid>
      </div>
    </>
  )
}
