import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'

type RatingProps = {
  value: number
  onChange?: (selectedValue: number) => void
}

const Rating = (props: RatingProps) => {
  const [value, setValue] = useState(0)
  const [iconSize, setIconSize] = useState(0)
  const containerRef = useRef<HTMLSpanElement | null>(null)
  const colorMap = {
    active: '#ffb700',
    inactive: '#fbf1a9',
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    const resizeIcons = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0]
      if (entry.contentRect) {
        const width = entry.contentRect.width
        setIconSize(Math.min(width / 5, 17)) // Compute icon size based on container width
      }
    }

    const observer = new ResizeObserver(resizeIcons)
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <span ref={containerRef} style={{ display: 'inline-block', width: '100%' }}>
      {[1, 2, 3, 4, 5].map((index) => {
        return (
          <FontAwesomeIcon
            icon={faStar}
            color={index <= value ? colorMap.active : colorMap.inactive}
            key={index}
            style={{ width: iconSize, height: iconSize }}
          />
        )
      })}
    </span>
  )
}

export default Rating
