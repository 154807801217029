import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faHeadset } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { SupportPageRoute } from '@/routes/support/SupportPage.route'

export const SupportRoute: TileRoleRoute = {
  path: 'support',
  text: 'Supporto',
  faIcon: faHeadset,
  element: (
    <ManagementGridFactory
      entity="tipologia_assistenza"
      referencesCollection="maintenance"
      referencesTitle="Supporto"
      displayField="ded_Dis"
      entityRoute={SupportPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Supporto',
      icon: faHeadset,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
