import CalendarPage from './CalendarPage'
import { RoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

const generateColorByUserId = (userid: number): string => {
  // Use a combination of a prime number and a multiplier for better distribution
  const hue = (userid * 43 + 17) % 360 // 43 and 17 are primes for better distribution
  const saturation = 40 + (Math.abs(userid * 3) % 40) // Saturation between 40% and 80%
  const lightness = 30 + (Math.abs(userid * 5) % 30) // Lightness between 30% and 60%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export const calendarPageRouteLoader = (async () => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()

  const getImpiegati = await qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname', 'email', 'reparto')
    builder.expanding('reparto', (repartoBuilder, reparto) => {
      repartoBuilder.select('id', 'nome')
    })
    builder.filter(impiegato.attivo.eq(true).and(impiegato.azienda.props.p_iva.eq('01955220346')))
    builder.orderBy(impiegato.fullname.asc())
  })

  const allEmployee = getImpiegati.data.value

  const groupedImpiegatiMap: Record<
    string,
    {
      fullname: string
      email: string | null
      items: { userid: number; fullname: string; email: string | null }[]
    }
  > = {}

  for (const impiegato of allEmployee) {
    const repartoName = impiegato.reparto?.nome || 'Unknown'
    if (!groupedImpiegatiMap[repartoName]) {
      groupedImpiegatiMap[repartoName] = { fullname: repartoName, email: null, items: [] }
    }
    groupedImpiegatiMap[repartoName].items.push({
      userid: impiegato.id,
      fullname: impiegato.fullname,
      email: impiegato.email,
    })
  }

  const coloredImpiegati = allEmployee.map((item, index) => ({
    ...item,
    color: generateColorByUserId(item.id),
  }))

  return defer({
    userInfo: await getAzureUserInformation(),
    impiegati: coloredImpiegati,
    groupedImpiegati: Object.values(groupedImpiegatiMap),
  })
}) satisfies LoaderFunction

const CalendarPageRoute: RoleRoute = {
  path: 'calendar',
  loader: calendarPageRouteLoader,
  element: <CalendarPage />,
  errorElement: <RouterErrorHandler />,
  mainMenuRoute: true,
  text: 'Calendario',
  faIcon: faCalendar,
  handle: {
    crumb: () => ({
      label: 'Calendario',
      icon: faCalendar,
    }),
  },
  allowedRoles: [
    Roles.GlobalAdministrator,
    Roles.Administrator,
    Roles.Marketing,
    Roles.TechDeveloper,
    Roles.Sales,
    Roles.Supervisor,
  ],
}

export default CalendarPageRoute
