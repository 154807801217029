import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { OrdersPageRoute } from '@/routes/orders/OrdersPage.route'

export const OrderRoute: TileRoleRoute = {
  path: 'order',
  text: 'Ordine',
  faIcon: faCartShopping,
  element: (
    <ManagementGridFactory
      entity={'stato_ordine'}
      referencesCollection={'ordini'}
      referencesTitle={'Ordini'}
      displayField={'ded_Dis'}
      entityRoute={OrdersPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Ordine',
      icon: faCartShopping,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
