import { create } from 'zustand'
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-common'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useEffect, useState } from 'react'
import {} from 'react-router'
import { RolesDescriptor } from '@/auth/azure/Roles'

import azureConfig, { loginRequest } from '@/auth/azure/azureConfig'
import { Router } from '@remix-run/router'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { apiScope } from '@/auth/api/qsadminapiManager'

export interface AzureUserInfo {
  groups?: string[]
  email?: string
  audience?: string
  name?: string
  roles?: RolesDescriptor
  sub?: string
}

interface AzureProps {
  pca?: IPublicClientApplication
  router?: Router
  userInfo?: AzureUserInfo
}

interface AzureState extends AzureProps {
  getToken: (scopes?: string[], tokenType?: 'id' | 'access', account?: AccountInfo) => Promise<string>
}

export const userDataFromAccountInfo = (account?: AccountInfo): AzureUserInfo => {
  return {
    email: account?.username,
    name: account?.name,
    audience: account?.idTokenClaims?.aud,
    groups: account?.idTokenClaims?.groups as string[],
    roles: new RolesDescriptor(account?.idTokenClaims?.roles),
    sub: account?.idTokenClaims?.sub,
  } satisfies AzureUserInfo
}

export const createAzureStore = (initProps?: Partial<AzureProps>) => {
  const DEFAULT_PROPS: AzureProps = {
    pca: undefined,
    router: initProps?.router,
  }

  return create<AzureState>()(
    devtools(
      subscribeWithSelector((set, get) => ({
        ...DEFAULT_PROPS,
        ...initProps,

        getToken: async (
          scopes: string[] = [apiScope],
          tokenType: 'id' | 'access' = 'access',
          account?: AccountInfo,
        ) => {
          const pca = get().pca
          if (!pca) {
            console.error('PCA not initialized')
            return ''
          }

          try {
            // Get active account or use provided account
            const activeAccount = account || pca.getActiveAccount()
            const accounts = pca.getAllAccounts()

            // If no active account but accounts exist, set first one as active
            if (!activeAccount && accounts && accounts.length > 0) {
              pca.setActiveAccount(accounts[0])
            }

            // Try to acquire token silently
            const authReq = {
              scopes,
              account: account || pca.getActiveAccount() || undefined,
            }

            const authRes = await pca.acquireTokenSilent(authReq)

            // Update user info if account available
            if (authRes?.account) {
              set({ userInfo: userDataFromAccountInfo(authRes.account) })
            }

            // Return the requested token type
            return { id: authRes?.idToken, access: authRes?.accessToken }[tokenType] ?? ''
          } catch (error: unknown) {
            console.warn('Silent token acquisition failed:', error)

            // Handle monitor_window_timeout error
            if (
              error &&
              typeof error === 'object' &&
              'errorCode' in error &&
              error.errorCode === 'monitor_window_timeout'
            ) {
              console.error('Silent token acquisition timed out.')
              if (get().router) await get().router?.navigate('/login')
              else throw error
              return ''
            }

            // Handle interaction required errors with redirect
            if (
              error instanceof InteractionRequiredAuthError ||
              (error && typeof error === 'object' && 'errorCode' in error && error.errorCode === 'no_account_error')
            ) {
              try {
                // Always use redirect for auth flow
                await pca.acquireTokenRedirect({
                  ...loginRequest,
                  scopes,
                })
                return ''
              } catch (redirectError) {
                console.error('Redirect token acquisition failed:', redirectError)
                if (get().router) await get().router?.navigate('/login')
                else throw redirectError
                return ''
              }
            }

            // For any other errors, redirect to login
            if (get().router) await get().router?.navigate('/login')
            else throw new Error('No active account and router not instantiated')

            return ''
          }
        },
      })),
    ),
  )
}

type AzureStore = ReturnType<typeof createAzureStore>

export const useAzureManager = createAzureStore()

export const getAzureUserInformation = async (scopes: string[] = [apiScope]): Promise<AzureUserInfo | undefined> => {
  let userInfo = useAzureManager.getState().userInfo
  if (!userInfo) {
    await useAzureManager.getState().getToken(scopes)
    userInfo = useAzureManager.getState().userInfo
  }
  return userInfo
}

export const useTokenRefresh = () => {
  const [token, setToken] = useState<string | undefined>()
  const refreshInterval = 60 * 1000 // every minute (in milliseconds)

  useEffect(() => {
    // Function to fetch and set the token
    const fetchToken = async () => {
      try {
        const newToken = await useAzureManager.getState().getToken()
        setToken(newToken)
        console.info('[TokenRefresher] token refreshed.')
      } catch (error) {
        console.error('[TokenRefresher] Error refreshing token:', error)
      }
    }

    // Initial fetch of the token
    fetchToken()

    // Set up the interval to fetch the token
    const intervalId = setInterval(() => {
      fetchToken()
    }, refreshInterval)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId)
  }, [refreshInterval])

  return token
}
