import { AzureUserInfo } from '@/auth/azure/azureManager'
import { ticket, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { DataGridRef } from 'devextreme-react/cjs/data-grid'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import React from 'react'
import { FetcherWithComponents } from 'react-router-dom'

export const handleOnClickTakeCharge = async (
  ticket: ticket | null | undefined,
  userInfo: AzureUserInfo | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
) => {
  if (
    (ticket?.incaricato && ticket?.incaricato.email !== userInfo?.email) ||
    (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email)
  ) {
    const result = confirm(
      ticket.incaricato && ticket.incaricato.fullname !== userInfo?.name
        ? `<i>Il ticket<b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`
        : `<i>Il ticket<b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato?.fullname}. Si desidera procedere ugualmente?</i>`,
      'Presa in Carico Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return false
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket.id}/takecharge`,
        encType: 'application/json',
      })
    })
  } else
    fetcher?.submit(JSON.stringify(ticket), {
      method: 'put',
      action: `/tickets/${ticket?.id}/takecharge`,
      encType: 'application/json',
    })
}

export const handleOnClickAssignToMe = async (
  ticket: ticket | null | undefined,
  userInfo: AzureUserInfo | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
  employees: user[],
) => {
  if (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email) {
    const result = confirm(
      `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
      'Assegnazione Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return false
      fetcher?.submit(
        JSON.stringify({
          ticketId: ticket.ded_Dis,
          userId: employees.find((employee) => employee.email === userInfo?.email)?.id,
        }),
        {
          method: 'post',
          action: `/tickets/${ticket?.id}/assign`,
          encType: 'application/json',
        },
      )
    })
  } else
    fetcher?.submit(
      JSON.stringify({
        ticketId: ticket?.ded_Dis,
        userId: employees.find((employee) => employee.email === userInfo?.email)?.id,
      }),
      {
        method: 'post',
        action: `/tickets/${ticket?.id}/assign`,
        encType: 'application/json',
      },
    )
}

export const handleOnClickRelease = async (
  ticket: ticket | null | undefined,
  userInfo: AzureUserInfo | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
) => {
  if (ticket?.incaricato && ticket?.incaricato.email !== userInfo?.email) {
    const result = confirm(
      `<i>Il ticket <b>${ticket.ded_Dis}</b> è in carico all'utente ${ticket.incaricato.fullname}. Si desidera procedere ugualmente?</i>`,
      'Rilascio Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket.id}/release`,
        encType: 'application/json',
      })
    })
  } else
    fetcher?.submit(JSON.stringify(ticket), {
      method: 'put',
      action: `/tickets/${ticket?.id}/release`,
      encType: 'application/json',
    })
}

export const handleOnClickRemoveAssignee = async (
  ticket: ticket | null | undefined,
  userInfo: AzureUserInfo | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
) => {
  if (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email) {
    const result = confirm(
      `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si desidera procedere ugualmente?</i>`,
      'Rimozione Assegnazione Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket.id}/removeassignee`,
        encType: 'application/json',
      })
    })
  } else
    fetcher?.submit(JSON.stringify(ticket), {
      method: 'put',
      action: `/tickets/${ticket?.id}/removeassignee`,
      encType: 'application/json',
    })
}

export const handleOnClickReopen = async (
  ticket: ticket | null | undefined,
  userInfo: AzureUserInfo | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
) => {
  if (ticket?.assegnato && ticket?.assegnato.email !== userInfo?.email) {
    const result = confirm(
      `<i>Il ticket <b>${ticket.ded_Dis}</b> è assegnato all'utente ${ticket.assegnato.fullname}. Si è sicuri di procedere ugualmente con la riapertura del ticket?</i>`,
      'Riapertura Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket.id}/reopen`,
        encType: 'application/json',
      })
    })
  } else {
    const result = confirm(
      `<i>Si è sicuri di procedere con la riapertura del ticket <b>${ticket?.ded_Dis}</b>?</i>`,
      'Riapertura Ticket',
    )
    result.then((dialogResult) => {
      if (dialogResult === false) return
      fetcher?.submit(JSON.stringify(ticket), {
        method: 'put',
        action: `/tickets/${ticket?.id}/reopen`,
        encType: 'application/json',
      })
    })
  }
}

export const handleOnClickDelete = async (
  ticket: ticket | null | undefined,
  fetcher: FetcherWithComponents<any> | undefined,
) => {
  const result = confirm(`<i>Vuoi davvero eliminare il ticket <b>${ticket?.ded_Dis}</b>?</i>`, 'Eliminazione Ticket')
  result.then((dialogResult) => {
    if (dialogResult === false) return
    fetcher?.submit(JSON.stringify(ticket), {
      method: 'put',
      action: `/tickets/${ticket?.id}/cancel`,
      encType: 'application/json',
    })
  })
}

export const handleOnClickSendClosureNotice = async (
  ticket: ticket | null | undefined,
  client: AxiosInstance,
  gridRef?: React.RefObject<DataGridRef>,
  setLoadPanelVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!ticket?.leg_email_contatto) notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
  else if (ticket?.sede) {
    const result = confirm(
      `<i>Vuoi davvero inviare la notifica di chiusura del ticket <b>${ticket.ded_Dis}</b>?</i>`,
      'Invio Notifica Chiusura Ticket',
    )
    result.then(async (dialogResult) => {
      if (dialogResult === false) return
      if (setLoadPanelVisible) setLoadPanelVisible(true)
      gridRef?.current?.instance().beginCustomLoading('Invia notifica chiusura in corso....')
      await client
        .put('/api/ticket/sendclosurenotice', ticket.ded_Dis, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          notify(`${response.data}`, 'success', 3000)
        })
        .catch((error: AxiosError<any, any>) => {
          console.log('error', error)
          notify(`${error.response?.data.error.exceptionMessage ?? error.response?.data.error.message}`, 'error', 3000)
        })
        .finally(() => {
          if (setLoadPanelVisible) setLoadPanelVisible(false)
          gridRef?.current?.instance().endCustomLoading()
        })
    })
  } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
}

export const handleOnClickSendContactNotice = async (
  ticket: ticket | null | undefined,
  client: AxiosInstance,
  gridRef?: React.RefObject<DataGridRef>,
  setLoadPanelVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!ticket?.leg_email_contatto) notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
  else if (ticket.sede) {
    const result = confirm(
      `<i>Vuoi davvero inviare la notifica di contatto del ticket <b>${ticket.ded_Dis}</b>?</i>`,
      'Invio Notifica Contatto Cliente',
    )
    result.then(async (dialogResult) => {
      if (dialogResult === false) return
      if (setLoadPanelVisible) setLoadPanelVisible(true)
      gridRef?.current?.instance().beginCustomLoading('Invia notifica contatto in corso....')
      await client
        .put('/api/ticket/sendcontactnotice', ticket.ded_Dis, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response: AxiosResponse) => {
          notify(`${response.data}`, 'success', 3000)
        })
        .catch((error: AxiosError<any, any>) => {
          console.log('error', error)
          notify(`${error.response?.data.error.exceptionMessage ?? error.response?.data.error.message}`, 'error', 3000)
        })
        .finally(() => {
          if (setLoadPanelVisible) setLoadPanelVisible(false)
          gridRef?.current?.instance().endCustomLoading()
        })
    })
  } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
}

export const handleOnClickSendSupportExpiredNotice = async (
  ticket: ticket | null | undefined,
  client: AxiosInstance,
  gridRef?: React.RefObject<DataGridRef>,
  setLoadPanelVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!ticket?.leg_email_contatto) notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
  else if (ticket.sede) {
    if (setLoadPanelVisible) setLoadPanelVisible(true)
    gridRef?.current?.instance().beginCustomLoading('Invia notifica assistenza scaduta in corso....')
    await client
      .post('/api/ticket/sendsupportexpirednotice', ticket.ded_Dis, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        notify(`${response.data}`, 'success', 3000)
      })
      .catch((error: AxiosError<any, any>) => {
        console.log('error', error)
        notify(`${error.response?.data.error.exceptionMessage ?? error.response?.data.error.message}`, 'error', 3000)
      })
      .finally(() => {
        if (setLoadPanelVisible) setLoadPanelVisible(false)
        gridRef?.current?.instance().endCustomLoading()
      })
  } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
}

export const handleOnClickSendOtherVendorNotice = async (
  ticket: ticket | null | undefined,
  client: AxiosInstance,
  gridRef?: React.RefObject<DataGridRef>,
  setLoadPanelVisible?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!ticket?.leg_email_contatto) notify("Email riferimento assente: impossibile eseguire l' azione.", 'warning', 3000)
  else if (ticket.sede) {
    if (setLoadPanelVisible) setLoadPanelVisible(true)
    gridRef?.current?.instance().beginCustomLoading('Invia notifica altro fornitore in corso....')
    await client
      .post('/api/ticket/sendothervendornotice', ticket.ded_Dis, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        notify(`${response.data}`, 'success', 3000)
      })
      .catch((error: AxiosError<any, any>) => {
        console.log('error', error)
        notify(`${error.response?.data.error.exceptionMessage ?? error.response?.data.error.message}`, 'error', 3000)
      })
      .finally(() => {
        if (setLoadPanelVisible) setLoadPanelVisible(false)
        gridRef?.current?.instance().endCustomLoading()
      })
  } else notify("Sede assente: impossibile eseguire l' azione.", 'warning', 3000)
}

export const handleOnClickAssignTo = (
  ticket: ticket | null | undefined,
  employees: user[],
  fetcher: FetcherWithComponents<any> | undefined,
  setAssignToData: React.Dispatch<
    React.SetStateAction<
      | {
          formData: {
            technician: user | null | undefined
          }
          onSubmitEditor: (
            e: any,
            params: {
              ticketId: string | null | undefined
              userId: number | null | undefined
            },
          ) => Promise<void>
          tecniciList: (user | null | undefined)[]
          ticket: ticket | null | undefined
        }
      | undefined
    >
  >,
  popupAssignRef: React.RefObject<PopupRef>,
) => {
  const tecnici: (user | null | undefined)[] = []
  tecnici.push(...employees)
  setAssignToData({
    ticket,
    tecniciList: tecnici,
    formData: {
      technician: null,
    },
    onSubmitEditor: async (
      e: any,
      params: {
        ticketId: string | null | undefined
        userId: number | null | undefined
      },
    ) => {
      e.preventDefault()
      try {
        fetcher?.submit(JSON.stringify(params), {
          method: 'post',
          action: `/tickets/${ticket?.id}/assign`,
          encType: 'application/json',
        })
      } catch (error: unknown) {
        notify(`Errore assegnazione ticket '${ticket?.ded_Dis}'. Dettagli : ${error}`, 'error', 2000)
      } finally {
        popupAssignRef.current?.instance().hide()
      }
    },
  })

  popupAssignRef.current?.instance().show()
}
