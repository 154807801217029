import { TicketActions, TicketEditorNoteFormProps } from '@/routes/tickets/TicketEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useRef } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { FileUploader } from 'devextreme-react'
import { onInitializedHtmlEditor, onValueChangedHtmlEditor, toolbarHtmlEditor } from '@/routes/utils'
import { FileUploaderRef } from 'devextreme-react/file-uploader'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { useScreenSize } from '@/themes/media-query'
import DocumentList from '@/components/document/DocumentList'
import { DocumentPermissions } from '@/enums'
import { ValueChangedEvent } from 'devextreme/ui/file_uploader'

const TicketEditorNoteForm = (props: TicketEditorNoteFormProps) => {
  const { note, action, readOnly, onSaveClick } = props
  const ref = useRef<FormRef>(null)
  const fileUploaderRef = useRef<FileUploaderRef>(null)
  const documentListRef = useRef<any>(null)
  const { client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()

  return (
    <>
      <DXForm
        formData={note}
        id={`ticket_action_form_${action}`}
        labelLocation={'top'}
        showValidationSummary={true}
        validationGroup={`ticketActionPopupValidation_${action}`}
        ref={ref}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            ref.current?.instance().option().isDirty !== null &&
            ref.current?.instance().option().isDirty !== undefined
          ) {
            ref.current.instance().getButton('btn-save')?.option('disabled', !ref.current?.instance().option().isDirty)
          }
        }}
      >
        <GroupItem colCount={1}>
          <SimpleItem
            dataField="testo"
            label={{ visible: false }}
            editorType="dxHtmlEditor"
            editorOptions={{
              stylingMode: 'filled',
              valueType: 'html',
              toolbar: toolbarHtmlEditor(currentScreenSize),
              readOnly: readOnly,
              mediaResizing: {
                enabled: true,
              },
              imageUpload: {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
              },
              height: 400,
              onValueChanged: onValueChangedHtmlEditor,
              onInitialized: onInitializedHtmlEditor,
            }}
          >
            <RequiredRule message="Testo obbligatorio"></RequiredRule>
          </SimpleItem>
        </GroupItem>
        <GroupItem>
          <DocumentList
            title="Allegati"
            folderPath={`${note?.ticket?.attachmentsFolderUrl}/_notes/${note?.tag}/attachments`}
            documentListGetRef={documentListRef}
            preliminarPermissionsAction={async () => {
              try {
                const response = await client.post(
                  '/api/ticket/drivepermissions',
                  {
                    objectId: note?.ticket?.id,
                    path: note?.ticket?.attachmentsFolderUrl,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  },
                )
                return response.status === 200 ? (response.data as DocumentPermissions) : DocumentPermissions.Deny
              } catch (error) {
                console.log('Errore in settaggio permessi sulla cartella allegati ticket', error)
                return DocumentPermissions.Deny
              }
            }}
            readOnly={true}
          />
          <FileUploader
            disabled={readOnly}
            ref={fileUploaderRef}
            name={'note_files'}
            selectButtonText={'Seleziona i files'}
            labelText={'o trascina i files qui'}
            multiple={true}
            accept={'*'}
            uploadMode="useForm"
            readyToUploadMessage=""
            allowedFileExtensions={[]}
            onValueChanged={(e: ValueChangedEvent) => {
              ref.current?.instance().getButton('btn-save')?.option('disabled', false)
            }}
          />
        </GroupItem>
        <GroupItem cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={1}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={readOnly || !ref.current?.instance().option().isDirty}
                icon={
                  action === TicketActions.SendTicketNote
                    ? 'email'
                    : action === TicketActions.CreateTicketNote
                      ? 'comment'
                      : 'save'
                }
                text={
                  action === TicketActions.SendTicketNote
                    ? 'Invia'
                    : action === TicketActions.CreateTicketNote
                      ? 'Crea'
                      : 'Salva'
                }
                width="150px"
                onClick={(e: ClickEvent) => {
                  const validationResult = ref.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  onSaveClick(fileUploaderRef.current?.instance().option().value)
                }}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </>
  )
}

export default TicketEditorNoteForm
