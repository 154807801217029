import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { ActivitiesPageRoute } from '@/routes/activities/ActivitiesPage.route'

export const CommercialActivityRoute: TileRoleRoute = {
  path: 'commercialActivity',
  text: 'Attività Commerciale',
  faIcon: faStore,
  element: (
    <ManagementGridFactory
      entity="tipologia_attivita_comm"
      referencesCollection="attivita_commerciali"
      referencesTitle="Attività Commerciali"
      displayField="ded_Dis"
      entityRoute={ActivitiesPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Tipo Attività Commerciale',
      icon: faStore,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
