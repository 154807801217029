import { Configuration } from '@azure/msal-browser/dist/config/Configuration'

import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser'
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

const loggingLevel = LogLevel.Warning

// Define common scope requests
export const loginRequest = {
  scopes: ['https://graph.microsoft.com/User.Read', 'profile', 'openid', 'email'],
}

const azureConfig = {
  auth: {
    clientId: 'b31fd6f9-1d56-46cb-bc82-da5a89c49cf4',
    authority: 'https://login.microsoftonline.com/8e594b94-e61e-4d57-83bc-8b84a5e69d89/',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
    secureCookies: false,
    claimsBasedCachingEnabled: true,
  },
  system: {
    // allowPlatformBroker: false,
    allowNativeBroker: false,
    tokenRenewalOffsetSeconds: 300, // Start renewing tokens 5 minutes before expiry
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error: {
            console.error(message)
            return
          }
          case LogLevel.Info: {
            console.info(message)
            return
          }
          case LogLevel.Verbose: {
            console.debug(message)
            return
          }
          case LogLevel.Warning: {
            console.warn(message)
            return
          }
          default: {
            return
          }
        }
      },
      logLevel: loggingLevel,
    },
  },
} satisfies Configuration

export const sharePointConfig = {
  siteId: '39474d0e-eca1-4bfa-a5d5-3cf94d3b9ed7',
}

export const graphConfig = {
  meEndpoint: 'https://graph.microsoft.com/v1.0/me',
}

export default azureConfig
