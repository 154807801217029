import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../styles/contacts.scss'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  type DataGridTypes,
  Export,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Item,
  Pager,
  Scrolling,
  Search,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { useScreenSize } from '@/themes/media-query'
import type { entita_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import notify from 'devextreme/ui/notify'
import { ContactEditorPanel } from '../ContactEditorPanel'
import { useLoaderData } from 'react-router-typesafe'
import { calculateEmailsFilterExpression, onExporting } from '@/routes/contacts/entity/EntityGrid.utils'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { entityGridRouteLoader } from '@/routes/contacts/entity/EntityGrid.route'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { ClickEvent } from 'devextreme/ui/button'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import { FormFilterItemDescriptorType } from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'
import { confirm } from 'devextreme/ui/dialog'
import { AxiosResponse } from 'axios'

const exportFormats = ['xlsx']

export type EntityGridProps = object
export function EntityGrid(props: EntityGridProps) {
  const { clientId } = useParams()
  const { title, sites, aziende, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof entityGridRouteLoader>()
  const token = useTokenRefresh()

  const currentScreenSize = useScreenSize()
  const [isPanelOpened, setPanelOpened] = useState(false)
  const [currentContact, setCurrentContact] = useState<entita_aziendale>()
  const gridRef = useRef<DataGridRef>(null)
  const { service, client } = useQsAdminApiManager()
  const navigate = useNavigate()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const entityDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/entita_aziendale`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter?.length > 0 ? currentFilter : null,
    expand: ['sede($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore))', 'emails'],
  }

  const changePanelOpened = useCallback(() => {
    console.log('PANEL OPENED')
    setPanelOpened(!isPanelOpened)
    gridRef.current?.instance().option('focusedRowIndex', -1)
  }, [isPanelOpened])

  const changePanelPinned = (value: boolean) => {
    const mainCrmContainer: any = document.querySelector('#crm-entity-main-container')
    const gridCrmContainer: any = document.querySelector('#crm-entity-list')
    const contactPanelContainer: any = document.querySelector('#contact-panel')

    if (mainCrmContainer && gridCrmContainer && contactPanelContainer) {
      if (value) {
        const totalWidth = mainCrmContainer.offsetWidth - contactPanelContainer.offsetWidth
        gridCrmContainer.style.width = `${totalWidth}px`
      } else {
        gridCrmContainer.style.width = mainCrmContainer.style.width
      }
    }
    gridRef.current?.instance().updateDimensions()
  }

  const onRowClick = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
    console.log('ROW CLICKED', data.id)
    setCurrentContact(data)
    setPanelOpened(true)
  }, [])

  const getGridHeight = useCallback(() => {
    let h = '72vh'
    if (currentScreenSize.isMedium) {
      h = '68vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const computeEmailsValue = (rowData: entita_aziendale) => rowData.emails?.[0]?.email?.trim() ?? ''

  const onContactUpdate = async (contact: entita_aziendale): Promise<entita_aziendale | null> => {
    try {
      const response: AxiosResponse<entita_aziendale, entita_aziendale> = await client.put(
        'api/entitaaziendale/update',
        { contact },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      gridRef.current?.instance().refresh()
      console.log('submit success')
      notify(`Contatto "${response.data.nome}"  aggiornato con successo`, 'success', 2000)
      return response.data
    } catch (error) {
      notify(error, 'error', 2000)
      return null
    }
  }

  const onContactReset = async (contactdata: entita_aziendale): Promise<entita_aziendale | null> => {
    console.log('reset', contactdata)
    const res = await service.entita_aziendale(contactdata.id).query((builder, contatto) => {
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expand('azienda')
      })
      builder.expand('emails')
    })
    console.log('QRY RES CONT')
    console.log(res.data)
    return res.data
  }

  const onContactDelete = async (newdata: entita_aziendale) => {
    if (newdata.hasLinkedObjects) {
      notify('Il contatto non può essere eliminato perchè in uso.', 'warning', 2000)
    } else {
      const result = confirm(
        `<i>Vuoi davvero eliminare il contatto <b>${newdata.nome}</b>?</i>`,
        'Eliminazione contatto',
      )
      result.then(async (dialogResult) => {
        if (dialogResult === false) return
        await service
          .entita_aziendale(newdata.id)
          .delete()
          .then(async (result) => {
            gridRef.current?.instance().refresh()
            console.log('delete success')
            setPanelOpened(false)
            notify(`Contatto "${newdata.nome}"  eliminato con successo`, 'success', 2000)
          })
          .catch((error) => {
            notify(error, 'error', 2000)
          })
      })
    }
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <div id="crm-entity-main-container">
        <div id="crm-entity-list-container" className="view crm-contact-list">
          <div id="crm-entity-list" className="view-wrapper view-wrapper-contact-list">
            {!clientId && (
              <div className="accordion-generic-filter-form">
                <Accordion
                  className={filtersApplied ? 'with-filter' : ''}
                  collapsible={true}
                  onContentReady={(e: ContentReadyEvent<any, any>) => {
                    e.component?.collapseItem(0)
                  }}
                >
                  <AccordionItem icon="filter" title={'Filtri'}>
                    <GenericFilterForm
                      FormItemDescriptors={filterConfiguration}
                      resetFilterClicked={function (e: ClickEvent): void {
                        setFilterConfiguration(getFiltersConfiguration())
                      }}
                      filterValueChanged={function (): void {
                        const currentFlts = getCurrentFilter()
                        console.log('GENFLT - VALUE CHNG', currentFlts)
                        if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                          console.log('GENFLT - SETTING FILT', currentFlts)
                          setCurrentFilter(currentFlts)
                        }
                      }}
                    />
                  </AccordionItem>
                </Accordion>
              </div>
            )}
            <DataGrid
              id={clientId ? 'client-entities-grid' : 'entities-grid'}
              className={'grid dx-card wide-card'}
              noDataText="Nessun contatto trovato"
              focusedRowEnabled
              height={getGridHeight}
              dataSource={entityDataSource}
              onRowClick={onRowClick}
              onExporting={onExporting}
              allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
              allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
              showColumnLines={true}
              rowAlternationEnabled={true}
              columnHidingEnabled={currentScreenSize.isXSmall || currentScreenSize.isSmall}
              ref={gridRef}
              groupPanel={{
                visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
                emptyPanelText: 'Trascina qui una colonna per raggruppare',
              }}
              remoteOperations={{
                filtering: true,
                grouping: false,
                groupPaging: false,
                paging: true,
                sorting: true,
                summary: true,
              }}
            >
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={clientId ? 'client-entities-grid' : 'entities-grid'}
                savingTimeout={50}
              />
              <Pager
                visible={true}
                allowedPageSizes={'auto'}
                displayMode={'compact'}
                showPageSizeSelector={false}
                showInfo={true}
                showNavigationButtons={false}
                infoText={gridTotalItemsLabel}
              />
              <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 150 : 250} />
              <ColumnChooser enabled />
              <Export
                texts={{ exportAll: 'Esporta dati in excel', exportSelectedRows: 'Esporta dati selezionati in excel' }}
                enabled
                allowExportSelectedData
                formats={exportFormats}
              />
              <Selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple" />
              <HeaderFilter visible={true} />
              <FilterPanel visible={true} />
              <FilterRow visible={true} />
              <Sorting mode="multiple" />
              <Scrolling mode="virtual" />
              <Toolbar>
                <Item
                  location="before"
                  locateInMenu="never"
                  showText="inMenu"
                  widget="dxButton"
                  options={{
                    hint: 'Refresh',
                    text: 'Refresh',
                    icon: 'refresh',
                    onClick: (e: ClickEvent) => {
                      gridRef.current?.instance().refresh()
                    },
                    stylingMode: 'text',
                  }}
                ></Item>
                <Item name="groupPanel" />
                <Item
                  visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                  locateInMenu={
                    currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                      ? 'always'
                      : 'auto'
                  }
                  showText="inMenu"
                  widget="dxButton"
                  options={{
                    hint: 'Nuovo contatto',
                    text: 'Nuovo contatto',
                    icon: 'plus',
                    onClick: (e: ClickEvent) => {
                      navigate(`new`)
                    },
                    stylingMode: 'text',
                  }}
                ></Item>
                <Item
                  name="columnChooserButton"
                  locateInMenu={
                    currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                      ? 'always'
                      : 'auto'
                  }
                  showText="inMenu"
                  options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
                />
                <Item
                  name="exportButton"
                  locateInMenu={
                    currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                      ? 'always'
                      : 'auto'
                  }
                  showText="inMenu"
                  options={{ hint: 'Esporta dati in excel' }}
                />
                <Item name="searchPanel" />
              </Toolbar>
              <Column dataField={'id'} width={'5%'} caption="ID" visible={false} dataType="number">
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'entita_aziendale', 'id', true)}></HeaderFilter>
                )}
              </Column>
              <Column dataField="nome" caption="NOME" minWidth={100}>
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'entita_aziendale', 'nome')}>
                    <Search enabled={true} searchExpr={'nome'} />
                  </HeaderFilter>
                )}
              </Column>
              <Column
                dataField="sede.azienda.nome"
                cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                  const data: entita_aziendale = cellData.row.data
                  return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                    <>
                      <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                    </>
                  ) : (
                    <>{data.sede?.azienda?.nome}</>
                  )
                }}
                caption="AZIENDA"
                minWidth={150}
                visible={clientId === undefined}
                defaultSortOrder="asc"
              >
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                    <Search enabled={true} searchExpr={'nome'} />
                  </HeaderFilter>
                )}
              </Column>
              <Column dataField="sede.nome" caption="SEDE" minWidth={150} allowHeaderFiltering={false} />
              <Column dataField="telefono" caption="TELEFONO">
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'entita_aziendale', 'telefono')}>
                    <Search enabled={true} searchExpr={'telefono'} />
                  </HeaderFilter>
                )}
              </Column>
              <Column
                dataField="ad_recipient"
                dataType="boolean"
                caption="RIF ADSK"
                allowHeaderFiltering={false}
              ></Column>
              <Column
                name={'emails'}
                caption={'EMAIL'}
                calculateCellValue={computeEmailsValue}
                calculateFilterExpression={calculateEmailsFilterExpression}
                allowFiltering={true}
              >
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'email_aziendale', 'email')}>
                    <Search enabled={true} searchExpr={'email'} />
                  </HeaderFilter>
                )}
              </Column>
              <Column dataField="sede.azienda.agente.commerciale_qs.fullname" caption="AGENTE" visible={false}>
                {!clientId && (
                  <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
                    <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
                  </HeaderFilter>
                )}
              </Column>
              <Column dataField={'sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
                <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
                  <Search enabled={true} searchExpr={'rivenditore.nome'} />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
          <ContactEditorPanel
            onContactUpdate={onContactUpdate}
            onContactReset={onContactReset}
            onContactDelete={onContactDelete}
            contact={currentContact}
            isOpened={isPanelOpened}
            changePanelOpened={changePanelOpened}
            changePanelPinned={changePanelPinned}
            aziende={currentContact?.sede?.azienda ? [currentContact.sede.azienda] : []}
            sedi={sites.data.value}
            rapporti={[]}
            readOnly={!isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
          />
        </div>
      </div>
    </>
  )
}
