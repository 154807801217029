import React, { useCallback, useRef } from 'react'
import {
  Column,
  ColumnChooser,
  ColumnChooserSearch,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid'
import { useScreenSize } from '@/themes/media-query'
import notify from 'devextreme/ui/notify'
import ODataStore from 'devextreme/data/odata/store'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import { ManagementGridExtendedColumn, ManagementGridProps } from '@/routes/admin/components/ManagementGrid/types'
import { useLocation } from 'react-router-dom'

export const ManagementGrid: React.FC<ManagementGridProps> = ({
  endpoint,
  title,
  columns,
  expandRelations = [],
  defaultPageSize = 20,
  storageKey,
  allowAdding = true,
  allowUpdating = true,
  allowDeleting = true,
  onRowInserted,
  onRowUpdated,
  onRowRemoved,
  initialFilter = null,
}) => {
  const path = useLocation()
  const token = useTokenRefresh()
  const dataGridRef = useRef<DataGridRef>(null)
  const currentScreenSize = useScreenSize()

  const dataSource = {
    store: new ODataStore({
      url: endpoint,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      // errorHandler: (e) => {
      //   console.error(e.errorDetails)
      //   console.error('SONOOOOOOOOOOOOO QUIIIIIIIIIIIIIIIIII')
      //   if (!e.errorDetails) return
      //   notify(
      //     {
      //       message: `Errore : ${e.errorDetails?.message}`,
      //       type: 'error',
      //       displayTime: 5000,
      //     },
      //     {
      //       position: 'bottom center',
      //       direction: 'up-push',
      //     },
      //   )
      // },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: expandRelations,
    requireTotalCount: true,
    filter: initialFilter,
  }
  // Success notification
  const handleSuccess = useCallback((operation: string) => {
    notify(
      {
        message: `Operation successful: ${operation}`,
        type: 'success',
        displayTime: 3000,
      },
      {
        position: 'bottom center',
        direction: 'up-push',
      },
    )
  }, [])

  return (
    <div className="management-grid content-block">
      <h2>{`${title}`}</h2>

      <DataGrid
        dataSource={dataSource}
        height="75vh"
        className={currentScreenSize.isSmall ? 'grid-height-small' : 'grid-height-default'}
        noDataText="Nessuna entry trovata"
        showBorders={false}
        remoteOperations={{
          filtering: true,
          sorting: true,
          paging: true,
          grouping: true,
          groupPaging: true,
          summary: true,
        }}
        showColumnLines={true}
        focusedRowEnabled={false}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall}
        allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
        allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
        rowAlternationEnabled={true}
        wordWrapEnabled={true}
        grouping={{
          // Fondamentale per il raggruppamento veloce!!!
          autoExpandAll: true,
        }}
        groupPanel={{
          visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
          emptyPanelText: 'Trascina qui una colonna per raggruppare',
        }}
        onRowInserted={(e) => {
          handleSuccess('Created')
          onRowInserted?.(e)
        }}
        onRowUpdated={(e) => {
          handleSuccess('Updated')
          onRowUpdated?.(e)
        }}
        onRowRemoved={(e) => {
          handleSuccess('Deleted')
          onRowRemoved?.(e)
        }}
        ref={dataGridRef}
      >
        <Editing
          mode="batch"
          allowAdding={allowAdding}
          allowUpdating={allowUpdating}
          allowDeleting={allowDeleting}
          useIcons={true}
          popup={{
            title,
            showTitle: true,
            width: 700,
            height: 525,
          }}
        />

        <StateStoring enabled={!!storageKey} type="localStorage" storageKey={storageKey} savingTimeout={50} />

        <HeaderFilter visible />
        <FilterPanel visible />
        <FilterRow visible />
        <SearchPanel visible width={currentScreenSize.isXSmall ? 200 : 250} />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <Export enabled />
        <ColumnChooser enabled>
          <ColumnChooserSearch enabled />
        </ColumnChooser>

        <Paging defaultPageSize={defaultPageSize} enabled />

        {columns.map((column: ManagementGridExtendedColumn, index) => (
          <Column
            key={column.dataField}
            dataField={column.dataField}
            caption={column.caption}
            dataType={column.dataType}
            width={column.width}
            visible={column.visible}
            allowEditing={column.allowEditing}
            format={column.format}
            cellRender={column.cellRender}
            hidingPriority={column.hidingPriority}
          />
        ))}
      </DataGrid>
    </div>
  )
}
