import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { azienda, pacchetto_vendita, sede, tipologia_durata_pacchetto } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { PackageUsageEditorFormProps } from '@/routes/packages/usage/PackageUsageEditor.types'
import InterventionEditorForm from '@/routes/interventions/InterventionEditor.form'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { PopupRef } from 'devextreme-react/cjs/popup'
import notify from 'devextreme/ui/notify'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Roles } from '@/auth/azure/Roles'

const PackageUsageEditorForm = (props: PackageUsageEditorFormProps) => {
  const {
    pacchetto,
    aziende,
    pacchetti,
    creating,
    statiFatturazioneIntervento,
    sede,
    intervento,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props

  const { clientId } = useParams()
  const { service } = useQsAdminApiManager()

  const revalidator = useRevalidator()

  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(clientId ? aziende[0] : sede.azienda)

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>(sede)

  const [currentTipologiaUnita, setCurrentTipologiaUnita] = useState<tipologia_durata_pacchetto | null | undefined>(
    pacchetto?.pacchetto_vendita?.tipologia_unita,
  )
  const [sediDataSource, setSediDataSource] = useState<DataSource>(ArraySourceComposer('id', sede.azienda?.sedi))

  const [pacchettiDataSource, setPacchettiDataSource] = useState<DataSource>(ArraySourceComposer('id', pacchetti))

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getPV = useCallback(async () => {
    const pacchettiData = await service.pacchetto_vendita().query((builder, pacchetto) => {
      builder.filter(pacchetto.sede.props.id.eq(Number(currentSite?.id)))
      builder.expanding('tipologia_unita', (tipologia_unitaBuilder, tipologia) => {
        tipologia_unitaBuilder.select('id', 'nome')
      })
      builder.select('id', 'ded_Dis', 'tipologia_unita')
      builder.orderBy(pacchetto.id.desc())
    })
    return pacchettiData.data.value
  }, [currentSite?.id, service])

  useEffect(() => {
    if (!currentCompanyChanged) return
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        setCurrentSiteChanged(true)
        setCurrentSite(sites[0])
        setPacchettiDataSource(ArraySourceComposer('id', []))
        formRef.current?.instance().updateData('pacchetto_vendita', null)
        setCurrentTipologiaUnita(null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    getPV().then((pacchetti: pacchetto_vendita[]) => {
      console.log('PACCHETTI', pacchetti)
      setPacchettiDataSource(ArraySourceComposer('id', pacchetti))
      formRef.current?.instance().updateData('pacchetto_vendita', null)
    })
    setCurrentTipologiaUnita(null)
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, currentSite, getPV])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'package_usage_form'}
        formData={pacchetto}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="packageUsageValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={4}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda PV' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              label={{ text: 'Sede PV' }}
              editorType="dxSelectBox"
              editorOptions={{
                value: currentSite,
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (currentSite) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentSiteChanged(true)
                    setCurrentSite(e.value)
                  }
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="intervento"
              label={{ text: 'Intervento' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', [pacchetto.intervento]),
                placeholder: 'Seleziona intervento...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare intervento',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="pacchetto_vendita"
              label={{ text: 'Pacchetto Vendita' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: pacchettiDataSource,
                placeholder: 'Seleziona pacchetto...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare pacchetto',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('PACCHETTI EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentTipologiaUnita(e.value.tipologia_unita)
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Tipologia durata' }}
              editorType="dxLookup"
              editorOptions={{
                value: currentTipologiaUnita,
                dataSource: ArraySourceComposer('id', [currentTipologiaUnita]),
                placeholder: 'Seleziona tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipologia',
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="unita_utilizzate"
              editorType="dxNumberBox"
              label={{ text: 'Durata' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Durata obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="intervento.durata_intervento"
              label={{ text: 'Durata Intervento' }}
              editorType="dxNumberBox"
              editorOptions={{
                readOnly: true,
              }}
            />
            <EmptyItem></EmptyItem>
            <GroupItem colSpan={2}>
              <SimpleItem
                dataField={'note'}
                editorType={'dxTextArea'}
                label={{ visible: true }}
                editorOptions={{
                  height: '350',
                }}
              ></SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colSpan={2}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={false} deferRendering={false} />
              <Tab title="Intervento">
                <InterventionEditorForm
                  creating={false}
                  intervention={intervento}
                  isRoleAllowed={true}
                  clients={intervento.sede?.azienda ? [intervento.sede.azienda] : []}
                  billingStates={statiFatturazioneIntervento}
                  technicians={intervento?.impiegati}
                  references={intervento?.riferimenti_cliente}
                  tasks={intervento?.task ? [intervento.task] : undefined}
                  userInfo={userInfo}
                  fetcher={fetcher}
                />
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 1 : 2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('pacchetto', pacchetto)
                  if (pacchetto) {
                    fetcher?.submit(JSON.stringify(pacchetto), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              setCurrentSite(
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default PackageUsageEditorForm
