import { TileRoleRoute } from '@/types'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '@/auth/azure/Roles'
import { UsersGrid } from '@/routes/admin/users/grid/UsersGrid'

export const UsersGridRoute: TileRoleRoute = {
  index: true,
  text: 'Gestione Utenti',
  element: <UsersGrid />,
  mainMenuRoute: false,
  faIcon: faUsers,
  allowedRoles: [Roles.GlobalAdministrator],
  tile: true,
}
