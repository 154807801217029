import { useEffect, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { Get as MgtGet, MgtTemplateProps, FileList } from '@microsoft/mgt-react'
import { Button, LoadIndicator } from 'devextreme-react'
import { DocumentPermissions } from '@/enums'
import { DocumentListProps } from '@/components/document/DocumentList.types'

const DocumentList = (props: DocumentListProps) => {
  const { title, folderPath, documentListGetRef, readOnly, preliminarPermissionsAction } = props
  const [permissions, setPermissions] = useState<DocumentPermissions>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const computePermissions = async () => {
      let objperm: DocumentPermissions = DocumentPermissions.Deny
      if (preliminarPermissionsAction) {
        objperm = await preliminarPermissionsAction()
      }
      setPermissions(objperm)
      setIsLoading(false)
    }

    computePermissions()
  }, [preliminarPermissionsAction])

  if (isLoading) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileListCustom = (props: MgtTemplateProps) => {
    const data = props.dataContext
    return (
      <FileList
        driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
        itemPath={folderPath === null ? undefined : folderPath}
        itemView="twolines"
        pageSize={10}
        draggable={true}
      ></FileList>
    )
  }

  const MgtFileListLoading = (props: MgtTemplateProps) => {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileListError = (props: MgtTemplateProps) => {
    return <div>Impossibile individuare la cartella da esplorare</div>
  }

  const MgtFileListNoData = (props: MgtTemplateProps) => {
    const [createDocLoadIndicatorVisible, setCreateDocLoadIndicatorVisible] = useState(false)

    if (permissions && permissions !== DocumentPermissions.Deny) {
      return <div>Nessun file presente</div>
    } else {
      return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
    }
  }

  return (
    <>
      <MgtGet
        ref={documentListGetRef}
        resource={`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${folderPath}`}
      >
        <MgtFileListCustom template="default" />
        <MgtFileListLoading template="loading" />
        <MgtFileListNoData template="no-data" />
        <MgtFileListError template="error" />
      </MgtGet>
    </>
  )
}

export default DocumentList
