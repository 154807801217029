import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { memo, useRef } from 'react'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import type { attivita_comm, commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import {
  Column,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Item,
  Paging,
  StateStoring,
  Toolbar,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import type { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { AzureUserInfo, useTokenRefresh } from '@/auth/azure/azureManager'
import notify from 'devextreme/ui/notify'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { isUserRoleAllowed } from '@/routes/utils'
import { Link } from 'react-router-dom'
import { Roles } from '@/auth/azure/Roles'

const ProjectEditorFormActivitiesGrid = (props: {
  project: commessa

  userInfo: AzureUserInfo | undefined
}) => {
  const { project, userInfo } = props
  const currentScreenSize = useScreenSize()
  const token = useTokenRefresh()
  const gridRef = useRef<DataGridRef>(null)

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA CON VENDITA':
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'PROGRAMMATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'IN CORSO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  const activitiesDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_comm`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['commessa.id', project.id]],
    expand: ['autore', 'contatto_aziendale', 'tipologia', 'stato'],
  })

  return (
    <>
      <DataGrid
        id={`project-activities-grid`}
        dataSource={activitiesDataSource}
        ref={gridRef}
        noDataText="Nessun' attività associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/activities/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={'project-activities-datagrid'}
          savingTimeout={50}
        />
        <Column
          type="buttons"
          width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
          alignment="left"
        >
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/activities/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column
          dataField={'id'}
          caption={'ID'}
          visible={false}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
          }
        />
        <Column
          dataField={'ded_Dis'}
          caption={'CODICE'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: attivita_comm = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
              <>
                <Link to={`/activities/${data.id}`}>{data.ded_Dis}</Link>
              </>
            ) : (
              <>{data.ded_Dis}</>
            )
          }}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
          }
        />
        <Column
          dataField="inizio"
          caption="INIZIO"
          dataType="date"
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
          }
        ></Column>
        <Column
          dataField="fine"
          caption="FINE"
          dataType="date"
          format={'dd/MM/yyyy'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
          }
        ></Column>
        <Column
          dataField={'autore.fullname'}
          caption={'AUTORE'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
          }
        />
        <Column
          dataField={'contatto_aziendale.fullname'}
          caption={'RIFERIMENTO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
          }
        />
        <Column
          dataField={'tipologia.nome'}
          caption={'TIPO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
          }
        />
        <Column
          dataField={'stato.nome'}
          caption={'STATO'}
          hidingPriority={
            currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
          }
        />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
        </Toolbar>
      </DataGrid>
    </>
  )
}

const ProjectEditorFormActivitiesGridMemoed = memo(ProjectEditorFormActivitiesGrid, (oldProps, newProps) => {
  return oldProps.project === newProps.project
})

export default ProjectEditorFormActivitiesGridMemoed
