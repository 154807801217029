import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { ad_quote, azienda, commessa, offerta, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { OrderEditorFormProps } from '@/routes/orders/OrderEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValidationCallbackData } from 'devextreme/common'
import { FileUploader } from 'devextreme-react'
import { FileUploaderRef } from 'devextreme-react/file-uploader'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import DataSource from 'devextreme/data/data_source'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { TaskPlanner } from '@/routes/tasks/TaskEditor.planner'
import { Roles } from '@/auth/azure/Roles'
import { customButton, customDisplayExpr, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { StatoOrdine } from '@/routes/orders/order.types'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { confirm } from 'devextreme/ui/dialog'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'
import { useScreenSize } from '@/themes/media-query'

const OrderEditorForm = (props: OrderEditorFormProps) => {
  const { creating, order, stati, isReseller, aziende, offerte, commesse, isRoleAllowed, userInfo, fetcher } = props

  const { clientId } = useParams()
  const [searchParams] = useSearchParams()
  const isConsuntivo: boolean = searchParams.get('isConsuntivo') === 'true'

  const { service } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()

  const revalidator = useRevalidator()

  const formRef = useRef<FormRef>(null)
  const fileUploaderRef = useRef<FileUploaderRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const popupRefProject = useRef<PopupRef>(null)

  const [currentOfferta] = useState<offerta | null | undefined>(order.offerta)
  const [currentQuota] = useState<ad_quote | null | undefined>(order.quota)

  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? aziende[0] : order.commessa?.sede?.azienda,
  )

  const [currentRivenditore, setCurrentRivenditore] = useState<string | null | undefined>(
    order?.commessa?.sede?.azienda?.rivenditore?.nome,
  )
  const [currentAgente, setCurrentAgente] = useState<string | null | undefined>(
    order?.commessa?.sede?.azienda?.agente?.commerciale_qs?.fullname,
  )

  const [currentSite, setCurrentSite] = useState<sede | null | undefined>(order.commessa?.sede)
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sedi, setSedi] = useState<(sede | null | undefined)[]>(order.commessa ? [order.commessa.sede] : [])

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))
  const [offerteDataSource, setOfferteDataSource] = useState<DataSource>(ArraySourceComposer('id', offerte))

  const getCompanyData = useCallback(async () => {
    const companyData = await service.azienda(Number(currentCompany?.id)).query((builder, azienda) => {
      builder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
        rivenditoreBuilder.select('id', 'nome')
      })
      builder.expanding('agente', (agenteBuilder, agente) => {
        agenteBuilder.expanding('commerciale_qs', (commercialeBuilder, commerciale) => {
          commercialeBuilder.select('id', 'fullname')
        })
        agenteBuilder.select('id', 'commerciale_qs')
      })
      builder.select('rivenditore', 'agente')
    })
    return companyData.data
  }, [currentCompany?.id, service])

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
          rivenditoreBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
          agenteBuilder.expanding('commerciale_qs', (commercialeBuilder, commerciale) => {
            commercialeBuilder.select('id', 'fullname')
          })
          agenteBuilder.select('id', 'commerciale_qs')
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore', 'agente')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteProjects = useCallback(async () => {
    const projectsData = await service.commessa().query((builder, commessa) => {
      builder
        .filter(commessa.sede.props.id.eq(Number(currentSite?.id)))
        .filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
      builder.orderBy(commessa.id.desc())
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.select('id', 'ded_Dis', 'titolo', 'sede')
    })
    return projectsData.data.value
  }, [currentSite?.id, service])

  const getSiteOffers = useCallback(async () => {
    const offersData = await service.offerta().query((builder, offerta) => {
      builder.filter(offerta.sede.props.id.eq(Number(currentSite?.id)))
      builder.filter(offerta.stato.props.id.eq(StatoOfferta.APERTA))
      builder.orderBy(offerta.id.desc())
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.select('id', 'ded_Dis', 'sede')
    })
    return offersData.data.value
  }, [currentSite?.id, service])

  const getProjectOffer = useCallback(
    async (offerId: number) => {
      const offersData = await service.offerta(offerId).query((builder, offerta) => {
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.expanding('sede', (sedeBuilder) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda')
          })
          commessaBuilder.select('id', 'ded_Dis', 'titolo', 'sede')
        })
        builder.select('commessa')
      })
      return offersData.data
    },
    [service],
  )

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSedi(sites)
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSite(sites[0])
      })
      getCompanyData().then((company: azienda) => {
        setCurrentRivenditore(company.rivenditore?.nome ?? null)
        setCurrentAgente(company.agente?.commerciale_qs?.fullname ?? null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, getCompanySites, currentCompany, getCompanyData])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', currentSite)
    if (currentSite) {
      if (isConsuntivo)
        getSiteProjects().then((commesse: commessa[]) => {
          console.log('commesse', commesse)
          setCommesseDataSource(ArraySourceComposer('id', commesse))
          formRef.current?.instance().updateData('commessa', null)
        })
      if (isReseller)
        getSiteOffers().then((offerte: offerta[]) => {
          console.log('offerte', offerte)
          setOfferteDataSource(ArraySourceComposer('id', offerte))
          formRef.current?.instance().updateData('offerta', null)
        })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, getSiteProjects, currentSite, isConsuntivo, isReseller, getSiteOffers])

  useEffect(() => {
    setCommesseDataSource(ArraySourceComposer('id', commesse))
  }, [commesse])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'order_form'}
        formData={order}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="orderValidation"
        ref={formRef}
        readOnly={
          !isRoleAllowed ||
          (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id !== StatoOrdine.PROVVISORIO)
        }
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer(
                  'id',
                  isReseller || isConsuntivo
                    ? aziende
                    : order && 'commessa' in order && order.commessa
                      ? [order.commessa.sede?.azienda]
                      : [],
                ),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  console.log(e)
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
                readOnly:
                  (!isReseller && !isConsuntivo) || (clientId !== null && clientId !== undefined) ? true : false,
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                value: currentSite,
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (currentSite) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: ArraySourceComposer('id', sedi),
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly:
                  (!isReseller && !isConsuntivo) || (clientId !== null && clientId !== undefined) ? true : false,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  setCurrentSite(e.value)
                  setCurrentSiteChanged(true)
                  formRef.current?.instance().updateData('sede', e.value)
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              editorType="dxTextBox"
              label={{ text: 'Agente' }}
              editorOptions={{ value: currentAgente, readOnly: true }}
            ></SimpleItem>
            <SimpleItem
              editorType="dxTextBox"
              label={{ text: 'Rivenditore' }}
              editorOptions={{ value: currentRivenditore, readOnly: true }}
            ></SimpleItem>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="evasione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Evasione' }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly:
                  creating ||
                  order.stato?.id === StatoOrdine.ANNULLATO ||
                  order.stato?.id === StatoOrdine.EVASO ||
                  !isRoleAllowed ||
                  userInfo?.roles?.hasRole(Roles.ExternalSales),
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log(e)
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('STATO EVENT CHANGED', e.previousValue, e.value)
                  if (e.value.id === StatoOrdine.EVASO)
                    formRef.current?.instance().updateData('evasione', new Date().toISOString())
                  else formRef.current?.instance().updateData('evasione', null)
                },
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="importo"
              label={{ text: 'Importo' }}
              editorType="dxNumberBox"
              editorOptions={{
                readOnly:
                  order.stato?.id === StatoOrdine.ANNULLATO ||
                  order.stato?.id === StatoOrdine.EVASO ||
                  !isRoleAllowed ||
                  (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id !== StatoOrdine.PROVVISORIO),
              }}
            >
              <RequiredRule message="Importo obbligatorio"></RequiredRule>
              <CustomRule
                reevaluate={true}
                message="Importo deve essere maggiore di 0"
                validationCallback={({ value }: ValidationCallbackData) => {
                  if (order.consuntivo) return value >= 0
                  return value > 0
                }}
              ></CustomRule>
            </SimpleItem>
            {!isConsuntivo && (
              <SimpleItem
                dataField="offerta"
                label={{ text: 'Offerta' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'open',
                      (e: ClickEvent) => {
                        if (order.offerta) window.open(`/offers/${order?.offerta?.id}`, '_blank')
                        else notify(`Deve essere selezionata un' offerta per poterla aprire.`, 'warning', 3000)
                      },
                      !isRoleAllowed,
                    ),
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: offerteDataSource,
                  placeholder: 'Seleziona offerta...',
                  // displayExpr: 'ded_Dis',
                  displayExpr: customDisplayExpr,
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare offerta',
                    hideOnOutsideClick: true,
                  },
                  readOnly: (!isReseller && order.stato?.id !== StatoOrdine.PROVVISORIO) || !isRoleAllowed,
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log(e)
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('OFFERTA EVENT CHANGED', e.previousValue, e.value)
                    getProjectOffer(e.value.id).then((offerta: offerta) => {
                      console.log('commessa', offerta.commessa)
                      setCurrentCompany(offerta.commessa?.sede?.azienda)
                      setCommesseDataSource(ArraySourceComposer('id', [offerta.commessa]))
                      formRef.current?.instance().updateData('commessa', offerta.commessa)
                    })
                  },
                }}
              >
                <CustomRule
                  message="Obbligatoria solo l'offerta o la quota"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return currentOfferta && currentQuota ? false : true
                  }}
                ></CustomRule>
              </SimpleItem>
            )}
            {!isReseller && !isConsuntivo && (
              <SimpleItem
                dataField="quota"
                label={{ text: 'Quota' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'open',
                      (e: ClickEvent) => {
                        window.open(`/quotes/${order?.quota?.id}`, '_blank')
                      },
                      order.quota ? false : true,
                    ),
                  ],
                  dataSource: ArraySourceComposer('id', order && 'quota' in order && order.quota ? [order.quota] : []),
                  placeholder: 'Seleziona quota...',
                  displayExpr: 'number',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare quota',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              >
                <CustomRule
                  message="Obbligatoria solo l'offerta o la quota"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return currentOfferta && currentQuota ? false : true
                  }}
                ></CustomRule>
              </SimpleItem>
            )}
            <SimpleItem
              dataField="commessa"
              label={{ text: 'Commessa' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    () => {
                      if (currentCompany) {
                        popupRefProject.current?.instance().show()
                      } else
                        notify(`Deve essere selezionata un' azienda per poter creare una commessa.`, 'warning', 3000)
                    },
                    !isConsuntivo,
                  ),
                  customButton(
                    'open',
                    () => {
                      if (order.commessa) window.open(`/projects/${order?.commessa?.id}`, '_blank')
                      else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                    },
                    false,
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: commesseDataSource,
                placeholder: 'Seleziona commessa...',
                displayExpr: (item: commessa) => {
                  return item
                    ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}${item.sede?.azienda?.nome ? ' - ' : ''}${item.sede?.azienda?.nome ?? ''}${item.sede?.nome ? ' - ' : ''}${item.sede?.nome ?? ''}`
                    : ``
                },
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare commessa',
                  hideOnOutsideClick: true,
                  width: currentScreenSize.isLarge ? '150%' : '',
                  resizeEnabled: currentScreenSize.isLarge ? true : false,
                },
                readOnly: !isConsuntivo,
              }}
            >
              {!isReseller && <RequiredRule message="Commessa obbligatoria"></RequiredRule>}
            </SimpleItem>
            <SimpleItem
              dataField="attivitaTecnicaRichiesta"
              label={{ text: 'Attività Tecnica Richiesta' }}
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: [true, false],
                displayExpr: (item: any) => {
                  console.log('displayExpr', item)
                  if (item === true) return 'SI'
                  else if (item === false) return 'NO'
                  else return null
                },
                placeholder: 'Seleziona flag...',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare falg',
                  hideOnOutsideClick: true,
                },
                readOnly:
                  (!isReseller && order.stato?.id !== StatoOrdine.PROVVISORIO && !isConsuntivo) || !isRoleAllowed,
              }}
            >
              <CustomRule
                type="custom"
                message="Attivita tecnica richiesta obbligatoria"
                validationCallback={(options: {
                  column: Record<string, any>
                  data: Record<string, any>
                  formItem: Record<string, any>
                  rule: Record<string, any>
                  validator: Record<string, any>
                  value: string | number
                }) => {
                  const value = options.value
                  try {
                    return value.toString() === 'true' || value.toString() === 'false' ? true : false
                  } catch {
                    //se entro nel catch è perche non si riesce ad applicare il toString() su un valore null
                    //quindi il campo non è sicuramente valorizzato e ritorno false sulla validazione
                    return false
                  }
                }}
              ></CustomRule>
            </SimpleItem>
            {!isReseller && (
              <SimpleItem
                dataField="consuntivo"
                label={{ text: 'Consuntivo' }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: [true, false],
                  displayExpr: (item: any) => {
                    console.log('displayExpr', item)
                    if (item === true) return 'SI'
                    else if (item === false) return 'NO'
                    else return null
                  },
                  placeholder: 'Seleziona flag...',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare falg',
                    hideOnOutsideClick: true,
                  },
                  readOnly: isConsuntivo || !creating,
                }}
              >
                <CustomRule
                  type="custom"
                  message="Consuntivo obbligatorio"
                  validationCallback={(options: {
                    column: Record<string, any>
                    data: Record<string, any>
                    formItem: Record<string, any>
                    rule: Record<string, any>
                    validator: Record<string, any>
                    value: string | number
                  }) => {
                    const value = options.value
                    try {
                      return value.toString() === 'true' || value.toString() === 'false' ? true : false
                    } catch {
                      //se entro nel catch è perche non si riesce ad applicare il toString() su un valore null
                      //quindi il campo non è sicuramente valorizzato e ritorno false sulla validazione
                      return false
                    }
                  }}
                ></CustomRule>
              </SimpleItem>
            )}
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={false} deferRendering={false} />
              <Tab title="note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    height: '370',
                  }}
                >
                  <RequiredRule message="Note obbligatorie"></RequiredRule>
                </SimpleItem>
              </Tab>
              {((!creating && !userInfo?.roles?.hasRole(Roles.ExternalSales)) ||
                (!creating &&
                  userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                  order.stato?.id === StatoOrdine.EVASO)) && (
                <Tab title="note evasione">
                  <SimpleItem
                    dataField={'note_evasione'}
                    editorType={'dxTextArea'}
                    label={{ visible: false }}
                    editorOptions={{
                      height: '370',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {((!creating && !userInfo?.roles?.hasRole(Roles.ExternalSales)) ||
                (!creating &&
                  userInfo?.roles?.hasRole(Roles.ExternalSales) &&
                  order.stato?.id === StatoOrdine.EVASO)) && (
                <Tab title="Pianificazione">
                  <SimpleItem>
                    <TaskPlanner order={order} areActionEnabled={false} />
                  </SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem>
          {creating && (
            <SimpleItem>
              <FileUploader
                name={'orderFileUploader'}
                ref={fileUploaderRef}
                selectButtonText={'Seleziona un pdf'}
                labelText={'o trascina un file qui'}
                multiple={false}
                accept={'application/pdf'}
                uploadMode={'useForm'}
                allowedFileExtensions={['.pdf']}
              />
            </SimpleItem>
          )}
        </GroupItem>
        <GroupItem
          visible={
            isUserRoleAllowed(userInfo?.roles, [
              Roles.GlobalAdministrator,
              Roles.Administrator,
              Roles.Supervisor,
              Roles.Sales,
            ]) ||
            (userInfo?.roles?.hasRole(Roles.ExternalSales) && order.stato?.id === StatoOrdine.PROVVISORIO)
          }
          cssClass="last-group"
        >
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            {!creating && (
              <ButtonItem name="btn-restore">
                <ButtonOptions
                  disabled={
                    !isUserRoleAllowed(userInfo?.roles, [
                      Roles.GlobalAdministrator,
                      Roles.Administrator,
                      Roles.Supervisor,
                    ]) ||
                    (order.stato?.id !== StatoOrdine.EVASO && order.stato?.id !== StatoOrdine.ANNULLATO)
                  }
                  icon="revert"
                  text="Da Evadere"
                  width="150px"
                  onClick={async (e: ClickEvent) => {
                    if (order.stato?.id === StatoOrdine.EVASO || order.stato?.id === StatoOrdine.ANNULLATO) {
                      const result = confirm(
                        `<i>Vuoi davvero ripristinare l' ordine <b>${order.ded_Dis}</b>?</i>`,
                        'Ripristino ordine',
                      )
                      result.then((dialogResult) => {
                        if (dialogResult === false) return
                        fetcher?.submit(JSON.stringify(order), {
                          method: 'put',
                          action: `/orders/${order.id}/restore`,
                          encType: 'application/json',
                        })
                      })
                    } else
                      notify(
                        `L'ordine deve essere nello stato 'EVASO' o 'ANNULLATO'. Stato: '${order.stato?.nome}'`,
                        'warning',
                        3000,
                      )
                  }}
                />
              </ButtonItem>
            )}
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('order', order)
                  if (order) {
                    console.log('order', order)
                    if (creating) {
                      const formData = new FormData()
                      const jsonBlob = new Blob([JSON.stringify(order)], { type: 'application/json' })
                      formData.append('order', JSON.stringify(order))
                      const files = fileUploaderRef.current?.instance().option().value
                      if (files && files.length > 0) {
                        formData.append('file', files[0])
                      }
                      formData.append('isReseller', String(isReseller))
                      fetcher?.submit(formData, {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'multipart/form-data',
                      })
                    } else {
                      fetcher?.submit(JSON.stringify(order), {
                        method: 'PUT',
                        encType: 'application/json',
                      })
                    }
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSedi(sites)
              setCurrentSite(
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
      <FormPopupProject
        popupRef={popupRefProject}
        sede={currentSite}
        azienda={currentCompany}
        refreshCommessa={async () => {
          if (currentSite)
            getSiteProjects().then((res: commessa[]) => {
              setCommesseDataSource(ArraySourceComposer('id', res))
              formRef.current?.instance().updateData('commessa', res[0])
              popupRefProject.current?.instance().hide()
            })
        }}
      ></FormPopupProject>
    </>
  )
}

export default OrderEditorForm
