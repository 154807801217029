import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { Outlet } from 'react-router-dom'
import { CommercialActivityRoute } from '@/routes/admin/types/pages/CommercialActivity.route'
import { CompanyRelationRoute } from '@/routes/admin/types/pages/CompanyRelation.route'
import { MeetingRoute } from '@/routes/admin/types/pages/Meeting.route'
import { PackageDurationRoute } from '@/routes/admin/types/pages/PackageDuration.route'
import { PaymentRoute } from '@/routes/admin/types/pages/Payment.route'
import { ProblemRoute } from '@/routes/admin/types/pages/Problem.route'
import { QSLicenseRoute } from '@/routes/admin/types/pages/QSLicense.route'
import { SupportRoute } from '@/routes/admin/types/pages/Support.route'
import { TicketRoute } from '@/routes/admin/types/pages/Ticket.route'
import { TicketEventRoute } from '@/routes/admin/types/pages/TicketEvent.route'

export const TypesRoute: TileRoleRoute = {
  path: 'types',
  text: 'Tipologie',
  element: (
    <>
      <Outlet />
    </>
  ),
  mainMenuRoute: false,
  allowedRoles: [Roles.GlobalAdministrator],
  tile: true,
  children: [
    CommercialActivityRoute,
    CompanyRelationRoute,
    MeetingRoute,
    PackageDurationRoute,
    PaymentRoute,
    ProblemRoute,
    QSLicenseRoute,
    SupportRoute,
    TicketRoute,
    TicketEventRoute,
  ],
}
