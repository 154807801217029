import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAzureManager } from '@/auth/azure/azureManager'
import './LogoutComponent.scss'
import { ProgressBar } from 'devextreme-react/progress-bar'
import { Button } from 'devextreme-react/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export const LogoutComponent: React.FC = () => {
  const [redirecting, setRedirecting] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const redirectTime = 3000 // 3 seconds
  const updateInterval = 30 // 30ms

  useEffect(() => {
    // Perform the actual logout
    const performLogout = async () => {
      const pca = useAzureManager.getState().pca
      if (pca) {
        try {
          await pca.logout()
        } catch (error) {
          console.error('Logout failed:', error)
        }
      }
    }

    performLogout()

    // Set up progress bar animation
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + (100 * updateInterval) / redirectTime
        return newProgress >= 100 ? 100 : newProgress
      })
    }, updateInterval)

    // Set a timeout before redirecting to show the success message
    const timer = setTimeout(() => {
      setRedirecting(true)
    }, redirectTime)

    return () => {
      clearTimeout(timer)
      clearInterval(progressInterval)
    }
  }, [])

  const handleManualRedirect = () => {
    setRedirecting(true)
  }

  if (redirecting) {
    return <Navigate to="/" />
  }

  return (
    <div className="logout-container">
      <div className="logout-card">
        <div className="logout-card-header">
          <h3>Logout Successful</h3>
        </div>
        <div className="logout-card-body">
          <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
          <p>You have been successfully logged out of QS Web Admin.</p>
          <p>Thank you for using our application.</p>

          <div className="redirect-message">
            Redirecting to home page in {Math.ceil((redirectTime - (progress * redirectTime) / 100) / 1000)} seconds...
          </div>

          <div className="progress-container">
            <ProgressBar min={0} max={100} value={progress} width="100%" showStatus={false} />
          </div>

          <Button
            text="Go to Home Page Now"
            type="default"
            stylingMode="contained"
            onClick={handleManualRedirect}
            className="redirect-button"
            style={{ marginTop: '20px' }}
          />
        </div>
      </div>
    </div>
  )
}
