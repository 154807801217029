import React from 'react'
import { Button } from 'devextreme-react/button'
import { ScrollView } from 'devextreme-react/scroll-view'
import { Toolbar, Item } from 'devextreme-react/toolbar'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import './LandingPage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faKey, faTicket, faUsers } from '@fortawesome/free-solid-svg-icons'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

export const LandingPage: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  return (
    <>
      <AuthenticatedTemplate>
        <Navigate to={redirectTo ?? '/dashboard'} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="landing-page">
          <Toolbar className="landing-header">
            <Item location="before">
              <div className="logo-container">
                <img src="https://tinyurl.com/25sgqoyx" alt="QS Informatica Logo" className="company-logo" />
                <span className="company-name">QS Web Admin</span>
              </div>
            </Item>
          </Toolbar>

          <ScrollView className="content-container">
            <div className="hero-section">
              <h1>Benvenuto nel portale di QS Web Admin</h1>
              <p>Il software di gestione interna per QS Informatica</p>
              <Button
                text="Accedi"
                type="default"
                stylingMode="contained"
                onClick={() => navigate('/login')}
                width={200}
                height={50}
                className="cta-button"
              />
            </div>

            <div className="benefits-section">
              <h2>Alcune funzionalità del software</h2>
              <div className="benefits-grid">
                <div className="benefit-card">
                  <FontAwesomeIcon icon={faTicket} className="benefit-icon" />
                  <h3>Gestione dei ticket</h3>
                  <p>Gestione dei ticket con priorità, stato, categoria e altro</p>
                </div>
                <div className="benefit-card">
                  <FontAwesomeIcon icon={faFileContract} className="benefit-icon" />
                  <h3>Gestione del flusso di commessa</h3>
                  <p>Gestione del flusso di commessa nell sue varie fasi e della documentazione</p>
                </div>
                <div className="benefit-card">
                  <FontAwesomeIcon icon={faUsers} className="benefit-icon" />
                  <h3>Gestione dei clienti e delle aziende</h3>
                  <p>Gestione dei clienti e delle aziende con possibilità di creare nuove entità</p>
                </div>
                <div className="benefit-card">
                  <FontAwesomeIcon icon={faKey} className="benefit-icon" />
                  <h3>Accesso sicuro</h3>
                  <p>Accesso sicuro grazie all&apos;integrazione con Azure AD</p>
                </div>
              </div>
            </div>
          </ScrollView>
        </div>
      </UnauthenticatedTemplate>
    </>
  )
}
