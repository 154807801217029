import { ButtonItem, Form, FormRef, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useRef, useState } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { DateBoxTypes } from 'devextreme-react/cjs/date-box'

export const BalanceDateSelector = (props: any) => {
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)
  const formRef = useRef<FormRef>(null)
  return (
    <>
      <div id="balance-date-selector-form-container" className={'dx-card responsive-paddings'}>
        <LoadingPanel
          position={{ of: '#balance-date-selector-form-container' }}
          visible={loadIndicatorVisible}
        ></LoadingPanel>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setLoadIndicatorVisible(true)
            props.onSubmitEditor(e, props.data)
            props.onSubmitEditor(e, props.data).finally(() => {
              setLoadIndicatorVisible(false)
            })
          }}
        >
          <Form
            id={`balance-date-selector-form}`}
            key={`balance-date-selector-form}`}
            formData={props.data}
            validationGroup="balanceDataValidation"
            ref={formRef}
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="refDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  applyValueMode: 'instantly',
                  onOpened: (e: DateBoxTypes.OpenedEvent) => {
                    formRef.current?.instance().getButton('btn-compute')?.option('disabled', true)
                  },
                  onClosed: (e: DateBoxTypes.ClosedEvent) => {
                    formRef.current?.instance().getButton('btn-compute')?.option('disabled', false)
                  },
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Riferimento' }}
              >
                <RequiredRule message="La definizione della versione è obbligatoria" />
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-compute"
                  buttonOptions={{
                    icon: 'formula',
                    text: 'Calcola',
                    useSubmitBehavior: true,
                    width: '150px',
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </>
  )
}

export default BalanceDateSelector
