import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { ClientsPageRoute } from '@/routes/clients/ClientsPage.route'

export const PaymentRoute: TileRoleRoute = {
  path: 'payment',
  text: 'Pagamento',
  faIcon: faCreditCard,
  element: (
    <ManagementGridFactory
      entity="tipologia_pagamento"
      referencesCollection="aziende"
      referencesTitle="Pagamenti"
      displayField="ded_Dis"
      entityRoute={ClientsPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Pagamento',
      icon: faCreditCard,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
