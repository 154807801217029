import { RoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { StatesRoute } from '@/routes/admin/states/states.route'
import { TypesRoute } from '@/routes/admin/types/types.route'
import { Outlet } from 'react-router-dom'
import { AdministrationDashboardRoute } from '@/routes/admin/AdministrationDashboard.route'
import { faGears } from '@fortawesome/free-solid-svg-icons'
import { RolesRoute } from '@/routes/admin/roles/roles.route'
import { UsersRoute } from '@/routes/admin/users/users.route'

export const AdministrationRoute: RoleRoute = {
  path: 'admin',
  element: <Outlet />,
  text: 'Amministrazione',
  faIcon: faGears,
  handle: {
    crumb: () => ({
      label: 'Amministrazione',
      icon: faGears,
    }),
  },
  mainMenuRoute: true,
  allowedRoles: [Roles.GlobalAdministrator],
  children: [AdministrationDashboardRoute, StatesRoute, TypesRoute, RolesRoute, UsersRoute],
}
