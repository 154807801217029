import { TileRoleRoute } from '@/types'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '@/auth/azure/Roles'
import { UserRoute } from '@/routes/admin/users/user/User.route'
import { UsersGridRoute } from '@/routes/admin/users/grid/UsersGrid.route'

export const UsersRoute: TileRoleRoute = {
  path: 'users',
  text: 'Gestione Utenti',
  mainMenuRoute: false,
  faIcon: faUser,
  handle: {
    crumb: () => ({
      label: 'Gestione Utenti',
      icon: faUser,
    }),
  },
  allowedRoles: [Roles.GlobalAdministrator],
  children: [UsersGridRoute, UserRoute],
  tile: true,
}
