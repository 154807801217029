import { RoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { AdministrationDashboard } from '@/routes/admin/AdministrationDashboard'

export const AdministrationDashboardRoute: RoleRoute = {
  element: <AdministrationDashboard />,
  text: 'Dashboard',
  icon: 'preferences',
  index: true,
  mainMenuRoute: false,
  allowedRoles: [Roles.GlobalAdministrator],
}
