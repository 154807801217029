import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { AnalysisRoute } from '@/routes/admin/states/pages/Analysis.route'
import { BillingPointRoute } from '@/routes/admin/states/pages/BillingPoint.route'
import { CommercialActivityRoute } from '@/routes/admin/states/pages/CommercialActivity.route'
import { MaintenancePlanRoute } from '@/routes/admin/states/pages/MaintenancePlan.route'
import { OfferRoute } from '@/routes/admin/states/pages/Offer.route'
import { OrderRoute } from '@/routes/admin/states/pages/Order.route'
import { QSLicenseRoute } from '@/routes/admin/states/pages/QSLicense.route'
import { ExternalLicenseRoute } from '@/routes/admin/states/pages/ExternalLicense.route'
import { SharingRoute } from '@/routes/admin/states/pages/Sharing.route'
import { TaskRoute } from '@/routes/admin/states/pages/Task.route'
import { TicketRoute } from '@/routes/admin/states/pages/Ticket.route'
import { ProjectsRoute } from '@/routes/admin/states/pages/Projects.route'
import { Outlet } from 'react-router-dom'

export const StatesRoute: TileRoleRoute = {
  path: 'states',
  text: 'Stati',
  element: (
    <>
      <Outlet />
    </>
  ),
  mainMenuRoute: false,
  allowedRoles: [Roles.GlobalAdministrator],
  tile: true,
  children: [
    AnalysisRoute,
    BillingPointRoute,
    CommercialActivityRoute,
    MaintenancePlanRoute,
    OfferRoute,
    OrderRoute,
    QSLicenseRoute,
    ExternalLicenseRoute,
    SharingRoute,
    TaskRoute,
    TicketRoute,
    ProjectsRoute,
  ],
}
