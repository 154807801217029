import { useRef } from 'react'
import {
  ButtonItem,
  ButtonOptions,
  Form as DxForm,
  FormRef,
  GroupItem,
  PatternRule,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { assistenza } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { List } from 'devextreme-react/list'
import { ValueChangedEvent } from 'devextreme/ui/switch'
import { useParams, useRevalidator } from 'react-router-dom'
import { ClickEvent } from 'devextreme/ui/button'
import './SupportEditor.scss'
import { ArraySourceComposer } from '@/auth/api/connector'
import { SupportEditorFormProps } from '@/routes/support/SupportEditor.form.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'

const SupportEditorForm = (props: SupportEditorFormProps) => {
  const { support, supportTypes, secondarySupports, allSupports, isRoleAllowed, fetcher } = props
  const { clientId } = useParams()

  const formRef = useRef<FormRef>(null)

  const revalidate = useRevalidator()

  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    console.log('FORMDATACHANGED EVENT')
    if (
      formRef.current?.instance().option().isDirty !== null &&
      formRef.current?.instance().option().isDirty !== undefined
    ) {
      console.log('is dirty', formRef.current?.instance().option().isDirty)
      formRef.current
        .instance()
        .getButton('btn-save')
        ?.option('disabled', !formRef.current?.instance().option().isDirty)
      formRef.current
        .instance()
        .getButton('btn-cancel')
        ?.option('disabled', !formRef.current?.instance().option().isDirty)
    }
  }

  const handleOnClickSave = (e: ClickEvent) => {
    const validationResult = formRef.current?.instance().validate()
    if (!validationResult?.isValid) return
    console.log('validation ok')
    if (support) {
      fetcher?.submit(JSON.stringify(support), {
        method: 'POST',
        encType: 'application/json',
      })
    } else {
      console.error('no data tu submit')
    }
  }
  return (
    <>
      <div className={clientId ? '' : 'content-block'}>
        <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidate.state === 'idle')} />
        <DxForm
          id={`swqs_editor_form_${support?.id || 'undefined'}`}
          key={`swqs_editor_form_${support?.id || 'undefined'}`}
          formData={support}
          ref={formRef}
          onFieldDataChanged={handleFieldDataChanged}
          validationGroup="qsSupportDataValidation"
          showValidationSummary={true}
          className="support-spaced-form"
          readOnly={!isRoleAllowed}
        >
          <GroupItem colCount={6}>
            <GroupItem colCount={4} colSpan={4}>
              <GroupItem colCount={2} colSpan={4} caption="Anagrafica">
                <SimpleItem
                  dataField="telefonica"
                  label={{ text: 'Telefonica' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer('id', supportTypes),
                    placeholder: `Seleziona lo stato dell'assistenza...`,
                    displayExpr: 'nome',
                    searchEnabled: false,
                    showClearButton: false,
                    dropDownCentered: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: `Seleziona lo stato dell'assistenza...`,
                      hideOnOutsideClick: true,
                    },
                  }}
                />
                <SimpleItem
                  dataField="maintenance"
                  label={{ text: 'Maintenance' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: ArraySourceComposer('id', supportTypes),
                    placeholder: `Seleziona lo stato dell'assistenza...`,
                    displayExpr: 'nome',
                    searchEnabled: false,
                    showClearButton: false,
                    dropDownCentered: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: `Seleziona lo stato dell'assistenza...`,
                      hideOnOutsideClick: true,
                    },
                  }}
                />
                <SimpleItem
                  dataField="disdetta_telefonica"
                  label={{ text: 'Disdetta Telefonica' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    type: 'date',
                    pickerType: 'calendar',
                    applyValueMode: 'instantly',
                    showClearButton: true,
                  }}
                  editorType="dxDateBox"
                />
                <SimpleItem
                  dataField="disdetta_aggiornamenti"
                  label={{ text: 'Disdetta Aggiornamenti' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    type: 'date',
                    pickerType: 'calendar',
                    applyValueMode: 'instantly',
                    showClearButton: true,
                  }}
                  editorType="dxDateBox"
                />
              </GroupItem>
              <GroupItem colCount={4} colSpan={4} caption="Dettagli Tariffa">
                <SimpleItem dataField="canone" label={{ text: 'Canone' }} editorType="dxNumberBox">
                  <RequiredRule message="L'importo del canone annuale è obbligatorio" />
                </SimpleItem>
                <SimpleItem
                  dataField="importo_precedente"
                  label={{ text: 'Importo Precedente' }}
                  editorType="dxNumberBox"
                >
                  <RequiredRule message="L'importo del canone annuale precedente è obbligatorio" />
                </SimpleItem>
                <SimpleItem
                  dataField="canone_personalizzazioni"
                  label={{ text: 'Canone Personalizzazioni' }}
                  editorType="dxNumberBox"
                />
                <SimpleItem
                  dataField="canone_telefonica"
                  label={{ text: 'Canone Telefonica' }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                    buttons: [
                      {
                        name: 'recompute',
                        location: 'after',
                        options: {
                          disabled: !isRoleAllowed,
                          visible: true,
                          stylingMode: 'text',
                          icon: 'pulldown',
                          onClick: () => {
                            fetcher?.submit(
                              {},
                              {
                                method: 'post',
                                action: `/clients/${clientId}/support/${support.id}/quote/phone`,
                                encType: 'application/json',
                              },
                            )
                          },
                        },
                      },
                    ],
                  }}
                />
                <SimpleItem dataField="tariffa_oraria" label={{ text: 'Tariffa Oraria' }} editorType="dxNumberBox" />
                <SimpleItem
                  dataField="posti"
                  label={{ text: 'Posti di lavoro' }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                />
                <SimpleItem dataField="durata" label={{ text: 'Durata' }} editorType="dxNumberBox">
                  <RequiredRule message="La durata del contratto è obbligatoria" />
                  <PatternRule message="La durata deve essere compresa tra 1 e 3 anni" pattern="^[123]$" />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={4} colSpan={4} caption="Scadenze">
                <SimpleItem
                  dataField="scadenza"
                  label={{ text: 'Scadenza' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    type: 'date',
                    pickerType: 'calendar',
                    applyValueMode: 'instantly',
                  }}
                  editorType="dxDateBox"
                />
                <SimpleItem
                  dataField="data_fatturazione"
                  label={{ text: 'Fatturazione' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    type: 'date',
                    pickerType: 'calendar',
                    applyValueMode: 'instantly',
                  }}
                  editorType="dxDateBox"
                />
                <SimpleItem
                  dataField="seconda_fatturazione"
                  label={{ text: 'Seconda Fatturazione' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    type: 'date',
                    pickerType: 'calendar',
                    applyValueMode: 'instantly',
                    showClearButton: true,
                  }}
                  editorType="dxDateBox"
                />
              </GroupItem>
              <GroupItem colCount={4} colSpan={4} caption="Opzioni">
                <SimpleItem dataField="timbro_firma" label={{ text: 'Timbro e Firma' }} editorType="dxSwitch" />
                <SimpleItem
                  dataField="fatturazione_viaggio"
                  label={{ text: 'Fatturazione Viaggio' }}
                  editorType="dxSwitch"
                />
                <SimpleItem
                  dataField="sospesa"
                  label={{ text: 'Sospesa' }}
                  editorType="dxSwitch"
                  editorOptions={{
                    onValueChanged: (e: ValueChangedEvent) => {
                      if (support !== undefined) {
                        formRef.current
                          ?.instance()
                          .updateData('data_sospensione', e.value ? new Date().toISOString() : null)
                      }
                    },
                  }}
                />
                <SimpleItem
                  dataField="data_sospensione"
                  label={{ text: 'Data Sospensione' }}
                  editorOptions={{
                    displayFormat: 'dd/MM/yyyy',
                    readOnly: true,
                  }}
                  editorType="dxDateBox"
                />
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={1} colSpan={2} caption="Note">
              <SimpleItem
                dataField={'note'}
                editorType={'dxTextArea'}
                label={{ visible: false }}
                editorOptions={{
                  height: '560',
                }}
              ></SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={2}>
            <GroupItem caption="Riferimento Padre">
              <SimpleItem
                dataField="riferimento"
                label={{ text: 'Riferimento' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', allSupports),
                  placeholder: 'Nessun contratto di riferimento',
                  displayExpr: (item: assistenza) =>
                    item ? `${item.sede?.azienda?.nome} - Sede ${item.sede?.nome}` : '',
                  searchEnabled: true,
                  showClearButton: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: `Seleziona il contratto padre...`,
                    hideOnOutsideClick: true,
                  },
                }}
              />
            </GroupItem>
            <GroupItem caption="Contratti Derivati">
              <SimpleItem
                render={() => (
                  <List
                    noDataText="Nessun contratto secondario trovato"
                    disabled={false}
                    dataSource={ArraySourceComposer('id', secondarySupports)}
                    height="100%"
                    displayExpr={(item: assistenza) =>
                      item ? `${item.sede?.azienda?.nome} - Sede ${item.sede?.nome}` : ''
                    }
                    onItemClick={(e: any) => {}}
                  />
                )}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem visible={isRoleAllowed} cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={2}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  text="Salva"
                  icon="save"
                  useSubmitBehavior={false}
                  width="150px"
                  onClick={handleOnClickSave}
                />
              </ButtonItem>
              <ButtonItem
                name="btn-cancel"
                buttonOptions={{
                  disabled: !formRef.current?.instance().option().isDirty,
                  icon: 'undo',
                  text: 'Annulla',
                  width: '150px',
                  onClick: async (e) => {
                    revalidate.revalidate()
                  },
                }}
              ></ButtonItem>
            </GroupItem>
          </GroupItem>
        </DxForm>
      </div>
    </>
  )
}

export default SupportEditorForm
