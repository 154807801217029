import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'

export const TicketRoute: TileRoleRoute = {
  path: 'ticket',
  text: 'Ticket',
  faIcon: faTicket,
  element: (
    <ManagementGridFactory
      entity="tipologia_ticket"
      referencesCollection="tickets"
      referencesTitle="Tipi Ticket"
      displayField="ded_Dis"
      entityRoute={TicketsPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Tipo Ticket',
      icon: faTicket,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
