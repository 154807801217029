import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { ClientsPageRoute } from '@/routes/clients/ClientsPage.route'

export const CompanyRelationRoute: TileRoleRoute = {
  path: 'companyReport',
  text: 'Relazione Aziendale',
  faIcon: faHandshake,
  element: (
    <ManagementGridFactory
      entity="tipologia_rapporto_azienda"
      referencesCollection="aziende"
      referencesTitle="Relazioni Aziendali"
      displayField="ded_Dis"
      entityRoute={ClientsPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Relazione Aziendale',
      icon: faHandshake,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
