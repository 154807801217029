import { ButtonItem, Form, FormRef, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { ArraySourceComposer } from '@/auth/api/connector'
import { azienda, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { LoadPanel, Popup } from 'devextreme-react'
import { useScreenSize } from '@/themes/media-query'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { LoadPanelRef } from 'devextreme-react/cjs/load-panel'
import { ClickEvent } from 'devextreme/ui/button'
import { DataGridRef } from 'devextreme-react/cjs/data-grid'
import notify from 'devextreme/ui/notify'

export type MoveCsnToSelectorOnSubmitEditorParams = {
  azienda: azienda | null | undefined
}
export type MoveCsnToSelector = {
  popupRef: React.RefObject<PopupRef>
  csnsGridRef?: React.RefObject<DataGridRef>
}

export const MoveCsnToSelector = (props: MoveCsnToSelector) => {
  const { popupRef, csnsGridRef } = props
  const formRef = useRef<FormRef>(null)

  const currentScreenSize = useScreenSize()
  const { client, service } = useQsAdminApiManager()
  const loadPanelRef = useRef<LoadPanelRef>(null)
  const [aziendeList, setAziendeList] = useState<azienda[]>([])

  const formData: {
    azienda: azienda | null | undefined
  } = {
    azienda: null,
  }

  const getAziende = async () => {
    const aziendeData = await service.azienda().query((builder, azienda) => {
      builder.filter(azienda.sedi.any())
      builder.orderBy(azienda.nome.asc())
      builder.select('id', 'nome')
    })
    return aziendeData.data.value
  }

  //al primo render del componente recupero le aziende
  useEffect(() => {
    console.log('recupero aziende movecsntoselector')
    getAziende().then((aziende: azienda[]) => {
      setAziendeList(aziende)
    })
  }, [])

  console.log('rendermovecsntoselector')

  return (
    <>
      <Popup
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 300 : currentScreenSize.isXSmall ? 350 : 700}
        height={'auto'}
        hideOnOutsideClick={true}
        title="Selezionare Azienda"
        showCloseButton={true}
        deferRendering={true}
        ref={popupRef}
        onHiding={() => {
          formRef.current?.instance().clear()
        }}
        resizeEnabled={false}
      >
        <div id="movecsnto-selector-form-container" className={'dx-card responsive-paddings'}>
          <LoadPanel
            ref={loadPanelRef}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
            hideOnParentScroll={false}
            position={{ of: '#movecsnto-selector-form-container' }}
          />

          <Form
            id={`movecsnto-selector-form}`}
            key={`movecsnto-selector-form}`}
            formData={formData}
            ref={formRef}
            validationGroup="movecsntoSelectorValidation"
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField="azienda"
                label={{ text: 'Azienda' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', aziendeList),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Azienda obbligatoria"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem cssClass="last-group">
              <GroupItem cssClass="buttons-group" colCount={1}>
                <ButtonItem
                  name="btn-move"
                  buttonOptions={{
                    icon: 'import',
                    text: 'Sposta csn',
                    disabled: false,
                    width: '150px',
                    onClick: async (e: ClickEvent) => {
                      const validationResult = formRef.current?.instance().validate()
                      if (!validationResult?.isValid) return
                      loadPanelRef.current?.instance().option('visible', true)
                      try {
                        const response = await client.put(
                          'api/azienda/movecsnto',
                          {
                            aziendaId: formData.azienda?.id,
                            csnId: csnsGridRef?.current?.instance().option('focusedRowKey'),
                          },
                          {
                            headers: { 'Content-Type': 'application/json' },
                          },
                        )
                        notify(`Spostamento csn avvenuto con successo.`, 'success', 2000)
                        popupRef.current?.instance().hide()
                        formRef.current?.instance().clear()
                        csnsGridRef?.current?.instance().refresh()
                      } catch (error: unknown) {
                        notify(`Errore spostamento csn. Dettagli : ${error}`, 'error', 2000)
                      } finally {
                        loadPanelRef.current?.instance().option('visible', false)
                      }
                    },
                  }}
                ></ButtonItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </Popup>
    </>
  )
}

export default MoveCsnToSelector
