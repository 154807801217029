import { Column, DataGrid, DataGridTypes, Paging, StateStoring } from 'devextreme-react/data-grid'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ad_quote, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import { CellPreparedEvent } from 'devextreme/ui/data_grid'
import { useScreenSize } from '@/themes/media-query'
import { isUserRoleAllowed } from '@/routes/utils'
import { Link } from 'react-router-dom'
import { Roles } from '@/auth/azure/Roles'
import { useEffect, useState } from 'react'
import { AzureUserInfo, getAzureUserInformation } from '@/auth/azure/azureManager'

const QuoteSubscriptions = (props: { quote: ad_quote }) => {
  const { quote } = props
  const currentScreenSize = useScreenSize()
  const [userInfo, setUserInfo] = useState<AzureUserInfo | undefined>()

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'status') {
        if (e.value) {
          switch (e.value) {
            case 'Active': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`

              break
            }
            case 'Canceled': {
              e.cellElement.style.cssText = `color: white; background-color: ${GridCellColor.RED}`

              break
            }
            case 'Expired': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`

              break
            }
            case 'Upgraded': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.ORANGE}`

              break
            }
            case 'Unregistered': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`

              break
            }
            case 'Registered': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.VIOLET}`

              break
            }
            case 'Inactive': {
              e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

              break
            }
          }
        }
      } else if (e.column.dataField === 'stato_noleggio.nome' && e.value) {
        switch (e.value) {
          case 'ALTRO DEALER': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.YELLOW}`

            break
          }
          case 'ATTIVO': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.EMERALD}`

            break
          }
          case 'SCADUTO': {
            e.cellElement.style.cssText = `color: black; background-color: ${GridCellColor.SALMON}`

            break
          }
          // No default
        }
      }
    }
  }
  useEffect(() => {
    getAzureUserInformation().then((userInfo: AzureUserInfo | undefined) => {
      setUserInfo(userInfo)
    })
  }, [])

  return (
    <>
      <DataGrid
        id={`quote-subs-grid-${quote && 'id' in quote ? quote?.id : null}`}
        dataSource={ArraySourceComposer(
          'id',
          quote?.ad_subscriptions?.sort((subA: ad_subscription, subB: ad_subscription) => {
            const dataA: Date = new Date(subA.startDate ?? '')
            const dataB: Date = new Date(subB.startDate ?? '')
            return dataB.getTime() - dataA.getTime()
          }),
        )}
        noDataText="Nessuna subscription associata alla quota"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'quote-subs-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        <Column
          dataField={'subscriptionId'}
          caption={'SUBSCRIPTION'}
          cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
            const data: ad_subscription = cellData.row.data
            return isUserRoleAllowed(userInfo?.roles, [
              Roles.GlobalAdministrator,
              Roles.TechDeveloper,
              Roles.Supervisor,
              Roles.Sales,
              Roles.ExternalSales,
              Roles.Marketing,
              Roles.Administrator,
            ]) ? (
              <>
                <Link to={`/software/autodesk/${data.id}`}>{data.subscriptionId}</Link>
              </>
            ) : (
              <>{data.subscriptionId}</>
            )
          }}
        ></Column>
        <Column dataField={'status'} caption={'STATO'} allowSorting={false} />
        <Column dataField={'productName'} caption={'PRODOTTO'} allowSorting={false} />
        <Column
          dataField={'endDate'}
          caption={'SCADENZA'}
          dataType={'date'}
          format={'dd/MM/yyyy'}
          allowSorting={false}
          width={'auto'}
        />
        <Column dataField={'term'} caption={'TERM'} allowSorting={false} />
        <Column dataField={'switchType'} caption={'SWITCH TYPE'} allowSorting={false} />
      </DataGrid>
    </>
  )
}

export default QuoteSubscriptions
