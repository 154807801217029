import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem } from 'devextreme-react/form'
import type { intervento } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { onInitializedHtmlEditor, onValueChangedHtmlEditor, toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'

const InterventionBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const intervention: intervento = props.data.data
  const currentScreenSize = useScreenSize()
  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'task_brief_detail_form'} formData={intervention} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <Tab title="Note">
                <SimpleItem
                  dataField="note"
                  label={{ visible: false }}
                  editorType="dxHtmlEditor"
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    readOnly: true,
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default InterventionBriefDetail
