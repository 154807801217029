import { ActivityEditorFormProps } from '@/routes/activities/ActivityEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ArraySourceComposer } from '@/auth/api/connector'
import DataSource from 'devextreme/data/data_source'
import { azienda, sede, contatto_aziendale, commessa, gruppo_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useNavigate, useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  customButton,
  isUserRoleAllowed,
  onInitializedHtmlEditor,
  onValueChangedHtmlEditor,
  toolbarHtmlEditor,
} from '@/routes/utils'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { useScreenSize } from '@/themes/media-query'
import { Roles } from '@/auth/azure/Roles'
import FormPopupContact from '@/routes/contacts/person/FormPopupContact'
import { ValidationCallbackData } from 'devextreme/common'

const ActivityEditorForm = (props: ActivityEditorFormProps) => {
  const { creating, activity, commesse, contatti, aziende, stati, tipologie, isRoleAllowed, userInfo, fetcher } = props
  const { clientId } = useParams()
  const [searchParams] = useSearchParams()
  const projectId = searchParams.get('projectId')
  const activityPrecId = searchParams.get('activityPrecId')

  const currentScreenSize = useScreenSize()
  const formRef = useRef<FormRef>(null)
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const { service } = useQsAdminApiManager()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? aziende[0] : activity.sede?.azienda,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(
    clientId && creating && !projectId ? true : false,
  )

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', activity.sede?.azienda?.sedi),
  )
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)

  const [contattiDataSource, setContattiDataSource] = useState<DataSource>(ArraySourceComposer('id', contatti))
  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  const popupNoteClienteRef = useRef<PopupRef>(null)
  const popupNuovoContattoRef = useRef<PopupRef>(null)
  const popupNuovaCommessaRef = useRef<PopupRef>(null)

  const [currentSite, setCurrentSite] = useState<sede>()

  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const [currentGruppoAziendale, setCurrentGruppoAziendale] = useState<gruppo_aziendale | null | undefined>(
    activity?.sede?.azienda?.gruppo_aziendale,
  )

  const validateSedeContatto = ({ value }: { value: string | number }) => {
    return activity.sede || activity.contatto_aziendale ? true : false
  }

  const validateSedeAzienda = ({ value }: { value: string | number }) => {
    if (currentCompany) return activity.sede ? true : false
    return true
  }

  const getAziendaData = useCallback(async () => {
    const aziendaData = await service.azienda(Number(currentCompany?.id)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppoBuilder, gruppo) => {
        gruppoBuilder.select('id', 'nome')
      })
      builder.select('gruppo_aziendale')
    })
    return aziendaData.data
  }, [currentCompany?.id, service])

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
          agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commercialeQs) => {
            commercialeQsBuilder.select('id', 'fullname')
          })
          agenteBuilder.select('id', 'commerciale_qs')
        })
        aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
          rivenditoreBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
          gruppo_aziendaleBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'agente', 'rivenditore', 'gruppo_aziendale')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteContacts = useCallback(async () => {
    const contactsData = await service.contatto_aziendale().query((builder, contatto) => {
      builder.filter(contatto.sede.props.id.eq(Number(activity.sede?.id)))
      builder.filter(contatto.attivo.eq(true))
      builder.orderBy(contatto.fullname.asc())
      builder.select('id', 'fullname')
    })
    return contactsData.data.value
  }, [activity.sede?.id, service])

  const getSiteProjects = useCallback(async () => {
    const projectsData = await service.commessa().query((builder, commessa) => {
      builder.filter(
        currentGruppoAziendale
          ? commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(Number(currentGruppoAziendale.id))
          : commessa.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.select('id', 'ded_Dis', 'titolo', 'sede')
      builder.orderBy(commessa.ded_Dis.desc())
    })
    return projectsData.data.value
  }, [service, currentGruppoAziendale, currentCompany?.id])

  //trigger sull'onchanged di azienda
  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      getAziendaData().then((company: azienda) => {
        console.log('data', company)
        setCurrentGruppoAziendale(company.gruppo_aziendale ?? null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites, getAziendaData])

  //trigger sull'onchanged di sede
  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', activity.sede)
    if (activity.sede) {
      getSiteContacts().then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setContattiDataSource(ArraySourceComposer('id', contacts))
        formRef.current?.instance().updateData('contatto_aziendale', null)
      })
      if (!projectId && !clientId && !activityPrecId)
        getSiteProjects().then((commesse: commessa[]) => {
          console.log('commesse', commesse)
          setCommesseDataSource(ArraySourceComposer('id', commesse))
          formRef.current?.instance().updateData('commessa', null)
        })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, activity.sede, getSiteContacts, getSiteProjects, projectId, clientId, activityPrecId])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'activity_form'}
        formData={activity}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="activityValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed || clientId,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (activity.sede) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showClearButton: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  setCurrentSiteChanged(true)
                },
              }}
            >
              <CustomRule
                message="Sede obbligatoria se 'Contatto' non presente"
                validationCallback={validateSedeContatto}
              ></CustomRule>
              <CustomRule
                message="Sede obbligatoria se 'Azienda' presente"
                validationCallback={validateSedeAzienda}
              ></CustomRule>
            </SimpleItem>
            <SimpleItem
              dataField="inizio"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Inizio' }}
            >
              <RequiredRule message="Data inizio obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="fine"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
                showClearButton: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Fine' }}
            ></SimpleItem>
            <SimpleItem
              dataField="tipologia"
              label={{ text: 'Tipo' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', tipologie),
                placeholder: 'Seleziona la tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipo',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Tipo obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="contatto_aziendale"
              label={{ text: 'Contatto' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    (e: ClickEvent) => {
                      if (activity.sede) {
                        const contact: contatto_aziendale = {
                          id: 0,
                          fullname: null,
                          ad_recipient: false,
                          nome: null,
                          cognome: null,
                          ruolo: null,
                          note: null,
                          titolo: null,
                          telefono: null,
                          attivo: true,
                          sede: activity.sede,
                          hasLinkedObjects: false,
                          ricezione_rapportino: false,
                        }
                        setCurrentContact(contact)
                        popupNuovoContattoRef.current?.instance().show()
                      } else notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                    },
                    !isRoleAllowed,
                  ),
                  { name: 'clear', location: 'after' },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: contattiDataSource,
                placeholder: 'Seleziona il contatto...',
                displayExpr: 'fullname',
                searchEnabled: true,
                showDropDownButton: true,
                showClearButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il contatto',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <CustomRule
                message="Contatto obbligatorio se 'Sede' non presente"
                validationCallback={validateSedeContatto}
              />
            </SimpleItem>
            <SimpleItem
              dataField="commessa"
              label={{ text: 'Commessa' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    () => {
                      if (activity.sede) {
                        setCurrentSite(activity.sede)
                        popupNuovaCommessaRef.current?.instance().show()
                      } else
                        notify(`Deve essere selezionata un' azienda per poter creare una commessa.`, 'warning', 3000)
                    },
                    !isRoleAllowed || (projectId !== null && projectId !== undefined),
                  ),
                  customButton(
                    'open',
                    () => {
                      if (activity.commessa) window.open(`/projects/${activity?.commessa?.id}`, '_blank')
                      else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                    },
                    false,
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: commesseDataSource,
                placeholder: 'Seleziona commessa...',
                displayExpr: (item: commessa) => {
                  return item
                    ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}${item.sede?.azienda?.nome ? ' - ' : ''}${item.sede?.azienda?.nome ?? ''}${item.sede?.nome ? ' - ' : ''}${item.sede?.nome ?? ''}`
                    : ``
                },
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                searchEnabled: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare commessa',
                  hideOnOutsideClick: true,
                  width: currentScreenSize.isLarge ? '150%' : '',
                  resizeEnabled: currentScreenSize.isLarge ? true : false,
                },
                readOnly: !isRoleAllowed || (projectId !== null && projectId !== undefined),
              }}
            >
              <CustomRule
                message={"Commessa obbligatoria se l'attività è di tipo 'DEMO'"}
                validationCallback={(options: ValidationCallbackData) => {
                  console.log('validateDemoProject')
                  return activity.tipologia?.nome !== 'DEMO' || options.value ? true : false
                }}
                reevaluate={true}
              />
            </SimpleItem>
            <GroupItem colCount={2} colSpan={2}></GroupItem>
            <ButtonItem name="goToPrecActivity" verticalAlignment={'bottom'} colSpan={1}>
              <ButtonOptions
                text={'Precedente'}
                icon={'arrowleft'}
                type="default"
                stylingMode="text"
                disabled={!activity?.precedente}
                onClick={() => {
                  navigate({
                    pathname: `/activities/${activity?.precedente?.id}`,
                  })
                }}
              />
            </ButtonItem>
            <ButtonItem name="addFollowUp" verticalAlignment={'bottom'} colSpan={1}>
              <ButtonOptions
                text={'Follow Up'}
                icon={'add'}
                type="default"
                stylingMode="text"
                disabled={creating || !isRoleAllowed}
                onClick={() => {
                  navigate({
                    pathname: '/activities/new',
                    search: `?activityPrecId=${activity?.id}`,
                  })
                }}
              />
            </ButtonItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField="descrizione"
              label={{ visible: true }}
              editorType="dxHtmlEditor"
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: 330,
                onValueChanged: onValueChangedHtmlEditor,
                onInitialized: onInitializedHtmlEditor,
              }}
            >
              <RequiredRule message="Descrizione obbligatoria"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            <ButtonItem name="btn-commessa">
              <ButtonOptions
                text="Commessa"
                icon="check"
                useSubmitBehavior={false}
                width={'150px'}
                disabled={creating || !isRoleAllowed}
                onClick={(e: ClickEvent) => {
                  if (activity?.commessa) {
                    notify(
                      `Attività commerciale già collegata a commessa. Commessa: ${activity.commessa.id}`,
                      'warning',
                      3000,
                    )
                  } else {
                    navigate({
                      pathname: '/projects/new',
                      search: `?activityId=${activity?.id}`,
                    })
                  }
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('formData', activity)
                  if (activity) {
                    fetcher?.submit(JSON.stringify(activity), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupContact
        migrating={false}
        popupRef={popupNuovoContattoRef}
        contact={currentContact}
        onContactSaved={(newContact) => {
          popupNuovoContattoRef.current?.instance().hide()
          getSiteContacts().then((contacts: contatto_aziendale[]) => {
            formRef.current?.instance().updateData('contatto_aziendale', newContact)
            setContattiDataSource(ArraySourceComposer('id', contacts))
          })
        }}
      ></FormPopupContact>
      <FormPopupProject
        popupRef={popupNuovaCommessaRef}
        sede={currentSite}
        azienda={currentCompany}
        refreshCommessa={async () => {
          getSiteProjects().then((res: commessa[]) => {
            setCommesseDataSource(ArraySourceComposer('id', res))
            formRef.current?.instance().updateData('commessa', res[0])
            popupNuovaCommessaRef.current?.instance().hide()
          })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={activity.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === activity.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default ActivityEditorForm
