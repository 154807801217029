import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import QuoteCreatorRoute from '@/routes/quotes/QuoteCreator.route'
import QuoteEditorRoute from '@/routes/quotes/QuoteEditor.route'
import QuotesPage from '@/routes/quotes/QuotesPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { ActionFunction, defer, LoaderFunction } from 'react-router-typesafe'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faArrowsSpin, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

export const quotesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Quote` : 'Quote'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    title,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Supervisor,
    ],
  })
}) satisfies LoaderFunction

const autoRenewalCreateAction = (async ({ request, params }: RouteFunctionParams) => {
  const client = useQsAdminApiManager.getState().client
  return await client.get('/api/autodeskpws/quotes/autorenewalcreate', {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}) satisfies ActionFunction

export const QuotesPageRoute: RoleRoute = {
  path: 'quotes',
  children: [
    {
      index: true,
      element: <QuotesPage />,
      loader: quotesPageRouteLoader,
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.Marketing,
        Roles.ExternalSales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
    QuoteCreatorRoute,
    QuoteEditorRoute,
    {
      path: 'autorenewalcreate',
      action: autoRenewalCreateAction,
      handle: {
        crumb: () => ({
          label: 'Rinnovo Automatico',
          icon: faArrowsSpin,
        }),
      },
      allowedRoles: [
        Roles.Sales,
        Roles.Administrator,
        Roles.GlobalAdministrator,
        Roles.ExternalSales,
        Roles.Supervisor,
      ],
    } as RoleRouteObject,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Quote',
  faIcon: faMoneyBillAlt,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Quote',
      icon: faMoneyBillAlt,
    }),
  },
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.ExternalSales,
    Roles.Supervisor,
  ],
}
