import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { PackagesPageRoute } from '@/routes/packages/PackagesPage.route'
import { PackagesSaleGridRoute } from '@/routes/packages/sale/PackagesSaleGrid.route'

export const PackageDurationRoute: TileRoleRoute = {
  path: 'packageDuration',
  text: 'Durata Pacchetto',
  faIcon: faClock,
  element: (
    <ManagementGridFactory
      entity="tipologia_durata_pacchetto"
      referencesCollection="pacchetto_venditas"
      referencesTitle="Durate Pacchetti"
      displayField="ded_Dis"
      entityRoute={`${PackagesPageRoute.path}/${PackagesSaleGridRoute.path}`}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Durata Pacchetto',
      icon: faClock,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
