import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { azienda, causale_centro_costo, sede, task } from '@/model/qsadminapi/QsAdminApiModuleModel'

import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useRevalidator, useSearchParams } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import DataSource from 'devextreme/data/data_source'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { HourEditorFormProps } from '@/routes/hours/HourEditor.types'
import { DateBox } from 'devextreme-react'
import { Button as DateBoxButton } from 'devextreme-react/date-box'
import { ValueChangedEvent as ValueChangedEventDateBox } from 'devextreme/ui/date_box'
import { Duration } from 'luxon'
import HourEditorFormActivitiesGridMemoed from '@/routes/hours/HourEditor.activities'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'
import { ValidationCallbackData } from 'devextreme/common'

const HourEditorForm = (props: HourEditorFormProps) => {
  const {
    creating,
    hour,
    tempoCliente,
    tempoTrasferta,
    tempoUfficio,
    clients,
    client,
    centriCosto,
    piattaforme,
    tasks,
    impiegati,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props
  const currentScreenSize = useScreenSize()
  const minSpanDate: Date = new Date(0, 0, 0, 0, 0)
  const maxSpanDate: Date = new Date(0, 0, 0, 10, 0)
  const hourDate: Date = new Date(hour.data ?? '')
  hourDate.setHours(0)
  hourDate.setMinutes(0)
  hourDate.setSeconds(0)
  hourDate.setMilliseconds(0)
  console.log('hourDate', hourDate)
  const startDateFilter: Date = new Date(hourDate.toISOString().replace('Z', ''))
  const endDateFilter: Date = new Date(
    new Date(hourDate.getFullYear(), hourDate.getMonth(), hourDate.getDate() + 1, 0, 0, 0)
      .toISOString()
      .replace('Z', ''),
  )
  const [currentOreUfficio, setCurrentOreUfficio] = useState<Date>(tempoUfficio)
  const [currentOreCliente, setCurrentOreCliente] = useState<Date>(tempoCliente)
  const [currentOreTrasferta, setCurrentOreTrasferta] = useState<Date>(tempoTrasferta)

  const { service } = useQsAdminApiManager()

  const revalidator = useRevalidator()
  const formRef = useRef<FormRef>(null)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(client)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(false)

  const [currentTaskSite, setCurrentTaskSite] = useState<sede | null | undefined>()
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(ArraySourceComposer('id', client?.sedi))

  const [tasksDataSource, setTasksDataSource] = useState<DataSource>(ArraySourceComposer('id', tasks))

  const [causalsList, setCausalsList] = useState<causale_centro_costo[]>(hour.centro_costo?.causali_centro_costo ?? [])

  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentFilter, setCurrentFilter] = useState<any[]>([
    ['impiegato.id', hour.impiegato?.id],
    ['data', '>=', startDateFilter],
    ['data', '<', endDateFilter],
  ])

  const [searchParams] = useSearchParams()
  const date = searchParams.get('date')
  const navigate = useNavigate()

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getSiteTasks = useCallback(
    async (siteId?: number | undefined) => {
      const tasksData = await service.task().query((builder, task) => {
        builder.filter(siteId ? task.sede.props.id.eq(siteId) : null)
        builder.filter(
          task.resp_tecnico.props.id
            .eq(Number(hour.impiegato?.id))
            .or(task.tecnici.any((tecnico) => tecnico.id.eq(Number(hour.impiegato?.id)))),
        )
        builder.select('id', 'ded_Dis')
        builder.orderBy(task.id.desc())
      })
      return tasksData.data.value
    },
    [hour.impiegato?.id, service],
  )

  const getTaskSite = useCallback(
    async (taskId: number) => {
      const tasksData = await service.task(taskId).query((builder) => {
        builder.expanding('sede', (sedeBuilder) => {
          sedeBuilder.expanding('azienda', (aziendaBuilder) => {
            aziendaBuilder.select('id', 'nome')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.select('sede')
      })
      return tasksData.data.sede
    },
    [service],
  )

  const getCausals = useCallback(
    async (centroCostoId: number | undefined) => {
      const causalsData = await service.causale_centro_costo().query((builder, causale) => {
        builder.filter(causale.centro_costo.props.id.eq(Number(centroCostoId)))
        builder.select('id', 'nome', 'note')
        builder.orderBy(causale.id.desc())
      })
      return causalsData.data.value
    },
    [service],
  )

  const validateCausale = ({ value }: ValidationCallbackData) => {
    return (value !== null && value !== undefined) || causalsList.length === 0
  }

  const setCurrentFilterValue = (impiegatoId: number, dateString: string) => {
    const minDate: Date = new Date(dateString)
    minDate.setHours(0)
    minDate.setMinutes(0)
    minDate.setSeconds(0)
    const maxDate: Date = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1, 0, 0, 0)
    setCurrentFilter([
      ['impiegato.id', impiegatoId],
      ['data', '>=', new Date(minDate.toISOString().replace('Z', ''))],
      ['data', '<', new Date(maxDate.toISOString().replace('Z', ''))],
    ])
  }

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    if (hour.sede) {
      if (!currentTaskSite)
        getSiteTasks(hour.sede.id).then((siteTasks: task[]) => {
          setTasksDataSource(ArraySourceComposer('id', siteTasks))
          if (hour.task) formRef.current?.instance().updateData('task', hour.task)
          else formRef.current?.instance().updateData('task', null)
        })
    } else {
      getSiteTasks().then((siteTasks: task[]) => {
        console.log('siteTasks', siteTasks)
        setTasksDataSource(ArraySourceComposer('id', siteTasks))
        formRef.current?.instance().updateData('task', null)
      })
    }
    setCurrentTaskSite(null)
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, hour.sede, getSiteTasks, hour.impiegato?.id, currentTaskSite, tasks, hour.task])

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        if (currentTaskSite) {
          formRef.current?.instance().updateData('sede', currentTaskSite)
        } else {
          if (currentCompany.id === client?.id) {
            formRef.current?.instance().updateData('sede', hour.sede)
          } else {
            formRef.current?.instance().updateData('sede', sites[0])
          }
        }
        setCurrentSiteChanged(true)
      })
    } else {
      setSediDataSource(ArraySourceComposer('id', []))
      formRef.current?.instance().updateData('sede', null)
      setCurrentSiteChanged(true)
    }
    setCurrentCompanyChanged(false)
  }, [currentCompanyChanged, currentCompany, getCompanySites, currentTaskSite, client?.id, hour.sede])

  useEffect(() => {
    formRef.current?.instance().getButton('btn-save')?.option('disabled', true)
    formRef.current?.instance().getButton('btn-cancel')?.option('disabled', true)
    setCurrentFilter([
      ['impiegato.id', hour.impiegato?.id],
      ['data', '>=', startDateFilter],
      ['data', '<', endDateFilter],
    ])
    setCausalsList(hour.centro_costo?.causali_centro_costo ?? [])
    setCurrentOreCliente(tempoCliente)
    setCurrentOreTrasferta(tempoTrasferta)
    setCurrentOreUfficio(tempoUfficio)
    if (creating) setCurrentCompany(null)
  }, [hour])

  useEffect(() => {
    if (client) setCurrentCompany(client)
    else setCurrentCompany(null)
    setSediDataSource(ArraySourceComposer('id', client?.sedi))
    setTasksDataSource(ArraySourceComposer('id', tasks))
  }, [client])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'hour_form'}
        formData={hour}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="hourValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              dataField="impiegato"
              label={{ text: 'Impiegato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona impiegato...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: !isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator]),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'impiegato",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  if (e.value && hour.data) {
                    setCurrentFilterValue(e.value.id, hour.data)
                  }
                  getSiteTasks().then((siteTasks: task[]) => {
                    console.log('siteTasks', siteTasks)
                    setTasksDataSource(ArraySourceComposer('id', siteTasks))
                    formRef.current?.instance().updateData('task', null)
                    formRef.current?.instance().updateData('sede', null)
                    setCurrentCompany(null)
                  })
                },
              }}
            >
              <RequiredRule message="Impiegato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="data"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                onValueChanged: (e: ValueChangedEventDateBox) => {
                  if (e.value && hour.impiegato) {
                    setCurrentFilterValue(hour.impiegato.id, e.value)
                  }
                },
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data' }}
            >
              <RequiredRule message="Data obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="centro_costo"
              label={{ text: 'Centro di Costo' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', centriCosto),
                placeholder: 'Seleziona centro di costo...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il centro di costo',
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log('CENTRO COSTO EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    getCausals(e.value.id).then((values: causale_centro_costo[]) => {
                      setCausalsList(values)
                      formRef.current?.instance().updateData('causale', null)
                    })
                  }
                },
              }}
            >
              <RequiredRule message="Centro di costo obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="causale"
              label={{ text: 'Causale' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', causalsList),
                placeholder: 'Seleziona la causale...',
                displayExpr: (item: causale_centro_costo) => {
                  return item ? `${item.nome} - ${item.note}` : ''
                },
                searchEnabled: true,
                dropDownCentered: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la causale',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <CustomRule message={'Causale obbbligatoria'} validationCallback={validateCausale} />
            </SimpleItem>
            <SimpleItem
              dataField="piattaforma"
              label={{ text: 'Piattaforma' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', piattaforme),
                placeholder: 'Seleziona piattaforma...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la piattaforma',
                  hideOnOutsideClick: true,
                },
                showClearButton: true,
              }}
            ></SimpleItem>
            <SimpleItem label={{ text: 'Ufficio' }}>
              <DateBox
                readOnly={!isRoleAllowed}
                value={currentOreUfficio}
                type="time"
                min={minSpanDate}
                max={maxSpanDate}
                pickerType="list"
                interval={15}
                displayFormat={'HH:mm'}
                onValueChanged={(e: ValueChangedEventDateBox) => {
                  formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                  formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                  setCurrentOreUfficio(e.value)
                }}
              >
                <DateBoxButton
                  name="prevOfficeSlot"
                  location="after"
                  options={{
                    icon: 'plus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreUfficio && currentOreUfficio < maxSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreUfficio(new Date(currentOreUfficio.getTime() + 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton
                  name="nextOfficeSlot"
                  location="after"
                  options={{
                    icon: 'minus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreUfficio && currentOreUfficio > minSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreUfficio(new Date(currentOreUfficio.getTime() - 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton name="dropDown" />
              </DateBox>
            </SimpleItem>
            <GroupItem colCount={hour.intervento ? 4 : 3} colSpan={2}>
              <SimpleItem
                label={{ text: 'Azienda' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', (e: ClickEvent) => {
                      if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                      else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                    }),
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentCompany,
                  dataSource: ArraySourceComposer('id', clients),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                    width: currentScreenSize.isLarge ? '150%' : '',
                    resizeEnabled: currentScreenSize.isLarge ? true : false,
                  },
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log('e', e)
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  },
                  showClearButton: true,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="sede"
                label={{ text: 'Sede' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'info',
                      () => {
                        if (hour.sede) {
                          setCurrentSite(hour.sede)
                          popupNoteClienteRef.current?.instance().show()
                        } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                      },
                      false,
                      'Note cliente',
                    ),
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: sediDataSource,
                  placeholder: 'Seleziona la sede...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  showDropDownButton: true,
                  dropDownButtonTemplate: 'dropDownButton',
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la sede',
                    hideOnOutsideClick: true,
                  },
                  readOnly: !isRoleAllowed,
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                    setCurrentSiteChanged(true)
                  },
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="task"
                label={{ text: 'Task' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: tasksDataSource,
                  placeholder: 'Seleziona il task...',
                  displayExpr: 'ded_Dis',
                  searchEnabled: true,
                  dropDownCentered: true,
                  showClearButton: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il task',
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('TASK EVENT CHANGED', e.previousValue, e.value)
                    if (e.value)
                      getTaskSite(e.value.id).then((site: sede | null | undefined) => {
                        if (site?.azienda?.id !== currentCompany?.id) {
                          setCurrentCompany(site?.azienda)
                          setCurrentTaskSite(site)
                          setCurrentCompanyChanged(true)
                        }
                      })
                  },
                }}
              ></SimpleItem>
              {hour.intervento && (
                <SimpleItem
                  dataField="intervento"
                  label={{ text: 'Intervento' }}
                  editorType="dxLookup"
                  editorOptions={{
                    dataSource: hour.intervento
                      ? ArraySourceComposer('id', [hour.intervento])
                      : ArraySourceComposer('id', []),
                    placeholder: 'Seleziona intervento...',
                    displayExpr: 'ded_Dis',
                    searchEnabled: true,
                    dropDownCentered: true,
                    showClearButton: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: 'Selezionare intervento',
                      hideOnOutsideClick: true,
                    },
                    readOnly: true,
                  }}
                ></SimpleItem>
              )}
            </GroupItem>
            <SimpleItem label={{ text: 'Cliente' }}>
              <DateBox
                readOnly={!isRoleAllowed}
                value={currentOreCliente}
                type="time"
                min={minSpanDate}
                max={maxSpanDate}
                pickerType="list"
                interval={15}
                displayFormat={'HH:mm'}
                onValueChanged={(e: ValueChangedEventDateBox) => {
                  formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                  formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                  setCurrentOreCliente(e.value)
                }}
              >
                <DateBoxButton
                  name="prevClientSlot"
                  location="after"
                  options={{
                    icon: 'plus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreCliente && currentOreCliente < maxSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreCliente(new Date(currentOreCliente.getTime() + 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton
                  name="nextClientSlot"
                  location="after"
                  options={{
                    icon: 'minus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreCliente && currentOreCliente > minSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreCliente(new Date(currentOreCliente.getTime() - 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton name="dropDown" />
              </DateBox>
            </SimpleItem>
            <SimpleItem label={{ text: 'Trasferta' }}>
              <DateBox
                readOnly={!isRoleAllowed}
                value={currentOreTrasferta}
                type="time"
                min={minSpanDate}
                max={maxSpanDate}
                pickerType="list"
                interval={15}
                displayFormat={'HH:mm'}
                onValueChanged={(e: ValueChangedEventDateBox) => {
                  formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                  formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                  setCurrentOreTrasferta(e.value)
                }}
              >
                <DateBoxButton
                  name="prevTransferSlot"
                  location="after"
                  options={{
                    icon: 'plus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreTrasferta && currentOreTrasferta < maxSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreTrasferta(new Date(currentOreTrasferta.getTime() + 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton
                  name="nextTransferSlot"
                  location="after"
                  options={{
                    icon: 'minus',
                    stylingMode: 'text',
                    onClick: () => {
                      if (currentOreTrasferta && currentOreTrasferta > minSpanDate) {
                        formRef.current?.instance().getButton('btn-save')?.option('disabled', false)
                        formRef.current?.instance().getButton('btn-cancel')?.option('disabled', false)
                        setCurrentOreTrasferta(new Date(currentOreTrasferta.getTime() - 15 * 60_000))
                      }
                    },
                  }}
                />
                <DateBoxButton name="dropDown" />
              </DateBox>
            </SimpleItem>
            <GroupItem colCount={3} colSpan={2}>
              <SimpleItem
                dataField="spese_trasferta"
                label={{ text: 'Spese Trasferta' }}
                editorType="dxNumberBox"
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="spese_vitto"
                label={{ text: 'Spese Vitto' }}
                editorType="dxNumberBox"
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="spese_alloggio"
                label={{ text: 'Spese Alloggio' }}
                editorType="dxNumberBox"
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                }}
              ></SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={3} colSpan={3}>
            <SimpleItem
              colSpan={3}
              dataField={'note'}
              editorType={'dxTextArea'}
              label={{ visible: true, text: 'Note' }}
              editorOptions={{
                height: '400',
              }}
            ></SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                icon="save"
                text="Salva"
                width="160px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')

                  const durationOffice: Duration = Duration.fromObject({
                    hours: currentOreUfficio?.getHours(),
                    minutes: currentOreUfficio?.getMinutes(),
                  })
                  hour.tempo_ufficio = durationOffice.toFormat('0.hh:mm:ss')

                  const durationClient: Duration = Duration.fromObject({
                    hours: currentOreCliente?.getHours(),
                    minutes: currentOreCliente?.getMinutes(),
                  })
                  hour.tempo_cliente = durationClient.toFormat('0.hh:mm:ss')

                  const durationTransfer: Duration = Duration.fromObject({
                    hours: currentOreTrasferta?.getHours(),
                    minutes: currentOreTrasferta?.getMinutes(),
                  })
                  hour.tempo_trasferta = durationTransfer.toFormat('0.hh:mm:ss')
                  if (hour) {
                    fetcher?.submit(JSON.stringify(hour), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                    if (date)
                      navigate({
                        pathname: '/hours/new',
                        search: `?date=${date}`,
                      })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  width="160px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <HourEditorFormActivitiesGridMemoed
        currentFilter={currentFilter}
        readOnly={!isRoleAllowed}
        userInfo={userInfo}
      ></HourEditorFormActivitiesGridMemoed>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default HourEditorForm
