import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { Accordion } from 'devextreme-react'
import {
  Column,
  ColumnChooser,
  DataGrid,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Sorting,
  Search,
  FilterPanel,
  DataGridTypes,
  Pager,
  Button as GridButton,
  MasterDetail,
} from 'devextreme-react/data-grid'
import ODataStore from 'devextreme/data/odata/store'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { ClickEvent } from 'devextreme/ui/button'
import dxDataGrid, {
  CellPreparedEvent,
  ColumnButtonClickEvent,
  ExportingEvent,
  RowDblClickEvent,
} from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { confirm } from 'devextreme/ui/dialog'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Item as AccordionItem } from 'devextreme-react/accordion'
import { projectsPageRouteLoader } from '@/routes/projects/ProjectsPage.route'
import { GridCellColor } from '@/enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed, onDataGridSelectedRowButtonClick } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'
import DocumentHead from '@/components/document-head/DocumentHead'
import ProjectsBriefDetail from '@/routes/projects/ProjectBriefDetail'

const ProjectsPage = () => {
  const { title, aziende, stati, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof projectsPageRouteLoader>()
  const token = useTokenRefresh()
  const { service } = useQsAdminApiManager()
  const { clientId } = useParams()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()
  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '120vh'
    } else if (currentScreenSize.isXSmall) {
      h = '75vh'
    }
    return h
  }, [currentScreenSize])

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'stato',
        placeHolder: 'Stato',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: stati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['stato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('inizio', 'Data Inizio'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, stati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const projectsDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/commessa`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: ['stato', 'sede($expand=azienda($expand=agente($expand=commerciale_qs), rivenditore))', 'autore'],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'COMPLETATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
      }
    }
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }
  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Commesse')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Projects.xlsx')
      })
    })
  }

  const deleteCommessa = async (projectId: number) => {
    return await service.commessa(projectId).delete()
  }

  const onDeleteClick = (e: ClickEvent) => {
    onDataGridSelectedRowButtonClick(gridRef, (rowdata: commessa) => {
      const result = confirm(`<i>Vuoi davvero eliminare la commessa <b>${rowdata.id}</b>?</i>`, 'Eliminazione commessa')
      result.then((dialogResult) => {
        if (dialogResult === false) return
        deleteCommessa(rowdata.id)
          .then(() => {
            // Aggiornamento griglia
            gridRef.current?.instance().refresh()

            notify(
              {
                message: `Commessa '${rowdata['ded_Dis']}' del cliente ${
                  rowdata['sede']?.azienda?.nome ?? 'Non definito'
                } eliminata con successo`,
                type: 'success',
                displayTime: 5000,
              },
              {
                position: 'bottom center',
                direction: 'up-push',
              },
            )
          })
          .catch((error: unknown) => {
            notify(
              {
                message: `Errore eliminazione commessa: ${error}`,
                type: 'error',
                displayTime: 5000,
              },
              {
                position: 'bottom center',
                direction: 'up-push',
              },
            )
          })
      })
    })
  }

  useEffect(() => {
    getCurrentFilter()
  }, [])

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      {!clientId && <h2 className={'content-block'}>{title}</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-projects-datagrid' : 'projects-datagrid'}
          height={getGridHeight}
          dataSource={projectsDataSource}
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
          allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
          width="100%"
          onCellPrepared={onCellPrepared}
          onExporting={onExporting}
          ref={gridRef}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          noDataText="Nessuna commessa trovata"
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: currentScreenSize.isLarge || currentScreenSize.isMedium,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <SearchPanel visible={true} searchVisibleColumnsOnly={false} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <FilterPanel visible={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <Sorting mode="multiple" />
          <MasterDetail enabled={true} component={ProjectsBriefDetail} />,
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-projects-datagrid' : 'projects-datagrid'}
            savingTimeout={50}
          />
          <Column
            visible={currentScreenSize.isXSmall || currentScreenSize.isSmall}
            type="buttons"
            width={currentScreenSize.isXSmall || currentScreenSize.isSmall ? '10%' : '2%'}
            alignment="left"
            showInColumnChooser={false}
          >
            <GridButton
              hint="Details"
              icon="search"
              onClick={(e: ColumnButtonClickEvent) => {
                navigate(`${e.row?.data.id}`)
              }}
            ></GridButton>
          </Column>
          <Column
            dataField={'id'}
            caption="ID"
            visible={false}
            dataType="number"
            defaultSortOrder="desc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            {!clientId && <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'id', true)}></HeaderFilter>}
          </Column>
          <Column
            dataField="ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: commessa = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/projects/${data.id}`}>{data.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ded_Dis}</>
              )
            }}
            visible={true}
            caption="CODICE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'ded_Dis', true)}>
                <Search enabled={true} searchExpr={'ded_Dis'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="sede.azienda.nome"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: commessa = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption="AZIENDA"
            visible={clientId === undefined}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
                <Search enabled={true} searchExpr={'nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="sede.nome"
            caption="SEDE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          />
          <Column
            dataField="sede.azienda.agente.commerciale_qs.fullname"
            caption="AGENTE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
                <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          <Column dataField={'sede.azienda.rivenditore.nome'} caption={'RIVENDITORE'} visible={false}>
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'Azienda', 'rivenditore.nome')}>
                <Search enabled={true} searchExpr={'rivenditore.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="titolo"
            visible={true}
            caption="TITOLO"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          ></Column>
          <Column
            dataField="autore.fullname"
            caption="AUTORE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'autore.fullname')}>
                <Search enabled={true} searchExpr={'autore.fullname'} />
              </HeaderFilter>
            )}
          </Column>
          {DateColumn({
            dataField: 'creazione',
            caption: 'CREAZIONE',
            format: 'dd/MM/yyyy',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined,
          })}
          {DateColumn({
            dataField: 'inizio',
            caption: 'DATA INIZIO',
            format: 'dd/MM/yyyy',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined,
          })}
          {DateColumn({
            dataField: 'fine',
            caption: 'DATA FINE',
            format: 'dd/MM/yyyy',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined,
          })}
          <Column
            dataField="stato.nome"
            caption="STATO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            {!clientId && (
              <HeaderFilter dataSource={columnSourceFactory(token, 'commessa', 'stato.nome')}>
                <Search enabled={true} searchExpr={'stato.nome'} />
              </HeaderFilter>
            )}
          </Column>
          <Column
            dataField="note"
            visible={false}
            showInColumnChooser={false}
            caption="NOTE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          />
          <Toolbar>
            <Item
              location="before"
              locateInMenu="never"
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Refresh',
                text: 'Refresh',
                icon: 'refresh',
                onClick: (e: ClickEvent) => {
                  gridRef.current?.instance().refresh()
                },
                stylingMode: 'text',
              }}
            ></Item>
            <Item name="groupPanel" />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova commessa',
                text: 'Nuova commessa',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina commessa',
                text: 'Elimina commessa',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default ProjectsPage
