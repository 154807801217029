import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Form as DxForm, RangeSlider } from 'devextreme-react'
import { Tooltip } from 'devextreme-react/range-slider'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { FormRef as DXFormRef, GroupItem, SimpleItem } from 'devextreme-react/form'
import { formatHHMMAsMin, formatMinAsHHMM } from '@/routes/admin/users/user/forms/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import UserStyles from '@/routes/admin/users/user/User.module.scss'
import { HashLoader } from 'react-spinners'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { ChildFormHandle } from '@/routes/admin/users/user/forms/types'
import { info_impiegato } from '@/model/qsadminapi/QsAdminApiModuleModel'

export type DetailedUserInfoProps = {
  infoId: number
}

export const DetailedUserInfo = forwardRef<ChildFormHandle, DetailedUserInfoProps>((props, ref) => {
  const qsadminapiService = useQsAdminApiManager()
  const dxFormRef = useRef<DXFormRef>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const timeSliderOptions = {
    min: 420,
    max: 1200,
  }
  const timeSliderLabelFormatter = (value: number) =>
    value === timeSliderOptions.min ? 'Mattina Inizio' : value === timeSliderOptions.max ? 'Pomeriggio Inizio' : ''

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef.current?.requestSubmit()
    },
  }))

  const fetcher = useQuery({
    queryKey: ['detailedUserInfo', props.infoId],
    queryFn: () => {
      return qsadminapiService.service.info_impiegato(props.infoId).query()
    },
  })
  const patcher = useMutation({
    mutationFn: (formData: info_impiegato) => {
      console.log('sending patch for user info')
      return qsadminapiService.service.info_impiegato(props.infoId).patch(formData)
    },
  })

  if (fetcher.isLoading)
    return (
      <div className={UserStyles.loaderWrapper}>
        <HashLoader loading={fetcher.isLoading} size={100} color={'#ff5722'} />
      </div>
    )
  if (fetcher.error) return <RouterErrorHandler />

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault()
        if (fetcher.data?.data) patcher.mutate(fetcher.data?.data)
      }}
    >
      <DxForm formData={fetcher.data?.data}>
        <GroupItem caption="Dati Aziendali Utente" colCount={4}>
          <GroupItem colSpan={4} colCount={5}>
            <SimpleItem
              dataField={'id'}
              colSpan={1}
              label={{
                text: 'ID',
              }}
              editorOptions={{
                disabled: true,
              }}
            />
            <SimpleItem
              colSpan={2}
              dataField={'interno'}
              editorType={'dxNumberBox'}
              editorOptions={{
                min: 0,
                max: 9999,
                showSpinButtons: true,
              }}
              label={{
                text: 'Interno',
              }}
            />
            <SimpleItem
              colSpan={2}
              dataField={'inps'}
              label={{
                text: 'INPS',
              }}
            />
          </GroupItem>
          <GroupItem colSpan={4} colCount={2}>
            <SimpleItem
              dataField={'email_rec'}
              label={{
                text: 'Email REC',
              }}
            />
            <SimpleItem
              dataField={'cellulare'}
              label={{
                text: 'Cellulare',
              }}
            />
          </GroupItem>

          <GroupItem colSpan={4} colCount={2}>
            <SimpleItem
              dataField={'matricola'}
              label={{
                text: 'Matricola',
              }}
            />
            <SimpleItem
              dataField={'divisione'}
              label={{
                text: 'Divisione',
              }}
            />
          </GroupItem>
          <GroupItem colSpan={4} colCount={4}>
            <SimpleItem
              colSpan={3}
              render={() => {
                return (
                  <RangeSlider
                    min={timeSliderOptions.min}
                    max={timeSliderOptions.max}
                    isValid={false}
                    defaultValue={[
                      formatHHMMAsMin(fetcher.data?.data?.entrata_mattina, timeSliderOptions.min),
                      formatHHMMAsMin(fetcher.data?.data?.entrata_pomerig, timeSliderOptions.max),
                    ]}
                    step={15}
                    showRange={false}
                    label={{
                      format: timeSliderLabelFormatter,
                      visible: true,
                      position: 'bottom',
                    }}
                    valueChangeMode={'onHandleMove'}
                    onValueChanged={(val) => {
                      fetcher.data!.data.entrata_mattina = formatMinAsHHMM(val.value[0])
                      fetcher.data!.data.entrata_pomerig = formatMinAsHHMM(val.value[1])
                      dxFormRef.current!.instance().updateData({
                        entrata_mattina: formatMinAsHHMM(val.value[0]),
                        entrata_pomerig: formatMinAsHHMM(val.value[1]),
                      })
                    }}
                  >
                    <Tooltip enabled={true} format={formatMinAsHHMM} showMode="always" position="top" />
                  </RangeSlider>
                )
              }}
            />
            <SimpleItem
              colSpan={1}
              dataField={'ore'}
              editorType={'dxNumberBox'}
              editorOptions={{
                min: 0,
                max: 12,
                step: 1,
                showSpinButtons: true,
              }}
              label={{
                text: 'Ore Lavorative',
              }}
            />
          </GroupItem>
        </GroupItem>
      </DxForm>
    </form>
  )
})

DetailedUserInfo.displayName = 'DetailedUserInfo'
