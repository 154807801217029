import ContactsPage from './ContactsPage'
import { RoleRoute, RoleRouteObject } from '@/types'
import { PersonGridRoute } from '@/routes/contacts/person/PersonGrid.route'
import { EntityGridRoute } from '@/routes/contacts/entity/EntityGrid.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { redirect } from 'react-router-dom'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'

export const contactsSubRoutes: RoleRoute[] = [
  {
    index: true,
    loader: () => redirect('people'),
    visible: false,
    allowedRoles: [Roles.Guest],
  } as RoleRouteObject,
  PersonGridRoute,
  EntityGridRoute,
]

const ContactsPageRoute: RoleRoute = {
  path: 'contacts',
  element: <ContactsPage />,
  errorElement: <RouterErrorHandler />,
  children: contactsSubRoutes,
  mainMenuRoute: true,
  text: 'Contatti',
  faIcon: faAddressBook,
  handle: {
    crumb: () => ({
      label: 'Contatti',
      icon: faAddressBook,
    }),
  },
  allowedRoles: [Roles.Guest],
}

export default ContactsPageRoute
