import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useTokenRefresh, AzureUserInfo, getAzureUserInformation } from '@/auth/azure/azureManager'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import {
  AsyncRule,
  Column,
  DataGrid,
  DataGridRef,
  Editing,
  Item,
  Lookup,
  NumericRule,
  Paging,
  RequiredRule,
  StateStoring,
  StringLengthRule,
  Toolbar,
} from 'devextreme-react/data-grid'
import type { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import { AutodeskCsnGridProps } from '@/routes/clients/client/ClientEditor.types'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import notify from 'devextreme/ui/notify'
import { ValidationCallbackData } from 'devextreme/common'
import '../client/ClientEditor.csns.scss'
import { ad_csn, contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { useEffect, useRef, useState } from 'react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import MoveCsnToSelector from '@/routes/clients/MoveCsnToSelector'
import { isUserRoleAllowed, onDataGridSelectedRowButtonClick } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'

const AutodeskCsnGrid = (props: AutodeskCsnGridProps) => {
  const { azienda, readOnly, contatti } = props
  const token = useTokenRefresh()
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const moveCsnToPopupRef = useRef<PopupRef>(null)
  const [userInfo, setUserInfo] = useState<AzureUserInfo | undefined>()

  const aziendaCsnDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/ad_csn`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    filter: [['azienda.id', azienda && 'id' in azienda ? azienda?.id : null]],
    expand: ['azienda', 'admin($expand=emails)', 'quoteContact($expand=emails)'],
  })

  useEffect(() => {
    getAzureUserInformation().then((userInfo: AzureUserInfo | undefined) => {
      setUserInfo(userInfo)
    })
  }, [])

  return (
    <>
      <DataGrid
        id={`client-csn-grid-${azienda && 'id' in azienda ? azienda?.id : null}`}
        dataSource={aziendaCsnDataSource}
        ref={gridRef}
        noDataText="Nessun csn associato al cliente"
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onSaving={(e: SavingEvent) => {
          console.log('SAVINGGG', e)
        }}
        onInitNewRow={(e: InitNewRowEvent) => {
          e.data.azienda = azienda
        }}
      >
        <Paging defaultPageSize={8} />
        <Editing
          mode="batch"
          allowAdding={!readOnly}
          allowDeleting={!readOnly}
          allowUpdating={!readOnly}
          selectTextOnEditStart={true}
          startEditAction={'click'}
        />
        <StateStoring enabled={true} type="localStorage" storageKey={`client-csn-grid`} savingTimeout={50} />
        <Column dataField={'id'} caption={'ID'} visible={false} />,
        <Column dataField={'csn'} caption={'CSN'} visible={true} editorOptions={{ maxLength: 12 }}>
          <RequiredRule message="Il csn è obbligatorio" />
          <NumericRule message="Il csn deve contenere solo caratteri numerici" />
          <StringLengthRule
            message="Il csn deve essere composto da 10 caratteri"
            max={10}
            min={10}
            ignoreEmptyValue={true}
          />
          <AsyncRule
            message="Il csn inserito è duplicato"
            ignoreEmptyValue={true}
            validationCallback={async (options: ValidationCallbackData): Promise<boolean> => {
              const clients = await useQsAdminApiManager
                .getState()
                .service.ad_csn()
                .query((builder, clientCsn) => {
                  builder
                    .select('id', 'csn', 'azienda')
                    .expand('azienda')
                    .filter(clientCsn.csn.eq(options.value as string))
                    .filter(azienda && 'id' in azienda ? clientCsn.azienda.props.id.ne(azienda.id) : null)
                  builder.count()
                })
              return options.value === undefined || options.value === '' || clients.data['@odata.count'] === 0
            }}
          />
        </Column>
        <Column dataField={'azienda.nome'} caption={'AZIENDA'} visible={false} editorOptions={{ readOnly: true }} />
        <Column
          dataField={'admin.id'}
          caption={'ADMIN'}
          cellRender={(cell: any) => {
            console.log(cell)
            const contatto: contatto_aziendale = cell.column.lookup.items.find((item: any) => item.id === cell.value)
            console.log('contatto', contatto)
            return (
              <>
                <div className="name-template">
                  <div>{contatto ? contatto.fullname : ''}</div>
                  <div className="dx-field-item-label-text email">
                    {contatto && contatto.emails ? contatto.emails?.[0]?.email?.trim() : ''}
                  </div>
                </div>
              </>
            )
          }}
        >
          <Lookup dataSource={contatti} displayExpr="fullname" valueExpr="id" allowClearing={true} />
        </Column>
        <Column
          dataField={'quoteContact.id'}
          caption={'QUOTE CONTACT'}
          cellRender={(cell: any) => {
            console.log(cell)
            const contatto: contatto_aziendale = cell.column.lookup.items.find((item: any) => item.id === cell.value)
            console.log('contatto', contatto)
            return (
              <>
                <div className="name-template">
                  <div>{contatto ? contatto.fullname : ''}</div>
                  <div className="dx-field-item-label-text email">
                    {contatto && contatto.emails ? contatto.emails?.[0]?.email?.trim() : ''}
                  </div>
                </div>
              </>
            )
          }}
        >
          <Lookup dataSource={contatti} displayExpr="fullname" valueExpr="id" allowClearing={true} />
        </Column>
        <Column dataField={'note'} caption={'NOTE'} visible={true} />
        <Toolbar>
          <Item
            location="before"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Refresh',
              text: 'Refresh',
              icon: 'refresh',
              onClick: (e: ClickEvent) => {
                gridRef.current?.instance().refresh()
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="addRowButton"
            options={{
              hint: 'Nuovo csn',
              text: 'Nuovo csn',
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
            location="after"
            locateInMenu="never"
            showText="inMenu"
            widget="dxButton"
            options={{
              hint: 'Sposta csn',
              text: 'Sposta csn',
              icon: 'import',
              onClick: (e: ClickEvent) => {
                onDataGridSelectedRowButtonClick(gridRef, (rowdata: ad_csn) => {
                  console.log('rowkey', gridRef.current?.instance().option('focusedRowKey'))
                  moveCsnToPopupRef.current?.instance().show()
                })
              },
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="saveButton"
            options={{
              hint: 'Salva',
              text: 'Salva',
              stylingMode: 'text',
            }}
          ></Item>
          <Item
            name="revertButton"
            options={{
              hint: 'Annulla modifiche',
              text: 'Annulla modifiche',
              stylingMode: 'text',
            }}
          ></Item>
        </Toolbar>
      </DataGrid>
      <MoveCsnToSelector csnsGridRef={gridRef} popupRef={moveCsnToPopupRef} />
    </>
  )
}

export default AutodeskCsnGrid
