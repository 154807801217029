import { LoaderFunction } from 'react-router-dom'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import PackagesSaleGrid from '@/routes/packages/sale/PackagesSaleGrid'
import { PackageSaleCreatorRoute } from '@/routes/packages/sale/PackageSaleCreator.route'
import { PackageSaleEditorRoute } from '@/routes/packages/sale/PackageSaleEditor.route'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Roles } from '@/auth/azure/Roles'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faShop } from '@fortawesome/free-solid-svg-icons'

export const packagesSaleGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Pacchetti Venduti` : 'Pacchetti Venduti'

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })
  return {
    title,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  }
}) satisfies LoaderFunction

export const PackagesSaleGridRoute = {
  path: 'sale',
  children: [
    {
      index: true,
      loader: packagesSaleGridRouteLoader,
      element: <PackagesSaleGrid />,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PackageSaleCreatorRoute,
    PackageSaleEditorRoute,
  ],

  faIcon: faShop,
  text: 'Pacchetti Venduti',
  handle: {
    crumb: () => ({
      label: 'Vendita',
      icon: faShop,
    }),
  },
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
