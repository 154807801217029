import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { InvoicingStepsPageRoute } from '@/routes/invoicingsteps/InvoicingStepsPage.route'

export const BillingPointRoute: TileRoleRoute = {
  path: 'billingPoint',
  text: 'Punto di Fatturazione',
  faIcon: faFileInvoiceDollar,
  element: (
    <ManagementGridFactory
      entity={'stato_punto_fatturazione'}
      referencesCollection={'punti_fatturazione_ordine'}
      referencesTitle={'Punti di Fatturazione Ordine'}
      displayField={'ded_Dis'}
      entityRoute={InvoicingStepsPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Punto di Fatturazione',
      icon: faFileInvoiceDollar,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
