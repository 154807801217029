import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'

export const TicketEventRoute: TileRoleRoute = {
  path: 'ticketEvent',
  text: 'Evento Ticket',
  faIcon: faCalendarDay,
  element: (
    <ManagementGridFactory
      entity="tipologia_evento_ticket"
      referencesCollection="eventi_ticket"
      referencesTitle="Eventi Ticket"
      displayField="id"
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Evento Ticket',
      icon: faCalendarDay,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
