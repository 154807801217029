import { Drawer } from 'devextreme-react/drawer'
import { ScrollView, ScrollViewRef } from 'devextreme-react/scroll-view'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Footer, Header, SideNavigationMenu } from '@/components'
import './side-nav-outer-toolbar.scss'
import { useScreenSize } from '@/themes/media-query'
import { Template } from 'devextreme-react/core/template'
import { useMenuPatch } from '@/themes/patches'
import { type ButtonTypes } from 'devextreme-react/button'
import type { SideNavToolbarProps } from '@/types'

export default function SideNavOuterToolbar({ title, children }: React.PropsWithChildren<SideNavToolbarProps>) {
  const scrollViewRef = useRef<ScrollViewRef>(null)
  const navigate = useNavigate()
  const { isXSmall, isLarge } = useScreenSize()
  const [patchCssClass, onMenuReady] = useMenuPatch()
  const [menuStatus, setMenuStatus] = useState(isLarge ? MenuStatus.Opened : MenuStatus.Closed)
  const [itemMenuClicked, setItemMenuClicked] = useState(false)
  const currentPath = useLocation()
  const toggleMenu = useCallback(({ event }: ButtonTypes.ClickEvent) => {
    setMenuStatus((prevMenuStatus) => (prevMenuStatus === MenuStatus.Closed ? MenuStatus.Opened : MenuStatus.Closed))
    event?.stopPropagation()
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed ? MenuStatus.TemporaryOpened : prevMenuStatus,
    )
  }, [])

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge ? MenuStatus.Closed : prevMenuStatus,
    )
    return menuStatus === MenuStatus.Closed
  }, [isLarge, menuStatus])

  const onNavigationChanged = useCallback(() => {
    setItemMenuClicked(true)
  }, [])

  useEffect(() => {
    if ((!isLarge || menuStatus === MenuStatus.TemporaryOpened) && itemMenuClicked) {
      setTimeout(() => {
        setMenuStatus(MenuStatus.Closed)
        setItemMenuClicked(false)
      }, 1500)
    }
  }, [currentPath.pathname, isLarge, menuStatus, itemMenuClicked])

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header menuToggleEnabled toggleMenu={toggleMenu} title={title}></Header>
      <Drawer
        className={['dx-drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={!isLarge}
        opened={menuStatus !== MenuStatus.Closed}
        template={'menu'}
      >
        <div id="main-container" className={'container dx-theme-background-color'}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
            <div className={'content'}>
              {React.Children.map(children, (item: any) => {
                return item.type !== Footer && item
              })}
            </div>
            <div className={'content-block'}>
              {React.Children.map(children, (item: any) => {
                return item.type === Footer && item
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          ></SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  )
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
}
