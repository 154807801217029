import { useAzureManager } from '@/auth/azure/azureManager'
import { Role } from '@/auth/azure/Roles'
import { allAuthenticatedChildrenRoutes } from '@/routes/authenticated/AuthenticatedLayout.route'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Navigate, useLocation, matchRoutes } from 'react-router-dom'

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const { userInfo } = useAzureManager()
  const allMatchingRoutes = matchRoutes(allAuthenticatedChildrenRoutes, location)
  const currentRoute = allMatchingRoutes?.at(-1)?.route
  const hasRequiredRole = currentRoute?.allowedRoles?.some((role: Role) => userInfo?.roles?.hasRole(role))

  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{hasRequiredRole ? children : <Navigate to="/access-denied" />}</AuthenticatedTemplate>
    </>
  )
}
