import { Accordion, AccordionTypes } from 'devextreme-react/accordion'
import { TileSectionHeader } from '@/routes/admin/components/TileSectionHeader/TileSectionHeader'
import { TileSection } from '@/routes/admin/components/TileSection/TileSection'
import { AdministrationRoute } from '@/routes/admin/Administration.route'
import { TileRoleRoute } from '@/types'
import { useCallback } from 'react'

const STORAGE_KEY = 'accordion-selected-paths'

export const AdministrationDashboard = () => {
  const tileSections = AdministrationRoute.children?.filter((item: TileRoleRoute) => !item.index && item.tile)

  const selectionChanged = useCallback((e: AccordionTypes.SelectionChangedEvent) => {
    const currentSelected = e.component.option('selectedItems')
    const selectedPaths = currentSelected
      ?.filter(Boolean)
      .map((item: TileRoleRoute) => item.path)
      .filter(Boolean)

    if (selectedPaths!.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(selectedPaths))
    }
  }, [])

  const savedPaths = localStorage.getItem(STORAGE_KEY)
  const initialSelectedItems = savedPaths
    ? tileSections?.filter((section) => JSON.parse(savedPaths).includes(section.path))
    : []

  return (
    <div className="content-block">
      <Accordion
        items={tileSections}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        itemTitleRender={TileSectionHeader}
        itemRender={TileSection}
        onSelectionChanged={selectionChanged}
        defaultSelectedItems={initialSelectedItems}
      />
    </div>
  )
}
