import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'

export const MaintenancePlanRoute: TileRoleRoute = {
  path: 'maintenancePlan',
  text: 'Piano di Manutenzione',
  faIcon: faWrench,
  element: (
    <ManagementGridFactory
      entity={'est_piano_mantenimento'}
      referencesCollection={'licenze_est'}
      referencesTitle={'Licenze'}
      displayField={'id'}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Piano di Manutenzione',
      icon: faWrench,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
