import React from 'react'
import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'

export const ProblemRoute: TileRoleRoute = {
  path: 'problem',
  text: 'Problema',
  faIcon: faTriangleExclamation,
  element: (
    <ManagementGridFactory
      entity="tipologia_problema"
      referencesCollection="tickets"
      referencesTitle="Problemi"
      displayField="ded_Dis"
      entityRoute={TicketsPageRoute.path}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Problema',
      icon: faTriangleExclamation,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
