export enum StatoTicket {
  'APERTO',
  'ASSEGNATO',
  'COMPLETATO',
  'ELIMINATO',
}

export enum TipologiaTicket {
  'INFO_COMMERCIALE',
  'INFO_TECNICA',
  'ASSISTENZA_TECNICA',
}
