import { Column, DataGrid, Paging, StateStoring } from 'devextreme-react/data-grid'
import { contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'

const PersonHistory = (props: { contact: contatto_aziendale | undefined }) => {
  const { contact } = props
  const currentScreenSize = useScreenSize()
  const token = useTokenRefresh()
  const tagFilter = contact?.storico_spostamenti_nuovo?.[0]?.tag ?? contact?.storico_spostamenti_vecchio?.[0]?.tag
  const contactHistoryDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/storico_contatto_aziendale`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: ['tag', `${tagFilter}`],
    expand: ['nuovo($expand=sede($expand=azienda))', 'vecchio($expand=sede($expand=azienda))'],
  }
  console.log('contact history', contact)
  return (
    <>
      <DataGrid
        id={`person-history-grid-${contact && 'id' in contact ? contact?.id : null}`}
        dataSource={contactHistoryDataSource}
        noDataText="Nessuno storico legato alla persona"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
      >
        <StateStoring enabled={true} type="localStorage" storageKey={'person-history-datagrid'} savingTimeout={50} />
        <Paging defaultPageSize={8} />
        {DateColumn({
          dataField: 'data',
          caption: 'DATA',
          format: 'dd/MM/yyyy',
          width: 'auto',
        })}
        <Column dataField={'vecchio.sede.azienda.nome'} caption={'DA'} allowSorting={false} />
        <Column dataField={'vecchio.sede.nome'} caption={''} allowSorting={false} />
        <Column dataField={'nuovo.sede.azienda.nome'} caption={'A'} allowSorting={false} />
        <Column dataField={'nuovo.sede.nome'} caption={''} allowSorting={false} />
      </DataGrid>
    </>
  )
}

export default PersonHistory
