import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import EntityCreatorRoute from '@/routes/contacts/entity/EntityCreator.rotue'
import { EntityGrid } from '@/routes/contacts/entity/EntityGrid'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'

import { defer, LoaderFunction } from 'react-router-typesafe'
import { faRobot } from '@fortawesome/free-solid-svg-icons'

export const entityGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const clientId = params.clientId
  const qsAdminApi = useQsAdminApiManager.getState().service

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Contatti Entità` : 'Contatti Entità'

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    title,
    sites: await getSites,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const EntityGridRoute = {
  path: 'entity',
  children: [
    {
      index: true,
      element: <EntityGrid />,
      loader: entityGridRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    EntityCreatorRoute,
  ],
  faIcon: faRobot,
  text: 'Entità',
  handle: {
    crumb: () => ({
      label: 'Entità',
      icon: faRobot,
    }),
  },
  allowedRoles: [Roles.Guest],
}
