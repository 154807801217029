import { Await, useLoaderData } from 'react-router-typesafe'
import { Form as DXForm, FormRef, GroupItem, SimpleItem } from 'devextreme-react/form'
import { Suspense, useCallback, useRef, useState } from 'react'
import DataGrid, {
  CustomRule,
  DataGridRef,
  RequiredRule,
  Column,
  Editing,
  Scrolling,
  StateStoring,
  Pager,
} from 'devextreme-react/data-grid'
import { ArraySourceComposer } from '@/auth/api/connector'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useNavigate, useParams } from 'react-router-dom'
import DocumentHead from '@/components/document-head/DocumentHead'
import { clientMigrationRouteLoader, NuovaSede } from '@/routes/clients/ClientMigration.route'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import notify from 'devextreme/ui/notify'
import { AxiosResponse } from 'axios'
import { Button, Toolbar } from 'devextreme-react'
import { Item as ToolbarItem } from 'devextreme-react/toolbar'
import { ClickEvent } from 'devextreme/ui/button'
import { useScreenSize } from '@/themes/media-query'
import { gridTotalItemsLabel } from '@/constants'

const ClientMigration = () => {
  const { title, getAziende, migrationData } = useLoaderData<typeof clientMigrationRouteLoader>()
  const { clientId } = useParams()
  const [loadPanelVisible, setLoadPanleVisible] = useState<boolean>(false)
  const { service, client } = useQsAdminApiManager()
  const [sediList, setSediList] = useState<NuovaSede[] | undefined>(migrationData.sedi)
  const navigate = useNavigate()
  const currentScreenSize = useScreenSize()

  const formRef = useRef<FormRef>(null)
  const gridRef = useRef<DataGridRef>(null)

  const getCompanySites = useCallback(
    async (companyId: number) => {
      console.log('getCompanySItes')
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.select('id', 'nome')
      })
      console.log(sitesData.data.value)
      return sitesData.data.value
    },
    [service],
  )

  return (
    <>
      <DocumentHead title={title}></DocumentHead>
      <Suspense fallback={<LoadingPanel />}>
        <Await resolve={getAziende}>
          {(aziende) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="clients-migration-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <LoadingPanel visible={loadPanelVisible}></LoadingPanel>
                <DXForm
                  id={'clients_migration_form'}
                  formData={migrationData}
                  ref={formRef}
                  onFieldDataChanged={(e: FieldDataChangedEvent) => {
                    console.log('formData', migrationData)
                  }}
                  showValidationSummary={true}
                >
                  <GroupItem colCount={1} colSpan={1}>
                    <SimpleItem
                      dataField="azienda_da_migrare"
                      editorType="dxLookup"
                      label={{ text: 'Azienda da migrare' }}
                      editorOptions={{
                        dataSource: ArraySourceComposer('id', aziende.data.value),
                        displayExpr: 'nome',
                        placeholder: `Seleziona l'azienda da migrare...`,
                        showClearButton: true,
                        searchEnabled: true,
                        dropDownOptions: {
                          showTitle: true,
                          title: 'Selezionare azienda da migrare',
                          hideOnOutsideClick: true,
                        },
                        readOnly: clientId,
                        onValueChanged: (e: ValueChangedEvent) => {
                          console.log(e)
                          if (e.previousValue?.id === e.value?.id) return
                          console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                          if (e.value)
                            getCompanySites(e.value.id)
                              .then((sedi: sede[]) => {
                                const newSites: NuovaSede[] = []
                                sedi.forEach((sede: sede) => {
                                  const newSite: NuovaSede = {
                                    id: sede.id,
                                    principale: null,
                                    nome: sede.nome,
                                    note: null,
                                    email: null,
                                    email_pec: null,
                                    telefono: null,
                                    fax: null,
                                    leg_srvpath: null,
                                    leg_email_rin_ad: null,
                                    leg_rif_ad: null,
                                    strada: null,
                                    citta: null,
                                    provincia: null,
                                    cap: null,
                                    stato: null,
                                    archived: null,
                                    nuovo_nome: `${sede.nome} nuova`,
                                  }
                                  newSites.push(newSite)
                                })
                                setSediList(newSites)
                              })
                              .catch((error) => {
                                console.log('error', error)
                              })
                        },
                      }}
                    >
                      <RequiredRule message="Azienda da migrare obbligatoria" />
                    </SimpleItem>
                    {/* <SimpleItem
                      dataField="sedi_da_migrare"
                      editorType="dxTagBox"
                      label={{ text: 'Sedi da migrare' }}
                      editorOptions={{
                        dataSource: ArraySourceComposer('id', sediList),
                        displayExpr: 'nome',
                        placeholder: 'Seleziona sedi...',
                        applyValueMode: 'instantly',
                        showSelectionControls: true,
                        onValueChanged: (e: ValueChangedEventTagBox) => {},
                        readOnly: true,
                      }}
                    >
                      <RequiredRule message="Almeno una sede obbligatoria" />
                    </SimpleItem> */}
                    {/* {migrationData.sedi_da_migrare?.map((sede, index: number) => (
                      <SimpleItem
                        key={`nuovo_nome_sede_da_migrare${index}`}
                        dataField={`sedi_destinazione[${index}].nome`}
                        label={{ text: `Nuovo nome sede ${sede.nome}` }}
                        editorType="dxTextBox"
                      >
                        <RequiredRule message="Nome obbligatorio"></RequiredRule>
                      </SimpleItem>
                    ))} */}
                    <SimpleItem
                      dataField="azienda_destinazione"
                      editorType="dxLookup"
                      label={{ text: 'Azienda destinazione' }}
                      editorOptions={{
                        dataSource: ArraySourceComposer('id', aziende.data.value),
                        displayExpr: 'nome',
                        placeholder: `Seleziona l'azienda destinazione...`,
                        showClearButton: true,
                        searchEnabled: true,
                        dropDownOptions: {
                          showTitle: true,
                          title: 'Selezionare azienda destinazione',
                          hideOnOutsideClick: true,
                        },
                      }}
                    >
                      <RequiredRule message="Azienda destinazione obbligatoria" />
                      <CustomRule
                        message="Azienda destinazione diversa da azienda da migrare"
                        reevaluate={true}
                        validationCallback={() => {
                          if (migrationData.azienda_da_migrare && migrationData.azienda_destinazione)
                            return migrationData.azienda_da_migrare?.id === migrationData.azienda_destinazione?.id
                              ? false
                              : true
                          else return true
                        }}
                      ></CustomRule>
                    </SimpleItem>
                  </GroupItem>
                  {/* <GroupItem cssClass="last-group">
                    <GroupItem cssClass="buttons-group" colCount={1}>
                      <ButtonItem name="btn-save">
                        <ButtonOptions
                          icon="import"
                          text="Migra"
                          width="150px"
                          onClick={async () => {
                            const validationResult = formRef.current?.instance().validate()
                            if (!validationResult?.isValid) return
                            setLoadPanleVisible(true)
                            try {
                              const response: AxiosResponse<any, any> = await client.put(
                                '/api/azienda/migrate',
                                {
                                  migrationData,
                                },
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                },
                              )
                              navigate(`/clients/${migrationData.azienda_destinazione?.id}/sites`)
                            } catch (error) {
                              notify(`Errore migrazione sedi. Errore:  ${error}`, 'error', 2000)
                              setLoadPanleVisible(false)
                            }
                          }}
                        />
                      </ButtonItem>
                    </GroupItem>
                  </GroupItem> */}
                </DXForm>
                <DataGrid
                  id={'site-migrate-datagrid'}
                  height={'auto'}
                  dataSource={sediList}
                  noDataText="Nessuna sede da migrare"
                  className={'dx-card wide-card'}
                  showBorders={false}
                  showColumnLines={true}
                  wordWrapEnabled={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  allowColumnResizing={currentScreenSize.isLarge || currentScreenSize.isMedium}
                  allowColumnReordering={currentScreenSize.isLarge || currentScreenSize.isMedium}
                  width="100%"
                  ref={gridRef}
                  columnHidingEnabled={
                    currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                  }
                >
                  <Scrolling mode="virtual" />
                  <Pager
                    visible={true}
                    allowedPageSizes={'auto'}
                    displayMode={'compact'}
                    showPageSizeSelector={false}
                    showInfo={true}
                    showNavigationButtons={false}
                    infoText={gridTotalItemsLabel}
                  />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'site-migrate-datagrid'}
                    savingTimeout={50}
                  />
                  <Editing
                    mode="cell"
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}
                    selectTextOnEditStart={true}
                    startEditAction={'click'}
                  />
                  <Column allowEditing={false} dataField={'nome'} caption="NOME SEDE" dataType="string"></Column>
                  <Column dataField={'nuovo_nome'} caption="NUOVO NOME SEDE" dataType="string" allowEditing={true}>
                    <RequiredRule message="Nuovo nome sede obbligatorio"></RequiredRule>
                  </Column>
                  {/* <Column
                    allowEditing={false}
                    dataField={'user.email'}
                    caption="EMAIL"
                    dataType="string"
                    hidingPriority={
                      currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                        ? 2
                        : undefined
                    }
                  ></Column>
                  <Column
                    dataField={'totals.TotalMealVoucher'}
                    caption="TOTALE BUONI PASTO"
                    dataType="number"
                    hidingPriority={
                      currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium
                        ? 1
                        : undefined
                    }
                  >
                    <CustomRule
                      validationCallback={(options: {
                        column: Record<string, any>
                        data: Record<string, any>
                        formItem: Record<string, any>
                        rule: Record<string, any>
                        validator: Record<string, any>
                        value: string | number
                      }) => {
                        return typeof options.value === 'number' ? options.value >= 0 : false
                      }}
                      message="Totale buoni pasto deve essere >= 0"
                    ></CustomRule>
                  </Column> */}
                </DataGrid>
                <Toolbar className="last-group">
                  <ToolbarItem location="after" locateInMenu="never">
                    <Button
                      text="Migra"
                      icon="import"
                      useSubmitBehavior={false}
                      width={'150px'}
                      onClick={async (e: ClickEvent) => {
                        const validationResult = formRef.current?.instance().validate()
                        if (!validationResult?.isValid) return
                        setLoadPanleVisible(true)
                        try {
                          const response: AxiosResponse<any, any> = await client.put(
                            '/api/azienda/migrate',
                            {
                              azienda_da_migrare: migrationData.azienda_da_migrare,
                              azienda_destinazione: migrationData.azienda_destinazione,
                              sedi: gridRef.current?.instance().getDataSource().items(),
                            },
                            {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            },
                          )
                          navigate(`/clients/${migrationData.azienda_destinazione?.id}/sites`)
                        } catch (error) {
                          notify(`Errore migrazione sedi. Errore:  ${error}`, 'error', 2000)
                          setLoadPanleVisible(false)
                        }
                      }}
                    />
                  </ToolbarItem>
                </Toolbar>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default ClientMigration
