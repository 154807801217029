import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { punto_fatturazione_ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import InvoicingStepEditor from '@/routes/invoicingsteps/InvoicingStepEditor'
import { StatoPuntoFatturazione } from '@/routes/invoicingsteps/invoicingstep.types'
import { ODataClientError } from '@odata2ts/http-client-api'

export const invoicingStepEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'invoicingStepId'>) => {
  const invoicingStepId = params.invoicingStepId
  let title: string

  const qsAdminApi = useQsAdminApiManager.getState().service

  let invoicingStep: punto_fatturazione_ordine

  if (invoicingStepId) {
    //editor
    const getInvoicingStep = await qsAdminApi
      .punto_fatturazione_ordine(Number(invoicingStepId))
      .query((builder, puntoFatturazione) => {
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('ordine', (ordineBuilder, ordine) => {
          ordineBuilder.select('id', 'ded_Dis')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Punto di fatturazione non trovato')
        throw new Error()
      })
    invoicingStep = getInvoicingStep.data satisfies punto_fatturazione_ordine
    title = `${invoicingStep.ded_Dis}`
  } else {
    //creator
    const statoDefault = await qsAdminApi
      .stato_punto_fatturazione(StatoPuntoFatturazione['DA FATTURARE'])
      .query((builder, stato) => {
        builder.select('id', 'nome')
      })
    invoicingStep = {
      id: 0,
      creazione: new Date().toISOString(),
      ded_RootFam: '',
      ded_Dis: '',
      note: null,
      stato: statoDefault.data,
      progressivo_ordine: 0,
    }
    title = `Nuovo punto di fatturazione`
  }

  const getStati = qsAdminApi.stato_punto_fatturazione().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getOrdini = qsAdminApi.ordine().query((builder, ordine) => {
    builder.filter(invoicingStep.ordine ? ordine.id.eq(invoicingStep.ordine.id) : null)
    builder.select('id', 'ded_Dis')
    builder.orderBy(ordine.ded_Dis.desc())
  })

  return defer({
    title,
    invoicingStep,
    getStati,
    getOrdini,
    getUserInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const invoicingStepEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const invoicingStep = (await request.json()) as punto_fatturazione_ordine
  console.log('invoicingStep', invoicingStep)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.punto_fatturazione_ordine().create(invoicingStep)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.punto_fatturazione_ordine(invoicingStep.id).update(invoicingStep)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const InvoicingStepEditorRoute = {
  path: ':invoicingStepId',
  element: <InvoicingStepEditor creating={false} />,
  loader: invoicingStepEditorRouteLoader,
  action: invoicingStepEditorRouteAction,
  allowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
} as RoleRouteObject

export default InvoicingStepEditorRoute
