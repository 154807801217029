// MSAL React
import * as msal from '@azure/msal-browser'
import { Providers, ProviderState, SimpleProvider } from '@microsoft/mgt-element'
import './polyfills'
import { createRoot } from 'react-dom/client'
import './index.css'
import './themes/generated/variables.css'
import { ComponentPreviews, useInitial } from './dev'
import { DevSupport } from '@react-buddy/ide-toolbox'
import azureConfig, { loginRequest } from '@/auth/azure/azureConfig'
import { getRouter } from '@/routes'
import { RouterProvider } from 'react-router-dom'
import { useAzureManager } from '@/auth/azure/azureManager'
import config from 'devextreme/core/config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

// This exports the whole icon packs for Brand and Solid.
library.add(fab, fas)

config({
  licenseKey: import.meta.env.VITE_DEVEXTREME_LICENSE_KEY,
  defaultCurrency: 'EUR',
})

// Create the MSAL application with standard configuration
msal.createStandardPublicClientApplication(azureConfig).then(async (msalInstance) => {
  // Handle redirect response if any - this must be done before any other MSAL operations
  // This ensures that the response is properly handled from a redirect
  await msalInstance
    .handleRedirectPromise()
    .catch((error) => {
      console.error('Error handling redirect:', error)
    })
    .then(async (response) => {
      // Enable account storage events for SSO
      msalInstance.enableAccountStorageEvents()

      // Get accounts but don't modify them
      const accounts = msalInstance.getAllAccounts()
      console.log('Initial accounts:', accounts)

      // Set the active account if we have accounts and no active account
      if (accounts.length > 0 && !msalInstance.getActiveAccount()) {
        msalInstance.setActiveAccount(accounts[0])
      }

      // Set up the Azure manager with the MSAL instance
      useAzureManager.setState({
        pca: msalInstance,
      })

      const router = await getRouter()

      // Create a simple provider for the Microsoft Graph Toolkit
      const authProvider = new SimpleProvider(
        async (scopes) => {
          // For Microsoft Graph API calls, use the correct scopes
          const graphScopes =
            Array.isArray(scopes) && scopes.length > 0 ? scopes : ['https://graph.microsoft.com/User.Read']
          return await useAzureManager.getState().getToken(graphScopes)
        },
        () =>
          msalInstance.loginRedirect(loginRequest).then(() => {
            Providers.globalProvider.setState(ProviderState.SignedIn)
          }),
        () => msalInstance.logout(),
      )

      // Set the global provider for Microsoft Graph Toolkit
      Providers.globalProvider = authProvider

      // Update the Azure manager with the router
      useAzureManager.setState({ router })

      await useAzureManager.getState().getToken()

      // Initialize React
      const container = document.querySelector('#root') as HTMLElement
      const root = createRoot(container)
      root.render(
        <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
          <RouterProvider router={router} />
        </DevSupport>,
      )
    })
})
