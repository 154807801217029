import { HashLoader } from 'react-spinners'

export const GlobalLoadingComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      {/*make the color relative to the scss*/}
      <HashLoader size={'200px'} loading={true} className={'spinner'} color={'#ff5722'} />
    </div>
  )
}
