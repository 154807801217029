import { ScrollViewNoteProps as NoteViewProps } from '@/routes/tickets/TicketEditor.types'
import { Providers } from '@microsoft/mgt-react'
import { GraphError } from '@microsoft/microsoft-graph-client'

import { Button, HtmlEditor } from 'devextreme-react'
import { useEffect, useState } from 'react'

const NoteView = (props: NoteViewProps) => {
  const { note, readOnly, visibleEditClick, onEditClick } = props
  const data: Date = new Date(note.data ?? '')
  const [existAttachments, setExistAttachments] = useState<boolean>(false)

  const displayName =
    note.emails && note.emails.length > 0
      ? note.ticket?.leg_contatto
      : (note.utente?.fullname ?? note.ticket?.leg_contatto)

  const getEmailStatus = () => {
    if (!note.inviata) return { className: '', hint: '' }
    return note.emails?.length === 0
      ? { className: 'buttonEmailSend', hint: 'Nota inviata' }
      : { className: 'buttonEmailReceived', hint: 'Nota ricevuta' }
  }

  const { className: emailClassName, hint: emailHint } = getEmailStatus()

  useEffect(() => {
    console.log('nota', note.tag)
    const getFiles = async () => {
      return await Providers.globalProvider.graph
        .api(
          `drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${note.ticket?.attachmentsFolderUrl}/_notes/${note?.tag}/attachments:/children`,
        )
        .get()
    }
    getFiles()
      .then((value) => {
        console.log('getFiles', value)
        setExistAttachments(true)
      })
      .catch((error: GraphError) => {
        console.log('error', error)
      })
  }, [note.tag, note.ticket?.attachmentsFolderUrl])

  return (
    <>
      <div className="dx-theme-background-color message">
        <div className="name">{displayName}</div>
        <div className="date">
          {data.toLocaleDateString()} {data.toLocaleTimeString()}
        </div>
        <HtmlEditor value={note.testo} readOnly={true} />
        <div className="buttons">
          <Button
            visible={visibleEditClick ?? true}
            icon={readOnly ? 'eyeopen' : 'edit'}
            hint={readOnly ? 'Visualizza Nota' : 'Edita Nota'}
            stylingMode="text"
            onClick={onEditClick}
          ></Button>
          <Button
            className={emailClassName}
            stylingMode="text"
            icon="email"
            hint={emailHint}
            disabled={!note.inviata}
          ></Button>
          <Button
            className={existAttachments ? 'buttonAttachEnabled' : ''}
            stylingMode="text"
            icon="attach"
            hint="Allegati Nota"
            disabled={!existAttachments}
          ></Button>
          <Button
            className={note.chiusura ? 'buttonCompleteEnabled' : ''}
            stylingMode="text"
            icon="check"
            hint="Nota chiusura"
            disabled={!note.chiusura}
          ></Button>
        </div>
      </div>
      <br />
    </>
  )
}

export default NoteView
