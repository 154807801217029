import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Button } from 'devextreme-react'
import classes from './References.module.scss'
import { PulseLoader } from 'react-spinners'

export interface GenericReferencesProps {
  /**
   * The parent OData path. For example: "stato_analisi"
   */
  parentEntityPath: string

  /**
   * The name of the child collection property on the parent, e.g. "analisi" or "attivita_comm"
   * We'll expand it in the query string.
   */
  childCollectionName: string

  /**
   * The field in the child that we'll display, e.g. "ded_Dis".
   */
  childDisplayField: string

  /**
   * The id of the parent on which to filter children
   */
  parentId: number

  /**
   * Which fields to select in the child collection. By default, we fetch "id" and childDisplayField.
   */
  childSelectFields?: string[]

  /**
   * If we want a clickable link, we open /entityRoute/{childId}. Omit if no links.
   */
  entityRoute?: string

  /**
   * How many references to show before "Show more..."
   */
  maxCount?: number
}

export function References({
  parentEntityPath,
  childCollectionName,
  childDisplayField,
  childSelectFields,
  entityRoute,
  parentId,
  maxCount = 4,
}: GenericReferencesProps) {
  const { service, client } = useQsAdminApiManager()
  const [showAll, setShowAll] = useState(false)

  // If childSelectFields isn't specified, default to ['id', childDisplayField]
  const finalChildSelect = childSelectFields ?? ['id', childDisplayField]

  // Build ?$expand=childCollection($select=field1,field2)
  const expandParam = encodeURIComponent(`${childCollectionName}($select=${finalChildSelect.join(',')})`)
  const selectParam = encodeURIComponent(`${childCollectionName}`)
  const filterParam = encodeURIComponent(`id eq ${parentId}`)
  const baseUrl = `${service.getPath()}/${parentEntityPath}`
  const finalUrl = `${baseUrl}?$expand=${expandParam}&select=${selectParam}&filter=${filterParam}`

  // We expect data.value = array of parent objects. Each parent has parent[childCollectionName] = array of children.
  const { data, isLoading, error } = useQuery({
    queryKey: [parentEntityPath, childCollectionName, finalUrl, parentId],
    queryFn: async () => {
      const response = await client.get(finalUrl)
      // OData typically returns { value: [...] }
      return response.data.value
    },
  })

  if (isLoading) return <PulseLoader size={10} />
  if (error) return <>Error: {String(error)}</>

  // Flatten out the children
  const parents = Array.isArray(data) ? data : []
  const allChildren: any[] = []
  for (const p of parents) {
    const childArray = p?.[childCollectionName]
    if (Array.isArray(childArray)) {
      allChildren.push(...childArray)
    }
  }

  const displayed = showAll ? allChildren : allChildren.slice(0, maxCount)
  const remainingCount = Math.max(allChildren.length - maxCount, 0)

  const handleClick = (childId: number) => {
    if (!entityRoute) return
    window.open(`/${entityRoute}/${childId}`, '_blank')
  }

  return (
    <div className={classes.labelContainer}>
      {displayed.map((child) => {
        const label = String(child?.[childDisplayField] ?? '')
        return (
          <Button key={child?.id} onClick={() => handleClick(child?.id)} className={classes.label}>
            {label}
          </Button>
        )
      })}
      {!showAll && allChildren.length > maxCount && (
        <Button className={classes.toggleExpand} onClick={() => setShowAll(true)}>
          Show {remainingCount} more...
        </Button>
      )}
      {showAll && allChildren.length > maxCount && (
        <Button className={classes.toggleExpand} onClick={() => setShowAll(false)}>
          Show less
        </Button>
      )}
    </div>
  )
}
