import { useState } from 'react'
import styles from './Debugger.module.scss'
import { useAzureManager } from '@/auth/azure/azureManager'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

const Debugger = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const azState = useAzureManager()
  if (import.meta.env.MODE === 'production') {
    return null
  }
  return (
    <>
      <button
        className={styles.debuggerIcon}
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        aria-label="Toggle debugger panel"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83" />
        </svg>
      </button>
      {isPanelOpen && (
        <div className={styles.debuggerPanel}>
          <div className={styles.panelHeader}>
            <span>Debug Tools</span>
            <button
              className={styles.minimizeButton}
              onClick={() => setIsPanelOpen(false)}
              aria-label="Minimize debugger panel"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="16"
                height="16"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <div className={styles.panelContent}>
            <AuthenticatedTemplate>
              <p>User Info</p>
              <p>{JSON.stringify(azState.userInfo, null, 2)}</p>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <p>Unauthenticated</p>
            </UnauthenticatedTemplate>
          </div>
        </div>
      )}
    </>
  )
}

export default Debugger
