import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import TicketsPage from '@/routes/tickets/TicketsPage'
import TicketCreatorRoute from '@/routes/tickets/TicketCreator.route'
import TicketEditorRoute, {
  assignAction,
  cancelAction,
  clearWaitAction,
  incrementCallNumberAction,
  releaseAction,
  removeAssigneeAction,
  reopenAction,
  setUrgentAction,
  setWaitAction,
  takeChargeAction,
} from '@/routes/tickets/TicketEditor.route'
import { azienda, stato_ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import TicketsHistoricPageRoute from '@/routes/tickets/historic/TicketsHistoricPage.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import { Reparto } from '@/enums'

import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel } from '@odata2ts/http-client-api'
import { faTicket } from '@fortawesome/free-solid-svg-icons'

export const activeState: stato_ticket = {
  id: 99_999,
  nome: 'ATTIVO',
  note: null,
}

export const ticketsPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const clientId = params.clientId
  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })

  const title = azienda ? `${azienda.data.nome} - Ticket` : 'Ticket'

  const getStati = await qsAdminApi.stato_ticket().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const stati: stato_ticket[] = getStati.data.value
  stati.push(activeState)

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.reparto.props.id.eq(Reparto.SVILUPPO).or(impiegato.reparto.props.id.eq(Reparto.TECNICO)))
    builder.select('id', 'fullname', 'attivo')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    title,
    stati,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const TicketsPageRoute: RoleRoute = {
  path: 'tickets',
  children: [
    {
      index: true,
      element: <TicketsPage />,
      loader: ticketsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    TicketCreatorRoute,
    TicketEditorRoute,
    TicketsHistoricPageRoute,
    {
      path: ':ticketId/cancel',
      action: cancelAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/seturgent',
      action: setUrgentAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Administrator, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/setwait',
      action: setWaitAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/clearwait',
      action: clearWaitAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/incrementcallnumber',
      action: incrementCallNumberAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Administrator, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/removeassignee',
      action: removeAssigneeAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/takecharge',
      action: takeChargeAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/release',
      action: releaseAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/assign',
      action: assignAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
    {
      path: ':ticketId/reopen',
      action: reopenAction,
      allowedRoles: [Roles.GlobalAdministrator, Roles.TechDeveloper, Roles.Supervisor],
    } as RoleRouteObject,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Ticket',
  faIcon: faTicket,
  mainMenuRoute: true,
  handle: {
    crumb: () => ({
      label: 'Ticket',
      icon: faTicket,
    }),
  },
  allowedRoles: [Roles.Guest],
}
