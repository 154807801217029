import classes from './TileSection.module.css'
import { RoleRoute, TileRoleRoute } from '@/types'
import { Tile } from '@/routes/admin/components/Tile/Tile'

const getItemPath = (item: TileRoleRoute, tile: TileRoleRoute) => {
  if (!item.path) {
    return '/admin'
  }

  const tilePath = tile.path ? `/${tile.path}` : ''
  return item.path + tilePath
}

export const TileSection = (item: RoleRoute) => {
  const tiles: TileRoleRoute[] | undefined = item.children?.filter((childRoute: TileRoleRoute) => childRoute.tile)
  return (
    <div className={classes.tileSection}>
      {tiles
        ? tiles.map((tile: TileRoleRoute, i) => <Tile key={i} path={getItemPath(item, tile)} item={tile} />)
        : null}
    </div>
  )
}
