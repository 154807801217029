import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { TicketsPageRoute } from '@/routes/tickets/TicketsPage.route'

export const TicketRoute: TileRoleRoute = {
  path: 'ticket',
  text: 'Ticket',
  faIcon: faTicket,
  element: (
    <ManagementGridFactory
      entity={'stato_ticket'}
      referencesCollection={'tickets'}
      referencesTitle={'Tickets'}
      displayField={'ded_Dis'}
      entityRoute={TicketsPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Ticket',
      icon: faTicket,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
