import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_promotion, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  EmptyItem,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ValidationCallbackData } from 'devextreme/common'
import DataSource from 'devextreme/data/data_source'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { NumberBoxTypes } from 'devextreme-react/cjs/number-box'
import './LineItem.scss'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import {
  CustomItemCreatingEvent,
  InitializedEvent,
  ValueChangedEvent as ValueChangedEventSelectBox,
} from 'devextreme/ui/select_box'
import { handleSubscriptionMouseOut, handleSubscriptionMouseOver } from '@/routes/quotes/utils'
import { Popover } from 'devextreme-react'
import { PopoverRef } from 'devextreme-react/cjs/popover'

// eslint-disable-next-line react/display-name
const LineItemNew = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const {
      item,
      products,
      creating,
      readOnly,
      promotions,
      csn,
      removeSelf,
      refreshProductsCatalog,
      refreshPromotions,
    } = props

    const [action, setAction] = useState<string>(item.action ?? '')
    const title = useMemo(() => {
      return item.quoteLineNumber
        ? `${action} ${item.quoteLineNumber}`
        : item.opportunityLineItemId
          ? `${action} ${item.opportunityLineItemId}`
          : `${action}`
    }, [action, item.opportunityLineItemId, item.quoteLineNumber])

    const { service } = useQsAdminApiManager()
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false)

    const formRef = useRef<FormRef>(null)

    const [style, setStyle] = useState<string>('line-item')
    const [termDataSource, setTermDataSource] = useState<DataSource>(
      ArraySourceComposer(
        'termCode',
        item && 'offer' in item && item.offer && item.offer.term ? [item.offer.term] : [],
      ),
    )
    const [promotionDataSource, setPromotionDataSource] = useState<DataSource>(ArraySourceComposer('code', promotions))
    const [productsDataSource, setProductsDataSource] = useState<DataSource>(
      ArraySourceComposer('offeringId', products),
    )
    const subsPopoverRef = useRef<PopoverRef>(null)
    const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<ad_subscription[]>([])
    const [currentSubs, setCurrentSubs] = useState<string | ad_subscription | null | undefined>(
      item.referenceSubscription,
    )
    const getSubscriptions = async (csnId: number) => {
      const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
        builder.filter(subscription.csn.props.id.eq(csnId))
        builder.filter(subscription.status.eq('Active'))
        builder.select(
          'id',
          'subscriptionId',
          'serialNumber',
          'quantity',
          'productCode',
          'productName',
          'term',
          'endDate',
          'switchType',
        )
      })
      return subscriptionsData.data.value
    }

    const getTerms = async (productCode: string) => {
      const termsData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.select('termCode', 'termDescription')
        builder.orderBy(product.termCode.asc())
      })
      return termsData.data.value
    }

    const getPromotions = async (
      action: string | undefined,
      productCode: string | null | undefined,
      iuCode: string | undefined,
      termCode: string | undefined | null,
      amCode: string | undefined | null,
      quantity: number | undefined,
    ) => {
      const promotionsData = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(productCode ?? null))
        builder.filter(promotion.orderAction.eq(action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq(iuCode ?? null).or(promotion.intendedUsageCode.eq(null)))
        builder.filter(promotion.termCode.eq(termCode ?? null).or(promotion.termCode.eq(null)))
        builder.filter(promotion.accessModelCode.eq(amCode ?? null).or(promotion.accessModelCode.eq(null)))
        builder.filter(promotion.quantityNeeded.lowerEquals(quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      return promotionsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          return formRef.current?.instance().validate()
        },
        isDirty() {
          return formRef.current?.instance().option().isDirty
        },
        setDirty(value: boolean) {
          formRef.current?.instance().option('isDirty', value)
        },
      } satisfies ILineItemRef
    }, [])

    //al primo render del componente carico le subs
    useEffect(() => {
      getSubscriptions(Number(csn?.id)).then((subs: ad_subscription[]) => {
        setSubscriptionsDataSource(subs)
      })
    }, [])

    useEffect(() => {
      setPromotionDataSource(ArraySourceComposer('code', promotions))
    }, [promotions])

    useEffect(() => {
      setProductsDataSource(ArraySourceComposer('offeringId', products))
    }, [products])

    return (
      <>
        <div id={`item-quote-container-${item.quoteLineNumber}`} className={style}>
          <LoadingPanel
            position={{ of: `#item-quote-container-${item.quoteLineNumber}` }}
            visible={loadIndicatorVisible}
          ></LoadingPanel>
          <DXForm
            id={`item_form_${item.quoteLineNumber}`}
            key={`item_form_${item.quoteLineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.quoteLineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <GroupItem colCount={1}>
                <SimpleItem>
                  <h3>{title}</h3>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={6} colSpan={1}>
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                <EmptyItem></EmptyItem>
                {item.action === 'New' && item.opportunityLineItemId ? (
                  <ButtonItem>
                    <ButtonOptions
                      text="Co-Term"
                      icon={'redo'}
                      stylingMode={'text'}
                      type="success"
                      onClick={(e) => {
                        console.log('e')
                        item.action = 'Co-term'
                        setAction(item.action)
                      }}
                    />
                  </ButtonItem>
                ) : item.action === 'Co-term' && item.opportunityLineItemId ? (
                  <ButtonItem>
                    <ButtonOptions
                      text="New"
                      icon={'revert'}
                      stylingMode={'text'}
                      type="danger"
                      onClick={(e) => {
                        console.log('e')
                        item.action = 'New'
                        setAction(item.action)
                      }}
                    />
                  </ButtonItem>
                ) : (
                  <EmptyItem></EmptyItem>
                )}
                {item.operation === '' || item.operation === 'Insert' ? (
                  <ButtonItem>
                    <ButtonOptions
                      disabled={readOnly}
                      text="Elimina"
                      icon={'trash'}
                      stylingMode={'text'}
                      type="danger"
                      onClick={(e) => {
                        removeSelf()
                        setStyle('line-item deleted')
                      }}
                    />
                  </ButtonItem>
                ) : (
                  <ButtonItem>
                    <ButtonOptions
                      disabled={readOnly}
                      text="Ripristina"
                      icon={'undo'}
                      stylingMode={'text'}
                      type="success"
                      onClick={(e) => {
                        setStyle('line-item')
                        item.operation = ''
                      }}
                    />
                  </ButtonItem>
                )}
              </GroupItem>
            </GroupItem>
            <GroupItem colCount={5}>
              <SimpleItem
                visible={item.action === 'New'}
                dataField="productCatalog"
                label={{ text: 'Prodotto' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'refresh',
                        hint: 'Aggiorna elenco',
                        onClick: () => {
                          setLoadIndicatorVisible(true)
                          if (refreshProductsCatalog) {
                            refreshProductsCatalog().finally(() => {
                              setLoadIndicatorVisible(false)
                            })
                          }
                        },
                      },
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: productsDataSource,
                  placeholder: 'Selezionare il prodotto...',
                  displayExpr: 'offeringName',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il prodotto',
                    hideOnOutsideClick: true,
                  },
                  disabled: item.operation === 'Delete',
                  readOnly: item.quoteLineNumber || item.opportunityLineItemId || readOnly ? true : false,
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.offeringCode === e.value?.offeringCode) return
                    console.log('PRODUCT EVENT CHANGED', e.previousValue, e.value)
                    if (e.value) {
                      getTerms(e.value.offeringCode).then((res: ad_product_catalog[]) => {
                        setTermDataSource(
                          ArraySourceComposer('termCode', [
                            ...new Map(
                              res.map((product: ad_product_catalog) => [product['termCode'], product]),
                            ).values(),
                          ]),
                        )
                        formRef.current?.instance().updateData('offer.term', null)
                      })
                    }
                  },
                }}
              >
                <RequiredRule message="Prodotto obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                visible={item.action === 'Co-term'}
                dataField="referenceSubscription"
                label={{ text: 'Subscription' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', async (e: ClickEvent) => {
                      if (item.referenceSubscription) {
                        if (typeof item.referenceSubscription === 'object')
                          window.open(`/software/autodesk/${item.referenceSubscription.id}`, '_blank')
                        else {
                          const sub = item.referenceSubscription
                          const getSub = await service.ad_subscription().query((builder, subsciption) => {
                            builder.filter(subsciption.subscriptionId.eq(sub))
                            builder.select('id')
                          })
                          if (getSub.data.value.length > 0)
                            window.open(`/software/autodesk/${getSub.data.value[0].id}`, '_blank')
                          else notify(`Nessun subcription trovato.`, 'warning', 3000)
                        }
                      } else notify(`Deve essere selezionato un subscription id per poterlo aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentSubs,
                  dataSource: ArraySourceComposer('id', subscriptionsDataSource),
                  placeholder: 'Selezionare subscription...',
                  displayExpr: 'subscriptionId',
                  searchEnabled: true,
                  acceptCustomValue: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare subscription',
                    hideOnOutsideClick: true,
                  },
                  readOnly: false,
                  disabled: false,
                  onCustomItemCreating: (e: CustomItemCreatingEvent) => {
                    console.log('CustomItemCreatingEvent', e)
                    if (!e.customItem) e.customItem = e.text
                    setCurrentSubs(e.customItem)
                  },
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log('ValueChangedEventSelectBox', e)
                    if (e.previousValue?.id === e.value?.id) return
                    setCurrentSubs(e.value)
                  },
                  onInitialized: (e: InitializedEvent) => {
                    e.element?.addEventListener('mouseout', (event) =>
                      handleSubscriptionMouseOut(event, e, subsPopoverRef),
                    )
                    e.element?.addEventListener('mouseover', (event) =>
                      handleSubscriptionMouseOver(event, e, subsPopoverRef),
                    )
                  },
                }}
              >
                <RequiredRule message="Subscription obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                visible={!item.opportunityLineItemId}
                dataField="offer.term"
                label={{ text: 'Termini' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: termDataSource,
                  placeholder: 'Selezionare il termine...',
                  displayExpr: 'termDescription',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il termine',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                }}
              >
                <RequiredRule message="Termini obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="startDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  disabled: item.operation === 'Delete',
                  type: 'date',
                  pickerType: 'calendar',
                  applyValueMode: 'instantly',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Inizio' }}
              >
                <CustomRule
                  message="La data di inizio deve essere entro 30 giorni dalla data odierna"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (value && item.action === 'New') {
                      const dataInizio: Date = value
                      const today: Date = new Date()
                      dataInizio.setHours(today.getHours())
                      dataInizio.setMinutes(today.getMinutes())
                      dataInizio.setSeconds(today.getSeconds())
                      const tsDifference: number = dataInizio.getTime() - today.getTime()
                      const dayDifference: number = Math.floor(tsDifference / (1000 * 60 * 60 * 24))
                      return dayDifference < 29
                    } else return true
                  }}
                ></CustomRule>
                <CustomRule
                  message="Data di inizio obbligatoria"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return !value && item.action === 'Co-term' ? false : true
                  }}
                ></CustomRule>
              </SimpleItem>
              <SimpleItem
                dataField="quantity"
                editorType="dxNumberBox"
                label={{ text: 'Quantità' }}
                editorOptions={{
                  disabled: item.operation === 'Delete',
                  showSpinButtons: true,
                  min: 0,
                  onValueChanged: (e: NumberBoxTypes.ValueChangedEvent) => {
                    console.log('quantity event changed', e)
                    getPromotions(
                      item.action,
                      item.productCatalog?.offeringCode,
                      item.offer?.intendedUsage?.code,
                      item.offer?.term?.termCode,
                      item.productCatalog?.accessModelCode,
                      item.quantity,
                    ).then((res: ad_promotion[]) => {
                      setPromotionDataSource(
                        ArraySourceComposer('code', [
                          ...new Map(res.map((promotion: ad_promotion) => [promotion['code'], promotion])).values(),
                        ]),
                      )
                    })
                  },
                }}
              >
                <RequiredRule message="Quantità obbligatoria"></RequiredRule>
                <CustomRule
                  message="Quantità deve essere maggiore di 0"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return value > 0
                  }}
                ></CustomRule>
              </SimpleItem>
              <SimpleItem
                visible={!item.opportunityLineItemId}
                dataField="promotion"
                label={{ text: 'Promozione' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'refresh',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'refresh',
                        hint: 'Aggiorna elenco',
                        onClick: () => {
                          setLoadIndicatorVisible(true)
                          if (refreshPromotions) {
                            refreshPromotions().finally(() => {
                              setLoadIndicatorVisible(false)
                            })
                          }
                        },
                      },
                    },
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: promotionDataSource,
                  placeholder: 'Selezionare la promozione...',
                  displayExpr: (item: ad_promotion) => {
                    return item ? `${item.name} - ${item.code}` : ''
                  },
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la promozione',
                    hideOnOutsideClick: true,
                    resizeEnabled: true,
                  },
                  clearButtonText: 'Clear',
                  showClearButton: true,
                  disabled: item.operation === 'Delete',
                }}
              ></SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && (
              <GroupItem colCount={4}>
                <SimpleItem
                  dataField="pricing.unitSRP"
                  label={{ text: 'Prezzo Singolo Prodotto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedSRP"
                  label={{ text: 'Prezzo Totale Prodotti' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.extendedDiscountedSRP"
                  label={{ text: 'Prezzo Totale Prodotti con Sconto' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField="pricing.endUserPrice"
                  label={{ text: 'Prezzo Finale' }}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                  }}
                ></SimpleItem>
              </GroupItem>
            )}
          </DXForm>
          {item.action === 'Co-term' && <Popover position="bottom" ref={subsPopoverRef} />}
        </div>
      </>
    )
  },
)

export default LineItemNew
