import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { gruppo_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useScreenSize } from '@/themes/media-query'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ClickEvent } from 'devextreme/ui/button'
import React, { useRef } from 'react'

const FormPopupClientGroup = (props: {
  popupRef: React.RefObject<PopupRef>
  clientGroups?: gruppo_aziendale[]
  clientFormRef?: React.RefObject<FormRef>
}) => {
  const currentScreenSize = useScreenSize()
  const newClientGroup: gruppo_aziendale = {
    id: 0,
    nome: null,
    note: null,
  }
  const { service } = useQsAdminApiManager()
  const formRef = useRef<FormRef>(null)

  return (
    <>
      <Popup
        ref={props.popupRef}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Nuovo gruppo aziendale`}
        container=".dx-viewport"
        width={currentScreenSize.isLarge || currentScreenSize.isMedium ? 700 : currentScreenSize.isXSmall ? 350 : 700}
        height={'auto'}
        resizeEnabled={false}
      >
        <DXForm
          id={'client_group_form'}
          formData={newClientGroup}
          labelLocation={'top'}
          showValidationSummary={true}
          validationGroup="clientGroupValidation"
          ref={formRef}
        >
          <GroupItem colCount={2}>
            <SimpleItem dataField="nome" label={{ text: 'Nome' }}>
              <RequiredRule message="Nome obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem dataField="note" label={{ text: 'Note' }}></SimpleItem>
          </GroupItem>
          <GroupItem cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={(e: ClickEvent) => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    service
                      .gruppo_aziendale()
                      .create(newClientGroup)
                      .then((res) => {
                        props.clientGroups?.push(res.data)
                        props.clientFormRef?.current?.instance().updateData('gruppo_aziendale', res.data)
                        props.popupRef.current?.instance().hide()
                        formRef.current?.instance().clear()
                      })
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

export default FormPopupClientGroup
