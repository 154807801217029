import {
  Column,
  DataGrid,
  Editing,
  Lookup,
  Paging,
  RequiredRule,
  Button as GridButton,
  StateStoring,
  DataGridRef,
  Toolbar,
  Item,
  DataGridTypes,
} from 'devextreme-react/data-grid'
import type { CellPreparedEvent, InitNewRowEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OrderEditorInvoicingStepsGridProps } from '@/routes/orders/OrderEditor.types'
import { ProgressBar } from 'devextreme-react'
import { Link, useNavigate } from 'react-router-dom'
import { Form as DxForm, GroupItem } from 'devextreme-react/form'
import DateColumn from '@/components/date-column/DateColumn'
import { useScreenSize } from '@/themes/media-query'
import { ClickEvent } from 'devextreme/ui/button'
import { punto_fatturazione_ordine, stato_task, task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import './OrderEditor.tasks.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck as faEmptyCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { GridCellColor } from '@/enums'
import { StatoPuntoFatturazione } from '@/routes/invoicingsteps/invoicingstep.types'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useTokenRefresh } from '@/auth/azure/azureManager'
import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'

const OrderEditorInvoicingStepsGrid = (props: OrderEditorInvoicingStepsGridProps) => {
  const { order, creating, readOnly, userInfo, showTitle } = props
  const currentScreenSize = useScreenSize()
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)
  const { service } = useQsAdminApiManager()
  const [stati, setStati] = useState<stato_task[]>([])
  const token = useTokenRefresh()
  const [puntiFatturazione, setPuntiFatturazione] = useState<punto_fatturazione_ordine[]>([])

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.id') {
      switch (e.value) {
        case StatoPuntoFatturazione.FATTURATO: {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
        case StatoPuntoFatturazione['DA FATTURARE']: {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
      }
    }
  }

  const getStati = useCallback(async () => {
    const stati = await service.stato_punto_fatturazione().query((builder, stato) => {
      builder.select('id', 'nome')
      builder.orderBy(stato.nome.asc())
    })
    return stati.data.value
  }, [service])

  const getPuntiFatturazione = useCallback(
    async (orderId?: number) => {
      const puntiFatturazione = await service.punto_fatturazione_ordine().query((builder, punto_fatturazione) => {
        builder.filter(punto_fatturazione.ordine.props.id.eq(Number(orderId)))
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.select('id', 'stato')
      })
      return puntiFatturazione.data.value
    },
    [service],
  )

  const invoicingStepsDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/punto_fatturazione_ordine`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['ordine.id', order && 'id' in order ? order?.id : null]],
    expand: ['ordine', 'stato'],
  })

  useEffect(() => {
    getStati().then((result) => {
      setStati(result)
    })
    getPuntiFatturazione(order?.id).then((result) => {
      setPuntiFatturazione(result)
    })
  }, [])

  return (
    <>
      <DxForm id="order-invoicingsteps">
        <GroupItem caption={showTitle === undefined || showTitle ? `Punti di Fatturazione` : ``}>
          <ProgressBar
            id="order-invoicingsteps-progress"
            className={
              puntiFatturazione.filter((t) => t.stato?.id === StatoPuntoFatturazione.FATTURATO).length ===
              puntiFatturazione.length
                ? 'test complete'
                : 'test'
            }
            visible={puntiFatturazione.length > 0}
            min={0}
            showStatus={true}
            max={puntiFatturazione.length}
            value={puntiFatturazione.filter((t) => t.stato?.id === StatoPuntoFatturazione.FATTURATO).length}
          />
          <DataGrid
            ref={gridRef}
            id={`order-invoicingSteps-grid`}
            dataSource={invoicingStepsDataSource}
            noDataText="Nessun punto di fatturazione associato all'ordine"
            keyExpr={'id'}
            showBorders={true}
            showColumnLines={true}
            focusedRowEnabled={true}
            columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            wordWrapEnabled={false}
            repaintChangesOnly={true}
            onCellPrepared={onCellPrepared}
            width="100%"
            onInitNewRow={(e: InitNewRowEvent) => {
              e.data.ordine = order
              e.data.stato = stati[0]
              e.data.creazione = new Date().toISOString()
            }}
            onRowDblClick={(e: RowDblClickEvent<any, any>) => {
              if (
                e.rowType === 'data' &&
                isUserRoleAllowed(userInfo?.roles, [Roles.Administrator, Roles.GlobalAdministrator, Roles.Supervisor])
              )
                navigate(`../../invoicingsteps/${e.data.id}`)
            }}
          >
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={`order-invoicingSteps-grid`}
              savingTimeout={50}
            />
            <Paging defaultPageSize={8} />
            <Editing
              mode="batch"
              allowAdding={!creating && !readOnly}
              allowDeleting={!creating && !readOnly}
              selectTextOnEditStart={true}
              startEditAction={'click'}
            />
            <Column
              dataType={'boolean'}
              dataField={'stato'}
              fixed={true}
              fixedPosition={'left'}
              showInColumnChooser={false}
              width={50}
              caption={''}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
              }
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const stato: task = cellData.data.stato
                console.log('stato', stato)
                return (
                  <FontAwesomeIcon
                    icon={stato.id === StatoPuntoFatturazione.FATTURATO ? faCircleCheck : faEmptyCircleCheck}
                    color={stato.id === StatoPuntoFatturazione.FATTURATO ? 'green' : 'orange'}
                  />
                )
              }}
            ></Column>
            <Column
              dataField={'ded_Dis'}
              caption={'CODICE'}
              cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
                const data: punto_fatturazione_ordine = cellData.row.data
                return isUserRoleAllowed(userInfo?.roles, [
                  Roles.GlobalAdministrator,
                  Roles.Administrator,
                  Roles.Supervisor,
                ]) ? (
                  <>
                    <Link to={`/invoicingsteps/${data.id}`}>{data.ded_Dis}</Link>
                  </>
                ) : (
                  <>{data.ded_Dis}</>
                )
              }}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
              }
            />
            <Column dataField={'id'} caption={'ID'} visible={false} />,
            <Column dataField={'ordine.ded_Dis'} caption={'ORDINE'} visible={false} />,
            <Column
              dataField={'stato.id'}
              caption={'STATO'}
              editorOptions={{ readOnly: true }}
              hidingPriority={
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
              }
            >
              <Lookup dataSource={stati} displayExpr="nome" valueExpr="id" allowClearing={true} />
            </Column>
            {DateColumn({
              dataField: 'creazione',
              caption: 'APERTURA',
              format: 'dd/MM/yyyy',
              hidingPriority:
                currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined,
            })}
            <Column dataField={'note'} caption={'NOTE'}>
              <RequiredRule message="Note obbligatorie" />
            </Column>
            <Column type="buttons" width={'4%'} alignment="left">
              <GridButton name="delete"></GridButton>
            </Column>
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                showText="inMenu"
                widget="dxButton"
                options={{
                  hint: 'Refresh',
                  text: 'Refresh',
                  icon: 'refresh',
                  onClick: (e: ClickEvent) => {
                    // gridRef.current?.instance().refresh()
                    getPuntiFatturazione(order?.id).then((result) => {
                      setPuntiFatturazione(result)
                    })
                  },
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="addRowButton"
                options={{
                  hint: 'Nuovo punto di fatturazione',
                  text: 'Nuovo punto di fatturazione',
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="saveButton"
                options={{
                  hint: 'Salva',
                  text: 'Salva',
                  stylingMode: 'text',
                }}
              ></Item>
              <Item
                name="revertButton"
                options={{
                  hint: 'Annulla modifiche',
                  text: 'Annulla modifiche',
                  stylingMode: 'text',
                }}
              ></Item>
            </Toolbar>
          </DataGrid>
        </GroupItem>
      </DxForm>
    </>
  )
}

export default OrderEditorInvoicingStepsGrid
