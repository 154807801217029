import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { OffersPageRoute } from '@/routes/offers/OffersPage.route'

export const OfferRoute: TileRoleRoute = {
  path: 'offer',
  text: 'Offerta',
  faIcon: faFileContract,
  element: (
    <ManagementGridFactory
      entity={'stato_offerta'}
      referencesCollection={'offerte'}
      referencesTitle={'Offerte'}
      displayField={'ded_Dis'}
      entityRoute={OffersPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Offerta',
      icon: faFileContract,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
