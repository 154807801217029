import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { azienda, contatto_aziendale, entita_aziendale, rapporto, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { AnalysisEditorFormProps } from '@/routes/analysis/AnalysisEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
  type FormRef as DXFormRef,
} from 'devextreme-react/form'
import DataSource from 'devextreme/data/data_source'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { Contacts } from '@/types'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ClickEvent } from 'devextreme/ui/button'
import saveAs from 'file-saver'
import { AxiosError, AxiosResponse } from 'axios'
import notify from 'devextreme/ui/notify'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import {
  customButton,
  customDisplayExpr,
  isUserRoleAllowed,
  onInitializedHtmlEditor,
  onValueChangedHtmlEditor,
  toolbarHtmlEditor,
} from '@/routes/utils'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'
import FormPopupContact from '@/routes/contacts/person/FormPopupContact'
import { ValidationCallbackData } from 'devextreme/common'

const AnalysisEditorForm = (props: AnalysisEditorFormProps) => {
  const { creating, analysis, stati, aziende, persone, entita, documentoGetRef, isRoleAllowed, userInfo, fetcher } =
    props
  const { clientId } = useParams()
  const { service, client } = useQsAdminApiManager()

  const currentScreenSize = useScreenSize()
  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const refNuovoContattoPopup = useRef<PopupRef>(null)
  const navigate = useNavigate()
  const formRef = useRef<DXFormRef>(null)
  const revalidator = useRevalidator()
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziende
        ? aziende[0]
        : undefined
      : analysis && 'sede' in analysis
        ? analysis?.sede?.azienda
        : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', analysis && 'sede' in analysis ? analysis?.sede?.azienda?.sedi : []),
  )

  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)

  const [personeDataSource, setPersoneDataSource] = useState<DataSource>(ArraySourceComposer('id', persone))

  const [entitaDataSource, setEntitaDataSource] = useState<DataSource>(ArraySourceComposer('id', entita))

  const [loadPanelVisible, setLoadPanelVisible] = useState(false)

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )
  const getSiteContacts = useCallback(
    async (siteId: number) => {
      const personeData = await service.contatto_aziendale().query((builder, persona) => {
        builder.filter(persona.sede.props.id.eq(siteId))
        builder.filter(persona.attivo.eq(true))
        builder.orderBy(persona.fullname.asc())
        builder.select('id', 'fullname')
      })
      const entitaData = await service.entita_aziendale().query((builder, entita) => {
        builder.filter(entita.sede.props.id.eq(siteId))
        builder.orderBy(entita.nome.asc())
        builder.select('id', 'nome')
      })
      return {
        persone: personeData.data.value,
        entita: entitaData.data.value,
      }
    },
    [service],
  )

  const handleOnClickNuovaRev = (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/analisi/createrevision', analysis.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        navigate(`../${response.data.id}`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickExportPDF = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/analisi/exportpdf', {
        params: { analysisId: Number(analysis.id) },
        headers: {
          'Content-Disposition': `attachment;filename=${analysis.mainDocumentUrl?.replace('.docx', '')}.pdf`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `${analysis.mainDocumentUrl?.replace('.docx', '')}.pdf`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', analysis.sede)
    if (analysis.sede) {
      getSiteContacts(analysis.sede.id).then((contacts: Contacts) => {
        console.log('contacts', contacts)
        setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
        formRef.current?.instance().updateData('riferimenti', null)
        setEntitaDataSource(ArraySourceComposer('id', contacts.entita))
        formRef.current?.instance().updateData('riferimenti_entita', null)
      })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, analysis.sede, getSiteContacts])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'analysis_form'}
        formData={analysis}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="analysisValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                readOnly: !!clientId || !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (analysis.sede) {
                        setCurrentSite(analysis.sede)
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  setCurrentSiteChanged(true)
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="riferimenti"
              editorType="dxTagBox"
              label={{ text: 'Riferimenti persona' }}
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    (e: ClickEvent) => {
                      if (analysis.sede) {
                        const contact: contatto_aziendale = {
                          id: 0,
                          fullname: null,
                          ad_recipient: false,
                          nome: null,
                          cognome: null,
                          ruolo: null,
                          note: null,
                          titolo: null,
                          telefono: null,
                          attivo: true,
                          sede: analysis.sede,
                          emails: [
                            {
                              id: 0,
                              email: null,
                              note: null,
                              rif_ad: false,
                            },
                          ],
                          hasLinkedObjects: false,
                          ricezione_rapportino: false,
                        }
                        setCurrentContact(contact)
                        refNuovoContattoPopup.current?.instance().show()
                      } else notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                    },
                    !isRoleAllowed,
                  ),
                ],
                dataSource: personeDataSource,
                displayExpr: 'fullname',
                placeholder: 'Seleziona riferimenti...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            >
              <CustomRule
                reevaluate={true}
                message="Riferimenti persona obbligatorio se 'Riferimenti entità' non presente"
                validationCallback={(options: ValidationCallbackData) => {
                  return options.value &&
                    options.value.length === 0 &&
                    analysis.riferimenti_entita &&
                    analysis.riferimenti_entita.length === 0
                    ? false
                    : true
                }}
              />
            </SimpleItem>
            <SimpleItem
              dataField="riferimenti_entita"
              editorType="dxTagBox"
              label={{ text: 'Riferimenti entità' }}
              editorOptions={{
                dataSource: entitaDataSource,
                displayExpr: 'nome',
                placeholder: 'Seleziona riferimenti...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
                searchEnabled: true,
              }}
            >
              <CustomRule
                reevaluate={true}
                message="Riferimenti entità obbligatorio se 'Riferimenti persona' non presente"
                validationCallback={(options: ValidationCallbackData) => {
                  return options.value &&
                    options.value.length === 0 &&
                    analysis.riferimenti &&
                    analysis.riferimenti.length === 0
                    ? false
                    : true
                }}
              />
            </SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: creating || !isRoleAllowed,
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data' }}
            >
              <RequiredRule message="Data obbligatoria"></RequiredRule>
            </SimpleItem>
            {analysis.offerte && analysis.offerte.length > 0 && (
              <SimpleItem
                label={{ text: 'Offerta' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', (e: ClickEvent) => {
                      if (analysis.offerte) window.open(`/offers/${analysis.offerte[0].id}`, '_blank')
                    }),
                  ],
                  dataSource: ArraySourceComposer('id', analysis.offerte),
                  value: analysis.offerte ? analysis.offerte[0] : null,
                  placeholder: 'Seleziona offerta...',
                  // displayExpr: 'ded_Dis',
                  displayExpr: customDisplayExpr,
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare offerta',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              ></SimpleItem>
            )}
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={false} deferRendering={false} />
              <Tab title="oggetto">
                <SimpleItem
                  dataField={'oggetto'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: '300' }}
                >
                  <RequiredRule message="Oggetto obbligatorio"></RequiredRule>
                </SimpleItem>
              </Tab>
              <Tab title="note">
                <SimpleItem
                  dataField="note"
                  label={{ visible: false }}
                  editorType="dxHtmlEditor"
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: 300,
                    onValueChanged: onValueChangedHtmlEditor,
                    onInitialized: onInitializedHtmlEditor,
                  }}
                ></SimpleItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem
          visible={isUserRoleAllowed(userInfo?.roles, [
            Roles.GlobalAdministrator,
            Roles.Administrator,
            Roles.Supervisor,
            Roles.TechDeveloper,
            Roles.Sales,
          ])}
          cssClass="last-group"
        >
          <GroupItem cssClass="buttons-group" colCount={creating ? 4 : 5}>
            <ButtonItem name="btn-offerta">
              <ButtonOptions
                text="offerta"
                icon="money"
                useSubmitBehavior={false}
                width={'150px'}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator, Roles.Sales])
                }
                onClick={(e: ClickEvent) => {
                  if (analysis?.stato?.id === StatoAnalisi.COMPLETATA)
                    if (analysis.offerte?.length === 0)
                      navigate({
                        pathname: '/offers/new',
                        search: `?analysisId=${analysis?.id}`,
                      })
                    else
                      notify(
                        `L'analisi è già in un' offerta. Offerta: '${analysis.offerte ? analysis.offerte[0].ded_Dis : ''}'`,
                        'warning',
                        3000,
                      )
                  else
                    notify(
                      `L'analisi deve essere nello stato 'COMPLETATA'. Stato: '${analysis.stato?.nome}'`,
                      'warning',
                      3000,
                    )
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-exportPDF">
              <ButtonOptions
                text="esporta pdf"
                icon="pdffile"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickExportPDF}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Administrator,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-newRev">
              <ButtonOptions
                text="nuova rev"
                icon="newfolder"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickNuovaRev}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Administrator,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('analysis', analysis)
                  try {
                    if (analysis) {
                      fetcher?.submit(JSON.stringify(analysis), {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'application/json',
                      })
                    } else console.error('no data tu submit')
                  } finally {
                    documentoGetRef.current?.refresh(true)
                  }
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
      <FormPopupContact
        migrating={false}
        popupRef={refNuovoContattoPopup}
        contact={currentContact}
        onContactSaved={(newContact) => {
          refNuovoContattoPopup.current?.instance().hide()
          getSiteContacts(Number(analysis.sede?.id)).then(
            (contacts: { persone: contatto_aziendale[]; entita: entita_aziendale[] }) => {
              formRef.current?.instance().updateData('riferimenti', [newContact])
              setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
            },
          )
        }}
      ></FormPopupContact>
    </>
  )
}

export default AnalysisEditorForm
