import { useCallback, useEffect, useRef, useState } from 'react'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  EmailRule,
  Form,
  FormRef,
  GroupItem,
  Item as FormItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { isIPerson } from './utils'
import { Button, type ButtonTypes } from 'devextreme-react/button'
import type { ValidationCallbackData } from 'devextreme-react/common'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'
import type {
  contatto_aziendale,
  email_aziendale,
  entita_aziendale,
  sede,
  azienda,
  rapporto,
  ad_csn,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import classNames from 'classnames'
import { Item as ToolbarItem, Toolbar } from 'devextreme-react/toolbar'
import { ScrollView } from 'devextreme-react/scroll-view'
import { ValidationGroup } from 'devextreme-react/validation-group'
import { ArraySourceComposer } from '@/auth/api/connector'
import notify from 'devextreme/ui/notify'
import { customButton } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ValueChangedEvent } from 'devextreme/ui/select_box'
import { LoadPanel } from 'devextreme-react'
import { LoadPanelRef } from 'devextreme-react/cjs/load-panel'
import { AxiosResponse } from 'axios'
type ContactEditorFormProps = {
  contact: contatto_aziendale | entita_aziendale | undefined
  onDataReset?: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  onDataSave?: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  onDataDelete?: (data: contatto_aziendale | entita_aziendale) => Promise<boolean>
  onDataMigratesClick?: (e: ClickEvent) => void
  onDataSaved?: (contact: contatto_aziendale | entita_aziendale) => void
  rapporti: rapporto[] | undefined
  aziende: azienda[]
  sedi: sede[]
  azienda?: azienda | null
  migrating?: boolean
  creating?: boolean
  readOnly?: boolean
}
const ContactEditorForm = (props: ContactEditorFormProps) => {
  const {
    contact,
    onDataReset,
    onDataSave,
    onDataDelete,
    onDataSaved,
    onDataMigratesClick,
    rapporti,
    aziende,
    sedi,
    azienda,
    migrating,
    creating,
    readOnly,
  } = props
  const formRef = useRef<FormRef>(null)
  //quando è true isEditing il form è editabile altrimenti è in sola lettura (in caso di creazione nuovo contatto deve essere subito editabile)
  const [isEditing, setIsEditing] = useState(creating ?? false)
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(creating ?? false)
  const [isPerson, setIsPerson] = useState<boolean>()
  const [, setContactEmailAddresses] = useState<string[]>()
  const [currentCompany, setCurrentCompany] = useState<azienda | null>()
  const [currentAdmin, setCurrentAdmin] = useState<boolean>()
  const [currentQuoteContact, setCurrentQuoteContact] = useState<boolean>()
  const { service, client } = useQsAdminApiManager()
  const [sediList, setSediList] = useState<sede[] | undefined>()
  const loadPanelRef = useRef<LoadPanelRef>(null)
  const toggleEditHandler = useCallback(() => {
    // Disabilito il tasto salva prima di entrare in editazione
    setSaveButtonEnabled(false)
    // Abilito l'editazione
    setIsEditing(!isEditing)
  }, [isEditing])
  const checkEmailIsNotDuplicated = (cbkData: ValidationCallbackData) => {
    console.log(cbkData)

    let refCount = 0
    const curEmlAddresses = getContactEmailAddresses()
    if (contact?.emails) {
      for (const email of curEmlAddresses) {
        if (email?.toLowerCase() === cbkData.value.toLowerCase()) {
          refCount = refCount + 1
        }
      }
    }

    console.log(refCount)
    return refCount <= 1
  }
  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    setSaveButtonEnabled(true)
  }
  const onCancelClick = useCallback(() => {
    if (contact && onDataReset)
      onDataReset(contact).then((res: any) => {
        setSaveButtonEnabled(false)
        setIsEditing(false)
      })
  }, [contact, onDataReset])
  const onSaveClick = useCallback(
    async ({ validationGroup }: ButtonTypes.ClickEvent) => {
      const validationResult = formRef.current?.instance().validate()
      if (!validationResult?.isValid) return
      if (contact) {
        if (!creating && onDataSave)
          onDataSave(contact).then((res: any) => {
            console.log('SAVED', contact, res)
            setSaveButtonEnabled(false)
            setIsEditing(false)
          })
        else {
          const validationResult = formRef.current?.instance().validate()
          if (!validationResult?.isValid) return
          loadPanelRef.current?.instance().option('visible', true)
          try {
            const response: AxiosResponse<contatto_aziendale, contatto_aziendale> = await client.post(
              'api/contattoaziendale/create',
              { contact, migrating },
              {
                headers: { 'Content-Type': 'application/json' },
              },
            )
            notify(
              migrating ? `Migrazione contatto avvenuta con successo.` : `Creazione contatto avvenuta con successo`,
              'success',
              2000,
            )
            formRef.current?.instance().clear()
            if (onDataSaved) onDataSaved(response.data)
          } catch (error: unknown) {
            notify(
              migrating
                ? `Errore migrazione contatto. Dettagli : ${error}`
                : `Errore creazione contatto. Dettagli : ${error}`,
              'error',
              2000,
            )
          } finally {
            loadPanelRef.current?.instance().option('visible', false)
          }
        }
      }
    },
    [client, contact, creating, migrating, onDataSave, onDataSaved],
  )
  const onDeleteClick = useCallback(
    ({ validationGroup }: ButtonTypes.ClickEvent) => {
      const validationResult = formRef.current?.instance().validate()
      if (!validationResult?.isValid) return
      if (contact && onDataDelete) onDataDelete(contact).then((res: any) => {})
    },
    [contact, onDataDelete],
  )

  const getSedi = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.select('id', 'nome')
      })
      return sitesData.data.value
    },
    [service],
  )

  const getContactEmailAddresses = useCallback(() => {
    const addresses: string[] = []
    if (contact?.emails) {
      for (const email_aziendale of contact.emails) {
        addresses.push(email_aziendale.email ?? '')
      }
    }
    return addresses
  }, [contact?.emails])
  useEffect(() => {
    if (contact) setIsPerson(isIPerson(contact))
    const emlAddresses = getContactEmailAddresses()
    setContactEmailAddresses(emlAddresses)
    // setIsEditing(false)
    if (contact && 'ad_csns' in contact) {
      const csnsAdmin = contact.ad_csns?.filter((csn: ad_csn) => csn.admin !== null)
      setCurrentAdmin(csnsAdmin && csnsAdmin.length > 0)
      const csnsQuoteContact = contact.ad_csns?.filter((csn: ad_csn) => csn.quoteContact !== null)
      setCurrentQuoteContact(csnsQuoteContact && csnsQuoteContact.length > 0)
    }
  }, [contact, getContactEmailAddresses])

  useEffect(() => {
    setCurrentCompany(azienda ?? contact?.sede?.azienda)
    if (azienda)
      getSedi(azienda.id).then((sedi: sede[]) => {
        setSediList(sedi)
        formRef.current?.instance().updateData('sede', sedi[0])
      })
    setSediList(sedi)
  }, [contact?.sede?.azienda, sedi, azienda, getSedi])

  return (
    <>
      <ScrollView className="panel-scroll">
        <ValidationGroup>
          <div className="data-part border">
            <LoadPanel
              ref={loadPanelRef}
              shadingColor="rgba(0,0,0,0.4)"
              showIndicator={true}
              shading={true}
              showPane={true}
              hideOnOutsideClick={false}
              hideOnParentScroll={false}
              position={{ of: '#person-migrate-selector-form-container' }}
            />
            <Form
              key={`frm-contact-${contact?.id}-emails${contact?.emails?.length ?? 0}`}
              className={classNames({
                'plain-styled-form': true,
                'view-mode': !isEditing,
              })}
              onFieldDataChanged={creating ? undefined : handleFieldDataChanged}
              formData={contact}
              labelMode={'static'}
              ref={formRef}
            >
              <GroupItem cssClass="contact-fields-group">
                <GroupItem colCount={2} cssClass="flatGroupBoxStyle">
                  <FormItem
                    cssClass="accent"
                    label={{ text: 'Azienda' }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      buttons: [
                        customButton('open', (e: ClickEvent) => {
                          if (contact?.sede) window.open(`/clients/${contact?.sede?.azienda?.id}`, '_blank')
                          else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                        }),
                        { name: 'dropDown', location: 'after' },
                      ],
                      value: currentCompany,
                      dataSource: ArraySourceComposer('id', aziende),
                      placeholder: "Seleziona l'azienda...",
                      displayExpr: 'nome',
                      searchEnabled: true,
                      dropDownOptions: {
                        showTitle: true,
                        title: "Selezionare l'azienda",
                        hideOnOutsideClick: true,
                      },
                      readOnly: !migrating,
                      onValueChanged: (e: ValueChangedEvent) => {
                        if (migrating) {
                          if (e.previousValue?.id === e.value?.id) return
                          if (e.value) {
                            setCurrentCompany(e.value)
                            getSedi(e.value.id).then((sedi: sede[]) => {
                              setSediList(sedi)
                              formRef.current?.instance().updateData('sede', sedi[0])
                            })
                          }
                        }
                      },
                    }}
                  >
                    <RequiredRule message="Azienda obbligatoria" />
                  </FormItem>
                  <SimpleItem
                    dataField="sede"
                    label={{ text: 'Sede' }}
                    editorType="dxLookup"
                    editorOptions={{
                      dataSource: ArraySourceComposer('id', sediList),
                      displayExpr: 'nome',
                      readOnly: !creating,
                    }}
                  >
                    <RequiredRule message="Sede obbligatoria"></RequiredRule>
                  </SimpleItem>
                </GroupItem>
                <SimpleItem visible={isPerson} label={{ text: 'Titolo' }} dataField="titolo" cssClass="accent" />
                <GroupItem visible={isPerson} colCount={2} cssClass="flatGroupBoxStyle">
                  <SimpleItem
                    label={{ text: 'Nome' }}
                    dataField="nome"
                    cssClass="accent"
                    editorOptions={{
                      elementAttr: { class: 'form-editor' },
                      inputAttr: { class: 'form-editor-input' },
                      readOnly: migrating,
                    }}
                  >
                    <RequiredRule message="Nome obbligatorio" />
                  </SimpleItem>
                  <SimpleItem
                    label={{ text: 'Cognome' }}
                    dataField="cognome"
                    cssClass="accent"
                    editorOptions={{
                      elementAttr: { class: 'form-editor' },
                      inputAttr: { class: 'form-editor-input' },
                      readOnly: migrating,
                    }}
                  />
                </GroupItem>
                <SimpleItem
                  visible={!isPerson}
                  label={{ text: 'Nome' }}
                  dataField="nome"
                  cssClass="accent"
                  editorOptions={{
                    elementAttr: { class: 'form-editor' },
                    inputAttr: { class: 'form-editor-input' },
                  }}
                />
                <GroupItem visible={isPerson} colCount={2} cssClass="flatGroupBoxStyle">
                  <SimpleItem label={{ text: 'Ruolo' }} dataField="ruolo" />
                  <SimpleItem
                    label={{ text: 'Rapporto' }}
                    dataField="rapporto"
                    editorType="dxLookup"
                    editorOptions={{
                      dataSource: ArraySourceComposer('id', rapporti),
                      placeholder: 'Seleziona il tipo di rapporto...',
                      displayExpr: 'nome',
                      searchEnabled: true,
                      dropDownCentered: true,
                      showClearButton: true,
                      dropDownOptions: {
                        showTitle: true,
                        title: 'Selezionare il rapporto',
                        hideOnOutsideClick: true,
                      },
                    }}
                  />
                </GroupItem>
                <SimpleItem
                  label={{ text: 'Telefono' }}
                  dataField="telefono"
                  editorOptions={{
                    elementAttr: { class: 'form-editor' },
                    inputAttr: { class: 'form-editor-input' },
                    readOnly: !isEditing,
                    buttons: [
                      customButton('call', (e: ClickEvent) => {
                        if (contact?.telefono) window.open(`tel:${contact?.telefono.replace(/\s+/g, '')}`, '_self')
                        else notify('Nessun numero di telefono da chiamare.', 'warning', 2000)
                      }),
                    ],
                  }}
                />
                <GroupItem name="emails-container" cssClass="flatGroupBoxStyle">
                  {getContactEmailAddresses()?.map((value, index) => (
                    <SimpleItem
                      key={`Email${index}`}
                      label={{ text: `Email ${index + 1}` }}
                      dataField={`emails[${index}].email`}
                      editorOptions={{
                        elementAttr: { class: 'form-editor' },
                        inputAttr: { class: 'form-editor-input' },
                        buttons: [
                          {
                            name: 'copy',
                            location: 'after',
                            options: {
                              visible: !isEditing,
                              stylingMode: 'text',
                              icon: 'copy',
                              hint: 'Copia',
                              onClick: () => {
                                navigator.clipboard.writeText(value).then(
                                  () => {
                                    notify(`Email copiata con successo`, 'success', 2000)
                                  },
                                  (error: any) => {
                                    console.log('Errore copia email', error)
                                    notify(`Errore copia email`, 'error', 2000)
                                  },
                                )
                              },
                            },
                          },
                          {
                            name: 'trash',
                            location: 'after',
                            options: {
                              visible: isEditing,
                              stylingMode: 'text',
                              icon: 'trash',
                              onClick: () => {
                                contact?.emails?.splice(index, 1)
                                setSaveButtonEnabled(true)
                                setContactEmailAddresses(getContactEmailAddresses)
                                // formContactRef.current?.forceUpdate()
                              },
                            },
                          },
                        ],
                      }}
                    >
                      <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
                      <RequiredRule message="L'indirizzo email è una informazione obbligatoria" />
                      <CustomRule
                        message={"L'Indirizzo email è già associato al contatto"}
                        validationCallback={checkEmailIsNotDuplicated}
                      />
                    </SimpleItem>
                  ))}
                  <ButtonItem name="addEmailBtn" visible={isEditing}>
                    <ButtonOptions
                      text={'Aggiungi Email'}
                      icon={'add'}
                      type="default"
                      stylingMode="text"
                      onClick={function () {
                        const emlObj: email_aziendale = {
                          id: 0,
                          email: null,
                          note: null,
                          rif_ad: false,
                        }
                        contact?.emails?.push(emlObj)
                        setSaveButtonEnabled(true)
                        setContactEmailAddresses(getContactEmailAddresses())
                        // formContactRef.current?.forceUpdate()
                      }}
                    />
                  </ButtonItem>
                </GroupItem>
                <SimpleItem
                  dataField="ad_recipient"
                  label={{ text: 'Contatto addizionale quota autodesk' }}
                  editorOptions={{
                    disabled: false,
                  }}
                  editorType="dxSwitch"
                />
                <SimpleItem
                  visible={isPerson && !creating}
                  label={{ text: 'Amministratore Software Autodesk' }}
                  editorOptions={{
                    disabled: true,
                    value: currentAdmin,
                  }}
                  editorType="dxSwitch"
                />
                <SimpleItem
                  visible={isPerson && !creating}
                  label={{ text: 'Contatto principale quota Autodesk' }}
                  editorOptions={{
                    disabled: true,
                    value: currentQuoteContact,
                  }}
                  editorType="dxSwitch"
                />
                <SimpleItem
                  visible={isPerson && !creating}
                  dataField="attivo"
                  label={{ text: 'Attivo' }}
                  editorOptions={{
                    disabled: false,
                  }}
                  editorType="dxSwitch"
                />
                <SimpleItem
                  dataField="ricezione_rapportino"
                  label={{ text: 'Ricezione rapportino' }}
                  editorType="dxSwitch"
                  visible={
                    contact?.sede?.azienda?.is_rivenditore && contact.sede.azienda.invio_rapportino_cliente_disabilitato
                      ? true
                      : false
                  }
                />
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{ height: '100' }}
                ></SimpleItem>
              </GroupItem>
            </Form>
          </div>
          <div className="data-part data-part-toolbar border">
            <Toolbar
              visible={
                contact &&
                'storico_spostamenti_nuovo' in contact &&
                contact.storico_spostamenti_vecchio &&
                contact.storico_spostamenti_vecchio.length > 0
                  ? false
                  : true
              }
            >
              <ToolbarItem visible={!isEditing && !creating}>
                <Button
                  disabled={readOnly}
                  icon="edit"
                  text="Edita"
                  stylingMode="outlined"
                  type="default"
                  width={'100px'}
                  onClick={toggleEditHandler}
                />
              </ToolbarItem>
              <ToolbarItem visible={!isEditing && !creating}>
                <Button
                  disabled={readOnly}
                  icon="trash"
                  text="Elimina"
                  stylingMode="outlined"
                  type="default"
                  width={'100px'}
                  onClick={onDeleteClick}
                />
              </ToolbarItem>
              <ToolbarItem visible={!isEditing && !creating && isPerson}>
                <Button
                  disabled={readOnly}
                  icon="import"
                  text="Migra"
                  stylingMode="outlined"
                  type="default"
                  width={'100px'}
                  onClick={onDataMigratesClick}
                />
              </ToolbarItem>
              <ToolbarItem location="after" visible={isEditing}>
                <Button
                  text="Salva"
                  icon="save"
                  stylingMode="outlined"
                  type="default"
                  disabled={!saveButtonEnabled}
                  width={'150px'}
                  onClick={onSaveClick}
                />
              </ToolbarItem>
              <ToolbarItem location="after" visible={isEditing && !creating}>
                <Button text="Annulla" icon="undo" stylingMode="outlined" width={'150px'} onClick={onCancelClick} />
              </ToolbarItem>
            </Toolbar>
          </div>
        </ValidationGroup>
      </ScrollView>
    </>
  )
}
export default ContactEditorForm
