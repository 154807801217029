import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { OfferEditorFormProps } from '@/routes/offers/OfferEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import {
  analisi,
  azienda,
  commessa,
  contatto_aziendale,
  entita_aziendale,
  gruppo_aziendale,
  sede,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import { Contacts } from '@/types'
import { StatoAnalisi } from '@/routes/analysis/AnalysisEditor.enums'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import { AxiosError, AxiosResponse } from 'axios'
import saveAs from 'file-saver'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { customButton, customDisplayExpr, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'
import FormPopupContact from '@/routes/contacts/person/FormPopupContact'
import { ValidationCallbackData } from 'devextreme/common'

const OfferEditorForm = (props: OfferEditorFormProps) => {
  const {
    creating,
    offer,
    stati,
    aziende,
    impiegati,
    analisi,
    persone,
    entita,
    commesse,
    documentoGetRef,
    templates,
    isRoleAllowed,
    userInfo,
    fetcher,
  } = props

  const { clientId } = useParams()
  const [searchParams] = useSearchParams()
  const projectId = searchParams.get('projectId')
  const analysisId = searchParams.get('analysisId')

  const { service, client } = useQsAdminApiManager()
  const currentScreenSize = useScreenSize()
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const formRef = useRef<FormRef>(null)
  const revalidator = useRevalidator()
  const [loadPanelVisible, setLoadPanelVisible] = useState(false)
  const navigate = useNavigate()

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(
    clientId && creating && !projectId ? true : false,
  )

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? aziende[0] : offer?.sede?.azienda,
  )

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', offer?.sede?.azienda?.sedi),
  )
  const [personeDataSource, setPersoneDataSource] = useState<DataSource>(ArraySourceComposer('id', persone))

  const [entitaDataSource, setEntitaDataSource] = useState<DataSource>(ArraySourceComposer('id', entita))

  const [analisiDataSource, setAnalisiDataSource] = useState<DataSource>(ArraySourceComposer('id', analisi))

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  const [currentGruppoAziendale, setCurrentGruppoAziendale] = useState<gruppo_aziendale | null | undefined>(
    offer?.sede?.azienda?.gruppo_aziendale,
  )
  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const refNuovoContattoPopup = useRef<PopupRef>(null)

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
          rivenditoreBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppoAziendaleBuilder, gruppo_aziendale) => {
          gruppoAziendaleBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'rivenditore', 'gruppo_aziendale')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteContacts = useCallback(async () => {
    const personeData = await service.contatto_aziendale().query((builder, persona) => {
      builder.filter(persona.sede.props.id.eq(Number(offer.sede?.id)))
      builder.filter(persona.attivo.eq(true))
      builder.orderBy(persona.fullname.asc())
      builder.expanding('emails', (emailsBuilder, email) => {
        emailsBuilder.select('id', 'email')
      })
      builder.select('id', 'fullname', 'emails')
    })
    const entitaData = await service.entita_aziendale().query((builder, entita) => {
      builder.filter(entita.sede.props.id.eq(Number(offer.sede?.id)))
      builder.orderBy(entita.nome.asc())
      builder.expanding('emails', (emailsBuilder, email) => {
        emailsBuilder.select('id', 'email')
      })
      builder.select('id', 'nome', 'emails')
    })
    return {
      persone: personeData.data.value,
      entita: entitaData.data.value,
    }
  }, [offer.sede?.id, service])

  const getSiteAnalisi = useCallback(async () => {
    console.log('gruppo', currentGruppoAziendale)
    const analisiData = await service.analisi().query((builder, analisi) => {
      builder.filter(
        currentGruppoAziendale
          ? analisi.sede.props.azienda.props.gruppo_aziendale.props.id.eq(Number(currentGruppoAziendale.id))
          : analisi.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.filter(
        creating ? analisi.stato.props.id.eq(StatoAnalisi.COMPLETATA).and(analisi.offerte.any().not()) : null,
      )
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.select('id', 'ded_Dis', 'sede')
      builder.orderBy(analisi.ded_Dis.desc())
    })

    return analisiData.data.value
  }, [creating, currentCompany?.id, currentGruppoAziendale, service])

  const getSiteCommesse = useCallback(async () => {
    console.log('gruppo', currentGruppoAziendale)
    const commesseData = await service.commessa().query((builder, commessa) => {
      builder.filter(
        currentGruppoAziendale
          ? commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(Number(currentGruppoAziendale.id))
          : commessa.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.orderBy(commessa.ded_Dis.desc())
      builder.select('id', 'ded_Dis', 'titolo', 'sede')
    })

    return commesseData.data.value
  }, [currentCompany?.id, currentGruppoAziendale, service])

  const getAziendaData = useCallback(async () => {
    const aziendaData = await service.azienda(Number(currentCompany?.id)).query((builder, azienda) => {
      builder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
        rivenditoreBuilder.select('id', 'nome')
      })
      builder.expanding('gruppo_aziendale', (gruppoBuilder, gruppo) => {
        gruppoBuilder.select('id', 'nome')
      })
      builder.select('rivenditore', 'gruppo_aziendale')
    })
    return aziendaData.data
  }, [currentCompany?.id, service])

  //commessa form popup
  const [currentSite, setCurrentSite] = useState<sede>()
  const popupRefProject = useRef<PopupRef>(null)

  const handleOnClickNuovaRev = (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/offerta/createrevision', offer.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        navigate(`../${response.data.id}`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickExportPDF = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .get('/api/offerta/exportpdf', {
        params: { offerId: Number(offer.id) },
        headers: {
          'Content-Disposition': `attachment;filename=${offer.ded_Dis}.pdf`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${offer.ded_Dis}.pdf`)
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  const handleOnClickSendOfferToReseller = async (e: ClickEvent) => {
    setLoadPanelVisible(true)
    client
      .post('/api/offerta/sendoffertoreseller', offer.id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        console.log('response', response.data)
        notify(`Offerta inviata al rivenditore.`, 'success', 2000)
      })
      .catch((error: AxiosError) => {
        notify(`Errore invio offerta al rivenditore. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        setLoadPanelVisible(false)
      })
  }

  //popolazione datasource lookup sede
  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      getAziendaData().then((company: azienda) => {
        console.log('data', company)
        setCurrentGruppoAziendale(company.gruppo_aziendale ?? null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, getCompanySites, currentCompany, getAziendaData])

  //popolazione datasource lookup riferimenti, analisi e commessa
  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', offer.sede)
    if (offer.sede) {
      getSiteContacts().then((contacts: Contacts) => {
        console.log('contacts', contacts)
        setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
        formRef.current?.instance().updateData('riferimenti', [])
        setEntitaDataSource(ArraySourceComposer('id', contacts.entita))
        formRef.current?.instance().updateData('riferimenti_entita', [])
      })
      if (!analysisId && !projectId && !clientId) {
        getSiteAnalisi().then((analisi: analisi[]) => {
          console.log('analisi', analisi)
          setAnalisiDataSource(ArraySourceComposer('id', analisi))
          formRef.current?.instance().updateData('analisi', null)
        })
        getSiteCommesse().then((commesse: commessa[]) => {
          console.log('commesse', commesse)
          setCommesseDataSource(ArraySourceComposer('id', commesse))
          formRef.current?.instance().updateData('commessa', null)
        })
      }
    } else {
      setPersoneDataSource(ArraySourceComposer('id', persone))
      setAnalisiDataSource(ArraySourceComposer('id', analisi))
      setCommesseDataSource(ArraySourceComposer('id', commesse))
      setEntitaDataSource(ArraySourceComposer('id', entita))
    }
    setCurrentSiteChanged(false)
  }, [
    analisi,
    analysisId,
    clientId,
    commesse,
    currentSiteChanged,
    entita,
    getSiteAnalisi,
    getSiteCommesse,
    getSiteContacts,
    offer.sede,
    persone,
    projectId,
  ])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle' && !loadPanelVisible)} />
      <DXForm
        id={'offer_form'}
        formData={offer}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="offerValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={2}>
          <GroupItem colCount={1}>
            <GroupItem colCount={3}>
              <SimpleItem
                label={{ text: 'Azienda' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton('open', (e: ClickEvent) => {
                      if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                      else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                    }),
                    { name: 'dropDown', location: 'after' },
                  ],
                  value: currentCompany,
                  dataSource: ArraySourceComposer('id', aziende),
                  placeholder: "Seleziona l'azienda...",
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: "Selezionare l'azienda",
                    hideOnOutsideClick: true,
                  },
                  readOnly: !isRoleAllowed || (clientId ? true : false),
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log(e)
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  },
                }}
              >
                <RequiredRule message="Azienda obbligatoria"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="sede"
                label={{ text: 'Sede' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'info',
                      () => {
                        if (offer.sede) {
                          popupNoteClienteRef.current?.instance().show()
                        } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                      },
                      false,
                      'Note cliente',
                    ),
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: sediDataSource,
                  placeholder: 'Seleziona la sede...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  showDropDownButton: true,
                  dropDownButtonTemplate: 'dropDownButton',
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la sede',
                    hideOnOutsideClick: true,
                  },
                  readOnly: !isRoleAllowed,
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    if (e.previousValue?.id === e.value?.id) return
                    console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                    setCurrentSiteChanged(true)
                  },
                }}
              >
                <RequiredRule message="Sede obbligatoria"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                label={{ text: 'Rivenditore' }}
                editorType="dxTextBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'email',
                      (e: ClickEvent) => handleOnClickSendOfferToReseller,
                      creating ||
                        !offer?.sede?.azienda?.rivenditore ||
                        !isUserRoleAllowed(userInfo?.roles, [
                          Roles.GlobalAdministrator,
                          Roles.Administrator,
                          Roles.Sales,
                        ]),
                      'Invia Offerta Rivenditore',
                    ),
                  ],
                  value: offer?.sede?.azienda?.rivenditore?.nome ?? '',
                  readOnly: true,
                }}
              />
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="riferimenti"
                editorType="dxTagBox"
                label={{ text: 'Riferimenti' }}
                editorOptions={{
                  buttons: [
                    customButton(
                      'add',
                      (e: ClickEvent) => {
                        if (offer.sede) {
                          const contact: contatto_aziendale = {
                            id: 0,
                            fullname: null,
                            ad_recipient: false,
                            nome: null,
                            cognome: null,
                            ruolo: null,
                            note: null,
                            titolo: null,
                            telefono: null,
                            attivo: true,
                            sede: offer.sede,
                            emails: [
                              {
                                id: 0,
                                email: null,
                                note: null,
                                rif_ad: false,
                              },
                            ],
                            hasLinkedObjects: false,
                            ricezione_rapportino: false,
                          }
                          setCurrentContact(contact)
                          refNuovoContattoPopup.current?.instance().show()
                        } else
                          notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                      },
                      !isRoleAllowed,
                    ),
                  ],
                  dataSource: personeDataSource,
                  // displayExpr: 'fullname',
                  placeholder: 'Seleziona riferimenti...',
                  displayExpr: (item: contatto_aziendale) => {
                    let displayExpreValue: string = ''
                    if (item) {
                      displayExpreValue += item.fullname
                      if (item.emails && item.emails.length > 0) displayExpreValue += ` - ${item.emails[0].email}`
                    }
                    return displayExpreValue
                  },
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                  searchEnabled: true,
                }}
              >
                <CustomRule
                  message="Riferimenti persona obbligatorio se 'Riferimenti entità non presente"
                  validationCallback={(options: ValidationCallbackData) => {
                    if (offer?.sede?.azienda?.rivenditore) return true
                    else
                      return options.value &&
                        options.value.length === 0 &&
                        offer.riferimenti_entita &&
                        offer.riferimenti_entita.length === 0
                        ? false
                        : true
                  }}
                  reevaluate={true}
                />
              </SimpleItem>
              <SimpleItem
                dataField="riferimenti_entita"
                editorType="dxTagBox"
                label={{ text: 'Riferimenti entità' }}
                editorOptions={{
                  dataSource: entitaDataSource,
                  // displayExpr: 'nome',
                  displayExpr: (item: entita_aziendale) => {
                    let displayExpreValue: string = ''
                    if (item) {
                      displayExpreValue += item.nome
                      if (item.emails && item.emails.length > 0) displayExpreValue += ` - ${item.emails[0].email}`
                    }
                    return displayExpreValue
                  },
                  placeholder: 'Seleziona riferimenti...',
                  applyValueMode: 'instantly',
                  showSelectionControls: true,
                  searchEnabled: true,
                }}
              >
                <CustomRule
                  message="Riferimenti entità obbligatorio se 'Riferimenti persona' non presente"
                  validationCallback={(options: ValidationCallbackData) => {
                    if (offer?.sede?.azienda?.rivenditore) return true
                    else
                      return options.value &&
                        options.value.length === 0 &&
                        offer.riferimenti &&
                        offer.riferimenti.length === 0
                        ? false
                        : true
                  }}
                  reevaluate={true}
                />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="stato"
                label={{ text: 'Stato' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', stati),
                  placeholder: 'Seleziona lo stato...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare lo stato',
                    hideOnOutsideClick: true,
                  },
                  readOnly: creating || !isRoleAllowed,
                }}
              >
                <RequiredRule message="Stato obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="data_creazione"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  type: 'date',
                  pickerType: 'calendar',
                  applyValueMode: 'instantly',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Creazione' }}
              >
                <RequiredRule message="Data creazione obbligatoria" />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={offer.ordini && offer.ordini.length > 0 ? 3 : 2}>
              {offer.ordini && offer.ordini.length > 0 && (
                <SimpleItem
                  label={{ text: 'Ordine' }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    buttons: [
                      customButton(
                        'open',
                        (e: ClickEvent) => {
                          if (offer.ordini) window.open(`/orders/${offer.ordini[0].id}`, '_blank')
                        },
                        !isRoleAllowed,
                      ),
                    ],
                    dataSource: ArraySourceComposer('id', offer.ordini),
                    value: offer.ordini ? offer.ordini[0] : null,
                    placeholder: 'Seleziona ordine...',
                    displayExpr: 'ded_Dis',
                    searchEnabled: true,
                    dropDownCentered: true,
                    dropDownOptions: {
                      showTitle: true,
                      title: 'Selezionare ordine',
                      hideOnOutsideClick: true,
                    },
                    readOnly: true,
                  }}
                ></SimpleItem>
              )}

              <SimpleItem
                dataField="analisi"
                label={{ text: 'Analisi' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'open',
                      (e: ClickEvent) => {
                        if (offer.analisi) window.open(`/analysis/${offer.analisi.id}`, '_blank')
                        else notify(`Deve essere selezionata un' analisi per poterla aprire.`, 'warning', 3000)
                      },
                      !isRoleAllowed,
                    ),
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: analisiDataSource,
                  placeholder: 'Seleziona analisi...',
                  // displayExpr: 'ded_Dis',
                  displayExpr: customDisplayExpr,
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare analisi',
                    hideOnOutsideClick: true,
                    width: currentScreenSize.isLarge ? '150%' : '',
                    resizeEnabled: currentScreenSize.isLarge ? true : false,
                  },
                  readOnly: analysisId,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="commessa"
                label={{ text: 'Commessa' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    customButton(
                      'add',
                      () => {
                        if (offer.sede) {
                          setCurrentSite(offer.sede)
                          popupRefProject.current?.instance().show()
                        } else
                          notify(`Deve essere selezionata una sede per poter creare una commessa.`, 'warning', 3000)
                      },
                      !isRoleAllowed || (projectId !== null && projectId !== undefined),
                    ),
                    customButton(
                      'open',
                      () => {
                        if (offer.commessa) window.open(`/projects/${offer.commessa.id}`, '_blank')
                        else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                      },
                      false,
                    ),
                    {
                      name: 'clear',
                      location: 'after',
                    },
                    { name: 'dropDown', location: 'after' },
                  ],
                  dataSource: commesseDataSource,
                  placeholder: 'Seleziona commessa...',
                  displayExpr: (item: commessa) => {
                    return item
                      ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}${item.sede?.azienda?.nome ? ' - ' : ''}${item.sede?.azienda?.nome ?? ''}${item.sede?.nome ? ' - ' : ''}${item.sede?.nome ?? ''}`
                      : ``
                  },
                  showDropDownButton: true,
                  dropDownButtonTemplate: 'dropDownButton',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare commessa',
                    hideOnOutsideClick: true,
                    width: currentScreenSize.isLarge ? '150%' : '',
                    resizeEnabled: currentScreenSize.isLarge ? true : false,
                  },
                  readOnly: !isRoleAllowed || (projectId !== null && projectId !== undefined),
                }}
              >
                <RequiredRule message="Commessa obbligatoria"></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="autore"
                label={{ text: 'Autore' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', impiegati),
                  placeholder: 'Seleziona autore...',
                  displayExpr: 'fullname',
                  searchEnabled: true,
                  dropDownCentered: true,
                  readOnly: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare autore',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Autore obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="template"
                label={{ text: 'Template' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('id', templates),
                  placeholder: 'Seleziona template...',
                  displayExpr: 'nome',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare template',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Template obbligatorio" />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="attivitaTecnicaRichiesta"
                label={{ text: 'Attività Tecnica Richiesta' }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: [true, false],
                  displayExpr: (item: any) => {
                    console.log('displayExpr', item)
                    if (item === true) return 'SI'
                    else if (item === false) return 'NO'
                    else return null
                  },
                  placeholder: 'Seleziona flag...',
                  searchEnabled: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare falg',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <CustomRule
                  type="custom"
                  message="Attivita tecnica richiesta obbligatoria"
                  validationCallback={(options: {
                    column: Record<string, any>
                    data: Record<string, any>
                    formItem: Record<string, any>
                    rule: Record<string, any>
                    validator: Record<string, any>
                    value: string | number
                  }) => {
                    const value = options.value
                    try {
                      return value.toString() === 'true' || value.toString() === 'false' ? true : false
                    } catch {
                      //se entro nel catch è perche non si riesce ad applicare il toString() su un valore null
                      //quindi il campo non è sicuramente valorizzato e ritorno false sulla validazione
                      return false
                    }
                  }}
                ></CustomRule>
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem colSpan={1}>
            <SimpleItem
              dataField={'note'}
              editorType={'dxTextArea'}
              label={{ visible: true }}
              editorOptions={{
                height: '330',
              }}
            >
              <RequiredRule message="Note obbligatorie"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 4 : 5}>
            <ButtonItem name="btn-newOrder">
              <ButtonOptions
                text="Ordine"
                icon="ordersbox"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={(e: ClickEvent) => {
                  if (offer.stato?.id === StatoOfferta.APERTA)
                    navigate({
                      pathname: '../../orders/new',
                      search: `?offerId=${offer?.id}`,
                    })
                  else
                    notify(`L'offerta deve essere nello stato 'APERTA'. Stato: '${offer.stato?.nome}'`, 'warning', 3000)
                }}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Administrator,
                    Roles.Supervisor,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-exportPDF">
              <ButtonOptions
                text="esporta pdf"
                icon="pdffile"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickExportPDF}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Administrator,
                    Roles.Sales,
                    Roles.ExternalSales,
                    Roles.Supervisor,
                  ])
                }
              />
            </ButtonItem>

            <ButtonItem name="btn-newRev">
              <ButtonOptions
                text="nuova rev"
                icon="newfolder"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={handleOnClickNuovaRev}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Administrator,
                    Roles.Sales,
                    Roles.ExternalSales,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('offer', offer)
                  try {
                    if (offer) {
                      fetcher?.submit(JSON.stringify(offer), {
                        method: creating ? 'POST' : 'PUT',
                        encType: 'application/json',
                      })
                    } else console.error('no data tu submit')
                  } finally {
                    documentoGetRef.current?.refresh(true)
                  }
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupProject
        popupRef={popupRefProject}
        sede={currentSite}
        azienda={currentCompany}
        refreshCommessa={async () => {
          getSiteCommesse().then((res: commessa[]) => {
            setCommesseDataSource(ArraySourceComposer('id', res))
            formRef.current?.instance().updateData('commessa', res[0])
            popupRefProject.current?.instance().hide()
          })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={offer.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === offer.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
      <FormPopupContact
        migrating={false}
        popupRef={refNuovoContattoPopup}
        contact={currentContact}
        onContactSaved={(newContact) => {
          refNuovoContattoPopup.current?.instance().hide()
          getSiteContacts().then((contacts: { persone: contatto_aziendale[]; entita: entita_aziendale[] }) => {
            formRef.current?.instance().updateData('riferimenti', [newContact])
            setPersoneDataSource(ArraySourceComposer('id', contacts.persone))
          })
        }}
      ></FormPopupContact>
    </>
  )
}

export default OfferEditorForm
