import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  EmptyItem,
  GroupItem,
  RequiredRule,
  SimpleItem,
  FormRef,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { azienda, commessa, gruppo_aziendale, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { PackageSaleEditorFormProps } from '@/routes/packages/sale/PackageSaleEditor.types'
import notify from 'devextreme/ui/notify'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { customButton, isUserRoleAllowed } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'

const PackageSaleEditorForm = (props: PackageSaleEditorFormProps) => {
  const { pacchetto, aziende, tipologieDurata, creating, commesse, isRoleAllowed, userInfo, fetcher } = props
  const { clientId } = useParams()

  const { service } = useQsAdminApiManager()
  const revalidator = useRevalidator()
  const currentScreenSize = useScreenSize()

  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)
  const popupRefProject = useRef<PopupRef>(null)

  const [currentSite, setCurrentSite] = useState<sede>()

  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? aziende[0] : pacchetto?.sede?.azienda,
  )

  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', pacchetto?.sede?.azienda?.sedi),
  )

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  const [currentGruppoAziendale, setCurrentGruppoAziendale] = useState<gruppo_aziendale | null | undefined>(
    pacchetto?.sede?.azienda?.gruppo_aziendale,
  )

  const getAziendaData = useCallback(async () => {
    const aziendaData = await service.azienda(Number(currentCompany?.id)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppoBuilder, gruppo) => {
        gruppoBuilder.select('id', 'nome')
      })
      builder.select('gruppo_aziendale')
    })
    return aziendaData.data
  }, [currentCompany?.id, service])

  const getCompanySites = useCallback(async () => {
    const sitesData = await service.sede().query((builder, sede) => {
      builder.filter(sede.azienda.props.id.eq(Number(currentCompany?.id)))
      builder.orderBy(sede.principale.desc())
      builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
        aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
          sediAziendaSedeBuilder.select('id', 'nome', 'note')
          sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
        })
        aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
          gruppo_aziendaleBuilder.select('id', 'nome')
        })
        aziendaSedeBuilder.select('id', 'nome', 'sedi', 'gruppo_aziendale')
      })
      builder.select('id', 'nome', 'azienda', 'note')
    })
    return sitesData.data.value
  }, [currentCompany?.id, service])

  const getSiteProjects = useCallback(async () => {
    const projectsData = await service.commessa().query((builder, commessa) => {
      builder.filter(
        currentGruppoAziendale
          ? commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(Number(currentGruppoAziendale.id))
          : commessa.sede.props.azienda.props.id.eq(Number(currentCompany?.id)),
      )
      builder.filter(commessa.stato.props.id.eq(StatoCommessa.APERTA))
      builder.expanding('sede', (sedeBuilder) => {
        sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
          aziendaBuilder.select('id', 'nome')
        })
        sedeBuilder.select('id', 'nome', 'azienda')
      })
      builder.orderBy(commessa.ded_Dis.desc())
      builder.select('id', 'ded_Dis', 'titolo', 'sede')
    })
    return projectsData.data.value
  }, [service, currentGruppoAziendale, currentCompany?.id])

  useEffect(() => {
    if (!currentCompanyChanged) return
    if (currentCompany) {
      getCompanySites().then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      getAziendaData().then((company: azienda) => {
        console.log('data', company)
        setCurrentGruppoAziendale(company.gruppo_aziendale ?? null)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites, getAziendaData])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', pacchetto.sede)
    if (pacchetto.sede) {
      if (!clientId)
        getSiteProjects().then((commesse: commessa[]) => {
          console.log('commesse', commesse)
          setCommesseDataSource(ArraySourceComposer('id', commesse))
          formRef.current?.instance().updateData('commessa', null)
        })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, pacchetto.sede, getSiteProjects, clientId])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'package_sale_form'}
        formData={pacchetto}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="packageSaleValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton('open', (e: ClickEvent) => {
                    if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                    else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                  }),
                  { name: 'dropDown', location: 'after' },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="sede"
              label={{ text: 'Sede' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'info',
                    () => {
                      if (pacchetto.sede) {
                        popupNoteClienteRef.current?.instance().show()
                      } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
                    },
                    false,
                    'Note cliente',
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: sediDataSource,
                placeholder: 'Seleziona la sede...',
                displayExpr: 'nome',
                searchEnabled: true,
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare la sede',
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed,
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) setCurrentSiteChanged(true)
                },
              }}
            >
              <RequiredRule message="Sede obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="creazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Creazione' }}
            >
              <RequiredRule message="Data creazione obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="data_fatturazione"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                type: 'date',
                pickerType: 'calendar',
                applyValueMode: 'instantly',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Fattura' }}
            ></SimpleItem>
            <SimpleItem
              dataField="commessa"
              label={{ text: 'Commessa' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  customButton(
                    'add',
                    () => {
                      if (pacchetto.sede) {
                        setCurrentSite(pacchetto.sede)
                        popupRefProject.current?.instance().show()
                      } else
                        notify(`Deve essere selezionata un' azienda per poter creare una commessa.`, 'warning', 3000)
                    },
                    !isRoleAllowed,
                  ),
                  customButton(
                    'open',
                    () => {
                      if (pacchetto.commessa) window.open(`/projects/${pacchetto?.commessa?.id}`, '_blank')
                      else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
                    },
                    false,
                  ),
                  {
                    name: 'clear',
                    location: 'after',
                  },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: commesseDataSource,
                placeholder: 'Seleziona commessa...',
                displayExpr: (item: commessa) => {
                  return item
                    ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}${item.sede?.azienda?.nome ? ' - ' : ''}${item.sede?.azienda?.nome ?? ''}${item.sede?.nome ? ' - ' : ''}${item.sede?.nome ?? ''}`
                    : ``
                },
                showDropDownButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                searchEnabled: true,
                showClearButton: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare commessa',
                  hideOnOutsideClick: true,
                  width: currentScreenSize.isLarge ? '150%' : '',
                  resizeEnabled: currentScreenSize.isLarge ? true : false,
                },
                readOnly: !isRoleAllowed,
              }}
            ></SimpleItem>
            <EmptyItem></EmptyItem>
            <SimpleItem
              dataField="tipologia_unita"
              label={{ text: 'Tipologia durata' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', tipologieDurata),
                placeholder: 'Seleziona tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipologia',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Tipologia durata obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="unita"
              editorType="dxNumberBox"
              label={{ text: 'Durata' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
                step: 0.5,
              }}
            >
              <RequiredRule message="Durata obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="costo_unita"
              editorType="dxNumberBox"
              label={{ text: 'Costo Unitario' }}
              editorOptions={{
                showSpinButtons: true,
                min: 0,
              }}
            >
              <RequiredRule message="Costo Unitario obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem dataField="consuntivo" label={{ text: 'A consuntivo' }} editorType="dxSwitch" />
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField={'note'}
              editorType={'dxTextArea'}
              label={{ visible: true }}
              editorOptions={{
                height: '350',
              }}
            >
              <RequiredRule message="Note obbligatorio"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 1 : 2}>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('pacchetto', pacchetto)
                  if (pacchetto) {
                    fetcher?.submit(JSON.stringify(pacchetto), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <FormPopupProject
        popupRef={popupRefProject}
        sede={currentSite}
        azienda={currentCompany}
        refreshCommessa={async () => {
          getSiteProjects().then((res: commessa[]) => {
            setCommesseDataSource(ArraySourceComposer('id', res))
            formRef.current?.instance().updateData('commessa', res[0])
            popupRefProject.current?.instance().hide()
          })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={pacchetto.sede}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites().then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === pacchetto.sede?.id
                }),
              )
            })
          }
        }}
        isRoleAllowed={isUserRoleAllowed(userInfo?.roles, [
          Roles.TechDeveloper,
          Roles.Sales,
          Roles.GlobalAdministrator,
          Roles.ExternalSales,
          Roles.Administrator,
        ])}
      ></FormPopupClientNote>
    </>
  )
}

export default PackageSaleEditorForm
