import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { attivita_comm, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import ActivityEditor from '@/routes/activities/ActivityEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { Roles } from '@/auth/azure/Roles'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { Qattivita_comm, Qcommessa } from '@/model/qsadminapi/QQsAdminApiModule'

export const activityEditorRouteLoader = (async ({
  request,
  params,
}: RouteFunctionParams<'activityId' | 'clientId'>) => {
  const activityId = params.activityId
  const clientId = params.clientId
  const activityPrecId = new URL(request.url).searchParams.get('activityPrecId')
  const projectId = new URL(request.url).searchParams.get('projectId')

  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()
  const user = await qsAdminApi.user().query((builder, impiegato) => {
    builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
    builder.expanding('azienda', (aziendaBuilder, azienda) => {
      aziendaBuilder.select('id', 'nome')
    })
    builder.select('id', 'fullname', 'azienda')
  })

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
        gruppo_aziendaleBuilder.select('id', 'nome')
      })
      builder.select('id', 'nome', 'gruppo_aziendale')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``

  let activity: attivita_comm

  const today = new Date().toISOString()
  const fullYear = new Date().getFullYear()
  if (activityId) {
    //editor
    const activityData = await qsAdminApi
      .attivita_comm(+activityId)
      .query((builder, attivita) => {
        builder.expanding('sede', (sedeBuilder, sede) => {
          sedeBuilder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
            aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
              sediAziendaSedeBuilder.select('id', 'nome', 'note')
              // sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc()) quando sede non è presente su attività, esplode l'ordinamento
            })
            aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
              agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commercialeQs) => {
                commercialeQsBuilder.select('id', 'fullname')
              })
              agenteBuilder.select('id', 'commerciale_qs')
            })
            aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
              rivenditoreBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppo_aziendaleBuilder, gruppo_aziendale) => {
              gruppo_aziendaleBuilder.select('id', 'nome')
            })
            aziendaSedeBuilder.select('id', 'nome', 'sedi', 'agente', 'rivenditore', 'gruppo_aziendale')
          })
          sedeBuilder.select('id', 'nome', 'azienda', 'note')
        })
        builder.expanding('autore', (autoreBuilder, autore) => {
          autoreBuilder.select('id', 'fullname')
        })
        builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
          contattoBuilder.select('id', 'fullname')
        })
        builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
          tipologiaBuilder.select('id', 'nome')
        })
        builder.expanding('stato', (statoBuilder, stato) => {
          statoBuilder.select('id', 'nome')
        })
        builder.expanding('precedente', (precedenteBuilder, precedeteActivity) => {
          precedenteBuilder.select('id')
        })
        builder.expanding('commessa', (commessaBuilder, commessa) => {
          commessaBuilder.select('id', 'titolo', 'ded_Dis')
        })
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Attività commerciale non trovata')
        throw new Error()
      })
    activity = activityData.data satisfies attivita_comm
    title += `${activity.ded_Dis}`
    if (clientId && activity?.sede?.azienda && activity?.sede?.azienda.id !== Number(clientId)) {
      throw new Error(
        `L'attività ${activity.ded_Dis} appartiene al cliente ${activity?.sede?.azienda.nome} e non al cliente corrente`,
      )
    }
  } else {
    //creator

    //followup
    const precActivityData = activityPrecId
      ? await qsAdminApi.attivita_comm(+activityPrecId).query((builder, activity) => {
          builder.expanding('contatto_aziendale', (contattoBuilder, contatto) => {
            contattoBuilder.select('id', 'fullname')
          })
          builder.expanding('tipologia', (tipologiaBuilder, tipologia) => {
            tipologiaBuilder.select('id', 'nome')
          })
          builder.select('id', 'ded_Dis')
        })
      : undefined

    //commessa eventuale dell'attività di partenza
    const projectPrecActivityData = precActivityData?.data
      ? await qsAdminApi.commessa().query((commessaBuilder, commessa) => {
          commessaBuilder.filter(
            commessa.attivita_commerciali.any((attivita: Qattivita_comm) => attivita.id.eq(precActivityData?.data.id)),
          )
          commessaBuilder.select('id', 'ded_Dis', 'titolo')
        })
      : undefined

    //creator a partire da commessa
    const projectData = projectId
      ? await qsAdminApi.commessa(Number(projectId)).query((builder, project) => {
          builder.select('id', 'ded_Dis', 'titolo')
        })
      : undefined

    const sedeData =
      precActivityData?.data || projectData?.data
        ? await qsAdminApi.sede().query((sedeBuilder, sede) => {
            if (precActivityData?.data)
              sedeBuilder.filter(
                sede.attivita_commerciali.any((attivita: Qattivita_comm) => attivita.id.eq(precActivityData?.data.id)),
              )
            if (projectData?.data)
              sedeBuilder.filter(sede.commesse.any((commessa: Qcommessa) => commessa.id.eq(projectData?.data.id)))
            sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
              aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
                sediAziendaSedeBuilder.select('id', 'nome', 'note')
                sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
              })
              aziendaSedeBuilder.expanding('agente', (agenteBuilder, agente) => {
                agenteBuilder.expanding('commerciale_qs', (commercialeQsBuilder, commercialeQs) => {
                  commercialeQsBuilder.select('id', 'fullname')
                })
                agenteBuilder.select('id', 'commerciale_qs')
              })
              aziendaSedeBuilder.expanding('rivenditore', (rivenditoreBuilder, rivenditore) => {
                rivenditoreBuilder.select('id', 'nome')
              })
              aziendaSedeBuilder.expanding('gruppo_aziendale', (gruppoAziendaleBuilder, gruppo_aziendale) => {
                gruppoAziendaleBuilder.select('id', 'nome')
              })
              aziendaSedeBuilder.select('id', 'nome', 'sedi', 'agente', 'rivenditore', 'gruppo_aziendale')
            })
            sedeBuilder.select('id', 'nome', 'azienda', 'note')
          })
        : undefined

    activity = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_Id: null,
      attachmentsFolderUrl: '',
      inizio: today,
      fine: null,
      creazione: today,
      descrizione: null,
      note_follow_up: null,
      anno_rif: fullYear,
      precedente: precActivityData?.data ?? null,
      sede: sedeData?.data.value[0] ?? null,
      contatto_aziendale: precActivityData?.data.contatto_aziendale ?? null,
      tipologia: precActivityData?.data.tipologia ?? null,
      stato: null,
      autore: user.data.value[0],
      commessa: projectPrecActivityData?.data.value[0] ?? projectData?.data ?? null,
    } satisfies attivita_comm

    title += activityPrecId
      ? `Nuova attività: follow up di attività ${activity?.precedente?.ded_Dis}`
      : `Nuova attività`
  }

  //query popolazione lookup
  const getCommesse =
    activity.sede || clientId
      ? qsAdminApi.commessa().query((builder, commessa) => {
          if (activity.sede?.azienda?.gruppo_aziendale?.id)
            builder.filter(
              commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(activity.sede.azienda.gruppo_aziendale.id),
            )
          else if (azienda?.data.gruppo_aziendale?.id)
            builder.filter(
              commessa.sede.props.azienda.props.gruppo_aziendale.props.id.eq(azienda?.data.gruppo_aziendale?.id),
            )
          else
            builder.filter(
              commessa.sede.props.azienda.props.id.eq(Number(activity.sede?.azienda?.id || azienda?.data.id)),
            )
          builder.filter(
            commessa.stato.props.id
              .eq(StatoCommessa.APERTA)
              .or(activity.commessa ? commessa.id.eq(Number(activity.commessa?.id)) : null),
          )
          builder.expanding('sede', (sedeBuilder) => {
            sedeBuilder.expanding('azienda', (aziendaBuilder, azienda) => {
              aziendaBuilder.select('id', 'nome')
            })
            sedeBuilder.select('id', 'nome', 'azienda')
          })
          builder.select('id', 'ded_Dis', 'titolo', 'sede')
          builder.orderBy(commessa.ded_Dis.desc())
        })
      : undefined

  const getTipologie = qsAdminApi.tipologia_attivita_comm().query((builder, tipologia) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia.nome.asc())
  })

  const getStati = qsAdminApi.stato_att_comm().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const getContatti = qsAdminApi.contatto_aziendale().query((builder, contatto) => {
    builder.filter(activity.sede ? contatto.sede.props.id.eq(activity.sede.id) : null)
    builder.filter(
      contatto.attivo
        .eq(true)
        .or(activity.contatto_aziendale ? contatto.id.eq(Number(activity.contatto_aziendale?.id)) : null),
    )
    builder.select('id', 'fullname')
    builder.orderBy(contatto.fullname.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    if (projectId || activityPrecId)
      builder.filter(
        activity.sede?.azienda?.gruppo_aziendale
          ? azienda.gruppo_aziendale.props.id.eq(Number(activity.sede.azienda.gruppo_aziendale.id))
          : activity.sede
            ? azienda.id.eq(Number(activity.sede.azienda?.id))
            : null,
      )
    else builder.filter(clientId ? azienda.id.eq(Number(clientId)) : null)
    if (userInfo?.roles?.hasRole(Roles.Sales))
      builder.filter(
        azienda.agente.props.commerciale_qs.props.id
          .eq(Number(user.data.value[0].id))
          .or(activity.sede ? azienda.id.eq(Number(activity.sede.azienda?.id)) : null),
      )
    if (userInfo?.roles?.hasRole(Roles.ExternalSales))
      builder.filter(
        azienda.rivenditore.props.id
          .eq(Number(user.data.value[0].azienda?.id))
          .or(activity.sede ? azienda.id.eq(Number(activity.sede.azienda?.id)) : null),
      )
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  return defer({
    title,
    activity,
    getCommesse,
    getTipologie,
    getStati,
    getContatti,
    getAziende,
    user,
    userInfo,
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Supervisor,
    ],
  })
}) satisfies LoaderFunction

export const activityEditorRouteAction = (async ({ request, params }: RouteFunctionParams) => {
  const activity = (await request.json()) as attivita_comm
  const qsAdminApi = useQsAdminApiManager.getState().service
  console.log('activity', activity)

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.attivita_comm().create(activity)
      console.log(res)
      return redirect(`../${res.data.id}`)
    }
    case 'PUT': {
      return await qsAdminApi.attivita_comm(activity.id).update(activity)
    }
    case 'PATCH': {
      return await qsAdminApi.attivita_comm(activity.id).patch(activity)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const ActivityEditorRoute = {
  path: ':activityId',
  element: <ActivityEditor creating={false} />,
  loader: activityEditorRouteLoader,
  action: activityEditorRouteAction,
  allowedRoles: [
    Roles.Sales,
    Roles.Administrator,
    Roles.GlobalAdministrator,
    Roles.Marketing,
    Roles.Supervisor,
    Roles.ExternalSales,
  ],
} as RoleRouteObject

export default ActivityEditorRoute
