import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { qqs_storico_licenza } from '@/model/qsadminapi/QQsAdminApiModule'
import type { Editableqs_licenza, qs_licenza, azienda } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { SoftwareQsEditor } from '@/routes/software/qs/SoftwareQsEditor'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { FormMode } from '@/enums'
import { redirect } from 'react-router-dom'
import { LoaderFunction, ActionFunction, defer } from 'react-router-typesafe'
import { ODataModelResponseV4 } from '@odata2ts/odata-core'
import { HttpResponseModel, ODataClientError } from '@odata2ts/http-client-api'
import { Role, Roles } from '@/auth/azure/Roles'
import { getAzureUserInformation } from '@/auth/azure/azureManager'

export const softwareQsRouteLoader = (async ({ params }: RouteFunctionParams<'clientId' | 'swQsId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service
  const clientId = params.clientId

  let azienda: HttpResponseModel<ODataModelResponseV4<azienda>> | undefined
  if (clientId)
    azienda = await qsAdminApi.azienda(Number(clientId)).query((builder, azienda) => {
      builder.select('id', 'nome')
    })
  let title = azienda ? `${azienda.data.nome} - ` : ``
  title += params.swQsId ? `Software Qs` : 'Nuova licenza'

  let getSwLicense: Editableqs_licenza | Promise<HttpResponseModel<ODataModelResponseV4<qs_licenza>>> = {
    rete: false,
    attenzione_speciale: false,
    hardware: false,
    ded_RootFam: '',
    ded_SubFam: '',
    ded_Dis: '',
    aggiornamenti: '',
    tipologia: '',
  }
  if (params.swQsId) {
    const currentSwId = +(params.swQsId as string)
    if (Number.isNaN(currentSwId)) throw new Error('Invalid swQsId')
    getSwLicense = qsAdminApi
      .qs_licenza(currentSwId)
      .query((builder, qqs_licenza) => {
        builder.expanding('sede', (sedeBuilder) => {
          sedeBuilder.expanding('azienda', (companyBuilder) => {
            companyBuilder.expand('sedi')
          })
        })
        builder.expanding('info_applicazioni', (infoAppsBuilder) => {
          infoAppsBuilder.expand('applicazione')
          infoAppsBuilder.expand('tipologia_licenza')
          infoAppsBuilder.expand('ultima_versione')
        })
        builder.expanding('storico', (historyBuilder) => {
          historyBuilder.expand('versione')
          historyBuilder.expanding('sede', (hSiteBuilder) => {
            hSiteBuilder.expand('azienda')
          })
          historyBuilder.orderBy(qqs_storico_licenza.data.desc())
        })
        builder.expand('versione_uso')
        builder.expand('stato_licenza')
      })
      .catch((error: ODataClientError) => {
        if (error.status === 404) throw new Error('404 Not Found: Licenza non trovata')
        throw new Error()
      })

    if (clientId) {
      const swQsResp = await getSwLicense
      const swQs = swQsResp.data satisfies qs_licenza
      console.log('lic', swQs)
      if (swQs?.sede?.azienda && swQs?.sede?.azienda.id !== Number(clientId)) {
        throw new Error(
          `Il software ${swQs.chiave ?? swQs.id_chiave ?? swQs.id} appartiene al cliente ${swQs?.sede?.azienda.nome} e non al cliente corrente`,
        )
      }
    }
  }

  const getAziende = qsAdminApi.azienda().query((builder, qazienda) => {
    builder.orderBy(qazienda.nome.asc())
    builder.select('id', 'nome')
    builder.filter(qazienda.sedi.any())
    builder.filter(clientId ? qazienda.id.eq(Number(clientId)) : null)
  })

  const getStatiLicenza = qsAdminApi.stato_qs_licenza().query((builder, qstato_qs_licenza) => {
    builder.orderBy(qstato_qs_licenza.nome.asc())
  })

  const getVersioni = qsAdminApi.qs_versione().query((builder, qqs_versione) => {
    builder.filter(qqs_versione.minor.eq(null))
    builder.filter(qqs_versione.sub.eq(null))
    builder.orderBy(qqs_versione.major.desc())
  })

  const getTipiLicenza = qsAdminApi.tipologia_qs_licenza().query((builder, tipologia_qs_licenza) => {
    builder.select('id', 'nome')
    builder.orderBy(tipologia_qs_licenza.nome.asc())
  })

  const getApplicazioni = qsAdminApi.qs_applicazione().query((builder, applicazione) => {
    builder.select('id', 'nome')
    builder.orderBy(applicazione.nome.asc())
  })

  return defer({
    title,
    getSwLicense,
    getAziende,
    getStatiLicenza,
    getVersioni,
    getTipiLicenza,
    getApplicazioni,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor],
  })
}) satisfies LoaderFunction

export const SoftwareQsCreatorRoute = {
  path: 'new',
  element: <SoftwareQsEditor mode={FormMode.Create} />,
  loader: softwareQsRouteLoader,
  action: (async ({ request, params }: RouteFunctionParams) => {
    const swQsData = (await request.json()) as qs_licenza
    const res = await useQsAdminApiManager.getState().service.qs_licenza().create(swQsData)
    return redirect(`../${res.data.id}`)
  }) satisfies ActionFunction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export const SoftwareQsEditorRoute = {
  path: ':swQsId',
  element: <SoftwareQsEditor mode={FormMode.Edit} />,
  loader: softwareQsRouteLoader,
  action: (async ({ request, params }: RouteFunctionParams) => {
    console.log('SoftwareQsEditorRoute action fired')
    const swQsData = (await request.json()) as qs_licenza
    return await useQsAdminApiManager.getState().service.qs_licenza(swQsData.id).patch(swQsData)
  }) satisfies ActionFunction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
