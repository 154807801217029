import { TileRoleRoute } from '@/types'
import { Roles } from '@/auth/azure/Roles'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { ManagementGridFactory } from '@/routes/admin/components/ManagementGrid/ManagementGridFactory'
import React from 'react'
import { TasksPageRoute } from '@/routes/tasks/TasksPage.route'

export const TaskRoute: TileRoleRoute = {
  path: 'task',
  text: 'Attività',
  faIcon: faTasks,
  element: (
    <ManagementGridFactory
      entity={'stato_task'}
      referencesCollection={'tasks'}
      referencesTitle={'Attività'}
      displayField={'ded_Dis'}
      entityRoute={TasksPageRoute.path as string}
    />
  ),
  handle: {
    crumb: () => ({
      label: 'Attività',
      icon: faTasks,
    }),
  },
  mainMenuRoute: false,
  tile: true,
  allowedRoles: [Roles.GlobalAdministrator],
}
